import {useLocation, useNavigate} from "react-router-dom";
import {Input} from "../app/Registration";
import {Button} from "./Button";

export function SwitchBusiness() {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <form
            onSubmit={(e: any) => {
                e.preventDefault();
                navigate(`/cs/${e.target[0].value}`, {state: {callback: location.pathname}});
            }}
            style={{display: "flex", gap: "4px", margin: "8px"}}
        >
            <Input type="text" placeholder="Switch to business ID…" />
            <Button variant="secondary" type="submit">
                ✓
            </Button>
        </form>
    );
}
