import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {DeliveryRide} from "../api/apiClient";
import {formatShortDateFancy, formatTime, formatTimeInterval} from "../app/dateUtils";
import {formatMoney} from "../app/moneyUtils";
import {Button} from "../components/Button";
import {DetailRow} from "../components/DetailRow";
import {TrackingLink} from "../components/TrackingLink";
import closeIcon from "../img/ic_close.svg";
import currentLocationIcon from "../img/ic_current-location.svg";
import feedbackIcon from "../img/ic_feedback.svg";
import {DetailContent, DetailHeader, SectionTitle} from "../rides/layout";
import {formatDeliveryRideStatus, formatVehicleInfo} from "../rides/ridesModel";
import {CancelState} from "./DeliveryRideDetail";
import {DeliveryRideRecreate} from "./DeliveryRideRecreate";
import {useOpenDetail} from "./OpenDetailContext";
import {StopItem} from "./StopItem";

interface RideDetailViewProps {
    ride: DeliveryRide;
    cancelState: CancelState;
    onCancel: () => void;
    onStopItemClicked: (stopId: string) => void;
}

export function RideDetailView({
    ride,
    cancelState,
    onCancel,
    onStopItemClicked,
}: RideDetailViewProps) {
    const intl = useIntl();
    const openDetail = useOpenDetail();

    const formattedStatus = formatDeliveryRideStatus(ride.status, intl);
    const deliveryTime = getDeliveryTime(ride, intl);
    const finishedAt = getFinishedAt(ride, intl);

    const status = [formattedStatus, finishedAt].filter((a) => a).join(" ");

    function closeDetail() {
        openDetail(null);
    }

    function cancelButtonText(cancelState: CancelState): string {
        switch (cancelState) {
            case "LOADING":
                return intl.formatMessage({id: "canceling"});
            case "SUCCESS":
                return intl.formatMessage({id: "ride_cancelled"});
            default:
                return `${intl.formatMessage({id: "cancel"})} ${
                    ride?.stops.length - 1 //FIXME when multi-multi, introduce shipment count
                } ${intl.formatMessage({id: "shipment_plural"}, {count: ride?.stops.length - 1})}`;
        }
    }

    return (
        <div>
            <DetailHeader>
                {intl.formatMessage({id: "ride"})}{" "}
                <Button variant="plain" onClick={closeDetail}>
                    <img src={closeIcon} alt="Close detail" />
                </Button>
            </DetailHeader>
            <DetailContent>
                {/*FIXME, support for pickup time requests */}
                {deliveryTime && (
                    <DetailRow title={intl.formatMessage({id: "delivery_time"})}>
                        {deliveryTime}
                    </DetailRow>
                )}
                <DetailRow title={intl.formatMessage({id: "status"})}>{status}</DetailRow>
                {ride.ridePrice?.estimatedPrice && (
                    <DetailRow
                        title={
                            ride.ridePrice.fixedPrice
                                ? intl.formatMessage({id: "fixed_price"})
                                : intl.formatMessage({id: "estimated_price"})
                        }
                    >
                        {formatMoney(ride.ridePrice.estimatedPrice, intl)}
                    </DetailRow>
                )}
                {(ride.driver || ride.vehicle) && <hr />}
                {ride.driver && (
                    <DetailRow title={intl.formatMessage({id: "driver"})}>
                        {ride.driver.name}
                    </DetailRow>
                )}
                {ride.driver && (
                    <DetailRow title={intl.formatMessage({id: "phone_number"})}>
                        {ride.driver.phoneNumber}
                    </DetailRow>
                )}
                {ride.vehicle && (
                    <DetailRow title={intl.formatMessage({id: "vehicle"})}>
                        {formatVehicleInfo(ride.vehicle, intl)}
                    </DetailRow>
                )}
                {ride.shareUrl &&
                    (ride.statusGroup === "IN_PROGRESS" ? (
                        <TrackingLink url={ride.shareUrl} imgSrc={currentLocationIcon}>
                            <FormattedMessage id="driver_location" />
                        </TrackingLink>
                    ) : (
                        <TrackingLink url={ride.shareUrl} imgSrc={feedbackIcon}>
                            <FormattedMessage id="rate_the_delivery" />
                        </TrackingLink>
                    ))}
                <SectionTitle>{intl.formatMessage({id: "stops"})}</SectionTitle>
                {ride?.stops.map((stop) => (
                    <StopItem
                        key={stop.id}
                        stop={stop}
                        onItemClicked={() => onStopItemClicked(stop.id)}
                    />
                ))}
                {ride.order.cancellable && (
                    <>
                        <Button
                            variant="destructive"
                            style={{marginTop: "16px"}}
                            onClick={() => onCancel()}
                            disabled={cancelState === "LOADING" || cancelState === "SUCCESS"}
                        >
                            {cancelButtonText(cancelState)}
                        </Button>
                        {cancelState === "FAILED" && (
                            <div>{intl.formatMessage({id: "cancel_failed"})}</div>
                        )}
                    </>
                )}
                <br />
                <DeliveryRideRecreate ride={ride} />
            </DetailContent>
        </div>
    );
}

function getDeliveryTime(ride: DeliveryRide, intl: IntlShape): string | null {
    const deliveryTime = ride.order.deliveryTime;
    if (ride.statusGroup === "COMPLETED") {
        return deliveryTime ? formatTimeInterval(deliveryTime) : null;
    }
    return deliveryTime ? formatTimeInterval(deliveryTime) : intl.formatMessage({id: "today_asap"});
}

function getFinishedAt(ride: DeliveryRide, intl: IntlShape): string | null {
    if (!ride.finishedAt) {
        return null;
    }
    const finishedAt = new Date(ride.finishedAt);
    return `${formatShortDateFancy(intl, finishedAt)} ${formatTime(finishedAt)}`;
}
