import {StopDefinition} from "../api/apiClient";
import {Pickup} from "../taxi/TaxiOrderForm";
import {LocationSuggestionWithId} from "../types/types";
import {clearSpaces} from "../utils/formatString";

export interface PickupWithLocation extends Pickup {
    location: LocationSuggestionWithId;
}

export const taxiStopsPayload = (
    pickups: Pickup[],
    dropoffs: (LocationSuggestionWithId | undefined)[]
): StopDefinition[] => {
    const pickupsPayload: StopDefinition[] = pickups
        .filter((pickup): pickup is PickupWithLocation => pickup.location != null)
        .map((pickup) => {
            return {
                location: pickup.location.location,
                contact: {
                    name: pickup.name,
                    phoneNumber: clearSpaces(pickup.phoneNumber),
                },
                type: "PICKUP",
                noteForDriver: pickup.noteForDriver,
                suggestSessionId: pickup.location?.suggestSessionId,
            };
        });
    const dropoffsPayload: StopDefinition[] = dropoffs
        .filter((dropoff): dropoff is LocationSuggestionWithId => dropoff != null)
        .map((dropoff) => {
            return {
                location: dropoff?.location,
                type: "DROP_OFF",
                suggestSessionId: dropoff?.suggestSessionId,
            };
        });

    return [...pickupsPayload, ...dropoffsPayload];
};
