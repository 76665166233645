import {zodResolver} from "@hookform/resolvers/zod";
import {isValidPhoneNumber} from "libphonenumber-js";
import {ChangeEvent, useState} from "react";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import * as z from "zod";

import {Alert} from "react-bootstrap";
import {
    UpdateBusinessCompanyRequest,
    companyLookup,
    getBusinessCompany,
    updateBusinessCompany,
} from "../api/apiClient";
import {Button} from "../components/Button";
import {countries, emailRegex} from "../constants/constants";
import {clearSpaces} from "../utils/formatString";
import {BasicPageWrapper} from "./Dashboard";
import {HomepageLink} from "./HomepageLink";
import {Error, Input, Label, Select} from "./Registration";

const requiredString = z.string().trim().min(1, {message: "required"});

const schema = z
    .object({
        displayName: requiredString,
        mainContactName: requiredString,
        mainContactPhoneNumber: z
            .string()
            .optional()
            .refine((value) => (value ? isValidPhoneNumber(value) : true), {
                message: "invalid_phone_number",
            }),
        mainContactEmail: z
            .string()
            .trim()
            .min(3, {message: "required"})
            .regex(emailRegex, {message: "invalid_email"}),
        contactEmails: z.string().optional(),
        billingEmail: z.string().optional(),
        companyId: requiredString,
        companyName: requiredString,
        taxId: z
            .string()
            .regex(/^[A-Z]{2}[0-9A-Z]+$/, "invalid_vat_id")
            .or(z.literal("")),
        paysVAT: z.boolean().optional(),
        street: requiredString,
        city: requiredString,
        zip: requiredString,
        country: z.string().min(2, {message: "required"}),
    })
    .refine((data) => (!data.paysVAT && !data.taxId) || data.taxId.match(/^[A-Z]{2}[0-9A-Z]+$/), {
        message: "required",
        path: ["taxId"],
    });

type CompanyDetailsFormValues = z.infer<typeof schema>;

function createPayload(values: CompanyDetailsFormValues): UpdateBusinessCompanyRequest {
    return {
        displayName: values.displayName.trim(),
        adminContact: {
            email: values.mainContactEmail?.trim(),
            name: values.mainContactName.trim(),
            phoneNumber: values.mainContactPhoneNumber
                ? clearSpaces(values.mainContactPhoneNumber)
                : undefined,
        },
        contactEmails: values.contactEmails?.split(",") || [],
        billingEmail: values.billingEmail?.trim() || undefined,
        companyId: values.companyId.trim(),
        companyName: values.companyName.trim(),
        taxId: values.taxId.trim(),
        paysVAT: values.paysVAT || false,
        billingAddress: {
            street: values.street,
            city: values.city,
            zip: values.zip,
            country: values.country,
        },
    };
}

async function createDefaultValues(): Promise<CompanyDetailsFormValues> {
    const data = await getBusinessCompany();

    return {
        displayName: data.displayName,
        mainContactName: data.adminContact.name || "",
        mainContactPhoneNumber: data.adminContact.phoneNumber || "",
        mainContactEmail: data.adminContact.email,
        contactEmails: data.contactEmails.toString(),
        billingEmail: data.billingEmail || "",
        companyId: data.companyId,
        companyName: data.companyName,
        taxId: data.taxId || "",
        paysVAT: data.paysVAT,
        street: data.billingAddress.street,
        city: data.billingAddress.city,
        zip: data.billingAddress.zip,
        country: data.billingAddress.country,
    };
}

export function CompanyDetails() {
    const [lookupLoading, setLookupLoading] = useState(false);

    const intl = useIntl();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        trigger,
        formState: {errors, isDirty},
    } = useForm<CompanyDetailsFormValues>({
        defaultValues: createDefaultValues,
        mode: "onTouched",
        resolver: zodResolver(schema),
        criteriaMode: "all",
    });

    async function onSubmit(data: CompanyDetailsFormValues) {
        try {
            await updateBusinessCompany(createPayload(data));
            toast.success(intl.formatMessage({id: "saved"}));
        } catch (e) {
            toast.error(intl.formatMessage({id: "something_went_wrong"}));
        }
    }

    const handleCompanyLookup = () => {
        if (!getValues("companyId")) {
            trigger("companyId");
            return;
        }
        setLookupLoading(true);
        const companyId = getValues("companyId");
        companyLookup(companyId)
            .then(({company, billingAddress}) => {
                setValue("companyName", company?.companyName || "");
                setValue("taxId", company?.taxId || "");
                setValue("paysVAT", company?.paysVAT || false);
                setValue("street", billingAddress?.street || "");
                setValue("city", billingAddress?.city || "");
                setValue("zip", billingAddress?.zip || "");
            })
            .catch((e) => console.log("err:", e))
            .finally(() => setLookupLoading(false));
    };

    function handlePaysVatChange(e: ChangeEvent<HTMLInputElement>) {
        setValue("paysVAT", e.target.checked, {shouldDirty: true});
        trigger("taxId");
    }

    return (
        <BasicPageWrapper>
            <HomepageLink />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Header>
                    <h1 style={{margin: 0}}>
                        <FormattedMessage id="account_details" />
                    </h1>
                    <Button data-cy="company-details-save-btn" type="submit" disabled={!isDirty}>
                        <FormattedMessage id="save" />
                    </Button>
                </Header>
                <Section>
                    <div>
                        <h2>
                            <FormattedMessage id="company_display_name" />
                        </h2>
                        <Subtitle>
                            <FormattedMessage id="company_display_name_description" />
                        </Subtitle>
                    </div>
                    <InputWrapper>
                        <Label htmlFor="displayName">
                            <FormattedMessage id="company_display_name" />
                        </Label>
                        <Input type="text" {...register("displayName")} />
                        {errors.displayName?.message && (
                            <Error>{intl.formatMessage({id: errors.displayName?.message})}</Error>
                        )}
                    </InputWrapper>
                </Section>
                <Section>
                    <div>
                        <h2>
                            <FormattedMessage id="main_contact" />
                        </h2>
                        <Subtitle>
                            <FormattedMessage id="main_contact_description" />
                        </Subtitle>
                    </div>
                    <Inputs>
                        <InputWrapper>
                            <Label htmlFor="mainContactName">
                                <FormattedMessage id="name" />
                            </Label>
                            <Input
                                type="text"
                                autoComplete="username"
                                {...register("mainContactName")}
                            />
                            {errors.mainContactName?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.mainContactName?.message})}
                                </Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="mainContactEmail">
                                <FormattedMessage id="email" />
                            </Label>
                            <Input
                                type="email"
                                autoComplete="username"
                                {...register("mainContactEmail")}
                            />
                            {errors.mainContactEmail?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.mainContactEmail?.message})}
                                </Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="mainContactPhoneNumber">
                                <FormattedMessage id="phone_number" />
                            </Label>
                            <Input type="tel" {...register("mainContactPhoneNumber")} />
                            {errors.mainContactPhoneNumber?.message && (
                                <Error>
                                    {intl.formatMessage({
                                        id: errors.mainContactPhoneNumber?.message,
                                    })}
                                </Error>
                            )}
                        </InputWrapper>
                    </Inputs>
                </Section>

                <Section>
                    <div>
                        <h2>
                            <FormattedMessage id="email_addresses" />
                        </h2>
                        <Subtitle>
                            <FormattedMessage id="email_addresses_description" />
                        </Subtitle>
                    </div>
                    <Inputs>
                        <InputWrapper>
                            <Label htmlFor="billingEmail">
                                <FormattedMessage id="billing_email" />
                            </Label>
                            <Input type="text" {...register("billingEmail")} />
                            {errors.billingEmail?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.billingEmail?.message})}
                                </Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="contactEmails">
                                <FormattedMessage id="news_email" />
                            </Label>
                            <Input type="text" {...register("contactEmails")} />
                            {errors.contactEmails?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.contactEmails?.message})}
                                </Error>
                            )}
                        </InputWrapper>
                    </Inputs>
                </Section>
                <Section>
                    <div>
                        <h2>
                            <FormattedMessage id="billing_details" />
                        </h2>
                        <Subtitle>
                            <FormattedMessage id="billing_details_description" />
                        </Subtitle>
                    </div>
                    <Inputs>
                        <InputWrapper>
                            <Label htmlFor="companyId">
                                <FormattedMessage id="tax_id" />
                            </Label>
                            <div style={{display: "flex", gap: "4px", width: "100%"}}>
                                <Input
                                    style={{flexGrow: "1"}}
                                    type="text"
                                    autoComplete="nope"
                                    {...register("companyId")}
                                />
                                <Button
                                    variant="secondary"
                                    onClick={handleCompanyLookup}
                                    disabled={lookupLoading}
                                >
                                    <FormattedMessage id="load_details" />
                                </Button>
                            </div>
                            {errors.companyId?.message && (
                                <Error>{intl.formatMessage({id: errors.companyId?.message})}</Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="companyName">
                                <FormattedMessage id="company_name" />
                            </Label>
                            <Input type="text" autoComplete="nope" {...register("companyName")} />
                            {errors.companyName?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.companyName?.message})}
                                </Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="taxId">
                                <FormattedMessage id="vat_id" />
                            </Label>
                            <Input type="text" {...register("taxId")} />
                            {errors.taxId?.message && (
                                <Error>{intl.formatMessage({id: errors.taxId?.message})}</Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="paysVAT">
                                <Input
                                    data-cy="company-details-vat-payer-checkbox"
                                    type="checkbox"
                                    {...register("paysVAT")}
                                    id="paysVAT"
                                    onChange={handlePaysVatChange}
                                />
                                <FormattedMessage id="vat_payer" />
                            </Label>
                            {watch("paysVAT") === false && (
                                <Alert
                                    data-cy="company-details-vat-payer-alert-warning"
                                    variant="warning"
                                >
                                    <FormattedMessage
                                        id="vat_payer_warning"
                                        values={{
                                            a: (name: string) => (
                                                <a
                                                    href={intl.formatMessage({
                                                        id: "mobile_app_url",
                                                    })}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {name}
                                                </a>
                                            ),
                                        }}
                                    />
                                </Alert>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="street">
                                <FormattedMessage id="street" />
                            </Label>
                            <Input type="text" autoComplete="nope" {...register("street")} />
                            {errors.street?.message && (
                                <Error>{intl.formatMessage({id: errors.street?.message})}</Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="city">
                                <FormattedMessage id="city" />
                            </Label>
                            <Input type="text" autoComplete="nope" {...register("city")} />
                            {errors.city?.message && (
                                <Error>{intl.formatMessage({id: errors.city?.message})}</Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="zip">
                                <FormattedMessage id="zip_code" />
                            </Label>
                            <Input type="text" autoComplete="nope" {...register("zip")} />
                            {errors.zip?.message && (
                                <Error>{intl.formatMessage({id: errors.zip?.message})}</Error>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Label htmlFor="country">
                                <FormattedMessage id="country" />
                            </Label>
                            <Select autoComplete="nope" {...register("country")} disabled>
                                {countries.map((code) => (
                                    <option value={code} key={code}>
                                        {intl.formatMessage({id: code})}
                                    </option>
                                ))}
                            </Select>
                            {errors.country?.message && (
                                <Error>{intl.formatMessage({id: errors.country?.message})}</Error>
                            )}
                        </InputWrapper>
                    </Inputs>
                </Section>
            </form>
        </BasicPageWrapper>
    );
}

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    position: sticky;
    top: 0;
    padding: 16px 0;
`;

const Section = styled.div`
    display: flex;
    gap: 24px;
    border-top: 1px solid var(--divider);
    padding: 24px 0;
    flex-wrap: wrap;
    > * {
        flex: 1;
    }
`;

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Subtitle = styled.p`
    font-weight: var(--font-weight-thin);
    color: var(--secondary);
    line-height: 1.4;
    margin-top: 8px;
`;
