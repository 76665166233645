import {useEffect} from "react";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";
import {useLocation, useNavigate} from "react-router-dom";

export function useCardStatusToast() {
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const success = query.get("success");

        if (success === "true") {
            toast.success(intl.formatMessage({id: "card_added_successfully"}));
        }

        if (success === "false") {
            toast.error(intl.formatMessage({id: "failed_to_add_card"}));
        }

        if (success) {
            query.delete("success");
            navigate(
                {
                    search: query.toString(),
                },
                {replace: true}
            );
        }
    }, [location]);
}
