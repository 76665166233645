import {FormattedMessage} from "react-intl";

export const getOrderFailedMessage = (code: string | undefined) => {
    switch (code?.toLowerCase()) {
        case "business_disabled":
            return (
                <FormattedMessage
                    id="company_disabled"
                    values={{
                        a: (link: string) => <a href={`mailto:${link}`}>{link}</a>,
                    }}
                />
            );
        case "phone_number_validation_exception":
            return <FormattedMessage id="order_failed_phone_number_validation_exception" />;
        case "validation_exception":
            return <FormattedMessage id="order_failed_validation_exception" />;
        case "location_validation_exception":
            return <FormattedMessage id="order_failed_location_validation_exception" />;
        case "incompatible_state_exception":
            return <FormattedMessage id="order_failed_incompatible_state_exception" />;
        case "project_code_required":
            return <FormattedMessage id="order_failed_project_code_required" />;
        case "legs_cant_be_delivered_on_time":
            return <FormattedMessage id="order_failed_legs_cant_be_delivered_on_time" />;
        case "legs_cant_be_delivered_in_limit":
            return <FormattedMessage id="order_failed_legs_cant_be_delivered_in_limit" />;
        case "too_many_stops":
            return <FormattedMessage id="order_failed_too_many_stops" />;
        case "pickup_and_drop_off_at_the_same_location":
            return <FormattedMessage id="order_failed_pickup_and_drop_off_at_the_same_location" />;
        case "pre_order_sooner_than_allowed":
            return <FormattedMessage id="order_failed_pre_order_sooner_than_allowed" />;
        case "pre_order_later_than_allowed":
            return <FormattedMessage id="order_failed_pre_order_later_than_allowed" />;
        case "invalid_time_interval":
            return <FormattedMessage id="order_failed_invalid_time_interval" />;
        case "pre_order_in_past":
            return <FormattedMessage id="order_failed_pre_order_in_past" />;
        case "ride_distance_longer_than_allowed":
            return <FormattedMessage id="order_failed_ride_distance_longer_than_allowed" />;
        case "unsupported_region":
            return <FormattedMessage id="order_failed_unsupported_region" />;
        case "driver_not_found":
            return <FormattedMessage id="order_failed_driver_not_found" />;
        case "invalid_courier_price_token":
            return <FormattedMessage id="order_failed_invalid_price_token" />;
        case "invalid_price_token":
            return <FormattedMessage id="order_failed_invalid_price_token" />;
        case "flight_number_not_found":
            return <FormattedMessage id="order_failed_flight_number_not_found" />;
        case "flight_not_in_future":
            return <FormattedMessage id="order_failed_flight_not_in_future" />;
        default:
            return <FormattedMessage id="order_failed_generic" />;
    }
};
