import styled from "styled-components";

export function CountBadge({count}: {count: number}) {
    return count > 0 ? <Count key={count}>{count}</Count> : null;
}

const Count = styled.span`
    display: flex;
    padding: 0px 8px;
    min-width: 28px;
    background-color: var(--blue-light);
    color: var(--dark);
    border-radius: 100px;
    font-size: 11px;
    text-align: center;
    font-weight: 700;
    margin-left: 8px;
    line-height: 100%;
    justify-content: center;
    align-items: center;
    animation: pulse ease-in-out 500ms;
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
`;
