import {useState} from "react";
import {Alert} from "react-bootstrap";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {
    StopDefinition,
    TaxiStop,
    usePreOrderAvailability,
    useUpdatePreorder,
} from "../api/apiClient";
import {Input, Label} from "../app/Registration";
import {Button} from "../components/Button";
import {DateTimeSelect} from "./DateTimeSelect";
import {useAvailableTimes} from "./taxiPreorderModel";

function getPreorderAvailabilityPayload(taxiStops: TaxiStop[]): StopDefinition[] {
    const stopDefinitions: StopDefinition[] = taxiStops.map((taxiStop, index) => {
        if (index === 0) {
            return {
                location: taxiStop.location,
                type: "PICKUP",
            };
        } else {
            return {
                location: taxiStop.location,
                type: "DROP_OFF",
            };
        }
    });

    return stopDefinitions;
}

function getFailedMessage(code?: string) {
    switch (code?.toLowerCase()) {
        case "pre_order_in_past":
            return <FormattedMessage id="pre_order_in_past" />;
        case "pre_order_not_updatable":
            return <FormattedMessage id="pre_order_not_updatable" />;
        case "pre_order_sooner_than_allowed":
            return <FormattedMessage id="pre_order_sooner_than_allowed" />;
        case "pre_order_later_than_allowed":
            return <FormattedMessage id="pre_order_later_than_allowed" />;
        default:
            return <FormattedMessage id="generic_error" />;
    }
}

export function ChangeTime({
    rideId,
    stops,
    originalTime,
    flightNumber: fn,
}: {
    rideId: string;
    stops: TaxiStop[];
    originalTime: string;
    flightNumber?: string;
}) {
    const intl = useIntl();
    const [show, setShow] = useState(false);
    const [time, setTime] = useState<Date>(new Date(originalTime));
    const [flightNumber, setFlightNumber] = useState<string | undefined>(fn);
    const update = useUpdatePreorder();
    const availability = usePreOrderAvailability();
    const availableTimes = useAvailableTimes({
        from: availability?.data?.earliestAllowedPreOrder,
        to: availability?.data?.latestAllowedPreOrder,
        time,
    });

    const handleShow = () => {
        if (availability.data == null) {
            availability.mutate(
                {stops: getPreorderAvailabilityPayload(stops)},
                {
                    onSuccess: () => {
                        setShow(true);
                    },
                    onError: () => {
                        toast.error(intl.formatMessage({id: "general_error"}));
                    },
                }
            );
        } else {
            setShow(true);
        }
    };

    const handleClose = () => {
        setShow(false);
        setTime(new Date(originalTime));
        setFlightNumber(fn);
        update.reset();
    };

    const handleTimeChange = (date: Date) => {
        setTime(date);
        update.reset();
    };

    const handleFlightNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFlightNumber(e.target.value);
        update.reset();
    };

    const handlePreorderUpdate = () => {
        update.mutate(
            {body: {requestedPickupAt: time.toISOString(), flightNumber}, rideId},
            {
                onSuccess: () => {
                    setShow(false);
                    toast.success(intl.formatMessage({id: "pickup_time_changed"}));
                },
            }
        );
    };

    if (!show) {
        return (
            <Button
                data-cy="preorder-change-pickup-time-btn"
                variant="secondary"
                fullWidth
                onClick={handleShow}
                loading={availability.isLoading}
            >
                {flightNumber ? (
                    <FormattedMessage id="change_pickup_or_flight_number" />
                ) : (
                    <FormattedMessage id="change_pickup_time" />
                )}
            </Button>
        );
    }

    return (
        <Wrapper>
            <div>
                <FormattedMessage id="set_pickup_time" />
            </div>
            <div style={{display: "flex", flexWrap: "nowrap", gap: "8px"}}>
                <DateTimeSelect
                    time={time}
                    availableTimes={availableTimes}
                    setTime={handleTimeChange}
                    disabled={update.isLoading}
                />
            </div>
            {fn && (
                <Label>
                    <FormattedMessage id="flight_number" />
                    <Input
                        type="text"
                        style={{width: "100%"}}
                        defaultValue={fn}
                        onChange={handleFlightNumberChange}
                        value={flightNumber}
                        placeholder={intl.formatMessage({
                            id: "flight_number",
                        })}
                        disabled={update.isLoading}
                    />
                </Label>
            )}

            {update.isError && (
                <Alert variant="danger">
                    {getFailedMessage((update.error as any).response.data.code)}
                </Alert>
            )}
            <div style={{display: "flex", flexWrap: "nowrap", gap: "8px"}}>
                <Button
                    variant="outline"
                    onClick={handleClose}
                    fullWidth
                    disabled={update.isLoading}
                >
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    data-cy="change-time-save-btn"
                    onClick={handlePreorderUpdate}
                    loading={update.isLoading}
                    fullWidth
                >
                    <FormattedMessage id="save" />
                </Button>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    --bleed: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: 8px;
    width: calc(100% + var(--bleed) + var(--bleed));
    margin-left: calc(var(--bleed) * -1);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
    border: 0.5px solid var(--border);
`;
