import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {isValidPhoneNumber} from "libphonenumber-js";
import {debounce} from "lodash";
import posthog from "posthog-js";
import {ChangeEvent, useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import * as z from "zod";
import {
    CompanyLookupResponse,
    PtyCreateBusinessRequest,
    checkEmail,
    companyLookup,
    isActive,
    sendFeedback,
} from "../api/apiClient";
import {createAccount, logout, useUserInfo} from "../auth/authModel";
import {Button} from "../components/Button";
import {PhoneInputReactHookForm} from "../components/PhoneInputReactHookForm";
import {countries, emailRegex} from "../constants/constants";
import logo from "../img/logo.svg";
import {clearSpaces} from "../utils/formatString";
import {getSlackHandle} from "../utils/getSlackHandle";
import {logGtm} from "../utils/gtm";
import {RegisterMarketing} from "./RegisterMarketing";
import {ServicePhoneNumber} from "./ServicePhoneNumber";

declare var appConfig: any;

const requiredString = z.string().trim().min(1, {message: "required"});

const schema = z
    .object({
        email: requiredString.regex(emailRegex, {message: "invalid_email"}).refine(
            //FIXME: debounce, in case of field refocus, validation is onChange
            async (value) => {
                let exists: boolean = false;
                if (value) {
                    posthog.capture("emailCheck", {email: value});
                    const data = await checkEmail(value);
                    exists = data.exists;
                    if (exists) {
                        posthog.capture("emailAlreadyExistsRegistration", {email: value});
                    }
                }
                return !exists;
            },
            {message: "email_already_taken"}
        ),
        password: requiredString.min(8, {message: "password_min_characters"}),
        name: requiredString,
        phoneNumber: requiredString.refine(
            (value) => {
                if (value.length === 13) {
                    posthog.capture("phoneNumberEntered", {phoneNumber: value});
                }
                return isValidPhoneNumber(value.replace(/[^\x20-\x7F]/g, ""));
            },
            {
                message: "invalid_phone_number",
            }
        ),
        companyId: requiredString,
        companyName: requiredString,
        taxId: z
            .string()
            .regex(/^[A-Z]{2}[0-9A-Z]+$/, "invalid_vat_id")
            .or(z.literal("")),
        paysVAT: z.boolean(),
        street: requiredString,
        city: requiredString,
        zip: requiredString,
        country: z.string().min(2, {message: "required"}),
    })
    .refine((data) => (!data.paysVAT && !data.taxId) || data.taxId.match(/^[A-Z]{2}[0-9A-Z]+$/), {
        message: "required",
        path: ["taxId"],
    });

type RegistrationFormValues = z.infer<typeof schema>;

function createPayload(values: RegistrationFormValues): PtyCreateBusinessRequest {
    return {
        admin: {
            email: values.email.trim(),
            name: values.name.trim(),
            phoneNumber: clearSpaces(values.phoneNumber),
            authService: "PWD",
            secret: values.password,
        },
        company: {
            companyId: values.companyId.trim(),
            companyName: values.companyName.trim(),
            displayName: values.companyName.trim(),
            taxId: values.taxId ? values.taxId.trim() : undefined,
            paysVAT: values.paysVAT || false,
        },
        billingAddress: {
            street: values.street.trim(),
            city: values.city.trim(),
            zip: values.zip.trim(),
            country: values.country.trim(),
        },
    };
}

export function Registration() {
    const {data: user, isLoading} = useUserInfo();
    const intl = useIntl();
    const [billingDisplay, setBillingDisplay] = useState<"readonly" | "inputs" | "">();
    const [lookupFailed, setLookupFailed] = useState(false);
    const [lookupLoading, setLookupLoading] = useState(false);
    const [companyAlreadyActive, setCompanyAlreadyActive] = useState(false);
    const navigate = useNavigate();
    const registerCompany = useMutation(createAccount);
    const {search} = useLocation();
    const emailParam = new URLSearchParams(search).get("your-email");
    const nameParam = new URLSearchParams(search).get("your-contact-person");
    const phoneNumberParam = new URLSearchParams(search).get("your-phone");
    const salesOwnerId = new URLSearchParams(search).get("r");
    const lftSegment = new URLSearchParams(search).get("lft_segment");
    const utmSource = new URLSearchParams(search).get("utm_source");
    const utmMedium = new URLSearchParams(search).get("utm_medium");
    //FIXME: 29.11. CS(JirkaH) should allow to ship this. Remove conditional code then
    const showVersionWithPhone = false;

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        trigger,
        control,
        formState: {errors, isDirty, isSubmitting},
    } = useForm<RegistrationFormValues>({
        mode: "onTouched",
        resolver: zodResolver(schema),
        criteriaMode: "all",
        defaultValues: {
            paysVAT: true,
            country: "CZ",
            email: emailParam || "",
            name: nameParam || "",
            phoneNumber: phoneNumberParam || "",
            password: "",
        },
    });

    useEffect(() => {
        if (isDirty) {
            posthog.capture("registrationInputsStarted");
        }
    }, [isDirty]);

    async function onSubmit(data: RegistrationFormValues) {
        registerCompany.mutate(createPayload(data), {
            onSuccess: () => {
                posthog.capture("registrationSuccessful");
                logGtm("registration");
                navigate("/onboarding/payment", {replace: true});
                sendFeedback({
                    text: `🆕 Company "${data.companyName}" just registred business account (${
                        salesOwnerId ? `Owner: ${getSlackHandle(salesOwnerId)}` : "self-served"
                    })${
                        lftSegment
                            ? ` 🔗 via ${lftSegment}`
                            : utmSource
                            ? ` 🔗 via ${utmSource} (${utmMedium})`
                            : ``
                    }${
                        companyAlreadyActive
                            ? ", ⚠️ this company has already another active account(s)"
                            : ""
                    }`,
                    currentUrl: "registration",
                    type: "AUTOMATED",
                });
            },
            onError: (error) => {
                if (axios.isAxiosError(error)) {
                    // Should not happen, already handled in form validation
                    if (error?.response?.data.code === "email_already_exists") {
                        alert(
                            intl.formatMessage(
                                {id: "email_is_already_admin_in_existing_account"},
                                {email: data.email}
                            )
                        );
                        return;
                    }
                }
                alert(intl.formatMessage({id: "registration_failed"}));
            },
        });
    }

    function isCompanyComplete({company, billingAddress, found}: CompanyLookupResponse) {
        if (!found || !company || !billingAddress) {
            return false;
        }
        const {companyName, taxId, paysVAT} = company;
        const {street, city, zip} = billingAddress;
        if (companyName && street && city && zip && ((taxId && paysVAT) || (!taxId && !paysVAT))) {
            return true;
        }
        return false;
    }

    function handleCompanyLookup() {
        if (billingDisplay === "inputs") {
            return;
        }
        setLookupLoading(true);
        const companyId = getValues("companyId");
        isActive(companyId)
            .then(({active}) => {
                setCompanyAlreadyActive(active);
                if (active) {
                    posthog.capture("alreadyActiveCompanyIdEnteredInRegistration", {
                        nameForm: getValues("name"),
                        emailForm: getValues("email"),
                        phoneNumberForm: getValues("phoneNumber"),
                        companyIdForm: companyId,
                    });
                }
            })
            .catch(() => setCompanyAlreadyActive(false));
        companyLookup(companyId)
            .then(({found, company, billingAddress}) => {
                if (!found) {
                    setValue("companyName", "");
                    setValue("taxId", "");
                    setValue("paysVAT", true);
                    setValue("street", "");
                    setValue("city", "");
                    setValue("zip", "");
                    setValue("country", "CZ");
                    setLookupFailed(true);
                    return;
                }
                setLookupFailed(false);
                setValue("companyName", company?.companyName || "");
                setValue("taxId", company?.taxId || "");
                setValue("paysVAT", company?.paysVAT || false);
                setValue("street", billingAddress?.street || "");
                setValue("city", billingAddress?.city || "");
                setValue("zip", billingAddress?.zip || "");
                setValue("country", "CZ");
                if (!isCompanyComplete({company, billingAddress, found})) {
                    setBillingDisplay("inputs");
                } else {
                    setBillingDisplay("readonly");
                }
            })
            .catch((e) => console.log("err:", e))
            .finally(() => {
                setLookupLoading(false);
                trigger("companyId");
            });
    }

    function handleCompanyIdChange(e: ChangeEvent<HTMLInputElement>) {
        setValue("companyId", e.target.value.replaceAll(" ", ""));
        if (e.target.value.trim().length === 0) {
            setLookupFailed(false);
            return;
        }
        debouncedCompanyLookup();
    }

    function handlePaysVatChange(e: ChangeEvent<HTMLInputElement>) {
        setValue("paysVAT", e.target.checked, {shouldDirty: true});
        trigger("taxId");
    }
    const debouncedCompanyLookup = debounce(handleCompanyLookup, 1000);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const nameForm = getValues("name");
            const emailForm = getValues("email");
            const phoneNumberForm = getValues("phoneNumber");
            const companyIdForm = getValues("companyId");
            if (nameForm || emailForm || phoneNumberForm || companyIdForm) {
                posthog.capture("registrationAbandoned", {
                    nameForm,
                    emailForm,
                    phoneNumberForm,
                    companyIdForm,
                });

                //Show a warning dialog as usual when dirty form
                event.preventDefault();
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    if (isLoading) {
        return null;
    }

    if (user == null) {
        return (
            <Page>
                <Form>
                    <Header>
                        <Logo width="80px" src={logo} alt="Liftago" />
                        {showVersionWithPhone ? (
                            <ServicePhoneNumber />
                        ) : (
                            <LoginLink>
                                <FormattedMessage id="are_you_a_driver" />{" "}
                                <a
                                    href={`${appConfig.driverRegistrationUrl}/v2`} //FIXME: remove v2 when becomes default
                                    style={{textDecoration: "underline"}}
                                >
                                    <FormattedMessage id="register_as_driver" />
                                </a>
                            </LoginLink>
                        )}
                    </Header>
                    <Wrapper style={{maxWidth: "340px"}}>
                        <Title>
                            <FormattedMessage id="create_business_account" />
                        </Title>
                        <Subtitle>
                            <FormattedMessage id="create_business_account_subtitle" />
                            <div>
                                <FormattedMessage id="already_have_an_account" />{" "}
                                <Link to="/login" replace style={{textDecoration: "underline"}}>
                                    <FormattedMessage id="login" />{" "}
                                </Link>
                            </div>
                        </Subtitle>
                        <div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                style={{display: "flex", flexDirection: "column"}}
                            >
                                <Label htmlFor="email">
                                    <FormattedMessage id="email" />
                                </Label>
                                <Input
                                    type="email"
                                    autoComplete="username"
                                    {...register("email")}
                                />
                                {errors.email?.message ? (
                                    <Error>
                                        {intl.formatMessage({id: errors.email.message})}
                                        {errors.email.message === "email_already_taken" && (
                                            <>
                                                {" "}
                                                <FormattedMessage id="or" />{" "}
                                                <Link
                                                    to="/login"
                                                    state={{email: getValues("email")}}
                                                    style={{textDecoration: "underline"}}
                                                >
                                                    <FormattedMessage id="login" />
                                                </Link>
                                            </>
                                        )}
                                    </Error>
                                ) : (
                                    <Hint>
                                        <FormattedMessage id="access_to_others" />
                                    </Hint>
                                )}
                                <Label htmlFor="password">
                                    <FormattedMessage id="password" />
                                </Label>
                                <Input
                                    type="password"
                                    autoComplete="new-password"
                                    {...register("password")}
                                />
                                {errors.password?.message ? (
                                    <Error>
                                        {intl.formatMessage({id: errors.password.message})}
                                    </Error>
                                ) : (
                                    watch("password")?.length < 8 && (
                                        <Hint>
                                            <FormattedMessage id="min_8_chars" />
                                        </Hint>
                                    )
                                )}
                                <Label htmlFor="name">
                                    <FormattedMessage id="your_name" />
                                </Label>
                                <Input type="text" {...register("name")} />
                                {errors.name?.message && (
                                    <Error>{intl.formatMessage({id: errors.name.message})}</Error>
                                )}
                                <Label htmlFor="phoneNumber">
                                    <FormattedMessage id="phone_number" />
                                    <PhoneInputReactHookForm control={control} name="phoneNumber" />
                                </Label>
                                {errors.phoneNumber?.message && (
                                    <Error>
                                        {intl.formatMessage({id: errors.phoneNumber.message})}
                                    </Error>
                                )}
                                <SectionTitle>
                                    <FormattedMessage id="billing_details" />
                                </SectionTitle>
                                <Label htmlFor="companyId">
                                    <FormattedMessage id="tax_id" />{" "}
                                    {lookupLoading && <div className="primary-spinner" />}
                                </Label>
                                <Input
                                    style={{flexGrow: "1"}}
                                    type="text"
                                    autoComplete="nope"
                                    {...register("companyId")}
                                    onChange={handleCompanyIdChange}
                                />
                                {errors.companyId?.message && !lookupFailed && (
                                    <Error>
                                        {intl.formatMessage({id: errors.companyId.message})}
                                    </Error>
                                )}
                                {lookupFailed && billingDisplay !== "inputs" && (
                                    <>
                                        <Error>
                                            <FormattedMessage id="tax_id_not_found" />
                                        </Error>
                                        <Button
                                            data-cy="registration-use-anyway-btn"
                                            variant="secondary"
                                            onClick={() => setBillingDisplay("inputs")}
                                        >
                                            <FormattedMessage id="use_anyway" />
                                        </Button>
                                    </>
                                )}
                                {!lookupFailed && billingDisplay === "readonly" && (
                                    <>
                                        <div
                                            style={{display: "flex", gap: "4px", marginTop: "8px"}}
                                        >
                                            <div
                                                style={{
                                                    flexGrow: "1",
                                                    fontSize: "var(--font-size-text)",
                                                }}
                                            >
                                                <p style={{fontWeight: "var(--font-weight-normal"}}>
                                                    {getValues("companyName")}
                                                </p>
                                                <p>
                                                    {getValues("taxId")}{" "}
                                                    {getValues("paysVAT") ? (
                                                        <FormattedMessage id="vat_payer" />
                                                    ) : (
                                                        <FormattedMessage id="vat_non_payer" />
                                                    )}
                                                </p>
                                                <p>{watch(["street", "city", "zip"]).join(", ")}</p>
                                                <p>{intl.formatMessage({id: watch("country")})}</p>
                                            </div>

                                            <Button
                                                variant="link"
                                                onClick={() => setBillingDisplay("inputs")}
                                            >
                                                <FormattedMessage id="edit" />
                                            </Button>
                                        </div>
                                        {!watch("paysVAT") && (
                                            <Alert variant="warning" style={{marginTop: "16px"}}>
                                                <FormattedMessage
                                                    id="vat_payer_warning"
                                                    values={{
                                                        a: (name: string) => (
                                                            <a
                                                                href={intl.formatMessage({
                                                                    id: "mobile_app_url",
                                                                })}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {name}
                                                            </a>
                                                        ),
                                                    }}
                                                />
                                            </Alert>
                                        )}
                                        {companyAlreadyActive && (
                                            <Alert variant="warning" style={{marginTop: "16px"}}>
                                                <FormattedMessage id="tax_id_already_registred" />
                                            </Alert>
                                        )}
                                    </>
                                )}
                                {billingDisplay === "inputs" && (
                                    <>
                                        <Label htmlFor="companyName">
                                            <FormattedMessage id="company_name" />
                                        </Label>
                                        <Input
                                            type="text"
                                            autoComplete="nope"
                                            {...register("companyName")}
                                        />
                                        {errors.companyName?.message && (
                                            <Error>
                                                {intl.formatMessage({
                                                    id: errors.companyName.message,
                                                })}
                                            </Error>
                                        )}
                                        <Label htmlFor="taxId">
                                            <FormattedMessage id="vat_id" />
                                        </Label>
                                        <Input
                                            type="text"
                                            {...register("taxId", {
                                                required: true,
                                            })}
                                        />
                                        {errors.taxId?.message && (
                                            <Error>
                                                {intl.formatMessage({id: errors.taxId.message})}
                                            </Error>
                                        )}
                                        <Label htmlFor="paysVAT">
                                            <Input
                                                type="checkbox"
                                                {...register("paysVAT")}
                                                id="paysVAT"
                                                onChange={handlePaysVatChange}
                                            />
                                            <FormattedMessage id="vat_payer" />
                                        </Label>
                                        {watch("paysVAT") === false && (
                                            <Alert variant="warning">
                                                <FormattedMessage
                                                    id="vat_payer_warning"
                                                    values={{
                                                        a: (name: string) => (
                                                            <a
                                                                href={intl.formatMessage({
                                                                    id: "mobile_app_url",
                                                                })}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {name}
                                                            </a>
                                                        ),
                                                    }}
                                                />
                                            </Alert>
                                        )}
                                        <Label htmlFor="street">
                                            <FormattedMessage id="street" />
                                        </Label>
                                        <Input
                                            type="text"
                                            autoComplete="nope"
                                            {...register("street")}
                                        />
                                        {errors.street?.message && (
                                            <Error>
                                                {intl.formatMessage({id: errors.street.message})}
                                            </Error>
                                        )}
                                        <Label htmlFor="city">
                                            <FormattedMessage id="city" />
                                        </Label>
                                        <Input
                                            type="text"
                                            autoComplete="nope"
                                            {...register("city")}
                                        />
                                        {errors.city?.message && (
                                            <Error>
                                                {intl.formatMessage({id: errors.city.message})}
                                            </Error>
                                        )}
                                        <Label htmlFor="zip">
                                            <FormattedMessage id="zip_code" />
                                        </Label>
                                        <Input
                                            type="text"
                                            autoComplete="nope"
                                            {...register("zip")}
                                        />
                                        {errors.zip?.message && (
                                            <Error>
                                                {intl.formatMessage({id: errors.zip.message})}
                                            </Error>
                                        )}
                                        <Label htmlFor="country">
                                            <FormattedMessage id="country" />
                                        </Label>
                                        <Select autoComplete="nope" {...register("country")}>
                                            {countries.map((code) => (
                                                <option value={code} key={code}>
                                                    {intl.formatMessage({id: code})}
                                                </option>
                                            ))}
                                        </Select>
                                        {errors.country?.message && (
                                            <Error>
                                                {intl.formatMessage({id: errors.country.message})}
                                            </Error>
                                        )}
                                    </>
                                )}
                                <Button
                                    data-cy="create-account-btn"
                                    type="submit"
                                    fullWidth
                                    large
                                    style={{marginTop: "16px"}}
                                    loading={isSubmitting || registerCompany.isLoading}
                                    disabled={
                                        lookupLoading || isSubmitting || registerCompany.isLoading
                                    }
                                >
                                    <FormattedMessage id="create_account" />
                                </Button>
                                <Legal>
                                    <FormattedMessage
                                        id="registration_legal_info"
                                        values={{
                                            terms: (name: string) => (
                                                <UnderlinedLink
                                                    href={intl.formatMessage({id: "terms_url"})}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {name}
                                                </UnderlinedLink>
                                            ),
                                            policy: (name: string) => (
                                                <UnderlinedLink
                                                    href={intl.formatMessage({id: "policy_url"})}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {name}
                                                </UnderlinedLink>
                                            ),
                                        }}
                                    />
                                </Legal>
                            </form>
                        </div>
                        {showVersionWithPhone ? (
                            <div style={{textAlign: "center", margin: "24px 0"}}>
                                <FormattedMessage id="already_have_an_account" />{" "}
                                <Link to="/login" replace style={{textDecoration: "underline"}}>
                                    <FormattedMessage id="login" />
                                </Link>
                            </div>
                        ) : (
                            <SalesContact>
                                <FormattedMessage
                                    id="need_help_email"
                                    values={{
                                        a: (link: string) => (
                                            <a
                                                href={`mailto:${link}`}
                                                style={{textDecoration: "underline"}}
                                            >
                                                {link}
                                            </a>
                                        ),
                                    }}
                                />
                                <p>
                                    <FormattedMessage id="reply_withing_24_hours" />
                                </p>
                            </SalesContact>
                        )}
                    </Wrapper>
                </Form>
                <RegisterMarketing />
            </Page>
        );
    }

    return (
        <Wrapper>
            <img src={logo} width="80px" alt="" />
            <Title>Liftago Business</Title>
            <Card>
                <FormattedMessage id="already_logged_in_as" /> <strong>{user.email}</strong>
                <Button
                    variant="secondary"
                    fullWidth
                    style={{marginTop: "16px"}}
                    onClick={() => navigate("/")}
                >
                    <FormattedMessage id="continue" />
                </Button>
            </Card>
            <Footer>
                <FormattedMessage id="do_you_want_to_create_new_business_account" />
                <Button variant="link" onClick={logout}>
                    <FormattedMessage id="logout" />
                </Button>
            </Footer>
        </Wrapper>
    );
}

export const Error = styled.span`
    font-size: var(--font-size-small);
    color: var(--red);
`;

export const Label = styled.label`
    margin-bottom: 2px;
    &:not(:first-child) {
        margin-top: var(--spacing-medium);
    }
`;

export const TrailingLabel = styled.span`
    margin-left: 4px;
    color: var(--secondary);
`;

export const Input = styled.input`
    padding: 6px 10px;
    border: 1px solid var(--quaternary);

    &:hover {
        border: 1px solid var(--tertiary);
    }

    &:focus {
        border: 1px solid var(--primary);
        box-shadow: inset 0 0 0 1px var(--primary);
        outline: none;
    }

    &[type="checkbox"] {
        margin-right: 4px;
    }
`;

export const Select = styled.select`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 6px 10px;
    border: 1px solid var(--quaternary);
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230070D4%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &:hover {
        border: 1px solid var(--tertiary);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background-image: none;
    }
`;

export const Wrapper = styled.div`
    margin: 16px auto;
    padding: 16px;
    max-width: 400px;
`;

export const Card = styled.div`
    padding: var(--card-padding);
    border-radius: var(--border-radius-large);
    border: 0.5px solid var(--quaternary);
    background-color: var(--white);
    box-shadow: 0px 1px 0px 0px rgba(237, 237, 237, 1);
`;

export const Title = styled.h1`
    font-weight: var(--font-weight-bold);
    margin-top: var(--spacing-large);
    margin-bottom: var(--spacing-large);
    letter-spacing: -0.5px;
    font-size: 24px;
`;

const Page = styled.div`
    display: flex;
`;

const Form = styled.div`
    width: 50%;
    flex-grow: 1;
`;

const Logo = styled.img`
    padding: 16px 0;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SectionTitle = styled.h2`
    font-size: 12px;
    color: var(--secondary);
    margin-top: var(--spacing-large);
    margin-bottom: -4px;
    font-variant-caps: all-small-caps;
    letter-spacing: 0.4px;
`;

const Footer = styled.div`
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Hint = styled.span`
    font-size: var(--font-size-small);
    color: var(--secondary);
    font-weight: var(--font-weight-thin);
`;

const Subtitle = styled.div`
    margin-top: -16px;
    margin-bottom: 24px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Legal = styled.p`
    margin-top: 16px;
    color: var(--secondary);
    font-size: 11px;
    text-align: center;
`;

const UnderlinedLink = styled.a`
    text-decoration: underline;
    color: var(--secondary);
    &:hover {
        text-decoration: underline;
    }
`;

//FIXME: Remove after showing version with CS phone number
const LoginLink = styled.div`
    color: var(--secondary);
    margin-right: 24px;
    font-size: 15px;
    text-align: right;
`;

const SalesContact = styled.div`
    margin: 32px 0 24px 0;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--light);
    text-align: center;
    opacity: 0.8;
`;
