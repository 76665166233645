import {posthog} from "posthog-js";
import {useContext, useState} from "react";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {TaxiRide, getLocationSuggestions} from "../api/apiClient";
import {Button} from "../components/Button";
import {generateUUID} from "../utils/generateUUID";
import {updateLocationCoords} from "../utils/updateLocationCoords";
import {TaxiOrderFormValues} from "./TaxiOrderForm";
import {FormValues} from "./TaxiPage";

async function getNewValues(ride: TaxiRide): Promise<TaxiOrderFormValues> {
    function transformPaymentType(
        paymentType: TaxiRide["paymentMethod"]
    ): TaxiOrderFormValues["paymentType"] {
        switch (paymentType) {
            case "COMPANY":
                return "company";
            case "PASSENGER_CASH":
                return "cash";
            case "PASSENGER_CARD":
                return "credit_card";
        }
    }

    const pickups = await Promise.all(
        ride.stops
            .filter((stop) => stop.type === "PICKUP")
            .map(async (stop) => {
                const locationResponse = await getLocationSuggestions({
                    serviceType: "TAXI",
                    query: stop.location.address,
                    addressType: "PICKUP",
                    suggestSessionId: generateUUID(),
                    tripId: generateUUID(),
                });

                const locationWithCoords = await updateLocationCoords(
                    locationResponse.suggestions[0]
                );

                return {
                    name: stop.contact?.name || "",
                    phoneNumber: stop.contact?.phoneNumber || "",
                    noteForDriver: stop.noteForDriver || "",
                    location: locationWithCoords,
                };
            })
    );

    const dropoffs = await Promise.all(
        ride.stops
            .filter((stop) => stop.type === "DROP_OFF")
            .map(async (stop) => {
                return await getLocationSuggestions({
                    serviceType: "TAXI",
                    query: stop.location.address,
                    addressType: "DESTINATION",
                    pickup: pickups[0].location?.location,
                    //ids not handled in case of recreate
                    suggestSessionId: generateUUID(),
                    tripId: generateUUID(),
                }).then((r) => r.suggestions[0]);
            })
    );

    return {
        pickups: [...pickups],
        dropoffs: [...dropoffs],
        paymentType: transformPaymentType(ride.paymentMethod),
        tierId: "",
        projectCode: ride.projectCode || "",
        costCenter: ride.costCenter || "",
        internalNote: ride.internalNote || "",
        preorderType: "pickup", //TODO: could add preorder recreate and prefill preorderTime
        pickupTimeType: "now",
        preorderTime: undefined,
        flightNumber: ride.flightNumber || "",
        driverCallsOrderer: false,
        specifiedDriverId: "",
        id: generateUUID(),
    };
}

export function TaxiRideRecreate({
    ride,
    onDetailClose,
}: {
    ride: TaxiRide;
    onDetailClose?: () => void;
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const formContext = useContext(FormValues);
    const intl = useIntl();

    function setInitials(event: any) {
        posthog.capture("prefillTaxiFromDetail");
        event.stopPropagation();
        setLoading(true);
        setError(false);
        getNewValues(ride)
            .then((response) => {
                formContext.setInitValues(response);
                toast.success(intl.formatMessage({id: "form_prefilled"}));
                if (window.innerWidth < 650) {
                    const orderTab: HTMLInputElement | null =
                        window.document.querySelector("#tab1");
                    if (orderTab) orderTab.checked = true;
                    onDetailClose && onDetailClose();
                }
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Button variant="secondary" fullWidth onClick={setInitials} disabled={loading}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path d="M7 3.5A1.5 1.5 0 018.5 2h3.879a1.5 1.5 0 011.06.44l3.122 3.12A1.5 1.5 0 0117 6.622V12.5a1.5 1.5 0 01-1.5 1.5h-1v-3.379a3 3 0 00-.879-2.121L10.5 5.379A3 3 0 008.379 4.5H7v-1z" />
                    <path d="M4.5 6A1.5 1.5 0 003 7.5v9A1.5 1.5 0 004.5 18h7a1.5 1.5 0 001.5-1.5v-5.879a1.5 1.5 0 00-.44-1.06L9.44 6.439A1.5 1.5 0 008.378 6H4.5z" />
                </svg>

                <FormattedMessage id="repeat_order" />
            </Button>
            {formContext.dirtyForm && (
                <div style={{opacity: 0.6, fontWeight: "normal", padding: "4px"}}>
                    ⚠ <FormattedMessage id="repeat_order_warning" />
                </div>
            )}
            {error && (
                <div style={{color: "var(--red)", fontWeight: "normal", lineHeight: 1.2}}>
                    <FormattedMessage id="repeat_order_error" />
                </div>
            )}
        </div>
    );
}
