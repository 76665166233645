import {useIntl} from "react-intl";
import styled from "styled-components";
import {DeliveryStop} from "../api/apiClient";
import deliveredIcon from "../img/status_delivered.svg";
import deliveringIcon from "../img/status_delivering.svg";
import returnIcon from "../img/status_return.svg";
import scheduledIcon from "../img/status_scheduled.svg";
import storedIcon from "../img/status_stored.svg";
import undeliveredIcon from "../img/status_undelivered.svg";
import {formatStopState, formatStopTime} from "../rides/ridesModel";
import {useRemainingMinutes} from "../rides/useRemainingMinutes";

export interface StopItemProps {
    stop: DeliveryStop;
    onItemClicked: () => void;
    returned?: boolean;
}

export function deliveryStopStatusIcon(status: DeliveryStop["status"]): string {
    switch (status) {
        case "IN_PROGRESS":
        case "ARRIVED":
        case "STORING":
            return deliveringIcon;
        case "STORED":
            return storedIcon;
        case "SCHEDULED":
        case "SCHEDULING":
            return scheduledIcon;
        case "RETURNING":
        case "RETURNED":
            return returnIcon;
        case "FAILED":
        case "CANCELLED":
            return undeliveredIcon;
        case "COMPLETED":
            return deliveredIcon;
    }
}
export function StopItem({stop, onItemClicked}: StopItemProps) {
    const intl = useIntl();
    const minutesRemaning = useRemainingMinutes(stop.arrival);

    const formattedState = formatStopState(stop.kind, stop.status, stop.arrival, intl);
    const formattedTime = formatStopTime(stop, minutesRemaning, intl);

    const stopStatus = [formattedState, formattedTime].filter((a) => a != null).join(" ");
    return (
        <Stop
            onClick={(event) => {
                event.stopPropagation();
                onItemClicked();
            }}
        >
            <Name>
                <img src={deliveryStopStatusIcon(stop.status)} alt="" />
                {stop.contact.name}
            </Name>
            <Status>{stopStatus}</Status>
        </Stop>
    );
}

const Stop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px;
    margin: 0 -8px;
    cursor: pointer;
    border-radius: var(--border-radius-small);
    font-variant-numeric: tabular-nums;

    &:hover {
        background-color: var(--light);
    }
`;
const Name = styled.div`
    display: flex;
    align-items: center;

    & > img {
        margin-right: 8px;
    }
`;

const Status = styled.div`
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-thin);
    text-align: right;
`;
