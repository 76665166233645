import {useEffect, useRef, useState} from "react";
import {Alert, Modal} from "react-bootstrap";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {getExampleLink, importDeliveryOrder} from "../api/apiClient";
import {Button} from "../components/Button";
import closeIcon from "../img/ic_close.svg";

interface errorResponse {
    message: string;
    details?: string[];
}

const BatchUpload = () => {
    const [file, setFile] = useState<File | null>(null);
    const [show, setShow] = useState<boolean>(false);
    const [error, setError] = useState<errorResponse | null>(null);
    const [example, setExample] = useState<string>();
    const [uploading, setUploading] = useState<boolean>(false);
    const intl = useIntl();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClose = () => {
        setShow(false);
        setError(null);
        setUploading(false);
        setFile(null);
    };

    const handleShow = () => setShow(true);

    const fileSelected = (event: any) => {
        setFile(event.target.files[0]);
        setError(null);
    };

    const uploadFile = () => {
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
            setUploading(true);
            importDeliveryOrder(formData)
                .then(() => {
                    handleClose();
                    toast.success(intl.formatMessage({id: "order_created"}));
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        setError(error.response.data);
                        setFile(null);
                        const currentInputRef = inputRef.current;
                        if (currentInputRef) {
                            currentInputRef.value = "";
                        }
                    } else {
                        setError({
                            message: intl.formatMessage({id: "order_failed"}),
                        });
                    }
                    setUploading(false);
                });
        } else {
            setError({message: intl.formatMessage({id: "choose_excel_file"})});
        }
    };

    const loadDeliveryRideExample = () => {
        getExampleLink().then((response) => setExample(response.excelUrl));
    };

    useEffect(() => {
        loadDeliveryRideExample();
    }, []);

    return (
        <>
            <Button variant="link" onClick={handleShow}>
                <FormattedMessage id="upload_excel" />
            </Button>
            <Modal show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="place_order_by_uploading" />
                    </Modal.Title>
                    <Button variant="plain" onClick={handleClose} disabled={uploading}>
                        <img src={closeIcon} alt="Close dialog" />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {example && (
                            <span style={{fontWeight: "normal"}}>
                                <FormattedMessage id="download" />{" "}
                                <a href={example}>
                                    <FormattedMessage id="sample_template" />
                                </a>
                            </span>
                        )}
                        <input
                            style={{paddingTop: "24px", paddingBottom: "8px"}}
                            className="excelInput"
                            type="file"
                            accept=".xlsx"
                            onChange={fileSelected}
                            ref={inputRef}
                            disabled={uploading}
                        />
                        {error && (
                            <Alert variant="danger">
                                <span>{error.message}</span>
                                {error &&
                                    error?.details &&
                                    error?.details.map((detail, i) => {
                                        return <li key={i}>{detail}</li>;
                                    })}
                            </Alert>
                        )}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={uploadFile} loading={uploading}>
                        <FormattedMessage id="submit" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BatchUpload;
