import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import styled from "styled-components";

export function HomepageLink() {
    return (
        <BackLink data-cy="homepage-back-btn" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor">
                <path
                    fillRule="evenodd"
                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                    clipRule="evenodd"
                />
            </svg>
            <FormattedMessage id="back" />
        </BackLink>
    );
}

export const BackLink = styled(Link)`
    font-size: 14px;
    margin-bottom: 8px;
    display: inline-block;
    font-variant-caps: all-small-caps;
    letter-spacing: 0.4px;
    display: flex;
    align-items: flex-start;
    & svg {
        margin-left: -4px;
    }
`;
