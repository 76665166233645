import {Controller, FieldValues, UseControllerProps} from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import {countryLabels} from "../utils/getCountryLables";

export function PhoneInputReactHookForm<T extends FieldValues>(props: UseControllerProps<T>) {
    return (
        <Controller
            name={props.name}
            control={props.control}
            render={({field}) => (
                <StyledPhoneInput
                    {...field}
                    onChange={(value: unknown) => field.onChange(value ?? "")}
                    enterKeyHint="next"
                    defaultCountry="CZ"
                    addInternationalOption={false}
                    labels={countryLabels}
                    countryOptionsOrder={[
                        "CZ",
                        "SK",
                        "AT",
                        "DE",
                        "ES",
                        "FR",
                        "IT",
                        "NL",
                        "PL",
                        "GB",
                        "US",
                        "|",
                        "...",
                    ]}
                />
            )}
        />
    );
}

export const StyledPhoneInput = styled(PhoneInput)`
    border-radius: var(--border-radius-small);
    border: 1px solid var(--quaternary);
    :has(input[type="tel"]:focus) {
        border: 1px solid var(--primary);
        box-shadow: inset 0 0 0 1px var(--primary);
        outline: none;
    }

    :has(input:hover),
    :has(select:hover) {
        border: 1px solid var(--tertiary);
    }

    & .PhoneInputCountry {
        border: none;
        margin: 0;
        padding: 7px 12px;
        border-radius: 5px 0 0 5px;
        &:has(select:focus) {
            //border: 1px solid var(--primary);
            //box-shadow: inset 0 0 0 1px var(--primary);
            outline: 2px solid var(--primary);
            outline-offset: -1px;
        }
    }

    & .PhoneInputCountryIcon {
        border-radius: 2px;
        outline: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    & input {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        border-left: 1px solid var(--quaternary);
        padding: 7px 10px;
        width: 100%;
        :focus {
            outline: none;
            border: none;
            border-left: 2px solid var(--primary);
        }
    }
`;
