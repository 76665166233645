type StringProperties<T> = {
    [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export function findDuplicates<T>(array: T[], property: StringProperties<T>): Promise<T[]> {
    return new Promise((resolve) => {
        const seen: Record<string, boolean> = {};
        const duplicates: T[] = [];

        array.forEach((item) => {
            const value = item[property] as string;

            if (seen[value]) {
                duplicates.push(item);
            } else {
                seen[value] = true;
            }
        });

        resolve(duplicates);
    });
}
