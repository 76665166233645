import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {BillingInfo} from "./BillingInfo";
import {BasicPageWrapper} from "./Dashboard";
import {HomepageLink} from "./HomepageLink";
import {Invoices} from "./Invoices";
import {PaymentMethod} from "./PaymentMethod";
import {ProjectCode} from "./ProjectCode";

export function Billing() {
    return (
        <BasicPageWrapper>
            <HomepageLink />
            <PaymentMethod />
            <h1>
                <FormattedMessage id="settings" />
            </h1>
            <Settings>
                <ProjectCode />
                <BillingInfo />
            </Settings>
            <Invoices />
        </BasicPageWrapper>
    );
}

const Settings = styled.div`
    display: flex;
    gap: 24px;
    align-items: stretch;
    margin-bottom: 24px;
    flex-wrap: wrap;
    & > :first-child {
        flex: 2;
        min-width: 350px;
    }

    & > :last-child {
        min-width: 200px;
        flex: 1;
    }
`;
