import {createContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useCallerCompany} from "../api/apiClient";
import {
    Content,
    PageContentWrapper,
    TabLeft,
    TabLeftView,
    TabRight,
    TabRightView,
} from "../app/layout";
import {CountBadge} from "../components/CountBadge";
import {useTaxiRides} from "../rides/useRides";
import {generateUUID} from "../utils/generateUUID";
import {TaxiOrderForm, TaxiOrderFormValues} from "./TaxiOrderForm";
import {TaxiRidesPage} from "./TaxiRidesPage";

export const defaultPickup = {
    name: "",
    phoneNumber: "",
    location: undefined,
    noteForDriver: "",
};

export const defaultInitValues: TaxiOrderFormValues = {
    pickups: [defaultPickup],
    dropoffs: [undefined],
    paymentType: "company",
    tierId: "",
    projectCode: "",
    costCenter: "",
    internalNote: "",
    preorderType: "pickup",
    pickupTimeType: "now",
    preorderTime: undefined,
    flightNumber: "",
    driverCallsOrderer: false,
    specifiedDriverId: "",
    // uuid is used for unique init data to reinitialize form when prefilling form with new init data
    // could be solved differetnly and perhaps rewritten to react-hook-form as formik is no longer maintained
    id: generateUUID(),
};

export const FormValues = createContext({
    initValues: defaultInitValues,
    setInitValues: (values: TaxiOrderFormValues) => {},
    dirtyForm: false,
    setDirtyForm: (dirty: boolean) => {},
});

export function TaxiPage() {
    const {data: ridesResponse} = useTaxiRides();
    const {data: company} = useCallerCompany();

    const [initValues, setInitValues] = useState(defaultInitValues);
    const [dirtyForm, setDirtyForm] = useState(false);

    useEffect(() => {
        setInitValues({
            ...initValues,
            paymentType:
                company?.horecaEnabled || !company?.paymentByCompanyAllowed ? "cash" : "company",
        });
    }, [company]);

    const activeRidesCount = ridesResponse?.rides.filter(
        (ride) => ride.statusGroup === "UPCOMING" || ride.statusGroup === "IN_PROGRESS"
    ).length;

    if (!company) {
        return null;
    }

    return (
        <>
            <TabLeft type="radio" name="tabs" id="tab1" defaultChecked />
            <TabLeftView className="tab1" htmlFor="tab1" role="tab" aria-controls="col1">
                <FormattedMessage id="order" />
            </TabLeftView>
            <TabRight type="radio" name="tabs" id="tab2" />
            <TabRightView className="tab2" htmlFor="tab2" role="tab" aria-controls="col2">
                <div style={{display: "flex"}}>
                    <FormattedMessage id="rides_list" />
                    <CountBadge count={Number(activeRidesCount)} />
                </div>
            </TabRightView>
            <Content>
                <FormValues.Provider value={{initValues, setInitValues, dirtyForm, setDirtyForm}}>
                    <PageContentWrapper>
                        <TaxiOrderForm company={company} />
                    </PageContentWrapper>
                    <TaxiRidesPage rides={ridesResponse?.rides ?? []} />
                </FormValues.Provider>
            </Content>
        </>
    );
}
