import {useState} from "react";
import {DeliveryRide} from "../api/apiClient";
import {RideDetail} from "./DeliveryRideDetail";
import {DetailWrapper, ListWrapper} from "../rides/layout";
import {DeliveryRidesList} from "./DeliveryRidesList";
import {StopDetail} from "./DeliveryStopDetail";
import {OpenDetailContext} from "./OpenDetailContext";

export type Detail = RideDetailType | StopDetailType;
export interface StopDetailType {
    type: "stop";
    stopId: string;
    rideId: string;
}

export interface RideDetailType {
    type: "ride";
    rideId: string;
}

export function DeliveryRidesPage({rides}: {rides: DeliveryRide[]}) {
    const [detail, setDetail] = useState<Detail | null>(null);
    const openedRide =
        detail == null || detail == null ? null : rides.find((ride) => ride.id === detail.rideId);

    return (
        <OpenDetailContext.Provider value={setDetail}>
            <ListWrapper>
                <DeliveryRidesList rides={rides} />
                {detail && openedRide && (
                    <DetailWrapper>
                        {detail.type === "stop" && (
                            <StopDetail ride={openedRide} stopId={detail.stopId} />
                        )}
                        {detail.type === "ride" && <RideDetail ride={openedRide} />}
                    </DetailWrapper>
                )}
            </ListWrapper>
        </OpenDetailContext.Provider>
    );
}
