import {zodResolver} from "@hookform/resolvers/zod";
import axios from "axios";
import {isValidPhoneNumber} from "libphonenumber-js";
import posthog from "posthog-js";
import {Alert, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {z} from "zod";
import {
    BusinessEmployeeDto,
    useCallerCompany,
    useCreateEmployee,
    useGetBusinessCompany,
    useGetBusinessPaymentInfo,
} from "../api/apiClient";
import {Button} from "../components/Button";
import {PhoneInputReactHookForm} from "../components/PhoneInputReactHookForm";
import closeIcon from "../img/ic_close.svg";
import {clearSpaces} from "../utils/formatString";
import {monthlyLimitSchema, removeAfterSchema} from "../utils/schemas";
import {queryClient} from "./App";
import {BusinessNotActiveModal} from "./BusinessNotActiveModal";
import {Error, Input, Label, Select, TrailingLabel} from "./Registration";
import {getCountryCurrencyString, getCurrencyByCountry} from "./moneyUtils";

export const newMobileUserSchema = z.object({
    name: z.string().min(1, {message: "required"}),
    phoneNumber: z
        .string()
        .min(1, {message: "required"})
        .refine((value) => isValidPhoneNumber(value.replace(/[^\x20-\x7F]/g, "")), {
            message: "invalid_phone_number",
        })
        .refine(
            (value) =>
                !queryClient
                    .getQueryData<BusinessEmployeeDto[] | undefined>([
                        "/self-service/business/employees",
                    ])
                    ?.some((user) => clearSpaces(user.phoneNumber) === clearSpaces(value)),

            {
                message: "mobile_user_already_invited",
            }
        ),
    costCenter: z.string().optional(),
    paymentOriginator: z.union([z.literal("EMPLOYEE"), z.literal("COMPANY")]).optional(),
    monthlyLimit: monthlyLimitSchema,
    removeAfter: removeAfterSchema,
});

type AddMobileUserFormValue = z.infer<typeof newMobileUserSchema>;

export function AddMobileUserForm() {
    const navigate = useNavigate();
    const intl = useIntl();
    const createUser = useCreateEmployee();
    const {data: company} = useGetBusinessCompany();
    const {data: business} = useGetBusinessPaymentInfo();
    const {data: companyInfo} = useCallerCompany();

    const handleClose = () => navigate("..", {replace: true});

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: {errors},
    } = useForm<AddMobileUserFormValue>({
        mode: "onTouched",
        resolver: zodResolver(newMobileUserSchema),
        criteriaMode: "all",
    });

    if (company == null || business == null || companyInfo == null) {
        return null;
    }

    if (companyInfo.disabledInfo.ridesEnabled === false) {
        return <BusinessNotActiveModal />;
    }

    async function onSubmit(data: AddMobileUserFormValue, country: string) {
        createUser.mutate(
            {
                name: data.name.trim(),
                phoneNumber: data.phoneNumber.trim().replace(/[^\x20-\x7F]/g, ""),
                costCenter: data.costCenter?.trim(),
                paymentOriginator: data.paymentOriginator === "EMPLOYEE" ? "EMPLOYEE" : "COMPANY",
                monthlyLimit:
                    data.monthlyLimit && data.monthlyLimit.length > 0
                        ? {
                              amount: Number(data.monthlyLimit),
                              ccy: getCurrencyByCountry(country),
                          }
                        : undefined,
                removeAfter: data.removeAfter ?? undefined,
            },
            {
                onSuccess: () => {
                    toast.success(intl.formatMessage({id: "user_added"}));
                    queryClient.invalidateQueries(["/self-service/business/employees"]);
                    posthog.capture("mobileUserAdded");
                    handleClose();
                },
            }
        );
    }

    function errorMessage(error: any) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 409) {
                return (
                    <Alert variant="danger">
                        {/* FIXME: Make server return info about user registred in a) this or b) different business account. Currently validating this business on FE and here assume catching only the ones from different account. */}
                        <FormattedMessage id="mobile_user_already_in_other_business" />
                    </Alert>
                );
            }
        }
        return (
            <Alert variant="danger">
                <FormattedMessage id="generic_error" />
            </Alert>
        );
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="invite_mobile_user" />
                </Modal.Title>
                <Button variant="plain" onClick={handleClose}>
                    <img src={closeIcon} alt="Close dialog" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                {createUser.error && errorMessage(createUser.error)}
                <form
                    id="user"
                    onSubmit={handleSubmit((data) =>
                        onSubmit(data, company.billingAddress.country)
                    )}
                    style={{display: "flex", flexDirection: "column"}}
                >
                    <Label htmlFor="name">
                        <FormattedMessage id="name" />
                    </Label>
                    <Input type="text" {...register("name")} />
                    {errors.name?.message && (
                        <Error>{intl.formatMessage({id: errors.name.message})}</Error>
                    )}
                    <Label htmlFor="phoneNumber">
                        <FormattedMessage id="phone_number" />
                    </Label>
                    <PhoneInputReactHookForm control={control} name="phoneNumber" />
                    {errors.phoneNumber?.message && (
                        <Error>{intl.formatMessage({id: errors.phoneNumber.message})}</Error>
                    )}
                    {(company?.billingAddress?.country === "CZ" ||
                        company?.billingAddress?.country === "SK") && (
                        <>
                            <Label htmlFor="monthlyLimit">
                                <FormattedMessage id="monthly_limit" />
                                <TrailingLabel>
                                    (<FormattedMessage id="optional" />)
                                </TrailingLabel>
                            </Label>
                            <MoneyInput>
                                <Input
                                    type="text"
                                    inputMode="decimal"
                                    {...register("monthlyLimit")}
                                    placeholder={intl.formatMessage({id: "no_limit"})}
                                    style={{maxWidth: "130px", paddingRight: "40px"}}
                                />
                                <Currency>
                                    {getCountryCurrencyString(company.billingAddress.country)}
                                </Currency>
                            </MoneyInput>
                            {errors.monthlyLimit?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.monthlyLimit.message})}
                                </Error>
                            )}
                        </>
                    )}
                    <Label htmlFor="costCenter">
                        <FormattedMessage id="cost_center" />
                        <TrailingLabel>
                            (<FormattedMessage id="optional" />)
                        </TrailingLabel>
                    </Label>
                    <Input type="text" {...register("costCenter")} />
                    {errors.costCenter?.message && (
                        <Error>{intl.formatMessage({id: errors.costCenter.message})}</Error>
                    )}
                    {companyInfo.employeeRemoveAfterEnabled && (
                        <>
                            <Label htmlFor="removeAfter">
                                <FormattedMessage id="remove_after" />
                                <TrailingLabel>
                                    (<FormattedMessage id="optional" />)
                                </TrailingLabel>
                            </Label>
                            <div style={{display: "flex"}}>
                                <Input id="removeAfter" type="date" {...register("removeAfter")} />
                                {watch("removeAfter") && (
                                    <Button
                                        variant="plain"
                                        onClick={() =>
                                            setValue("removeAfter", undefined, {shouldDirty: true})
                                        }
                                    >
                                        <img src={closeIcon} alt="Reset date" />
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                    {errors.removeAfter?.message && (
                        <Error>{intl.formatMessage({id: errors.removeAfter.message})}</Error>
                    )}
                    {business?.paymentByEmployeeAllowed && (
                        <>
                            <Label htmlFor="paymentOriginator">
                                <FormattedMessage id="payment_type" />
                            </Label>
                            <Select autoComplete="nope" {...register("paymentOriginator")}>
                                <option value="COMPANY">
                                    {intl.formatMessage({id: "payment_originator_company"})}
                                </option>
                                <option value="EMPLOYEE">
                                    {intl.formatMessage({id: "payment_originator_employee"})}
                                </option>
                            </Select>
                            {errors.paymentOriginator?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.paymentOriginator.message})}
                                </Error>
                            )}
                        </>
                    )}
                </form>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    data-cy="invite-mobile-user-btn"
                    type="submit"
                    form="user"
                    variant="primary"
                    loading={createUser.isLoading}
                >
                    <FormattedMessage id="invite_user" />
                </Button>
            </Modal.Footer>
        </>
    );
}

export function AddMobileUser() {
    const navigate = useNavigate();
    const handleClose = () => navigate("..");

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static" centered>
                <AddMobileUserForm />
            </Modal>
        </>
    );
}

export const MoneyInput = styled.div`
    position: relative;
    align-self: flex-start;
`;

export const Currency = styled.span`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`;
