import {FormattedMessage, useIntl} from "react-intl";
import {Money} from "../api/apiClient";
import {formatMoney} from "../app/moneyUtils";

export function TipGiven({tip}: {tip: Money}) {
    const intl = useIntl();
    return (
        <div>
            <span>
                <FormattedMessage
                    id="tip_sent_to_the_driver"
                    values={{tip: formatMoney(tip, intl)}}
                />
            </span>
        </div>
    );
}
