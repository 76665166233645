import {FormattedMessage} from "react-intl";
import {NavLink, Outlet} from "react-router-dom";
import styled from "styled-components";
import {BasicPageWrapper} from "./Dashboard";
import {HomepageLink} from "./HomepageLink";

export function Users() {
    return (
        <BasicPageWrapper>
            <HomepageLink />
            <h1>
                <FormattedMessage id="users" />
            </h1>
            <Tabs>
                <StyledNavLink data-cy="users-mobile-app-btn" to="mobile-app">
                    <FormattedMessage id="mobile_app" />
                </StyledNavLink>
                <StyledNavLink data-cy="users-web-app-btn" to="web-app">
                    <FormattedMessage id="web_app" />
                </StyledNavLink>
            </Tabs>
            <Outlet />
        </BasicPageWrapper>
    );
}

const Tabs = styled.div`
    display: flex;
    box-shadow: inset 0px -1px 0 var(--divider);
    font-size: var(--font-size-text);
    gap: 8px;
`;

const StyledNavLink = styled(NavLink)`
    color: #334e73;
    padding: 12px;
    &:hover:not(.active) {
        color: var(--dark);
    }
    &.active {
        border-bottom: 4px solid var(--primary);
        color: var(--primary);
    }
`;
