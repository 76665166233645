import {ReactNode} from "react";
import styled from "styled-components";

export function TrackingLink({
    url,
    imgSrc,
    children,
}: {
    url: string;
    imgSrc?: string;
    children: ReactNode;
}) {
    const urlObject = new URL(url);
    const searchParams = urlObject.searchParams;
    searchParams.append("utm_source", "webapp");
    return (
        <Link target="_blank" rel="noopener noreferrer" href={urlObject.toString()}>
            <img src={imgSrc} alt="" />
            {children}
        </Link>
    );
}

//TODO, extend button component and create anchor element based on href attribute
const Link = styled.a`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 8px 12px;
    background-color: var(--blue-light);
    border-radius: var(--border-radius-small);

    &:hover {
        color: var(--primary);
        filter: brightness(95%);
    }

    & img {
        margin-right: var(--spacing-tiny);
    }
`;
