import {useFormikContext} from "formik";
import {FormControl} from "react-bootstrap";
import {useIntl} from "react-intl";
import {Location, LocationSuggestionsRequest} from "../api/apiClient";
import {PhoneInputFormik} from "../components/PhoneInputFormik";
import {SearchPlacesInput} from "../components/SearchPlacesInput";
import {IconFormControlWrapper} from "../components/forms";
import {formikLocationChange} from "../helpers/formikLocationChange";
import locationIcon from "../img/ic_location.svg";
import messageIcon from "../img/ic_message.svg";
import personIcon from "../img/ic_person.svg";
import {generateUUID} from "../utils/generateUUID";
import {DeliveryOrderFormValues} from "./deliveryOrderModel";

export interface StopItemFormGroupProps {
    prefix: string;
    namePlaceholder: string;
    locationPlaceholder: string;
    addressType?: LocationSuggestionsRequest["addressType"];
    pickup?: Location;
}

export function StopItemFormGroup({
    prefix,
    namePlaceholder,
    locationPlaceholder,
    addressType,
    pickup,
}: StopItemFormGroupProps) {
    const {getFieldProps, setFieldValue, setFieldTouched} =
        useFormikContext<DeliveryOrderFormValues>();
    const intl = useIntl();

    return (
        <fieldset>
            <IconFormControlWrapper icon={personIcon} name={`${prefix}.name`}>
                <FormControl
                    type="text"
                    placeholder={namePlaceholder}
                    autoComplete="off"
                    {...getFieldProps(`${prefix}.name`)}
                />
            </IconFormControlWrapper>
            <PhoneInputFormik
                name={`${prefix}.phoneNumber`}
                placeholder={intl.formatMessage({id: "phone_number"})}
            />
            <IconFormControlWrapper icon={locationIcon} name={`${prefix}.location`}>
                <SearchPlacesInput
                    placeholder={locationPlaceholder}
                    serviceType="DELIVERY"
                    addressType={addressType}
                    pickup={pickup}
                    tripId={addressType === "DESTINATION" ? generateUUID() : undefined}
                    {...getFieldProps(`${prefix}.location`)}
                    onChange={(selected) => {
                        formikLocationChange(`${prefix}.location`, selected[0], setFieldValue);
                    }}
                    onBlur={() => setFieldTouched(`${prefix}.location`, true)}
                />
            </IconFormControlWrapper>
            <IconFormControlWrapper icon={messageIcon} name={`${prefix}.noteForDriver`}>
                <FormControl
                    type="text"
                    placeholder={
                        intl.formatMessage({id: "message_to_driver"}) +
                        " (" +
                        intl.formatMessage({id: "optional"}) +
                        ")"
                    }
                    {...getFieldProps(`${prefix}.noteForDriver`)}
                />
            </IconFormControlWrapper>
        </fieldset>
    );
}
