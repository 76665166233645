export const transformValues = (value: string, field: string | number): any => {
    const val = value.trim().replace(/^00/, "+");
    //Rough guessing of incomplete phone number
    //TODO: Could improve by using libphonenumber somehow
    if (field === "phoneNumber") {
        if (val.length === 9 && !val.includes("+")) {
            return `+420${val}`;
        } else if (val.length >= 11 && !val.includes("+")) {
            return `+${val}`;
        }
    }
    return val;
};
