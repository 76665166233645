import {useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import toast from "react-hot-toast";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import styled from "styled-components";
import {
    getEncodedInvoicingSummary,
    getEncodedIssuedInvoice,
    IssuedInvoice,
    useGetBusinessInvoicings,
    useGetBusinessPaymentInfo,
} from "../api/apiClient";
import {Button} from "../components/Button";
import {downloadInvoicingSummaryFile} from "../utils/downloadInvoicingSummaryFile";
import {formatDateToYYYYMM} from "./dateUtils";
import {formatMoneyWithDecimals} from "./moneyUtils";
import {downloadFileFromBase64String} from "../utils/downloadFileFromBase64String";

function getSummaryFilename(date: string) {
    return `summary-${formatDateToYYYYMM(new Date(date))}.xlsx`;
}

function getInvoiceFilename(date: string) {
    return `invoice-${formatDateToYYYYMM(new Date(date))}.pdf`;
}

const downloadIssuedInvoice = (invoice: IssuedInvoice, invoicingStart: string, intl: IntlShape) => {
    getEncodedIssuedInvoice(invoice.invoiceId)
        .then(response => downloadFileFromBase64String(response.data, getInvoiceFilename(invoicingStart)))
        .catch(() => toast.error(intl.formatMessage({id: "generic_error"})))
};

export function Invoices({short = false}: {short?: boolean}) {
    const intl = useIntl();
    const {data: summaries} = useGetBusinessInvoicings();
    const {data: paymentInfo} = useGetBusinessPaymentInfo();
    const [shortState, setShortState] = useState(short);

    if (summaries == null || paymentInfo == null) {
        return null;
    }

    const SHORT_ITEM_COUNT = 3;

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                }}
            >
                <h1>
                    <FormattedMessage id="billing" />
                </h1>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="summary_password_tooltip">
                            <FormattedMessage id="summary_password_tooltip" />
                        </Tooltip>
                    }
                >
                    <Password>
                        <FormattedMessage id="summary_password" />:{" "}
                        <strong>{paymentInfo?.summaryPassword}</strong>
                    </Password>
                </OverlayTrigger>
            </div>
            <InvoicesWrapper>
                {summaries.items.map((item, i) => {
                    if (shortState && i > SHORT_ITEM_COUNT - 1) {
                        return null;
                    }
                    return (
                        <Row key={item.start}>
                            <Col>
                                <Month>
                                    {new Intl.DateTimeFormat(undefined, {
                                        month: "long",
                                        year: "numeric",
                                    }).format(new Date(item.start))}
                                </Month>
                                <Summary>
                                    <FormattedMessage id="taxi" />:{" "}
                                    <Count>{item.taxiRideCount}</Count> •{" "}
                                    <FormattedMessage id="shipment" />:{" "}
                                    <Count>{item.numberOfShipments}</Count>
                                </Summary>
                            </Col>
                            <Col>
                                <Amount>
                                    {item.issuedInvoice?.amount != null &&
                                        formatMoneyWithDecimals(item.issuedInvoice?.amount, intl)}
                                </Amount>
                                {item.issuedInvoice && (
                                    <PaymentStatus
                                        paid={item.issuedInvoice?.paid}
                                        due={item.issuedInvoice?.dueDate}
                                    />
                                )}
                            </Col>
                            <Downloads>
                                {item.issuedInvoice && (
                                    <>
                                        <Button
                                            data-cy="invoice-download-btn"
                                            variant="link"
                                            onClick={() => downloadIssuedInvoice(item.issuedInvoice!!, item.start, intl)}
                                        >
                                            <FormattedMessage id="invoice" />
                                        </Button>
                                        ·
                                    </>
                                )}
                                <Button
                                    data-cy="summary-download-btn"
                                    variant="link"
                                    onClick={() =>
                                        toast.promise(
                                            downloadInvoicingSummaryFile(
                                                getSummaryFilename(item.start),
                                                () => getEncodedInvoicingSummary(item.invoicingId),
                                            ),
                                            {
                                                loading: `${intl.formatMessage({
                                                    id: "downloading",
                                                })} ${getSummaryFilename(item.start)}`,
                                                success: null,
                                                error: intl.formatMessage({
                                                    id: "download_failed",
                                                }),
                                            },
                                            {
                                                success: {
                                                    duration: 1,
                                                },
                                            },
                                        )
                                    }
                                >
                                    <FormattedMessage id="summary" />
                                </Button>

                            </Downloads>
                        </Row>
                    );
                })}
                {shortState && summaries.items.length > SHORT_ITEM_COUNT && (
                    <ShowMoreButton onClick={() => setShortState(false)}>
                        <Row style={{display: "flex", justifyContent: "space-around"}}>
                            <FormattedMessage id="show_all" />
                        </Row>
                    </ShowMoreButton>
                )}
            </InvoicesWrapper>
        </>
    );
}

function PaymentStatus({paid, due}: {paid: boolean; due: string}) {
    const dueDate = new Date(due);
    const currentDate = new Date();

    if (paid) {
        return (
            <Lozenge variant="positive">
                <FormattedMessage id="paid" />
            </Lozenge>
        );
    }
    if (dueDate > currentDate) {
        return (
            <Lozenge variant="neutral">
                <FormattedMessage id="payment_pending" />
            </Lozenge>
        );
    }
    if (dueDate < currentDate) {
        return (
            <Lozenge variant="negative">
                <FormattedMessage id="payment_overdue" />
            </Lozenge>
        );
    }
    return null;
}

const ShowMoreButton = styled.div`
    background: none;
    color: var(--primary);
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
`;

const InvoicesWrapper = styled.div`
    border: 0.5px solid var(--quaternary);
    border-radius: var(--border-radius-large);
    margin-bottom: 24px;
`;
const Row = styled.div`
    display: grid;
    grid-template-columns: 5fr 3fr 3fr;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 0.5px solid var(--quaternary);

    &:last-child {
        border-bottom: none;
    }
`;

const Col = styled.div`
    padding-right: 16px;
`;
const Month = styled.div`
    font-size: var(--font-size-text);
`;
const Summary = styled.div`
    color: var(--secondary);
`;
const Amount = styled.div`
    font-size: var(--font-size-text);
`;

const Count = styled.span`
    color: var(--dark);
    font-weight: var(--font-weight-bold);
`;
const Downloads = styled.div`
    display: flex;
    gap: 8px;
    justify-self: right;
    align-items: baseline;
`;

interface LozengeProps {
    variant: "positive" | "negative" | "neutral";
}

const Lozenge = styled.p<LozengeProps>`
    border-radius: 4px;
    background-color: var(--light);
    color: var(--dark);
    padding: 2px 5px 3px 5px;
    font-variant: all-small-caps;
    width: fit-content;
    letter-spacing: 0.3px;
    font-size: 12px;
    line-height: 100%;

    ${(p) =>
            p.variant === "negative" &&
            `
            background-color: var(--red);
            color: var(--white);
        `}

    ${(p) =>
            p.variant === "positive" &&
            `
            background-color: var(--green);
            color: var(--white);
        `}
`;

const Password = styled.span`
    padding: 8px 16px;
    background-color: var(--light);
    border-radius: 100px;
    font-size: 14px;
`;
