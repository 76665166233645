import {Location} from "../api/apiClient";
import {StopItem} from "./deliveryOrderModel";

function getStopData(stop: string): StopItem | undefined {
    const data = localStorage.getItem(stop);
    if (data != null && data !== "undefined" && data !== "null") {
        const parsedStop: StopItem = JSON.parse(data);
        const location: Location | undefined = parsedStop.location?.location;
        if (location?.id?.startsWith("google") || location?.id?.startsWith("seznam")) {
            return parsedStop;
        } else {
            return {...parsedStop, location: undefined};
        }
    }
}

export function getStoredPickup(): StopItem | undefined {
    return getStopData("pickupData");
}

export function getStoredFallback(): StopItem | undefined {
    return getStopData("fallbackData");
}

export function getStoredFallbackEnabled() {
    const fallbackEnabledStorage = localStorage.getItem("isFallbackEnabled");
    return fallbackEnabledStorage !== null ? JSON.parse(fallbackEnabledStorage) === true : false;
}

export function getStoredPickupFilter() {
    const pickupFilter = localStorage.getItem("pickupFilter");
    return pickupFilter;
}

function setStopData(stopData: StopItem | undefined, key: string) {
    if (stopData) {
        localStorage.setItem(
            key,
            JSON.stringify({
                ...stopData,
                location: {...stopData.location, suggestSessionId: undefined},
            })
        );
    }
}

export function setPickupStorage(pickupData: StopItem | undefined) {
    setStopData(pickupData, "pickupData");
}
export function setFallbackStorage(fallbackData: StopItem | undefined) {
    setStopData(fallbackData, "fallbackData");
}

export function setFallbackEnabledStorage(checked: boolean) {
    localStorage.setItem("isFallbackEnabled", checked.toString());
}

export function setPickupFilterStorage(filter: string | null) {
    if (filter == null) {
        localStorage.removeItem("pickupFilter");
        return;
    }
    localStorage.setItem("pickupFilter", filter);
}
