import {useFormikContext} from "formik";
import {Typeahead, TypeaheadMenu} from "react-bootstrap-typeahead";
import {useEmployees} from "../api/apiClient";

export interface NameAutocompleteInputProps {
    name: string;
    placeholder: string;
    index: number;
}

export function NameAutocompleteInput({name, placeholder, index}: NameAutocompleteInputProps) {
    const {getFieldProps, setFieldValue, setFieldTouched} = useFormikContext();
    const {data} = useEmployees();

    const fieldValue = getFieldProps(name).value as string;

    function createArray(value?: string): string[] {
        if (value) {
            return [value];
        }
        return [];
    }

    const handleChange = (suggestions: string[]) => {
        if (suggestions.length > 0) {
            setFieldValue(name, suggestions[0], true);
            const phoneNumber = data?.employees.find((e) => e.name === suggestions[0])?.phoneNumber;
            setFieldValue(`pickups.${index}.phoneNumber`, phoneNumber, true);

            //Tradeoff: fill the cost center of first employee (edge case for now)
            const costCenter = data?.employees.find((e) => e.name === suggestions[0])?.costCenter;
            if (costCenter && index === 0) {
                setFieldValue("costCenter", costCenter, true);
            }
        }
    };

    return (
        <Typeahead
            id={name}
            inputProps={{name}}
            selected={createArray(fieldValue)}
            onBlur={() => setFieldTouched(name, true)}
            onInputChange={(s) => setFieldValue(name, s, true)}
            onChange={handleChange}
            renderMenu={
                ((results: any, menuProps: any, props: any) => {
                    if (results.length === 0) {
                        return null;
                    }
                    return (
                        <TypeaheadMenu
                            {...menuProps}
                            labelKey={props.labelKey}
                            options={results}
                            text={props.text}
                        />
                    );
                }) as any
            }
            options={data?.employees.map((employee) => employee.name) ?? []}
            placeholder={placeholder}
            highlightOnlyResult={false}
        />
    );
}
