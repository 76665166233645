import styled from "styled-components";

export const Dropzone = styled.div<{isDragAccept: boolean; isDragReject: boolean}>`
    background-color: var(--light);
    border: 2px dashed var(--tertiary);
    flex-grow: 1;
    padding: 24px 16px;
    display: grid;
    place-content: center;
    border-radius: var(--border-radius);
    &:hover,
    :focus {
        border-color: var(--primary);
        cursor: pointer;
        color: var(--primary);
    }
    ${(p) =>
        p.isDragAccept &&
        `background-color: var(--blue-light);
        border-color: var(--primary);
        color: var(--primary);
        `}
    ${(p) =>
        p.isDragReject &&
        `background-color: var(--red-light);
        border-color: var(--red);
        color: var(--red);
        `}
`;
