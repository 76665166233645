import {posthog} from "posthog-js";
import {useContext, useState} from "react";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {DeliveryRide, getLocationSuggestions} from "../api/apiClient";
import {Button} from "../components/Button";
import {FormValues, getExpressPreorderInitialTime} from "../delivery/DeliveryPage";
import {formatAddress} from "../rides/ridesModel";
import {generateUUID} from "../utils/generateUUID";
import {updateLocationCoords} from "../utils/updateLocationCoords";
import {DeliveryOrderFormValues, StopItem} from "./deliveryOrderModel";
import * as storage from "./localStorageModel";

async function getNewValues(ride: DeliveryRide): Promise<DeliveryOrderFormValues> {
    const tripId = generateUUID();
    const suggestSessionId = generateUUID();
    const pickups: StopItem[] = await Promise.all(
        ride.stops
            .filter((stop) => stop.kind === "PICKUP")
            .map(async (stop) => {
                const locationResponse = await getLocationSuggestions({
                    serviceType: "DELIVERY",
                    query: formatAddress(stop.destination),
                    addressType: "PICKUP",
                    suggestSessionId: generateUUID(),
                    tripId: generateUUID(),
                });

                const locationWithCoords = await updateLocationCoords(
                    locationResponse.suggestions[0]
                );

                return {
                    name: stop.contact?.name || "",
                    phoneNumber: stop.contact?.phoneNumber || "",
                    location: locationWithCoords,
                    noteForDriver: stop.noteForDriver || "",
                    kind: "PICKUP",
                    tripId,
                };
            })
    );

    const dropoffs: StopItem[] = await Promise.all(
        ride.stops
            .filter((stop) => stop.kind === "HANDOVER")
            .map(async (stop) => {
                const locationResponse = await getLocationSuggestions({
                    serviceType: "DELIVERY",
                    query: formatAddress(stop.destination),
                    addressType: "DESTINATION",
                    pickup: pickups[0].location?.location,
                    //ids not handled in case of recreate
                    suggestSessionId,
                    tripId,
                });

                const locationWithCoords = await updateLocationCoords(
                    locationResponse.suggestions[0]
                );

                return {
                    name: stop.contact?.name || "",
                    phoneNumber: stop.contact?.phoneNumber || "",
                    location: locationWithCoords,
                    noteForDriver: stop.noteForDriver || "",
                    kind: "HANDOVER",
                    tripId,
                };
            })
    );

    const isRoundTrip =
        ride?.stops[0]?.destination && ride?.stops[2]?.destination
            ? formatAddress(ride.stops[0].destination) === formatAddress(ride.stops[2].destination)
            : false;

    return {
        pickup: pickups[0],
        handovers: isRoundTrip ? [dropoffs[0]] : dropoffs,
        fallbackEnabled: storage.getStoredFallbackEnabled(),
        fallback: storage.getStoredFallback() || {...pickups[0], kind: "FALLBACK"},
        projectCode: "", //FIXME B4F
        costCenter: "", //FIXME B4F
        arrivalTime: undefined,
        deliveryType: isRoundTrip ? "ROUND_TRIP" : "ONE_WAY",
        pickupDates: [],
        pickupTime: getExpressPreorderInitialTime(),
        tierId: "",
        // uuid is used for unique init data to reinitialize form when prefilling form with new init data
        // could be solved differetnly and perhaps rewritten to react-hook-form as formik is no longer maintained
        id: generateUUID(),
    };
}

export function DeliveryRideRecreate({
    ride,
    onDetailClose,
}: {
    ride: DeliveryRide;
    onDetailClose?: () => void;
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const formContext = useContext(FormValues);
    const intl = useIntl();

    function setInitials(event: any) {
        posthog.capture("prefillDeliveryFromDetail");
        event.stopPropagation();
        setLoading(true);
        setError(false);
        getNewValues(ride)
            .then((response) => {
                formContext.setInitValues(response);
                toast.success(intl.formatMessage({id: "form_prefilled"}));
                if (window.innerWidth < 650) {
                    const orderTab: HTMLInputElement | null =
                        window.document.querySelector("#tab1");
                    if (orderTab) orderTab.checked = true;
                    onDetailClose && onDetailClose();
                }
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Button variant="secondary" fullWidth onClick={setInitials} disabled={loading}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path d="M7 3.5A1.5 1.5 0 018.5 2h3.879a1.5 1.5 0 011.06.44l3.122 3.12A1.5 1.5 0 0117 6.622V12.5a1.5 1.5 0 01-1.5 1.5h-1v-3.379a3 3 0 00-.879-2.121L10.5 5.379A3 3 0 008.379 4.5H7v-1z" />
                    <path d="M4.5 6A1.5 1.5 0 003 7.5v9A1.5 1.5 0 004.5 18h7a1.5 1.5 0 001.5-1.5v-5.879a1.5 1.5 0 00-.44-1.06L9.44 6.439A1.5 1.5 0 008.378 6H4.5z" />
                </svg>

                <FormattedMessage id="repeat_order" />
            </Button>
            {formContext.dirtyForm && (
                <div style={{opacity: 0.6, fontWeight: "normal", padding: "4px"}}>
                    ⚠ <FormattedMessage id="repeat_order_warning" />
                </div>
            )}
            {error && (
                <div style={{color: "var(--red)", fontWeight: "normal", lineHeight: 1.2}}>
                    <FormattedMessage id="repeat_order_error" />
                </div>
            )}
        </div>
    );
}
