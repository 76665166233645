import {UseFieldArrayRemove, UseFormSetError} from "react-hook-form";
import {z} from "zod";
import {
    BusinessEmployeeDto,
    createEmployee,
    deleteEmployee,
    updateEmployee,
} from "../../api/apiClient";
import {newMobileUserSchema} from "../AddMobileUser";
import {editMobileUserSchema} from "../EditMobileUser";

const mobileUserIdSchema = z.object({
    employeeId: z.string(),
});

const editMobileUserSchemaWithId = editMobileUserSchema.merge(mobileUserIdSchema);

export type AddMobileUserFormValue = z.infer<typeof newMobileUserSchema>;
export type EditMobileUserFormValue = z.infer<typeof editMobileUserSchemaWithId>;

export const bulkSchema = z.object({
    newUsers: z.array(newMobileUserSchema),
    modifiedUsers: z.array(editMobileUserSchemaWithId),
    removedUsers: z.array(mobileUserIdSchema),
});

export type Segments = {
    new: AddMobileUserFormValue[];
    removed: EditMobileUserFormValue[];
    modified: EditMobileUserFormValue[];
    same: BusinessEmployeeDto[];
};

export type FormValues = {
    newUsers: AddMobileUserFormValue[];
    modifiedUsers: EditMobileUserFormValue[];
    removedUsers: EditMobileUserFormValue[];
};

export type CsvUser = {
    name: string;
    phoneNumber: string;
    monthlyLimit: string;
    costCenter: string;
};

export type BulkRequest = {
    data: FormValues;
    ccy: string;
    remove: {
        newUser: UseFieldArrayRemove;
        modifiedUser: UseFieldArrayRemove;
        removedUser: UseFieldArrayRemove;
    };
    setError: UseFormSetError<FormValues>;
};

export function segmentUsers(users: BusinessEmployeeDto[], csvUsers: CsvUser[]) {
    let newItems = [];
    const removedItems = [];
    const modifiedItems = [];
    const sameItems = [];

    if (users.length === 0) {
        newItems = csvUsers;
    } else {
        for (const user of users) {
            const matchedCsvUser = csvUsers.find(
                (csvUser) => csvUser.phoneNumber === user.phoneNumber
            );
            if (matchedCsvUser == null) {
                // Users phone number not in CSV
                removedItems.push({
                    ...user,
                    monthlyLimit: user.monthlyLimit?.amount ? String(user.monthlyLimit.amount) : "",
                });
            } else if (usersHasDifferences(matchedCsvUser, user)) {
                // Users phone number in CSV with different other data
                modifiedItems.push({
                    ...matchedCsvUser,
                    employeeId: user.employeeId,
                    removeAfter: user.removeAfter,
                });
            } else {
                // Users phone number in CSV and other data not different
                sameItems.push(user);
            }
        }
        for (const csvUser of csvUsers) {
            // Users in CSV but not in current users
            const matchedUser = users.find((user) => user.phoneNumber === csvUser.phoneNumber);
            if (!matchedUser) {
                newItems.push(csvUser);
            }
        }
    }

    return {
        new: newItems,
        removed: removedItems,
        modified: modifiedItems,
        same: sameItems,
    };
}

export function usersHasDifferences(csv: CsvUser, current: BusinessEmployeeDto) {
    const currentMonthlyLimit = current.monthlyLimit ? current.monthlyLimit.amount.toString() : "";
    const currentCostCenter = current.costCenter ?? "";
    if (
        csv.name !== current.name ||
        csv.costCenter !== currentCostCenter ||
        csv.monthlyLimit !== currentMonthlyLimit
    ) {
        return true;
    }
    return false;
}

export const bulkEmployeeRequest = async ({data, ccy, remove, setError}: BulkRequest) => {
    const newUserPromises = data.newUsers.map((user) =>
        createEmployee({
            ...user,
            paymentOriginator: user.paymentOriginator ?? "COMPANY", //FIXME: get rid of undefined
            monthlyLimit:
                user.monthlyLimit && user.monthlyLimit.length > 0
                    ? {amount: parseFloat(user.monthlyLimit), ccy}
                    : undefined,
            removeAfter: user.removeAfter ?? undefined,
        })
    );
    const newUserResults = await Promise.allSettled(newUserPromises);
    let newUsersToRemove: number[] = [];
    newUserResults.forEach((result, index) => {
        if (result.status === "fulfilled") {
            newUsersToRemove.push(index);
        } else {
            if (result.reason.response?.status === 409) {
                //or data.code === confict
                setError(`newUsers.${index}.phoneNumber`, {
                    //FIXME for some reason, this won't invalidate form – find out
                    type: "custom",
                    message: "mobile_user_already_in_other_business",
                });
            }
        }
    });

    const updateUserPromises = data.modifiedUsers.map((user) =>
        updateEmployee(
            {
                ...user,
                paymentOriginator: user.paymentOriginator ?? "COMPANY", //FIXME: get rid of undefined
                monthlyLimit:
                    user.monthlyLimit && user.monthlyLimit.length > 0
                        ? {amount: parseFloat(user.monthlyLimit), ccy}
                        : undefined,
                removeAfter: user.removeAfter ?? undefined, //FIXME: I don't want to cry while receiving null from backend and then can't send it back
            },
            user.employeeId
        )
    );
    let modifiedUsersToRemove: number[] = [];
    const modifiedUserResults = await Promise.allSettled(updateUserPromises);
    modifiedUserResults.forEach((result, index) => {
        if (result.status === "fulfilled") {
            modifiedUsersToRemove.push(index);
        }
    });

    const removeUserPromises = data.removedUsers.map((user) => deleteEmployee(user.employeeId));
    let removedUsersToRemove: number[] = [];
    const removedUserResults = await Promise.allSettled(removeUserPromises);
    removedUserResults.forEach((result, index) => {
        if (result.status === "fulfilled") {
            removedUsersToRemove.push(index);
        }
    });

    const allResults = [...newUserResults, ...modifiedUserResults, ...removedUserResults];

    const allFailed = allResults.every((result) => result.status === "rejected");
    const failedOnlyExisting = allResults
        .filter((result): result is PromiseRejectedResult => result.status === "rejected")
        .every((result) => result.reason?.response?.status === 409);
    const allSucceed = allResults.every((result) => result.status === "fulfilled");

    remove.newUser(newUsersToRemove);
    remove.modifiedUser(modifiedUsersToRemove);
    remove.removedUser(removedUsersToRemove);
    const resolution = allSucceed ? "success" : allFailed ? "failed" : "partially";
    return {
        resolution,
        failedOnlyExisting,
        newUsersToRemove,
        modifiedUsersToRemove,
        removedUsersToRemove,
    } as const;
};
