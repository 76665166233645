import {debounce} from "lodash";
import {useEffect, useMemo, useState} from "react";
import {Menu, MenuItem, Typeahead} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {
    Location,
    LocationSuggestion,
    LocationSuggestionsRequest,
    getLocationSuggestions,
} from "../api/apiClient";
import {formatMoney} from "../app/moneyUtils";
import locationIcon from "../img/ic_location.svg";
import locationHistoryIcon from "../img/ic_location_history.svg";
import locationPredefinedIcon from "../img/ic_location_predefined.svg";
import {LocationSuggestionWithId} from "../types/types";
import { generateUUID } from "../utils/generateUUID";

export interface SearchPlacesInputProps {
    name: string;
    placeholder?: string;
    addressType?: LocationSuggestionsRequest["addressType"];
    pickup?: Location;
    serviceType: LocationSuggestionsRequest["serviceType"];
    tripId?: string;
    allowPredefinedPrice?: boolean;
    value: LocationSuggestion | undefined;
    onChange: (selected: (LocationSuggestion | undefined)[]) => void;
    onBlur?: () => void;
}

export function SearchPlacesInput({
    name,
    placeholder,
    addressType,
    pickup,
    serviceType,
    tripId,
    allowPredefinedPrice,
    value,
    onChange,
    onBlur,
}: SearchPlacesInputProps) {
    const [locationOptions, setLocationOptions] = useState<LocationSuggestionWithId[]>([]);
    const intl = useIntl();

    const suggestSessionId = useMemo(() => generateUUID(), []);

    async function handleSearch(query: string) {
        try {
            const response = await getLocationSuggestions({
                serviceType,
                query,
                addressType,
                pickup,
                suggestSessionId,
                tripId,
            });
            setLocationOptions(
                response.suggestions.map((suggestion) => ({
                    ...suggestion,
                    suggestSessionId,
                }))
            );
        } catch {}
    }

    const debouncedSearch = debounce(handleSearch, 200);

    function createLocationArray(value?: LocationSuggestion): LocationSuggestion[] {
        if (value != null) {
            return [value];
        } else {
            return [];
        }
    }

    useEffect(() => {
        if (value?.location.id == null) {
            handleSearch("");
        }
    }, [pickup?.id ?? "", value?.location.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Typeahead
            id={name}
            inputProps={{name}}
            labelKey={(option) => option.location.address}
            filterBy={() => true}
            selected={createLocationArray(value)}
            highlightOnlyResult={true}
            onInputChange={debouncedSearch}
            onBlur={onBlur}
            onChange={onChange}
            options={locationOptions}
            placeholder={placeholder}
            emptyLabel={intl.formatMessage({id: "no_address_found"})}
            renderMenu={(results, menuProps) => (
                <Menu {...menuProps}>
                    {results.map((result, index) => (
                        <MenuItem
                            key={index}
                            option={result}
                            position={index}
                            className="no-padding"
                        >
                            <MenuItemContent
                                suggestion={result}
                                allowPredefinedPrice={!!allowPredefinedPrice}
                            />
                        </MenuItem>
                    ))}
                </Menu>
            )}
        />
    );
}

function MenuItemContent({
    suggestion,
    allowPredefinedPrice,
}: {
    suggestion: LocationSuggestion;
    allowPredefinedPrice: boolean;
}) {
    const intl = useIntl();

    const [addressFirstLine, ...addressRest] = suggestion.location.address.split(",");
    const addressSecondLine = addressRest.join(",").trim();

    let icon = null;
    switch (suggestion.type) {
        case "PREDEFINED_PLACE":
            icon = locationPredefinedIcon;
            break;
        case "HISTORY":
            icon = locationHistoryIcon;
            break;
        case "SEARCH":
            icon = locationIcon;
            break;
    }

    return (
        <MenuItemView>
            <div className="icon">
                <img src={icon} alt="" />
            </div>
            <div className="address">
                <div>{addressFirstLine}</div>
                {addressSecondLine && <div className="text-muted">{addressSecondLine}</div>}
            </div>
            {suggestion.price && allowPredefinedPrice && (
                <div className="price">{formatMoney(suggestion.price, intl)}</div>
            )}
        </MenuItemView>
    );
}

const MenuItemView = styled.div`
    display: flex;
    align-items: center;

    > div {
        padding: 5px;
    }

    .address {
        white-space: normal;
        flex-shrink: 10;
        overflow: auto;
        .text-muted {
            font-size: 90%;
        }
        > div {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .price {
        flex-shrink: 0;
        font-weight: bold;
        color: var(--primary);
        margin-left: auto;
    }
`;
