import {useQueryClient} from "@tanstack/react-query";
import {Formik} from "formik";
import posthog from "posthog-js";
import {useContext, useRef} from "react";
import toast from "react-hot-toast";
import {IntlShape, useIntl} from "react-intl";
import * as yup from "yup";
import {
    CompanyInfo,
    TaxiOrderRequest,
    TaxiOvervewTierData,
    TaxiOverviewResponse,
    useCostCenters,
    useCreateTaxiOrder,
    useProjectCodeSettings,
} from "../api/apiClient";
import {queryClient} from "../app/App";
import {BusinessStatus} from "../app/BusinessStatus";
import {FormWrapper} from "../app/layout";
import {taxiStopsPayload} from "../helpers/taxiStopsWithLocation";
import {LocationSuggestionWithId} from "../types/types";
import {createCachedPhoneNumberValidator} from "../utils/cachedPhoneNumberValidator";
import {clearSpaces} from "../utils/formatString";
import {generateUUID} from "../utils/generateUUID";
import {TaxiOrderFormView} from "./TaxiOrderFormView";
import {FormValues, defaultInitValues} from "./TaxiPage";

export type PaymentType = "company" | "cash" | "credit_card";
export type CarType = "premium" | "all";

export interface Pickup {
    name: string;
    phoneNumber: string;
    location?: LocationSuggestionWithId;
    noteForDriver: string;
}
export interface TaxiOrderFormValues {
    pickups: Pickup[];
    dropoffs: (LocationSuggestionWithId | undefined)[];
    paymentType: PaymentType;
    projectCode: string;
    costCenter: string;
    internalNote: string;
    preorderType: "pickup" | "dropoff";
    pickupTimeType: "now" | "15min" | "later";
    preorderTime?: Date;
    flightNumber: string;
    driverCallsOrderer: boolean;
    specifiedDriverId: string;
    tierId: string;
    id: string;
}

function validationSchema({
    intl,
    projectCodeRequired,
    optionalPhoneNumber,
}: {
    intl: IntlShape;
    projectCodeRequired: boolean;
    optionalPhoneNumber: boolean;
}) {
    const phoneValidator = createCachedPhoneNumberValidator();

    const address = yup
        .object()
        .nullable()
        .required(intl.formatMessage({id: "required"}))
        .test(
            "isAddressComplete",
            intl.formatMessage({id: "incomplete_address"}),
            (value) => value?.complete === true
        );

    const noteForDriver = yup.string().max(300, intl.formatMessage({id: "too_long"}));

    const name = yup.string().required(intl.formatMessage({id: "required"}));

    const pickup = yup.object().shape({
        location: address,
        name,
        phoneNumber: yup
            .string()
            .required(intl.formatMessage({id: "required"}))
            .test(
                "checkPhoneNumber",
                intl.formatMessage({id: "invalid_phone_number"}),
                phoneValidator
            ),
        noteForDriver,
    });

    const pickupWithOptionaPhone = yup.object().shape({
        location: address,
        name,
        phoneNumber: yup
            .string()
            .test(
                "checkPhoneNumber",
                intl.formatMessage({id: "invalid_phone_number"}),
                phoneValidator
            ),
        noteForDriver,
    });
    return yup.object().shape({
        pickups: yup.array().when({
            is: (pickups: any) => optionalPhoneNumber && pickups.length === 1,
            then: yup.array().of(pickupWithOptionaPhone),
            otherwise: yup.array().of(pickup),
        }),
        dropoffs: yup.array().of(address),
        paymentType: yup.string(),
        carType: yup.string(),
        flightNumber: yup
            .string()
            .matches(/^[A-Za-z\s]+\d+$/, intl.formatMessage({id: "invalid_flight_number"}))
            .when(["tierId", "pickupTimeType"], {
                is: (
                    tierId: TaxiOvervewTierData["tierId"],
                    pickupTimeType: TaxiOrderFormValues["pickupTimeType"]
                ) => tierId === "TIER_AIRPORT_TRANSFER" && pickupTimeType === "later",
                then: yup.string().required(intl.formatMessage({id: "required"})),
            }),
        projectCode: projectCodeRequired
            ? yup.string().when(["paymentType"], {
                  is: (paymentType: PaymentType) => paymentType === "company",
                  then: yup.string().required(intl.formatMessage({id: "required"})),
              })
            : yup.string(),
        costCenter: yup.string(),
        internalNote: yup.string(),
        specifiedDriverId: yup.number().typeError(intl.formatMessage({id: "invalid_driver_id"})),
    });
}

function createTaxiPayload(values: TaxiOrderFormValues, tripId: string): TaxiOrderRequest {
    const taxiOverviewData: TaxiOverviewResponse | undefined = queryClient.getQueryData([
        "taxiOverview",
    ]);

    return {
        stops: taxiStopsPayload(values.pickups, values.dropoffs),
        paidByPassenger: values.paymentType !== "company",
        rideParams: {
            acceptsCreditCards: values.paymentType === "credit_card",
            premiumCar: false, //FIXME: Deprecates with introduction of TaxiOverview tiers
        },
        costCenter:
            values.paymentType === "company" ? values.costCenter.trim() || undefined : undefined,
        projectCode:
            values.paymentType === "company" ? values.projectCode.trim() || undefined : undefined,
        internalNote: values.internalNote || undefined,
        driverCallsOrderer: !values.pickups[0].phoneNumber && values.pickups.length < 2,
        type:
            values.pickupTimeType === "later"
                ? "PREORDER"
                : values.pickupTimeType === "15min"
                ? "SHORT_PREORDER"
                : "NORMAL",
        requestedPickupAt:
            values.pickupTimeType === "later" && values.preorderType === "pickup"
                ? values.preorderTime?.toISOString()
                : undefined,
        requestedDropOffAt:
            values.pickupTimeType === "later" && values.preorderType === "dropoff"
                ? values.preorderTime?.toISOString()
                : undefined,
        flightNumber:
            values.pickupTimeType === "later"
                ? clearSpaces(values.flightNumber) || undefined
                : undefined,
        specifiedDriverId: values.specifiedDriverId.trim() || undefined,
        orderUuid: generateUUID(),
        priceToken:
            values.pickupTimeType === "later"
                ? taxiOverviewData?.tiers.find((tier) => tier.tierId === values.tierId)?.priceToken
                : undefined,
        tierId: values.tierId || "TIER_DEFAULT",
    };
}

export function TaxiOrderForm({company}: {company: CompanyInfo}) {
    const intl = useIntl();
    const taxiOrder = useCreateTaxiOrder();
    const queryClient = useQueryClient();
    const {data: projectCodes} = useProjectCodeSettings();
    const {data: costCenters} = useCostCenters();
    const {initValues, setInitValues} = useContext(FormValues);

    const tripIdRef = useRef(generateUUID());
    const tripId = tripIdRef.current;

    async function handleSubmit(values: TaxiOrderFormValues) {
        if (!company.disabledInfo.ridesEnabled) {
            toast.error(intl.formatMessage({id: "business_account_not_active"}));
            return;
        }
        await taxiOrder.mutateAsync(createTaxiPayload(values, tripId), {
            onSuccess: () => {
                toast.success(intl.formatMessage({id: "order_created"}));
                posthog.capture("taxiOrdered"); //FIXME remove in October23 and use only "orderCreated"
                posthog.capture("orderCreated", {type: "taxi"});
                setInitValues({
                    ...defaultInitValues,
                    paymentType:
                        company?.horecaEnabled || !company?.paymentByCompanyAllowed
                            ? "cash"
                            : "company",
                    id: generateUUID(),
                });
                tripIdRef.current = generateUUID();
                queryClient.invalidateQueries(["/self-service/orderer/projectCodes"]);
                queryClient.invalidateQueries(["/self-service/orderer/costCenters"]);
            },
            onError: () => {
                queryClient.invalidateQueries(["taxiOverview"]);
                toast.error(intl.formatMessage({id: "order_failed_title"}));
            },
        });
    }

    const projectCodeRequired = projectCodes?.mode === "REQUIRED";
    const optionalPhoneNumber = company.optionalPassengerPhoneNumber;

    return (
        <FormWrapper>
            <BusinessStatus />
            <Formik
                initialValues={initValues}
                enableReinitialize
                validationSchema={validationSchema({
                    intl,
                    projectCodeRequired,
                    optionalPhoneNumber,
                })}
                onSubmit={handleSubmit}
            >
                <TaxiOrderFormView
                    {...{
                        projectCodes,
                        costCenters,
                        company,
                        taxiOrder,
                        tripId,
                    }}
                />
            </Formik>
        </FormWrapper>
    );
}
