import {ReactNode} from "react";
import {FieldArrayWithId, FieldErrors, UseFormRegister} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {BusinessEmployeeDto} from "../../api/apiClient";
import {formatUserPaymentType} from "../../helpers/formatUserPaymentType";
import {formatMoney} from "../moneyUtils";
import {ErrorIconLabel} from "./ErrorIconLabel";
import {FormValues} from "./bulkModel";

export function TableHead({
    showMonthlyLimit,
    showPaymentType,
}: {
    showMonthlyLimit: boolean;
    showPaymentType: boolean;
}) {
    return (
        <thead>
            <tr>
                <StyledTh></StyledTh>
                <StyledTh>
                    <FormattedMessage id="name" />
                </StyledTh>
                <StyledTh>
                    <FormattedMessage id="phone_number" />
                </StyledTh>
                {showMonthlyLimit && (
                    <StyledTh>
                        <FormattedMessage id="monthly_limit" />
                        {/* (<FormattedMessage id="optional" />) FIXME*/}
                    </StyledTh>
                )}
                <StyledTh>
                    <FormattedMessage id="cost_center" />
                    {/* (<FormattedMessage id="optional" />) */}
                </StyledTh>
                {showPaymentType && (
                    <StyledTh>
                        <FormattedMessage id="payment_type" />
                    </StyledTh>
                )}
            </tr>
        </thead>
    );
}

export function UsersTableInteractive({
    userFields,
    fieldsName,
    register,
    errors,
    showMonthlyLimit,
    showPaymentType,
    ccy,
    title,
    isDisabled,
}: {
    userFields: FieldArrayWithId<FormValues, "modifiedUsers" | "newUsers" | "removedUsers", "id">[];
    fieldsName: "newUsers" | "modifiedUsers" | "removedUsers";
    register: UseFormRegister<FormValues>;
    errors: FieldErrors<FormValues>;
    showMonthlyLimit: boolean;
    showPaymentType: boolean;
    ccy: string;
    title: ReactNode;
    isDisabled: boolean;
}) {
    const intl = useIntl();
    return (
        <>
            <SectionTitle>
                {title} ({userFields.length})
            </SectionTitle>
            {userFields.length > 0 && (
                <StyledTable>
                    <TableHead {...{showMonthlyLimit, showPaymentType}} />
                    <tbody>
                        {userFields.map((field, index) => (
                            <tr key={field.id}>
                                <StyledTd>{index + 1}</StyledTd>
                                <StyledTd error={!!errors[fieldsName]?.[index]?.name}>
                                    <StyledInput
                                        type="text"
                                        {...register(`${fieldsName}.${index}.name` as const)}
                                        readOnly={fieldsName === "removedUsers" || isDisabled}
                                    />
                                    {errors[fieldsName]?.[index]?.name?.message && (
                                        <ErrorIconLabel>
                                            <FormattedMessage
                                                id={errors[fieldsName]?.[index]?.name?.message}
                                            />
                                        </ErrorIconLabel>
                                    )}
                                </StyledTd>
                                <StyledTd error={!!errors[fieldsName]?.[index]?.phoneNumber}>
                                    <StyledInput
                                        type="tel"
                                        {...register(`${fieldsName}.${index}.phoneNumber` as const)}
                                        readOnly
                                    />
                                    {errors[fieldsName]?.[index]?.phoneNumber?.message && (
                                        <ErrorIconLabel>
                                            <FormattedMessage
                                                id={
                                                    errors[fieldsName]?.[index]?.phoneNumber
                                                        ?.message
                                                }
                                            />
                                        </ErrorIconLabel>
                                    )}
                                </StyledTd>
                                {showMonthlyLimit && (
                                    <StyledTd error={!!errors[fieldsName]?.[index]?.monthlyLimit}>
                                        <MoneyInput>
                                            <StyledInput
                                                type="text"
                                                inputMode="decimal"
                                                {...register(
                                                    `${fieldsName}.${index}.monthlyLimit` as const
                                                )}
                                                readOnly={
                                                    fieldsName === "removedUsers" || isDisabled
                                                }
                                                placeholder={intl.formatMessage({
                                                    id: "no_limit",
                                                })}
                                                style={{
                                                    maxWidth: "130px",
                                                    paddingRight: "40px",
                                                }}
                                            />
                                            <Currency>
                                                <FormattedMessage id={ccy} />
                                            </Currency>
                                        </MoneyInput>
                                        {errors[fieldsName]?.[index]?.monthlyLimit?.message && (
                                            <ErrorIconLabel>
                                                <FormattedMessage
                                                    id={
                                                        errors[fieldsName]?.[index]?.monthlyLimit
                                                            ?.message
                                                    }
                                                />
                                            </ErrorIconLabel>
                                        )}
                                    </StyledTd>
                                )}
                                <StyledTd>
                                    <StyledInput
                                        type="text"
                                        {...register(`${fieldsName}.${index}.costCenter` as const)}
                                        readOnly={fieldsName === "removedUsers" || isDisabled}
                                    />
                                </StyledTd>
                                {showPaymentType && (
                                    <StyledTd>
                                        <StyledSelect
                                            autoComplete="nope"
                                            {...register(
                                                `${fieldsName}.${index}.paymentOriginator` as const
                                            )}
                                            disabled={fieldsName === "removedUsers" || isDisabled}
                                        >
                                            <option value="COMPANY">
                                                {intl.formatMessage({
                                                    id: "payment_originator_company",
                                                })}
                                            </option>
                                            <option value="EMPLOYEE">
                                                {intl.formatMessage({
                                                    id: "payment_originator_employee",
                                                })}
                                            </option>
                                        </StyledSelect>
                                    </StyledTd>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </StyledTable>
            )}
        </>
    );
}

export function UsersTableNonInteractive({
    users,
    showMonthlyLimit,
    showPaymentType,
    ccy,
    title,
}: {
    users: BusinessEmployeeDto[];
    showMonthlyLimit: boolean;
    showPaymentType: boolean;
    ccy: string;
    title: ReactNode;
}) {
    const intl = useIntl();

    return (
        <>
            <details>
                <summary>
                    <SectionTitle style={{display: "inline-block"}}>
                        {title} ({users.length})
                    </SectionTitle>
                </summary>
                {users.length > 0 && (
                    <StyledTable>
                        <TableHead {...{showMonthlyLimit, showPaymentType}} />
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={user.phoneNumber}>
                                    <Td>{index + 1}</Td>
                                    <Td>{user.name}</Td>
                                    <Td>{user.phoneNumber}</Td>
                                    {showMonthlyLimit && (
                                        <Td>
                                            {user.monthlyLimit ? (
                                                formatMoney(user.monthlyLimit, intl)
                                            ) : (
                                                <FormattedMessage id="no_limit" />
                                            )}
                                        </Td>
                                    )}
                                    <Td>{user.costCenter}</Td>
                                    {showPaymentType && (
                                        <Td>{formatUserPaymentType(user.paymentOriginator)}</Td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </StyledTable>
                )}
            </details>
        </>
    );
}

const SectionTitle = styled.h2`
    margin-top: 4px;
    margin-bottom: 8px;
`;

const MoneyInput = styled.div`
    position: relative;
    align-self: flex-start;
`;

const Currency = styled.span`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`;

const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    table-layout: auto;
    margin-bottom: 24px;
`;

const StyledTh = styled.th`
    text-align: left;
    border: 1px solid var(--light);
    padding: 8px;
    background-color: var(--light);
`;

const StyledTd = styled.td<{error?: boolean}>`
    position: relative;
    &:first-of-type {
        color: var(--secondary);
        text-align: center;
        background-color: var(--light);
    }
    text-align: left;
    border: 1px solid var(--light);
    //FIXME: fix this maddness
    &:focus-within,
    &:focus-within:hover {
        outline: 2px solid var(--primary);
        outline-offset: -1px;
        ${(p) => p.error && `outline: 2px solid var(--red);`}
    }
    &:hover {
        &:focus-within,
        :focus-within:hover {
            outline: 2px solid var(--primary);
            outline-offset: -1px;
        }
        &:not(:has(input:disabled, input:read-only)) {
            outline: 1px solid var(--quaternary);
            outline-offset: -0.5px;
            ${(p) => p.error && `outline: 2px solid var(--red);outline-offset: -1px;`}
        }
    }
    &input:disabled,
    input:read-only {
        color: var(--secondary);
        cursor: not-allowed;
    }
    ${(p) =>
        p.error &&
        `outline: 1px solid var(--red);outline-offset: -0.5px;background-color: var(--red-light);`}
`;

const StyledInput = styled.input`
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    margin: 0;
    border: none;
    outline: none;
    transition: border-color 0.3s; /* Add a smooth transition effect for the border color */
    background-color: transparent;
    &:disabled,
    :read-only {
        color: var(--secondary);
        cursor: not-allowed;
    }
`;

const StyledSelect = styled.select`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    padding: 8px;
    &:disabled,
    :read-only {
        color: var(--secondary);
        cursor: not-allowed;
    }
`;

const Td = styled.td`
    padding: 8px;
    font-weight: var(--font-weight-thin);
    text-align: left;
    border: 1px solid var(--light);
    &:first-of-type {
        color: var(--secondary);
        text-align: center;
        background-color: var(--light);
    }
`;
