import styled from "styled-components";
import {Shimmer} from "./Shimmer";

export function TierSkeleton() {
    return (
        <Radio>
            <Shimmer w="24" h="24" radius="24" />
            <Content>
                <Row>
                    <Shimmer w="40" />
                    <Shimmer w="40" />
                </Row>
                <Row>
                    <Shimmer w="80" />
                    <Shimmer />
                </Row>
            </Content>
        </Radio>
    );
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: var(--font-weight-normal);
`;

const Radio = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 12px;
    margin: 0 -8px;
`;

const Content = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
