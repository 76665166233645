import {AxiosResponse} from "axios";
import {Buffer} from "buffer";

export async function downloadInvoicingSummaryFile(filename: string, fetchSummary: () => Promise<AxiosResponse>) {
    let response = await fetchSummary();

    while (response.status === 202) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        response = await fetchSummary();
    }

    const decodedBytes = Buffer.from(response.data, "base64");
    
    const url = window.URL.createObjectURL(new Blob([decodedBytes]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
}