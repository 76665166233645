import {Alert} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {useCallerCompany} from "../api/apiClient";
import {useUserInfo} from "../auth/authModel";
import {Button} from "../components/Button";
import {isMonthlyRequestPending} from "../helpers/isMonthlyRequestPending";
import {ExpiredCardBanner} from "./ExpiredCardBanner";

export function BusinessStatus() {
    const {data: user, isLoading: isCompanyLoading} = useUserInfo();
    const {data: company, isLoading: isUserLoading} = useCallerCompany();
    const navigate = useNavigate();

    if (company == null || user == null || isCompanyLoading || isUserLoading) {
        return null;
    }

    const {ridesEnabled, disabled, hasCard, cardExpired} = company.disabledInfo;
    const isNotAdmin = !user?.roles.includes("BUSINESS_ADMIN");

    if (ridesEnabled) {
        return null;
    }

    if (isNotAdmin) {
        return (
            <Alert variant="warning">
                <FormattedMessage
                    id="company_not_active"
                    values={{
                        a: (link: string) => <a href={`mailto:${link}`}>{link}</a>,
                    }}
                />
            </Alert>
        );
    }

    if (isMonthlyRequestPending(company.monthlyPaymentRequestedAt)) {
        return (
            <Alert variant="secondary">
                <FormattedMessage
                    id="monthly_payment_request_pending"
                    values={{
                        link: (link: string) => (
                            <Link to="admin/billing" style={{textDecoration: "underline"}}>
                                {link}
                            </Link>
                        ),
                    }}
                />
            </Alert>
        );
    }

    if (disabled) {
        return (
            <Alert variant="warning">
                <FormattedMessage
                    id="company_disabled"
                    values={{
                        a: (link: string) => <a href={`mailto:${link}`}>{link}</a>,
                    }}
                />
            </Alert>
        );
    }

    if (cardExpired && company.tier === "BASIC") {
        return <ExpiredCardBanner />;
    }

    if (!hasCard && company.tier === "BASIC") {
        return (
            <Card>
                <div>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.40118 3.00293C10.5562 1.00293 13.4442 1.00293 14.5982 3.00293L21.9532 15.7509C23.1072 17.7509 21.6632 20.2509 19.3542 20.2509H4.64518C2.33618 20.2509 0.893179 17.7509 2.04718 15.7509L9.40018 3.00293H9.40118ZM12.0002 8.24993C12.1991 8.24993 12.3899 8.32895 12.5305 8.4696C12.6712 8.61025 12.7502 8.80102 12.7502 8.99993V12.7499C12.7502 12.9488 12.6712 13.1396 12.5305 13.2803C12.3899 13.4209 12.1991 13.4999 12.0002 13.4999C11.8013 13.4999 11.6105 13.4209 11.4698 13.2803C11.3292 13.1396 11.2502 12.9488 11.2502 12.7499V8.99993C11.2502 8.80102 11.3292 8.61025 11.4698 8.4696C11.6105 8.32895 11.8013 8.24993 12.0002 8.24993ZM12.0002 16.4999C12.1991 16.4999 12.3899 16.4209 12.5305 16.2803C12.6712 16.1396 12.7502 15.9488 12.7502 15.7499C12.7502 15.551 12.6712 15.3603 12.5305 15.2196C12.3899 15.0789 12.1991 14.9999 12.0002 14.9999C11.8013 14.9999 11.6105 15.0789 11.4698 15.2196C11.3292 15.3603 11.2502 15.551 11.2502 15.7499C11.2502 15.9488 11.3292 16.1396 11.4698 16.2803C11.6105 16.4209 11.8013 16.4999 12.0002 16.4999Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
                <CardContent>
                    <h2>
                        <FormattedMessage id="business_account_not_active" />
                    </h2>
                    <Text>
                        <FormattedMessage id="business_account_not_active_description" />
                    </Text>
                    <Action>
                        <Button variant="outline" onClick={() => navigate("/admin/billing")}>
                            <FormattedMessage id="choose_payment_method_action" />
                        </Button>
                    </Action>
                </CardContent>
            </Card>
        );
    }

    return (
        <Alert variant="warning">
            <FormattedMessage
                id="company_not_active"
                values={{
                    a: (link: string) => <a href={`mailto:${link}`}>{link}</a>,
                }}
            />
        </Alert>
    );
}

//TODO: Export Card
const Card = styled.div`
    display: flex;
    gap: 16px;
    border-radius: 16px;
    padding: 24px 20px;
    margin-bottom: 24px;
    border: 1px solid #ffe8d1;
    box-shadow: #ffe8d1 0px 1px 1px 0px;
    background: linear-gradient(175deg, #fffbf7 0%, #fffdfb 50%);
    & svg {
        color: #ff8000;
    }
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Text = styled.p`
    color: var(--secondary);
    font-weight: 400;
`;

const Action = styled.div`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 8px;
`;
