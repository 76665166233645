import {AdvancedMarker, Map, useMap} from "@vis.gl/react-google-maps";
import {ChangeEvent, useEffect, useState} from "react";
import {Typeahead, TypeaheadMenu} from "react-bootstrap-typeahead";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {
    Location,
    TaxiOrderRequest,
    getLocationSuggestions,
    taxiOverview,
    useCostCenters,
    useCreateTaxiOrder,
    useProjectCodeSettings,
} from "../api/apiClient";
import {Input} from "../app/Registration";
import {formatTime, getDurationInMinutes} from "../app/dateUtils";
import {Content} from "../app/layout";
import {getPrice} from "../app/moneyUtils";
import {Button} from "../components/Button";
import {Lozenge} from "../components/Lozenge";
import {SearchPlacesInput} from "../components/SearchPlacesInput";
import {taxiStopsPayload} from "../helpers/taxiStopsWithLocation";
import {useKeyPress} from "../hooks/useKeyPress";
import {LocationSuggestionWithId} from "../types/types";
import {transformValues} from "../utils/csvUtils";
import {generateUUID} from "../utils/generateUUID";
import {updateLocationCoords} from "../utils/updateLocationCoords";
import {Polyline} from "./Polyline";
import {Pickup} from "./TaxiOrderForm";

//FIXME: Get rid of initial placeholder data and check all undefined values ale separate into another component

function getCoords(location: Location) {
    return {
        lat: location.lat || 0, //FIXME: fallback not good
        lng: location.lon || 0, //FIXME: fallback not good
    };
}

function formatDropoffTime(time: string) {
    const [hours, minutes] = time.split(":");
    const formattedHours = hours.padStart(2, "0");
    return `${formattedHours}:${minutes}`;
}

const initialPickups = [
    {
        pickup: {
            name: "Teston",
            phoneNumber: "+93607222333",
            noteForDriver: "někdy je potřeba zazvonit",
            location: {
                location: {
                    address: "Placeholder",
                    id: "",
                    type: "ADDRESS",
                    lat: 50,
                    lon: 14,
                },
                complete: true,
                type: "SEARCH",
                price: undefined,
            },
        },
        label: "00:00",
        group: "00:00",
        id: generateUUID(),
        selected: false,
        highlighted: false,
        sorted: false,
        order: undefined,
        selectedOrder: 0,
        dropoffTime: "",
        departureTime: "",
        route: "",
        priceToken: "",
        tierId: "",
        rideDuration: "",
        price: "",
    },
];

type PickupForRouting = typeof initialPickups[number];

function isPickupsForRoutingType(obj: any): obj is PickupForRouting[] {
    return obj !== undefined;
}

// type PickupTableData = {
//     label: string;
//     name: string;
//     phoneNumber: string;
//     address: string;
//     noteForDriver: string;
// };

// function isPickupTableData(obj: any): obj is PickupTableData {
//     return obj !== undefined;
// }

const MAX_PICKUPS = 3;

const groupBy = <T extends Record<string, unknown>, K extends keyof T>(
    arr: readonly T[],
    keyProperty: K
) =>
    arr.reduce((output, item) => {
        const key = String(item[keyProperty]);
        output[key] ||= [];
        output[key].push(item);
        return output;
    }, {} as Record<string, T[]>);

function getSavedDestination() {
    const data = localStorage.getItem("route-planner-destination");
    if (data == null) {
        return undefined;
    }
    return JSON.parse(data);
}

export function RoutePlanner() {
    const [pickups, setPickups] = useState(initialPickups);
    const [destination, setDestination] = useState<LocationSuggestionWithId | undefined>(
        getSavedDestination
    );
    const [dropoffDate, setDropoffDate] = useState(
        new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split("T")[0]
    );
    const [costCenter, setCostCenter] = useState("");
    const [projectCode, setProjectCode] = useState("");
    const [previewMode, setPreviewMode] = useState(false);
    const {data: costCenters} = useCostCenters();
    const {data: projectCodes} = useProjectCodeSettings();
    const taxiOrder = useCreateTaxiOrder();
    const map = useMap();
    const intl = useIntl();
    //const navigate = useNavigate();
    useKeyPress(83, () => handleNewGroup());

    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener("paste", () => handlePaste);

        // Clean up the event listener when component unmounts
        return () => {
            document.removeEventListener("paste", () => handlePaste);
        };
    }, []);

    useEffect(() => {
        if (!map) return;

        let boundsData = pickups;
        if (pickups.some((p) => p.highlighted) && !previewMode) {
            boundsData = pickups.filter((p) => p.highlighted);
        }
        const bounds = new google.maps.LatLngBounds();
        boundsData?.forEach((item) =>
            bounds.extend({
                lat: item.pickup.location.location.lat,
                lng: item.pickup.location.location.lon,
            })
        );
        if (destination?.location.lat && destination.location.lon) {
            bounds.extend({lat: destination?.location.lat, lng: destination?.location.lon});
        }
        map.fitBounds(bounds);
    }, [map, pickups, destination]); //TODO: Maybe too expensive to call after every pickup change 🤷‍♂️

    useEffect(() => {
        //Reload warning
        const unloadCallback = (event: any) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    async function handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
        const clipText = e.clipboardData.getData("text/plain");
        const splitToRows = clipText.split(/\r\n|\n|\r/g);
        const splitToRowsAndCols = splitToRows.map((row) => row.split("\t"));
        const formattedData = splitToRowsAndCols
            .filter((row) => row.some((cell) => cell.trim() !== "")) // Filter out empty rows
            .map((row) => ({
                label: row[0],
                name: row[1],
                phoneNumber: row[2],
                address: row[3],
                noteForDriver: row[4],
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        // FIXME: debug why this is not working and add some validation on input
        // if (isPickupTableData(formattedData) === false) {
        //     alert("Data in wrong format. Unable to parse it");
        //     return;
        // }
        const pickupPromises = Promise.all(
            formattedData.map(async (stop, index) => {
                try {
                    const locationResponse = await getLocationSuggestions({
                        serviceType: "TAXI",
                        query: stop.address,
                        addressType: "PICKUP",
                        suggestSessionId: generateUUID(),
                        tripId: generateUUID(),
                    });

                    if (locationResponse.suggestions.length === 0) {
                        throw Error(
                            `Can't find location for address ${stop.address} (row: ${index})`
                        );
                    }

                    const locationWithCoords = await updateLocationCoords(
                        locationResponse.suggestions[0]
                    );

                    return {
                        pickup: {
                            name: stop.name,
                            phoneNumber: transformValues(stop.phoneNumber, "phoneNumber") || "",
                            noteForDriver: stop.noteForDriver || "",
                            location: locationWithCoords,
                        },
                        label: stop.label,
                        group: stop.label,
                        id: generateUUID(),
                        selected: false,
                        highlighted: false,
                        sorted: false,
                        order: undefined,
                        selectedOrder: 0,
                        dropoffTime: formatDropoffTime(stop.label),
                    };
                } catch (error) {
                    throw error;
                }
            })
        );

        const pickups = (await toast.promise(pickupPromises, {
            loading: "Fetching locations...",
            success: "Fetched successfully",
            error: (e) => e.toString(),
        })) as PickupForRouting[];

        if (pickups.some((p) => p == null || p.pickup.location == null)) {
            toast.error("Some data didn't load properly");
        } else if (isPickupsForRoutingType(pickups)) {
            setPickups(pickups);
        } else {
            toast.error("Something went wrong");
        }
    }

    const selectedCount = pickups.filter((p) => p.selected).length;

    function handleHightlight(key: string) {
        const newState = pickups.map((p) => {
            if (p.group === key) {
                p.highlighted = !p.highlighted;
            } else {
                p.highlighted = false;
            }
            return p;
        });
        setPickups(newState);
    }

    function cleanSelectedOrder(removedOrder: number) {
        const newPickups = pickups.map((p) => {
            if (p.selectedOrder > removedOrder) {
                p.selectedOrder = p.selectedOrder - 1;
                return p;
            }
            return p;
        });
        setPickups(newPickups);
    }

    function handleSelect(pickup: PickupForRouting) {
        const isMaxSelected = pickups.filter((p) => p.selected).length >= MAX_PICKUPS;
        const lastSelectedOrder = pickups.filter((p) => p.selectedOrder > 0).length;

        const newState = pickups.map((p) => {
            if (p.id === pickup.id) {
                if (isMaxSelected && !pickup.selected) {
                    toast.error(`Max pickups (${MAX_PICKUPS}) selected`);
                    return p;
                }
                if (pickup.selected) {
                    cleanSelectedOrder(pickup.selectedOrder);
                    p.selected = false;
                    p.selectedOrder = 0;
                } else {
                    p.selected = true;
                    p.selectedOrder = lastSelectedOrder + 1;
                }
            }
            return p;
        });
        setPickups(newState);
    }

    function handleDropoffTimeChange(key: string, event: ChangeEvent<HTMLInputElement>) {
        const newState = pickups.map((p) => {
            if (p.group === key) {
                p.dropoffTime = event.target.value;
            }
            return p;
        });
        setPickups(newState);
    }

    async function handleNewGroup() {
        try {
            if (!destination) {
                toast.error("Enter destination first");
                return;
            }
            const selectedPickups = pickups
                .sort((a, b) => a.selectedOrder - b.selectedOrder)
                .filter((p) => p.selected);

            const alreadySelectedGroups = selectedPickups
                .filter((p) => p.group)
                .map((p) => p.group);
            const newGroupId = generateUUID();

            const pickupStops = selectedPickups.map((p) => p.pickup) as Pickup[];

            const preorderUTCTime = new Date(
                `${dropoffDate}T${selectedPickups[0].dropoffTime}:00`
            ).toISOString();

            const overview = await taxiOverview({
                stops: taxiStopsPayload(pickupStops, [destination]),
                requestedDropOffAt: preorderUTCTime,
                rideParams: {
                    acceptsCreditCards: false,
                    premiumCar: false,
                },
                orderType: "PREORDER",
                tripId: newGroupId, //FIXME: relevant tripId
            });

            let selectedIndex = 0;
            const newPickups = pickups.map((p) => {
                if (p.selected) {
                    p.group = newGroupId;
                    p.sorted = true;
                    p.selected = false;
                    p.selectedOrder = 0;
                    p.departureTime = overview.tiers[0].legArrivals?.[selectedIndex] ?? "";
                    p.priceToken = overview.tiers[0].priceToken ?? "";
                    p.tierId = overview.tiers[0].tierId;
                    p.route = overview.tiers[0].rideShape ?? "";
                    p.rideDuration = overview.tiers[0].rideDuration ?? "";
                    p.price = getPrice(
                        overview.tiers[0].minPrice,
                        overview.tiers[0].maxPrice,
                        intl
                    );
                    selectedIndex++;
                } else if (alreadySelectedGroups.includes(p.group)) {
                    p.sorted = false;
                    p.departureTime = "";
                    p.priceToken = "";
                    p.tierId = "";
                    p.route = "";
                    p.rideDuration = "";
                    p.price = "";
                }
                return p;
            });

            setPickups(newPickups);
        } catch (error: any) {
            alert("Can't load the price" + error.toString());
        }
    }

    const groupedPickups = groupBy(pickups, "group");

    async function handleCreateOrders() {
        //TODO
        // Handle error (keep the group and on page, otherwise navigate one level up)

        if (projectCodes?.mode === "REQUIRED" && !projectCode) {
            toast.error("Project code is required");
            return;
        }

        const allSorted = pickups.every((p) => p.sorted);
        if (!allSorted) {
            toast.error("Some pickups not routed yet");
            return;
        }

        const maxPickupsExceeded = Object.values(groupedPickups).some((arr) => arr.length > 3);
        if (maxPickupsExceeded) {
            toast.error(`Some routes has more than ${MAX_PICKUPS} pickups`);
            return;
        }

        if (destination == null) {
            toast.error("Destination is missing");
            return;
        }

        const allHasDropoffTime = pickups.every((p) => p.dropoffTime);
        if (!allHasDropoffTime) {
            toast.error("Some routes don't have dropoff time set");
            return;
        }

        for (const key in groupedPickups) {
            if (groupedPickups.hasOwnProperty(key)) {
                const group = groupedPickups[key];
                const pickups = group.map((p) => p.pickup) as Pickup[];

                const preorderUTCTime = new Date(
                    `${dropoffDate}T${group[0].dropoffTime}:00`
                ).toISOString();

                const payload = {
                    stops: taxiStopsPayload(pickups, [destination]),
                    costCenter: costCenter || undefined,
                    projectCode: projectCode || undefined,
                    paidByPassenger: false,
                    driverCallsOrderer: false,
                    type: "PREORDER",
                    requestedDropOffAt: preorderUTCTime,
                    orderUuid: generateUUID(),
                    tripId: key, //FIXME: relevant tripId
                    priceToken: group[0].priceToken,
                    tierId: group[0].tierId,
                } as TaxiOrderRequest;
                toast.promise(taxiOrder.mutateAsync(payload), {
                    loading: "Loading",
                    success: "Ordered successfuly",
                    error: "Order failed",
                });
            }
        }
    }

    async function handleDestinationChange(selected: (LocationSuggestionWithId | undefined)[]) {
        const location = selected[0];
        if (location != null) {
            setDestination(location);
            const updatedLocation = await updateLocationCoords(location);
            setDestination(updatedLocation);
            localStorage.setItem("route-planner-destination", JSON.stringify(updatedLocation));
        } else {
            setDestination(undefined);
            localStorage.removeItem("route-planner-destination");
        }
    }

    return (
        <Content>
            <div style={{maxWidth: "400px", flexShrink: "0", overflow: "scroll"}}>
                <div style={{padding: "8px"}}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                        }}
                    >
                        <Link to="/taxi">← Zpět</Link>
                        <Button onClick={handleCreateOrders}>Objednat vše</Button>
                    </div>
                    <br />
                    {/* <Input type="file" style={{width: "100%"}}></Input> */}
                    <Input
                        type="text"
                        style={{width: "100%"}}
                        value=""
                        onPaste={handlePaste}
                        onChange={() => ""}
                        placeholder="Zkopírujte tabulku vyzvednutí"
                    ></Input>
                    <br />
                    <br />
                    <label style={{width: "100%"}}>
                        Cílová adresa
                        <SearchPlacesInput
                            name="destintation"
                            addressType={"PICKUP"}
                            serviceType="TAXI"
                            tripId={generateUUID()}
                            value={destination}
                            onChange={handleDestinationChange}
                        />
                    </label>
                    <div style={{display: "flex", gap: "8px"}}>
                        <label
                            style={{
                                width: "100%",
                            }}
                        >
                            Nákladové středisko
                            <Typeahead
                                id="costCenter"
                                selected={[costCenter]}
                                onInputChange={(s) => setCostCenter(s)}
                                onChange={(suggestions: string[]) => {
                                    if (suggestions.length > 0) {
                                        setCostCenter(suggestions[0]);
                                    }
                                }}
                                renderMenu={
                                    ((results: any, menuProps: any, props: any) => {
                                        if (results.length === 0) {
                                            return null;
                                        }
                                        return (
                                            <TypeaheadMenu
                                                {...menuProps}
                                                labelKey={props.labelKey}
                                                options={results}
                                                text={props.text}
                                            />
                                        );
                                    }) as any
                                }
                                options={costCenters?.costCenterHistory || [""]}
                                highlightOnlyResult={true}
                                placeholder=""
                            />
                        </label>
                        <label
                            style={{
                                width: "100%",
                            }}
                        >
                            Projektový kód
                            <Typeahead
                                id="projectCode"
                                selected={[projectCode]}
                                onInputChange={(s) => setProjectCode(s)}
                                onChange={(suggestions: string[]) => {
                                    if (suggestions.length > 0) {
                                        setProjectCode(suggestions[0]);
                                    }
                                }}
                                renderMenu={
                                    ((results: any, menuProps: any, props: any) => {
                                        if (results.length === 0) {
                                            return null;
                                        }
                                        return (
                                            <TypeaheadMenu
                                                {...menuProps}
                                                labelKey={props.labelKey}
                                                options={results}
                                                text={props.text}
                                            />
                                        );
                                    }) as any
                                }
                                options={projectCodes?.codeHistory || [""]}
                                highlightOnlyResult={true}
                                placeholder=""
                            />
                        </label>
                    </div>
                    <label style={{display: "flex", flexDirection: "column"}}>
                        Datum jízdy{" "}
                        <Input
                            type="date"
                            value={dropoffDate}
                            onChange={(e) => setDropoffDate(e.target.value)}
                        />
                    </label>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h3>Vytvořte trasy a zkontrolujte čas vysazení</h3>
                        <Button
                            variant="link"
                            large
                            onClick={() => setPreviewMode(!previewMode)}
                            style={{fontSize: "20px"}}
                        >
                            {previewMode ? "😳" : "🫣"}
                        </Button>
                    </div>
                </div>
                {Object.keys(groupedPickups).map((key) => (
                    <PickupGroup
                        key={key}
                        onMouseEnter={() => previewMode && handleHightlight(key)}
                        onMouseLeave={() => previewMode && handleHightlight(key)}
                    >
                        {/* <h1>{key}</h1> */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "16px",
                            }}
                        >
                            <Input
                                type="time"
                                value={groupedPickups[key][0].dropoffTime}
                                onChange={(e) => handleDropoffTimeChange(key, e)}
                                style={{flex: "1"}}
                            />
                            {groupedPickups[key][0].rideDuration ? (
                                <div style={{color: "var(--secondary)"}}>
                                    {groupedPickups[key][0]?.price}
                                    {" · "}
                                    {getDurationInMinutes(groupedPickups[key][0].rideDuration)} min
                                </div>
                            ) : null}
                            {!previewMode && (
                                <Button
                                    variant="link"
                                    large
                                    onClick={() => handleHightlight(key)}
                                    style={{
                                        fontSize: "20px",
                                        marginTop: "-8px",
                                        marginBottom: "-8px",
                                    }}
                                >
                                    {groupedPickups[key][0].highlighted ? "📍" : "👁️"}
                                </Button>
                            )}
                        </div>
                        {groupedPickups[key].map((p, index) => (
                            <div key={p.id}>
                                <Label>
                                    <input
                                        type="checkbox"
                                        value={p.id}
                                        name={p.id}
                                        id={p.id}
                                        checked={p.selected}
                                        onChange={() => handleSelect(p)}
                                        //disabled={selectedCount >= MAX_PICKUPS && !p.selected} //not accessible
                                    />
                                    <div>
                                        {p.selectedOrder ? (
                                            <span style={{color: "var(--primary)"}}>
                                                {p.selectedOrder}
                                            </span>
                                        ) : (
                                            p.sorted && index + 1
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        <Lozenge>{p.label}</Lozenge>
                                        {p.pickup.location.location.address}
                                    </div>
                                </Label>
                            </div>
                        ))}
                    </PickupGroup>
                ))}
                {selectedCount > 0 && (
                    <BottomPanel>
                        <Button onClick={handleNewGroup} large>
                            Vytvořit novou trasu ({selectedCount}) [⇧+⌥+S]
                        </Button>
                    </BottomPanel>
                )}
            </div>
            <Map
                mapTypeControl={false}
                mapId={"95d28c73960d6b8a"}
                streetViewControl={false}
                fullscreenControl={false}
                clickableIcons={false}
            >
                {Object.keys(groupedPickups).map((key) =>
                    groupedPickups[key].map((p, index) => {
                        if (index === 0 && p.route) {
                            return (
                                <Polyline
                                    encodedPaths={[p.route]}
                                    strokeColor="black"
                                    strokeOpacity={
                                        p.highlighted
                                            ? 1
                                            : previewMode && pickups.some((p) => p.highlighted)
                                            ? 0
                                            : 0.4
                                    }
                                    strokeWeight={4}
                                />
                            );
                        }
                        return null;
                    })
                )}

                {Object.keys(groupedPickups).map((key) =>
                    groupedPickups[key].map((p, index) => (
                        <AdvancedMarker
                            position={{
                                lat: p.pickup.location.location.lat,
                                lng: p.pickup.location.location.lon,
                            }}
                            key={p.id}
                            onClick={() => handleSelect(p)}
                            zIndex={p.selected || p.highlighted ? 100 : 1}
                            draggable
                        >
                            <MapTag
                                style={
                                    p.selected
                                        ? {backgroundColor: "orange"}
                                        : p.sorted
                                        ? {backgroundColor: "var(--green)"}
                                        : {}
                                }
                                transparent={pickups.some((p) => p.highlighted) && !p.highlighted}
                                onMouseEnter={() => previewMode && handleHightlight(key)}
                                onMouseLeave={() => previewMode && handleHightlight(key)}
                            >
                                {p.departureTime ? formatTime(new Date(p.departureTime)) : p.label}
                                {p.selectedOrder ? (
                                    <Order theme="var(--primary)">{p.selectedOrder}</Order>
                                ) : (
                                    p.sorted && <Order>{index + 1}</Order>
                                )}
                            </MapTag>
                        </AdvancedMarker>
                    ))
                )}
                {destination && (
                    <AdvancedMarker position={getCoords(destination.location)} zIndex={1}>
                        <MapTag style={{backgroundColor: "black"}}>
                            {pickups.filter((p) => p.selected)[0]?.dropoffTime ??
                                pickups.filter((p) => p.highlighted)[0]?.dropoffTime ??
                                "Cíl"}
                        </MapTag>
                    </AdvancedMarker>
                )}
            </Map>
        </Content>
    );
}

const MapTag = styled.div<{transparent?: boolean}>`
    --leg-height: 8px;
    position: relative;
    background-color: var(--primary);
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    padding: 4px 8px;
    position: relative;
    outline: 2px solid black;
    bottom: var(--leg-height);
    opacity: ${(p) => (p.transparent ? "0.1" : "1")};

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0);
        width: 3px;
        height: var(--leg-height);
        background-color: black;
    }
`;

const Order = styled.span`
    position: absolute;
    font-size: 11px;
    top: -8px;
    right: -8px;
    border-radius: 100px;
    background-color: black;
    background-color: ${(p) => p.theme && p.theme};
    height: 1.4rem;
    width: 1.4rem;
    display: grid;
    place-items: center;
`;

const PickupGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    border-bottom: 1px solid var(--divider);
    &:hover {
        background-color: var(--light);
    }
`;

const BottomPanel = styled.div`
    position: absolute;
    bottom: 16px;
    left: 50%;
    padding: 12px;
    border-radius: 16px;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.23);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.08);
`;

const Label = styled.label`
    margin: 0;
    display: flex;
    gap: 8px;
    align-items: center;
`;
