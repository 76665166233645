import {useEffect, useState} from "react";

export function useRemainingMinutes(endTime: string | undefined): number | null {
    const [timeRemaining, setTimeRemaining] = useState<number | null>(
        endTime ? new Date(endTime).getTime() - new Date().getTime() : null
    );

    useEffect(() => {
        const arrivalElapse = setInterval(() => {
            if (!endTime) return;
            setTimeRemaining(new Date(endTime).getTime() - new Date().getTime());
        }, 10000);
        return () => clearInterval(arrivalElapse);
    }, [endTime]);

    return timeRemaining ? Math.ceil(timeRemaining / 1000 / 60) : null;
}
