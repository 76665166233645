import {FormattedMessage, IntlShape} from "react-intl";
import {Money} from "../api/apiClient";

export function getPrice(min: Money, max: Money, intl: IntlShape) {
    if (max.amount === min.amount) {
        return formatMoney(max, intl);
    }
    return `${min.amount} – ${formatMoney(max, intl)}`;
}

export function formatMoney(money: Money, intl: IntlShape): string {
    return `${money.amount}\u00A0${intl.formatMessage({id: money.ccy})}`;
}

export function formatMoneyWithDecimals(money: Money, intl: IntlShape): string {
    const formatter = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: money.ccy,
    });
    return formatter.format(money.amount);
}

export function getCountryCurrencyString(country: string) {
    switch (country) {
        case "CZ":
            return <FormattedMessage id="CZK" />;
        case "SK":
            return <FormattedMessage id="EUR" />;
        default:
            return <FormattedMessage id="CZK" />;
    }
}

export function getCurrencyByCountry(country: string) {
    switch (country) {
        case "CZ":
            return "CZK";
        case "SK":
            return "EUR";
        default:
            return "";
    }
}
