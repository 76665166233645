import {geocode} from "../api/apiClient";
import {LocationSuggestionWithId} from "../types/types";

export async function updateLocationCoords(location: LocationSuggestionWithId) {
    try {
        const coords = await geocode({
            query: location.location.address,
        });

        return {
            ...location,
            location: {
                ...location.location,
                lat: coords.coordinates.lat,
                lon: coords.coordinates.lon,
            },
        };
    } catch (error) {
        console.error("Error updating coordinates:", error);
        //TODO: Return location without coords as currently not needed. In future we need to handle this e.g. Chałupki, Polsko
        return location;
    }
}
