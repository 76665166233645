import {datadogLogs} from "@datadog/browser-logs";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import {App} from "./app/App";
import {setupAxiosInterceptors} from "./helpers/setupAxiosInterceptors";

if (appConfig.dataDogKey != null) {
    datadogLogs.init({
        clientToken: appConfig.dataDogKey,
        service: "self-service-web",
        site: "datadoghq.eu",
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
    });
}

setupAxiosInterceptors();

ReactDOM.render(<App />, document.getElementById("root"));

/* global appConfig */
if (appConfig.googleAnalyticsId != null) {
    ReactGA.initialize(appConfig.googleAnalyticsId);
    ReactGA.pageview(window.location.pathname + window.location.search);
}

if (appConfig.gtmId != null) {
    TagManager.initialize({
        gtmId: appConfig.gtmId,
    });
}
