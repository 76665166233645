import {DeliveryRide, TaxiRide} from "../api/apiClient";

export function hasRecentlyFailed({
    finishedAt,
    failReason,
    status,
}: {
    finishedAt?: string;
    failReason?: TaxiRide["failReason"]; //FIXME: add DeliveryRide["failReason"] on B4F and add recreate from card to DeliveryRideView
    status?: TaxiRide["status"] | DeliveryRide["status"];
}) {
    if (finishedAt && (failReason === "CANCELLED_BY_DRIVER" || status === "FAILED")) {
        const finishedDate = new Date(finishedAt);
        const now = new Date();
        const diffInMinutes = (now.getTime() - finishedDate.getTime()) / (1000 * 60);
        return diffInMinutes < 30;
    }
    return false;
}
