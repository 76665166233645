import {Modal} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";
import {useCallerCompany, useGetEmployees} from "../../api/apiClient";
import {BulkModal} from "./BulkModal";

export function BulkMobileUsers() {
    const navigate = useNavigate();
    const handleClose = () => navigate("..");
    const {data: users} = useGetEmployees();
    const {data: companyInfo} = useCallerCompany();

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" size="lg" centered>
            {users && companyInfo ? (
                <BulkModal users={users} companyInfo={companyInfo} />
            ) : (
                <Modal.Body>
                    <FormattedMessage id="loading" />
                </Modal.Body>
            )}
        </Modal>
    );
}
