import {useFormikContext} from "formik";
import * as duration from "iso8601-duration";
import {useEffect} from "react";
import {DateTimeSelect} from "./DateTimeSelect";
import {TaxiOrderFormValues} from "./TaxiOrderForm";
import {TimeSelectorProps, getAllAvailableTimes, useAvailableTimes} from "./taxiPreorderModel";

export function TimeSelector({from, to, name}: TimeSelectorProps) {
    const {
        values: {preorderTime},
        setFieldValue,
    } = useFormikContext<TaxiOrderFormValues>();

    const availableTimes = useAvailableTimes({from, to, time: preorderTime});

    useEffect(() => {
        const isPreorderTimeEligable = preorderTime
            ? duration.end(duration.parse(from)) < preorderTime
            : false;

        if (isPreorderTimeEligable) {
            setFieldValue(name, preorderTime);
        } else {
            const allAvailableTimes = getAllAvailableTimes({startDate: new Date(), from, to});
            const initialPreorderTime = allAvailableTimes[0];
            setFieldValue(name, initialPreorderTime);
        }
        return () => setFieldValue(name, undefined);
    }, [from, to]);

    function setTime(date: Date) {
        setFieldValue(name, date);
    }

    return <DateTimeSelect time={preorderTime} availableTimes={availableTimes} setTime={setTime} />;
}
