import {getCountries, getCountryCallingCode} from "libphonenumber-js";
import cz from "react-phone-number-input/locale/cz.json";
import en from "react-phone-number-input/locale/en.json";
import {getLanguage} from "../translations";

function getLocale() {
    const lang = getLanguage();
    return lang === "cs" ? cz : en;
}

// Create object with country labels
export const countryLabels = getCountries().reduce((labels, country) => {
    return {
        ...labels,
        [country]: `${getLocale()[country]} (+${getCountryCallingCode(country)})`,
    };
}, {});
