import {getAccessToken} from "../auth/authModel";

declare var appConfig: any;

export function headers() {
    const token = getAccessToken();

    if (token == null) {
        return {};
    }

    return {
        Authorization: `Bearer ${token}`,
        'x-ltg-app-version': `${appConfig.appVersion}`,
    };
}
