import {useEffect, useState} from "react";
import {cancelRide, DeliveryRide} from "../api/apiClient";
import {useOpenDetail} from "./OpenDetailContext";
import {RideDetailView} from "./DeliveryRideDetailView";

export interface RideDetailProps {
    ride: DeliveryRide;
}

export type CancelState = "NONE" | "LOADING" | "SUCCESS" | "FAILED";

export function RideDetail({ride}: RideDetailProps) {
    const openDetail = useOpenDetail();

    const [cancelState, setCancelState] = useState<CancelState>("NONE");

    useEffect(() => {
        setCancelState("NONE");
    }, [ride.status, ride.id]);

    function onCancelRide() {
        setCancelState("LOADING");
        cancelRide(ride.id)
            .then(() => {
                setCancelState("SUCCESS");
            })
            .catch(() => {
                setCancelState("FAILED");
            });
    }

    function openStopDetail(stopId: string) {
        openDetail({
            type: "stop",
            rideId: ride.id,
            stopId: stopId,
        });
    }

    return (
        <RideDetailView
            ride={ride}
            cancelState={cancelState}
            onCancel={onCancelRide}
            onStopItemClicked={openStopDetail}
        />
    );
}
