import {ReactElement} from "react";
import {AddOrdererRolePage} from "../auth/AddOrdererRolePage";
import {useUserInfo} from "../auth/authModel";

export function OrderPageAccess({children}: {children: ReactElement}) {
    const {data: user, isLoading: isUserLoading} = useUserInfo();

    if (isUserLoading || user == null) {
        return null;
    }

    const isOrderer = user?.roles.includes("BUSINESS_ORDERER");

    if (!isOrderer) {
        return <AddOrdererRolePage />;
    }
    return children;
}
