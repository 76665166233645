import {useField} from "formik";
import {useEffect} from "react";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import {countryLabels} from "../utils/getCountryLables";
import {FormError} from "./forms";

export const PhoneInputFormik = ({name, placeholder}: {name: string; placeholder: string}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, _, helpers] = useField<string | undefined>(name);

    useEffect(() => {
        document?.querySelector(".PhoneInputCountrySelect")?.setAttribute("tabindex", "-1");
    }, []);

    return (
        <div style={{marginTop: "4px", marginBottom: "0"}}>
            <OrderFormPhoneInput
                {...field}
                onChange={(value: string) => {
                    helpers.setValue(value ?? "");
                }}
                autoComplete="off"
                enterKeyHint="next"
                defaultCountry="CZ"
                addInternationalOption={false}
                labels={countryLabels}
                placeholder={placeholder}
                countryOptionsOrder={[
                    "CZ",
                    "SK",
                    "AT",
                    "DE",
                    "ES",
                    "FR",
                    "IT",
                    "NL",
                    "PL",
                    "GB",
                    "US",
                    "|",
                    "...",
                ]}
            />
            <FormError name={name} />
        </div>
    );
};

const OrderFormPhoneInput = styled(PhoneInput)`
    border-radius: var(--border-radius-small);
    //border: 1px solid var(--quaternary);
    &:hover {
        background-color: var(--light);
    }
    :has(:focus) {
        outline: 1px solid var(--quaternary);
        //border: 1px solid transparent;
    }

    & .PhoneInputCountry {
        /* border-radius: 8px 0 0 8px;
        border: 1px solid green; */
        border: none;
        margin: 0;
        padding: 7px 8px;
    }

    & .PhoneInputCountryIcon {
        border-radius: 2px;
        outline: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    & .PhoneInputCountrySelectArrow {
        display: none;
    }

    & input {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        border-left: 1px solid transparent;
        padding: 7px 0px;
        width: 100%;
        :focus {
            outline: none;
            border: none;
            /* border-left: 1px solid var(--quaternary); */
            border-left: 1px solid transparent;
        }
        ::placeholder {
            color: var(--tertiary);
        }
    }
`;
