import {useQuery} from "@tanstack/react-query";
import cookie from "js-cookie";
import posthog from "posthog-js";
import {
    addOrdererRole,
    AddOrdererRoleRequest,
    createBusiness,
    login,
    LoginRequest,
    PtyCreateBusinessRequest,
    user,
} from "../api/apiClient";
import {queryClient} from "../app/App";

let accessToken = cookie.get("accessToken");

export const getAccessToken = () => accessToken;

function cookieParams() {
    const domain = document.location.hostname.split(".").slice(-2).join(".");
    const secure = document.location.protocol === "https:";
    return {domain, secure};
}

export function setAccessToken(token: string) {
    accessToken = token;
    cookie.set("accessToken", token, cookieParams());
}

export function removeAccessToken() {
    accessToken = undefined;
    cookie.remove("accessToken", cookieParams());
}

export async function authenticate(values: LoginRequest) {
    const {accessToken} = await login(values);
    if (accessToken == null) {
        throw new Error("No access token loaded.");
    }
    setAccessToken(accessToken);
    resetData();
    window.history.replaceState(null, ""); //triggers password keychain prompt
}

export async function createAccount(values: PtyCreateBusinessRequest) {
    const {accessToken} = await createBusiness(values);
    if (accessToken == null) {
        throw new Error("No access token loaded.");
    }
    setAccessToken(accessToken);
    resetData();
    window.history.replaceState(null, ""); //triggers password keychain prompt
}

export async function addOrdererRoleAndHandleToken(values: AddOrdererRoleRequest) {
    const {accessToken} = await addOrdererRole(values);
    if (accessToken == null) {
        throw new Error("No access token loaded.");
    }
    setAccessToken(accessToken);
    resetData();
}

function resetData() {
    queryClient.resetQueries();
    posthog.reset();
}

export async function logout() {
    removeAccessToken();
    resetData();
    localStorage.clear();
}

async function fetchUserInfo() {
    if (accessToken == null) {
        throw new Error("No access token loaded.");
    }

    try {
        return await user();
    } catch {
        throw new Error("Failed to fetch user info.");
    }
}

export function useUserInfo() {
    return useQuery(["userInfo"], fetchUserInfo, {
        //refetchOnMount: false,
        retry: false,
    });
}
