import {DateObject} from "react-multi-date-picker";
import {DeliveryOrderRequest, DeliveryOverview, Stop, TimeInterval} from "../api/apiClient";
import {queryClient} from "../app/App";
import {DELIVERY_EXPRESS_PREORDER_TIER_ID} from "../constants/constants";
import {LocationSuggestionWithId} from "../types/types";
import {clearSpaces} from "../utils/formatString";
import {generateUUID} from "../utils/generateUUID";

export interface StopItem {
    name: string;
    phoneNumber: string;
    location?: LocationSuggestionWithId;
    kind: Stop["kind"];
    noteForDriver: string;
    tripId?: string;
}

export interface DateInterval {
    from: Date;
    to: Date;
}

export interface DeliveryOrderFormValues {
    pickup: StopItem;
    handovers: StopItem[];
    fallback?: StopItem;
    arrivalTime?: DateInterval;
    pickupDates?: DateObject[];
    pickupTime?: DateObject;
    costCenter: string;
    projectCode: string;
    fallbackEnabled: boolean;
    deliveryType: "ONE_WAY" | "ROUND_TRIP";
    tierId: string;
    id: string;
}

export const defaultHandover: StopItem = {
    name: "",
    phoneNumber: "",
    location: undefined,
    noteForDriver: "",
    kind: "HANDOVER",
};

function normalizeTier(str: string) {
    if (str.endsWith("_PREORDER")) {
        return str.slice(0, -9);
    }
    return str;
}

function getArrivalTime(
    kind: Stop["kind"],
    arrivalTime?: DateInterval,
    isExpressPreorder?: boolean,
    pickupDateTime?: DateObject
): TimeInterval | undefined {
    if (isExpressPreorder && kind === "PICKUP" && pickupDateTime) {
        return {
            from: pickupDateTime.toDate().toISOString(),
            to: pickupDateTime
                .setHour(pickupDateTime.hour + 2) //FIXME: for 2-hour validation from logistics, consider chaning on backend
                .toDate()
                .toISOString(),
        };
    }

    if (kind === "HANDOVER" && arrivalTime) {
        return {
            from: arrivalTime.from.toISOString(),
            to: arrivalTime.to.toISOString(),
        };
    }
}

export function createDeliveryPayload(
    values: DeliveryOrderFormValues,
    isCourierOrder: boolean,
    priceToken?: string
): DeliveryOrderRequest {
    const deliveryTiers: DeliveryOverview | undefined = queryClient.getQueryData(["deliveryTiers"]);
    const hasFallback = values.fallbackEnabled && values.fallback != null && !isCourierOrder;
    const isRoundTrip = values.deliveryType === "ROUND_TRIP";
    const stopItems: StopItem[] = [
        values.pickup,
        ...values.handovers,
        ...((isRoundTrip ? [{...values.pickup, kind: "HANDOVER"}] : []) as StopItem[]),
        ...((hasFallback ? [values.fallback] : []) as StopItem[]),
    ];

    const courierTiers = deliveryTiers?.courier;

    const isExpressPreorder = values.tierId === DELIVERY_EXPRESS_PREORDER_TIER_ID;

    const pickupDateTime =
        values.pickupDates && values.pickupTime && values.pickupDates.length > 0
            ? values.pickupDates[0]
                  .setHour(values.pickupTime.hour)
                  .setMinute(values.pickupTime.minute)
            : undefined;

    const tiers: {[key: string]: string} = courierTiers
        ? (() => {
              const initialObject: {[key: string]: string} = {};

              if (courierTiers.express) {
                  initialObject[courierTiers.express.tierId] =
                      courierTiers.express.priceOffer.token || "";
              }

              if (
                  courierTiers.slotDelivery &&
                  courierTiers.slotDelivery.slots &&
                  courierTiers.slotDelivery.slots.length > 0
              ) {
                  for (const {
                      tierId,
                      priceOffer: {token},
                  } of courierTiers.slotDelivery.slots) {
                      initialObject[tierId] = token || "";
                  }
              }

              return initialObject;
          })()
        : {};

    const courierInfo: DeliveryOrderRequest["courierInfo"] = isCourierOrder
        ? {
              returnUndelivered: !hasFallback,
              costCenter: values.costCenter || undefined,
              projectCode: values.projectCode || undefined,
              tierId: normalizeTier(values.tierId),
              priceToken: priceToken
                  ? priceToken
                  : tiers
                  ? tiers[normalizeTier(values.tierId)]
                  : "",
          }
        : undefined;

    const stops = stopItems.map((stopItem): Stop => {
        return {
            contact: {
                name: stopItem.name,
                phoneNumber: clearSpaces(stopItem.phoneNumber),
            },
            location: stopItem.location!.location,
            kind: stopItem.kind,
            noteForDriver: stopItem.noteForDriver,
            arrivalTime: getArrivalTime(
                stopItem.kind,
                values.arrivalTime,
                isExpressPreorder,
                pickupDateTime
            ),
            tripId: stopItem.tripId,
            suggestSessionId: stopItem.location?.suggestSessionId,
        };
    });

    return {
        stops: stops,
        orderUuid: generateUUID(),
        courierInfo,
    };
}
