import {useEffect, useState} from "react";

export function useElementOffset(element: HTMLElement | null) {
    const [offset, setOffset] = useState({height: 0, width: 0});

    useEffect(() => {
        if (!element) return;

        let animationFrameId: number;

        // Function to update the offset state
        const updateOffset = () => {
            animationFrameId = requestAnimationFrame(() => {
                setOffset({height: element.offsetHeight, width: element.offsetWidth});
            });
        };

        // Create a ResizeObserver to watch the element's size changes
        const observer = new ResizeObserver(updateOffset);

        // Start observing the element
        observer.observe(element);

        // Initial measurement
        updateOffset();

        // Cleanup the observer and cancel animation frame when the component unmounts or element changes
        return () => {
            observer.disconnect();
            cancelAnimationFrame(animationFrameId);
        };
    }, [element]);

    return offset;
}
