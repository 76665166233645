import {FormattedMessage} from "react-intl";
import {BusinessUserDto, UserInfo} from "../api/apiClient";

export function hasAdminRole(roles: BusinessUserDto["roles"] | UserInfo["roles"]) {
    return roles.includes("BUSINESS_ADMIN");
}

export function hasCustomerCareRole(roles: UserInfo["roles"]) {
    return roles.includes("CUSTOMER_CARE");
}

export function hasExternalCustomerCareRole(roles: UserInfo["roles"]) {
    return roles.includes("EXTERNAL_CUSTOMER_CARE");
}

export function formatRole(roles: BusinessUserDto["roles"] | UserInfo["roles"]) {
    if (roles.includes("BUSINESS_ADMIN")) {
        return <FormattedMessage id="admin" />;
    } else if (roles.includes("BUSINESS_ORDERER")) {
        return <FormattedMessage id="orderer" />;
    }
    return null;
}
