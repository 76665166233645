import {Alert} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Outlet} from "react-router-dom";
import styled from "styled-components";
import {useUserInfo} from "../auth/authModel";
import {hasAdminRole} from "../utils/userUtils";

export function AdminAccess() {
    const {data: user, isLoading: isUserLoading} = useUserInfo();

    if (isUserLoading || !user) {
        return null;
    }

    const isAdmin = hasAdminRole(user.roles);

    if (!isAdmin) {
        return (
            <NarrowWrapper>
                <Alert variant="warning">
                    <FormattedMessage id="you_dont_have_permissions_to_access_section" />
                </Alert>
            </NarrowWrapper>
        );
    }

    return <Outlet />;
}

const NarrowWrapper = styled.div`
    margin: 24px auto;
    max-width: 350px;
`;
