import {zodResolver} from "@hookform/resolvers/zod";
import {isValidPhoneNumber} from "libphonenumber-js";
import {posthog} from "posthog-js";
import {Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {z} from "zod";
import {checkEmail, useCreateUser} from "../api/apiClient";
import {Button} from "../components/Button";
import {PhoneInputReactHookForm} from "../components/PhoneInputReactHookForm";
import {emailRegex} from "../constants/constants";
import closeIcon from "../img/ic_close.svg";
import {clearSpaces} from "../utils/formatString";
import {queryClient} from "./App";
import {Error, Input, Label, Select} from "./Registration";

const schema = z.object({
    name: z.string().min(1, {message: "required"}),
    email: z
        .string()
        .min(1, {message: "required"})
        .regex(emailRegex, {message: "invalid_email"})
        .refine(
            async (value) => {
                let exists: boolean = false;
                if (value) {
                    const data = await checkEmail(value);
                    exists = data.exists;
                }
                return !exists;
            },
            {message: "email_already_taken"}
        ),
    phoneNumber: z
        .string()
        .min(1, {message: "required"})
        .refine((value) => isValidPhoneNumber(value.replace(/[^\x20-\x7F]/g, "")), {
            message: "invalid_phone_number",
        }),
    role: z.string().min(1, {message: "required"}),
});

type AddWebUserFormValue = z.infer<typeof schema>;

export function AddWebUserForm() {
    const navigate = useNavigate();
    const intl = useIntl();
    const createUser = useCreateUser();

    const handleClose = () => navigate("..", {replace: true});

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<AddWebUserFormValue>({
        mode: "onTouched",
        resolver: zodResolver(schema),
        criteriaMode: "all",
        defaultValues: {
            phoneNumber: "+420",
        },
    });

    async function onSubmit(data: AddWebUserFormValue) {
        createUser.mutate(
            {
                email: data.email.trim(),
                name: data.name.trim(),
                phoneNumber: clearSpaces(data.phoneNumber),
                roles:
                    data.role === "BUSINESS_ORDERER"
                        ? ["BUSINESS_ORDERER"]
                        : ["BUSINESS_ADMIN", "BUSINESS_ORDERER"],
            },
            {
                onSuccess: () => {
                    toast.success(intl.formatMessage({id: "user_added"}));
                    queryClient.invalidateQueries(["/self-service/business/users"]);
                    posthog.capture("webUserAdded");
                    handleClose();
                },
                onError: () => toast.error(intl.formatMessage({id: "generic_error"})),
            }
        );
    }
    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="invite_web_user" />
                </Modal.Title>
                <Button variant="plain" onClick={handleClose}>
                    <img src={closeIcon} alt="Close dialog" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <form
                    id="user"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{display: "flex", flexDirection: "column"}}
                >
                    <Label htmlFor="name">
                        <FormattedMessage id="name" />
                    </Label>
                    <Input type="text" {...register("name")} />
                    {errors.name?.message && (
                        <Error>{intl.formatMessage({id: errors.name.message})}</Error>
                    )}
                    <Label htmlFor="email">
                        <FormattedMessage id="email" />
                    </Label>
                    <Input type="email" {...register("email")} />
                    {errors.email?.message && (
                        <Error>{intl.formatMessage({id: errors.email.message})}</Error>
                    )}
                    <Label htmlFor="phoneNumber">
                        <FormattedMessage id="phone_number" />
                    </Label>
                    <PhoneInputReactHookForm control={control} name="phoneNumber" />
                    {errors.phoneNumber?.message && (
                        <Error>{intl.formatMessage({id: errors.phoneNumber.message})}</Error>
                    )}
                    <Label htmlFor="role">
                        <FormattedMessage id="role" />
                    </Label>
                    <Select autoComplete="nope" {...register("role")}>
                        <option value="BUSINESS_ORDERER">
                            {intl.formatMessage({id: "orderer"})}
                        </option>
                        <option value="BUSINESS_ADMIN">{intl.formatMessage({id: "admin"})}</option>
                    </Select>
                    {errors.role?.message && (
                        <Error>{intl.formatMessage({id: errors.role.message})}</Error>
                    )}
                </form>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    data-cy="invite-web-user-btn"
                    type="submit"
                    form="user"
                    onClick={handleSubmit(onSubmit)}
                    variant="primary"
                    loading={createUser.isLoading}
                >
                    <FormattedMessage id="invite_user" />
                </Button>
            </Modal.Footer>
        </>
    );
}

export function AddWebUser() {
    const navigate = useNavigate();
    const handleClose = () => navigate("..");

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static" centered>
                <AddWebUserForm />
            </Modal>
        </>
    );
}
