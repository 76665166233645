// generated client

import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "axios";
import {headers} from "./apiUtils";

declare var appConfig: any;

export interface ErrorResponse {
    code: string;
    message: string;
    details?: object;
}

export interface BusinessPaymentType {
    paymentType: "CARD" | "INVOICE";
}

export interface BusinessPaymentInfoUpdate {
    projectCodeMode: "NONE" | "OPTIONAL" | "REQUIRED";
}

export interface BusinessPaymentInfoResponse {
    paymentType: "CARD" | "INVOICE";
    projectCodeMode: "NONE" | "OPTIONAL" | "REQUIRED";
    summaryPassword: string;
    paymentByCompanyForbidden: boolean;
    employeeExpenseLimitAllowed: boolean;
    paymentByEmployeeAllowed: boolean;
    payerId: string;
    cardDebt?: Money;
}

export interface Money {
    amount: number;
    ccy: string;
}

export interface BusinessCompanyAddress {
    city: string;
    street: string;
    zip: string;
    country: string;
}

export interface UpdateBusinessCompanyRequest {
    displayName: string;
    companyName: string;
    companyId: string;
    taxId?: string;
    paysVAT: boolean;
    billingEmail?: string;
    billingAddress: BusinessCompanyAddress;
    contactEmails: string[];
    adminContact?: UpdateBusinessCompanyRequestAdminContact;
    adminId?: string;
}

export interface UpdateBusinessCompanyRequestAdminContact {
    email?: string;
    phoneNumber?: string;
    name?: string;
}

export interface AdminContact {
    email: string;
    phoneNumber?: string;
    name?: string;
}

export interface BusinessCompany {
    businessId: string;
    displayName: string;
    companyName: string;
    companyId: string;
    taxId?: string;
    paysVAT: boolean;
    billingEmail?: string;
    billingAddress: BusinessCompanyAddress;
    contactEmails: string[];
    adminContact: AdminContact;
    adminId: string;
}

export interface UpdateBusinessUserRequest {
    name: string;
    phoneNumber: string;
    roles: ("BUSINESS_ADMIN" | "BUSINESS_ORDERER")[];
}

export interface CreateOrUpdateEmployeeRequest {
    phoneNumber: string;
    name: string;
    costCenter?: string;
    paymentOriginator: "COMPANY" | "EMPLOYEE";
    monthlyLimit?: Money;
    removeAfter?: string;
}

export interface PhoneValidationRequest {
    phoneNumber: string;
}

export interface PhoneValidationResponse {
    valid: boolean;
}

export interface Location {
    address: string;
    id?: string;
    type: "ADDRESS" | "PREDEFINED_PLACE";
    lat?: number;
    lon?: number;
}

export interface RideParams {
    acceptsCreditCards: boolean;
    premiumCar: boolean;
}

export interface StopContact {
    name: string;
    phoneNumber: string;
}

export interface StopDefinition {
    location: Location;
    type: "PICKUP" | "DROP_OFF";
    noteForDriver?: string;
    contact?: StopContact;
    suggestSessionId?: string;
}

export interface TaxiOrderRequest {
    orderUuid: string;
    paidByPassenger: boolean;
    stops: StopDefinition[];
    noteForDriver?: string;
    costCenter?: string;
    projectCode?: string;
    rideParams?: RideParams;
    driverCallsOrderer: boolean;
    requestedPickupAt?: string;
    requestedDropOffAt?: string;
    flightNumber?: string;
    type: "PREORDER" | "SHORT_PREORDER" | "NORMAL";
    internalNote?: string;
    specifiedDriverId?: string;
    priceToken?: string;
    tierId: string;
}

export interface PreOrderAvailabilityRequest {
    stops: StopDefinition[];
}

export interface PreOrderAvailability {
    available: boolean;
    unavailabilityReason?:
        | "NOT_AVAILABLE"
        | "RIDE_TOO_SHORT"
        | "UNSUPPORTED_DESTINATION"
        | "UNSUPPORTED_TIER";
    minimalDistance?: number;
    earliestAllowedPreOrder?: string;
    latestAllowedPreOrder?: string;
    flightNumberAllowed?: boolean;
}

export interface PreOrderAvailabilityResponse {
    availabilities: TierPreOrderAvailability[];
}

export interface TierPreOrderAvailability {
    tierId: string;
    availability: PreOrderAvailability;
}

export interface TaxiOverviewRequest {
    stops: StopDefinition[];
    rideParams: RideParams;
    orderType: "PREORDER" | "SHORT_PREORDER" | "NORMAL";
    requestedPickupAt?: string;
    requestedDropOffAt?: string;
    tripId?: string;
    specifiedDriverId?: string;
}

export interface TaxiOvervewTierData {
    tierId: string;
    available: boolean;
    arrivalDuration?: string;
    rideDuration: string;
    legArrivals?: string[];
    rideShape?: string;
    minPrice: Money;
    maxPrice: Money;
    preOrderAvailability: PreOrderAvailability;
    priceToken?: string;
}

export interface TaxiOverviewResponse {
    tiers: TaxiOvervewTierData[];
}

export interface PreOrderUpdate {
    requestedPickupAt: string;
    flightNumber?: string;
}

export interface PayTaxiTipRequest {
    tipPaymentId: string;
    price: Money;
    tipAmountType: "FIXED" | "PERCENT" | "CUSTOM";
}

export interface PayTaxiTipResponse {
    result: "SUCCESS" | "DENIED" | "ERROR";
}

export interface Coordinates {
    lat: number;
    lon: number;
}

export interface ReverseGeocodeRequest {
    coordinates: Coordinates;
}

export interface CollectOnDelivery {
    price: number;
    variableSymbol: string;
}

export interface Contact {
    name: string;
    phoneNumber: string;
}

export interface PriceEstimationRequest {
    stops: Stop[];
}

export interface Stop {
    location: Location;
    contact: Contact;
    collectOnDelivery?: CollectOnDelivery;
    kind: "PICKUP" | "HANDOVER" | "FALLBACK";
    noteForDriver?: string;
    arrivalTime?: TimeInterval;
    tripId?: string;
    suggestSessionId?: string;
}

export interface TimeInterval {
    from: string;
    to: string;
}

export interface PriceEstimationResponse {
    estimate: Money;
    priceToken?: string;
}

export interface LocationSuggestionsRequest {
    query: string;
    addressType?: "PICKUP" | "DESTINATION";
    pickup?: Location;
    serviceType?: "TAXI" | "DELIVERY";
    tripId?: string;
    suggestSessionId?: string;
}

export interface LocationSuggestion {
    location: Location;
    complete: boolean;
    type: "SEARCH" | "PREDEFINED_PLACE" | "HISTORY";
    price?: Money;
}

export interface LocationSuggestionsResponse {
    suggestions: LocationSuggestion[];
}

export interface GeocodeRequest {
    query: string;
    tripId?: string;
    near?: Coordinates;
}

export interface GeocodeResponse {
    id: string;
    coordinates: Coordinates;
}

export interface Feedback {
    text: string;
    currentUrl: string;
    type: "USER_INPUT" | "AUTOMATED";
}

export interface CourierInfo {
    costCenter?: string;
    projectCode?: string;
    returnUndelivered: boolean;
    priceToken: string;
    tierId?: string;
}

export interface DeliveryOrderRequest {
    orderUuid?: string;
    stops: Stop[];
    courierInfo?: CourierInfo;
}

export interface DeliveryArrivalEstimationRequest {
    stops: Stop[];
}

export interface RideEstimationResponse {
    estimatedArrival: string;
    estimatedRide: string;
}

export interface DeliveryOverviewRequest {
    stops: Stop[];
    serviceType: "ONE_WAY" | "ROUND_TRIP";
}

export interface CourierTiers {
    express?: DeliveryExpressTier;
    slotDelivery?: SlotDeliveryOverview;
}

export interface DeliveryExpressTier {
    tierId: string;
    priceOffer: PriceOffer;
    estimatedArrival?: string;
    estimatedRide?: string;
}

export interface DeliveryOverview {
    validFor: string;
    logistics?: PriceEstimationResponse;
    courier?: CourierTiers;
}

export interface DeliverySlotTier {
    tierId: string;
    slot: TimeInterval;
    priceOffer: PriceOffer;
    busy: boolean;
}

export interface PriceOffer {
    price: Money;
    token?: string;
}

export interface SlotDeliveryOverview {
    enabled: boolean;
    blockingStopIndices: number[];
    slots: DeliverySlotTier[];
}

export interface CollectOnDeliveryEnableRequest {
    bankAccount: string;
}

export interface PtyAddressDto {
    city: string;
    street: string;
    zip: string;
    country: string;
}

export interface PtyAdminDto {
    email: string;
    name: string;
    phoneNumber: string;
    authService: string;
    secret: string;
}

export interface PtyCompanyDto {
    companyId: string;
    companyName: string;
    displayName: string;
    paysVAT: boolean;
    shortName?: string;
    taxId?: string;
}

export interface PtyCreateBusinessRequest {
    admin: PtyAdminDto;
    billingAddress: PtyAddressDto;
    company: PtyCompanyDto;
    tenant?: "LIFTAGO" | "GRID" | "BOTH";
}

export interface CreateBusinessResult {
    businessId: string;
    accessToken: string;
}

export interface CreateBusinessUserRequest {
    email: string;
    name: string;
    phoneNumber: string;
    roles: ("BUSINESS_ADMIN" | "BUSINESS_ORDERER")[];
}

export interface BusinessUserDto {
    userId: string;
    email?: string;
    name?: string;
    phoneNumber?: string;
    roles: ("BUSINESS_ADMIN" | "BUSINESS_ORDERER")[];
}

export interface BusinessEmployeeDto {
    employeeId: string;
    phoneNumber: string;
    name: string;
    costCenter?: string;
    invitedAt: string;
    inviteAcceptedAt?: string;
    pasAppRegistered: boolean;
    paymentOriginator: "COMPANY" | "EMPLOYEE";
    monthlyLimit?: Money;
    removeAfter?: string;
}

export interface BusinessDebtResponse {
    cardDebt?: Money;
}

export interface InitPaymentCardEntryResponse {
    processUrl: string;
}

export interface LoginRequest {
    username: string;
    password: string;
}

export interface LoginResponse {
    accessToken: string;
    roles: (
        | "ROOT"
        | "ADMIN"
        | "USER_MANAGER"
        | "DRIVER"
        | "PASSENGER"
        | "MARKETER"
        | "PAYER"
        | "DRIVER_VERIFIER"
        | "AFFILIATE_PARTNER"
        | "CUSTOMER_CARE"
        | "EXTERNAL_CUSTOMER_CARE"
        | "BUSINESS_ADMIN"
        | "BUSINESS_ORDERER"
        | "SYSTEM_ORDERER"
        | "CREDITS_ADMIN"
        | "MARKETING_ADMIN"
        | "OPERATOR"
        | "SUPPLY"
        | "FINANCE"
        | "EXTERNAL_PARTNER"
        | "UNKNOWN"
    )[];
}

export interface ImpersonateBusinessRequest {
    businessId: string;
}

export interface ChangePasswordRequest {
    newPassword: string;
    changePasswordToken: string;
}

export interface AddOrdererRoleRequest {
    phoneNumber: string;
    name: string;
}

export interface AddOrdererRoleResponse {
    accessToken: string;
}

export interface OrdererInfo {
    name: string;
    email: string;
    phoneNumber: string;
}

export interface RideUpdate {
    type: "INITIAL" | "TAXI" | "DELIVERY";
}

export interface DriverInfo {
    name: string;
    phoneNumber: string;
}

export interface Passenger {
    name: string;
    phoneNumber?: string;
}

export interface RidePrice {
    estimatedPrice: Money;
    fixedPrice: boolean;
    tips?: RideTip[];
}

export interface RideTip {
    price: Money;
    type: "IN_RIDE" | "AFTER_RIDE" | "WEB_APP" | "IMPLICIT" | "UNKNOWN";
}

export interface SpecifiedDriverInfo {
    id: string;
    name: string;
    phoneNumber: string;
}

export interface TaxiRide {
    id: string;
    createdAt: string;
    passenger?: Passenger;
    noteForDriver?: string;
    costCenter?: string;
    projectCode?: string;
    pickup: Location;
    destination: Location;
    stops: TaxiStop[];
    cancellable: boolean;
    driver?: DriverInfo;
    vehicle?: VehicleInfo;
    pickupArrival?: string;
    status:
        | "SCHEDULING"
        | "SCHEDULED"
        | "IN_SEARCHING"
        | "PICKUP_ARRIVAL"
        | "PICKUP_WAITING"
        | "ON_BOARD"
        | "RETURNING"
        | "FINISHED"
        | "CANCELLED"
        | "FAILED";
    statusGroup: "UPCOMING" | "IN_PROGRESS" | "COMPLETED";
    failReason?: "CANCELLED_BY_DRIVER" | "CANCELLED_BY_ORDERER" | "PAYMENT_AUTHORIZATION_FAILURE";
    finishedAt?: string;
    requestedPickupAt?: string;
    requestedDropOffAt?: string;
    orderType: "PREORDER" | "SHORT_PREORDER" | "NORMAL";
    internalNote?: string;
    ridePrice?: RidePrice;
    paymentMethod: "COMPANY" | "PASSENGER_CASH" | "PASSENGER_CARD";
    requestedVehicle: "ANY" | "PREMIUM";
    shareUrl?: string;
    specifiedDriver?: SpecifiedDriverInfo;
    flightNumber?: string;
    tierId?: string;
    tipEnabled: boolean;
    tipCcy?: string;
    tipped?: Money;
}

export interface TaxiRidesResponse {
    rides: TaxiRide[];
}

export interface TaxiStop {
    id: string;
    location: Location;
    status:
        | "SCHEDULING"
        | "SCHEDULED"
        | "IN_PROGRESS"
        | "STORING"
        | "STORED"
        | "ARRIVED"
        | "COMPLETED"
        | "RETURNING"
        | "RETURNED"
        | "FAILED"
        | "CANCELLED";
    type: "PICKUP" | "DROP_OFF";
    noteForDriver?: string;
    contact?: StopContact;
    arrivalEstimate?: string;
}

export interface VehicleInfo {
    licencePlate?: string;
    color?:
        | "BLACK"
        | "BLUE"
        | "BROWN"
        | "GREEN"
        | "ORANGE"
        | "PURPLE"
        | "RED"
        | "SILVER"
        | "WHITE"
        | "YELLOW"
        | "UNKNOWN";
    model?: string;
    brand?: string;
}

export interface CollectOnDeliveryInfo {
    variableSymbol: string;
    price: number;
    proofOfDelivery?: string;
    paymentInstrument?: string;
}

export interface DeliveryRide {
    id: string;
    order: OrderInfo;
    driver?: DriverInfo;
    vehicle?: VehicleInfo;
    status:
        | "SCHEDULING"
        | "SCHEDULED"
        | "IN_SEARCHING"
        | "PICKUP_ARRIVAL"
        | "PICKUP_WAITING"
        | "ON_BOARD"
        | "RETURNING"
        | "FINISHED"
        | "CANCELLED"
        | "FAILED";
    statusGroup: "UPCOMING" | "IN_PROGRESS" | "COMPLETED";
    stops: DeliveryStop[];
    finishedAt?: string;
    shareUrl?: string;
    ridePrice?: RidePrice;
}

export interface DeliveryRidesResponse {
    rides: DeliveryRide[];
}

export interface DeliveryStop {
    id: string;
    clientStopId: string;
    status:
        | "SCHEDULING"
        | "SCHEDULED"
        | "IN_PROGRESS"
        | "STORING"
        | "STORED"
        | "ARRIVED"
        | "COMPLETED"
        | "RETURNING"
        | "RETURNED"
        | "FAILED"
        | "CANCELLED";
    origin?: StopLocation;
    destination: StopLocation;
    contact: Contact;
    kind: "PICKUP" | "HANDOVER" | "FALLBACK";
    noteForDriver?: string;
    arrivalTime?: TimeInterval;
    arrival?: string;
    cancellable: boolean;
    courierInfo?: StopCourierInfo;
    depot?: Depot;
    collectOnDelivery?: CollectOnDeliveryInfo;
}

export interface Depot {
    reservationId: string;
    address: string;
    type: "ALZA_BOX";
}

export interface OrderInfo {
    createdAt: string;
    deliveryTime?: TimeInterval;
    cancellable: boolean;
}

export interface StopCourierInfo {
    costCenter?: string;
    projectCode?: string;
}

export interface StopLocation {
    description?: string;
    street: string;
    zipCode?: string;
    city: string;
    houseNumber: string;
    coordinates: Coordinates;
}

export interface ProjectCodeSettings {
    mode: "NONE" | "OPTIONAL" | "REQUIRED";
    codeHistory: string[];
}

export interface CostCenters {
    costCenterHistory: string[];
}

export interface ExampleFileLink {
    excelUrl: string;
}

export interface CompanyInfo {
    companyName: string;
    partnerDeliveryOrderEnabled: boolean;
    pricePerDropOff?: Money;
    priceEstimationAllowed: boolean;
    paymentByCompanyAllowed: boolean;
    horecaEnabled: boolean;
    optionalPassengerPhoneNumber: boolean;
    driverSpecificationEnabled: boolean;
    employeeRemoveAfterEnabled: boolean;
    monthlyPaymentRequestedAt?: string;
    disabledInfo: DisabledInfo;
    tier: "BASIC" | "ADVANCED";
}

export interface DisabledInfo {
    ridesEnabled: boolean;
    disabled: boolean;
    hasCardDebt?: boolean;
    hasCard: boolean;
    cardExpired: boolean;
}

export interface Employee {
    name: string;
    phoneNumber: string;
    costCenter?: string;
}

export interface Employees {
    employees: Employee[];
}

export interface CollectOnDeliveryStatusResponse {
    status: "ENABLED" | "DISABLED" | "UNAVAILABLE";
    bankAccount?: string;
}

export interface CompanyLookupAddress {
    street: string;
    city: string;
    zip: string;
    country: string;
    state?: string;
}

export interface CompanyLookupCompany {
    companyName: string;
    companyId: string;
    taxId?: string;
    paysVAT: boolean;
}

export interface CompanyLookupResponse {
    found: boolean;
    company?: CompanyLookupCompany;
    billingAddress?: CompanyLookupAddress;
}

export interface CheckEmailResult {
    exists: boolean;
}

export interface CopmanyIdActiveResult {
    active: boolean;
}

export interface BusinessInvoicing {
    invoicingId: number;
    start: string;
    end: string;
    taxiRideCount?: number;
    numberOfShipments?: number;
    totalIssuedInvoicesAmount?: number;
    issuedInvoice?: IssuedInvoice;
}

export interface BusinessInvoicingList {
    items: BusinessInvoicing[];
}

export interface IssuedInvoice {
    invoiceId: number;
    amount: Money;
    paid: boolean;
    dueDate: string;
}

export interface CardInfo {
    maskedCln: string;
    expiration: string;
    type: string;
}

export interface UserInfo {
    email: string;
    roles: (
        | "ROOT"
        | "ADMIN"
        | "USER_MANAGER"
        | "DRIVER"
        | "PASSENGER"
        | "MARKETER"
        | "PAYER"
        | "DRIVER_VERIFIER"
        | "AFFILIATE_PARTNER"
        | "CUSTOMER_CARE"
        | "EXTERNAL_CUSTOMER_CARE"
        | "BUSINESS_ADMIN"
        | "BUSINESS_ORDERER"
        | "SYSTEM_ORDERER"
        | "CREDITS_ADMIN"
        | "MARKETING_ADMIN"
        | "OPERATOR"
        | "SUPPLY"
        | "FINANCE"
        | "EXTERNAL_PARTNER"
        | "UNKNOWN"
    )[];
    name?: string;
    phoneNumber?: string;
}

export interface SoftUpdateResponse {
    softUpdateNeeded: boolean;
}

export async function setBusinessPaymentType(
    body: BusinessPaymentType
): Promise<BusinessPaymentType> {
    const response = await axios.put<BusinessPaymentType>(
        `${appConfig.apiUrl}/self-service/businesses/paymentType`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useSetBusinessPaymentType() {
    return useMutation(
        [`/self-service/businesses/paymentType`],
        async (body: BusinessPaymentType) => await setBusinessPaymentType(body)
    );
}

export async function getBusinessPaymentInfo(): Promise<BusinessPaymentInfoResponse> {
    const response = await axios.get<BusinessPaymentInfoResponse>(
        `${appConfig.apiUrl}/self-service/businesses/paymentInfo`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetBusinessPaymentInfo() {
    return useQuery(
        [`/self-service/businesses/paymentInfo`],
        async () => await getBusinessPaymentInfo()
    );
}

export async function updateBusinessPaymentInfo(
    body: BusinessPaymentInfoUpdate
): Promise<BusinessPaymentInfoResponse> {
    const response = await axios.put<BusinessPaymentInfoResponse>(
        `${appConfig.apiUrl}/self-service/businesses/paymentInfo`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useUpdateBusinessPaymentInfo() {
    return useMutation(
        [`/self-service/businesses/paymentInfo`],
        async (body: BusinessPaymentInfoUpdate) => await updateBusinessPaymentInfo(body)
    );
}

export async function getBusinessCompany(): Promise<BusinessCompany> {
    const response = await axios.get<BusinessCompany>(
        `${appConfig.apiUrl}/self-service/businesses/company`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetBusinessCompany() {
    return useQuery([`/self-service/businesses/company`], async () => await getBusinessCompany());
}

export async function updateBusinessCompany(
    body: UpdateBusinessCompanyRequest
): Promise<BusinessCompany> {
    const response = await axios.put<BusinessCompany>(
        `${appConfig.apiUrl}/self-service/businesses/company`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useUpdateBusinessCompany() {
    return useMutation(
        [`/self-service/businesses/company`],
        async (body: UpdateBusinessCompanyRequest) => await updateBusinessCompany(body)
    );
}

export async function updateUser(body: UpdateBusinessUserRequest, userId: string) {
    const response = await axios.put(
        `${appConfig.apiUrl}/self-service/business/users/${encodeURIComponent(userId)}`,
        body,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useUpdateUser() {
    return useMutation(
        [`/self-service/business/users/{userId}`],
        async ({body, userId}: {body: UpdateBusinessUserRequest; userId: string}) =>
            await updateUser(body, userId)
    );
}

export async function removeUserFromBusiness(userId: string) {
    const response = await axios.delete(
        `${appConfig.apiUrl}/self-service/business/users/${encodeURIComponent(userId)}`,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useRemoveUserFromBusiness() {
    return useMutation(
        [`/self-service/business/users/{userId}`],
        async (userId: string) => await removeUserFromBusiness(userId)
    );
}

export async function updateEmployee(body: CreateOrUpdateEmployeeRequest, employeeId: string) {
    const response = await axios.put(
        `${appConfig.apiUrl}/self-service/business/employees/${encodeURIComponent(employeeId)}`,
        body,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useUpdateEmployee() {
    return useMutation(
        [`/self-service/business/employees/{employeeId}`],
        async ({body, employeeId}: {body: CreateOrUpdateEmployeeRequest; employeeId: string}) =>
            await updateEmployee(body, employeeId)
    );
}

export async function deleteEmployee(employeeId: string) {
    const response = await axios.delete(
        `${appConfig.apiUrl}/self-service/business/employees/${encodeURIComponent(employeeId)}`,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useDeleteEmployee() {
    return useMutation(
        [`/self-service/business/employees/{employeeId}`],
        async (employeeId: string) => await deleteEmployee(employeeId)
    );
}

export async function phoneNumberValid(
    body: PhoneValidationRequest
): Promise<PhoneValidationResponse> {
    const response = await axios.post<PhoneValidationResponse>(
        `${appConfig.apiUrl}/self-service/validations/phoneNumber`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function usePhoneNumberValid() {
    return useMutation(
        [`/self-service/validations/phoneNumber`],
        async (body: PhoneValidationRequest) => await phoneNumberValid(body)
    );
}

export async function createTaxiOrder(body: TaxiOrderRequest) {
    const response = await axios.post(`${appConfig.apiUrl}/self-service/taxiOrder`, body, {
        headers: headers(),
    });

    return response;
}

export function useCreateTaxiOrder() {
    return useMutation(
        [`/self-service/taxiOrder`],
        async (body: TaxiOrderRequest) => await createTaxiOrder(body)
    );
}

export async function preOrderAvailability(
    body: PreOrderAvailabilityRequest
): Promise<PreOrderAvailability> {
    const response = await axios.post<PreOrderAvailability>(
        `${appConfig.apiUrl}/self-service/taxiOrder/preOrderAvailability`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function usePreOrderAvailability() {
    return useMutation(
        [`/self-service/taxiOrder/preOrderAvailability`],
        async (body: PreOrderAvailabilityRequest) => await preOrderAvailability(body)
    );
}

export async function preOrderAvailabilityV2(
    body: PreOrderAvailabilityRequest
): Promise<PreOrderAvailabilityResponse> {
    const response = await axios.post<PreOrderAvailabilityResponse>(
        `${appConfig.apiUrl}/self-service/taxiOrder/preOrderAvailabilityV2`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function usePreOrderAvailabilityV2() {
    return useMutation(
        [`/self-service/taxiOrder/preOrderAvailabilityV2`],
        async (body: PreOrderAvailabilityRequest) => await preOrderAvailabilityV2(body)
    );
}

export async function taxiOverview(body: TaxiOverviewRequest): Promise<TaxiOverviewResponse> {
    const response = await axios.post<TaxiOverviewResponse>(
        `${appConfig.apiUrl}/self-service/taxiOrder/overview`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useTaxiOverview() {
    return useMutation(
        [`/self-service/taxiOrder/overview`],
        async (body: TaxiOverviewRequest) => await taxiOverview(body)
    );
}

export async function updatePreorder(body: PreOrderUpdate, rideId: string) {
    const response = await axios.post(
        `${appConfig.apiUrl}/self-service/rides/${encodeURIComponent(rideId)}/update-preorder`,
        body,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useUpdatePreorder() {
    return useMutation(
        [`/self-service/rides/{rideId}/update-preorder`],
        async ({body, rideId}: {body: PreOrderUpdate; rideId: string}) =>
            await updatePreorder(body, rideId)
    );
}

export async function payTaxiTip(
    body: PayTaxiTipRequest,
    rideId: string
): Promise<PayTaxiTipResponse> {
    const response = await axios.post<PayTaxiTipResponse>(
        `${appConfig.apiUrl}/self-service/rides/${encodeURIComponent(rideId)}/taxi/tip`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function usePayTaxiTip() {
    return useMutation(
        [`/self-service/rides/{rideId}/taxi/tip`],
        async ({body, rideId}: {body: PayTaxiTipRequest; rideId: string}) =>
            await payTaxiTip(body, rideId)
    );
}

export async function reverseGeocode(body: ReverseGeocodeRequest): Promise<Location> {
    const response = await axios.post<Location>(
        `${appConfig.apiUrl}/self-service/reverse-geocode`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useReverseGeocode() {
    return useMutation(
        [`/self-service/reverse-geocode`],
        async (body: ReverseGeocodeRequest) => await reverseGeocode(body)
    );
}

export async function estimate(body: PriceEstimationRequest): Promise<PriceEstimationResponse> {
    const response = await axios.post<PriceEstimationResponse>(
        `${appConfig.apiUrl}/self-service/pricing/estimate`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useEstimate() {
    return useMutation(
        [`/self-service/pricing/estimate`],
        async (body: PriceEstimationRequest) => await estimate(body)
    );
}

export async function getLocationSuggestions(
    body: LocationSuggestionsRequest
): Promise<LocationSuggestionsResponse> {
    const response = await axios.post<LocationSuggestionsResponse>(
        `${appConfig.apiUrl}/self-service/location-suggestions`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetLocationSuggestions() {
    return useMutation(
        [`/self-service/location-suggestions`],
        async (body: LocationSuggestionsRequest) => await getLocationSuggestions(body)
    );
}

export async function geocode(body: GeocodeRequest): Promise<GeocodeResponse> {
    const response = await axios.post<GeocodeResponse>(
        `${appConfig.apiUrl}/self-service/geocode`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGeocode() {
    return useMutation(
        [`/self-service/geocode`],
        async (body: GeocodeRequest) => await geocode(body)
    );
}

export async function sendFeedback(body: Feedback) {
    const response = await axios.post(`${appConfig.apiUrl}/self-service/feedback`, body, {
        headers: headers(),
    });

    return response;
}

export function useSendFeedback() {
    return useMutation(
        [`/self-service/feedback`],
        async (body: Feedback) => await sendFeedback(body)
    );
}

export async function createDeliveryOrder(body: DeliveryOrderRequest) {
    const response = await axios.post(`${appConfig.apiUrl}/self-service/deliveryOrder`, body, {
        headers: headers(),
    });

    return response;
}

export function useCreateDeliveryOrder() {
    return useMutation(
        [`/self-service/deliveryOrder`],
        async (body: DeliveryOrderRequest) => await createDeliveryOrder(body)
    );
}

export async function deliveryRideEstimate(
    body: DeliveryArrivalEstimationRequest
): Promise<RideEstimationResponse | null> {
    const response = await axios.post<RideEstimationResponse>(
        `${appConfig.apiUrl}/self-service/deliveryOrder/rideEstimate`,
        body,
        {
            headers: headers(),
        }
    );

    if (response.status === 204) {
        return null;
    }

    return response.data;
}

export function useDeliveryRideEstimate() {
    return useMutation(
        [`/self-service/deliveryOrder/rideEstimate`],
        async (body: DeliveryArrivalEstimationRequest) => await deliveryRideEstimate(body)
    );
}

export async function deliveryOverview(body: DeliveryOverviewRequest): Promise<DeliveryOverview> {
    const response = await axios.post<DeliveryOverview>(
        `${appConfig.apiUrl}/self-service/deliveryOrder/overview`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useDeliveryOverview() {
    return useMutation(
        [`/self-service/deliveryOrder/overview`],
        async (body: DeliveryOverviewRequest) => await deliveryOverview(body)
    );
}

export async function importDeliveryOrder(body: FormData) {
    const response = await axios.post(
        `${appConfig.apiUrl}/self-service/deliveryOrder/import`,
        body,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useImportDeliveryOrder() {
    return useMutation(
        [`/self-service/deliveryOrder/import`],
        async (body: FormData) => await importDeliveryOrder(body)
    );
}

export async function enableCollectOnDelivery(body: CollectOnDeliveryEnableRequest) {
    const response = await axios.post(
        `${appConfig.apiUrl}/self-service/collect-on-delivery/enable`,
        body,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useEnableCollectOnDelivery() {
    return useMutation(
        [`/self-service/collect-on-delivery/enable`],
        async (body: CollectOnDeliveryEnableRequest) => await enableCollectOnDelivery(body)
    );
}

export async function requestMonthlyPayment() {
    const response = await axios.post(
        `${appConfig.apiUrl}/self-service/businesses/request-monthly-payment`,
        null,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useRequestMonthlyPayment() {
    return useMutation(
        [`/self-service/businesses/request-monthly-payment`],
        async () => await requestMonthlyPayment()
    );
}

export async function createBusiness(
    body: PtyCreateBusinessRequest
): Promise<CreateBusinessResult> {
    const response = await axios.post<CreateBusinessResult>(
        `${appConfig.apiUrl}/self-service/businesses/register`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useCreateBusiness() {
    return useMutation(
        [`/self-service/businesses/register`],
        async (body: PtyCreateBusinessRequest) => await createBusiness(body)
    );
}

export async function getUsers(): Promise<BusinessUserDto[]> {
    const response = await axios.get<BusinessUserDto[]>(
        `${appConfig.apiUrl}/self-service/business/users`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetUsers() {
    return useQuery([`/self-service/business/users`], async () => await getUsers());
}

export async function createUser(body: CreateBusinessUserRequest): Promise<BusinessUserDto[]> {
    const response = await axios.post<BusinessUserDto[]>(
        `${appConfig.apiUrl}/self-service/business/users`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useCreateUser() {
    return useMutation(
        [`/self-service/business/users`],
        async (body: CreateBusinessUserRequest) => await createUser(body)
    );
}

export async function getEmployees(): Promise<BusinessEmployeeDto[]> {
    const response = await axios.get<BusinessEmployeeDto[]>(
        `${appConfig.apiUrl}/self-service/business/employees`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetEmployees() {
    return useQuery([`/self-service/business/employees`], async () => await getEmployees());
}

export async function createEmployee(
    body: CreateOrUpdateEmployeeRequest
): Promise<BusinessEmployeeDto[]> {
    const response = await axios.post<BusinessEmployeeDto[]>(
        `${appConfig.apiUrl}/self-service/business/employees`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useCreateEmployee() {
    return useMutation(
        [`/self-service/business/employees`],
        async (body: CreateOrUpdateEmployeeRequest) => await createEmployee(body)
    );
}

export async function executeCardDebt(): Promise<BusinessDebtResponse> {
    const response = await axios.post<BusinessDebtResponse>(
        `${appConfig.apiUrl}/self-service/business/debt/execute`,
        null,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useExecuteCardDebt() {
    return useMutation(
        [`/self-service/business/debt/execute`],
        async () => await executeCardDebt()
    );
}

export async function initPaymentCardEntry(
    redirectUri: string
): Promise<InitPaymentCardEntryResponse> {
    const response = await axios.post<InitPaymentCardEntryResponse>(
        `${appConfig.apiUrl}/self-service/business/cards/initPaymentCardEntry`,
        null,
        {
            headers: headers(),
            params: {redirectUri},
        }
    );

    return response.data;
}

export function useInitPaymentCardEntry() {
    return useMutation(
        [`/self-service/business/cards/initPaymentCardEntry`],
        async (redirectUri: string) => await initPaymentCardEntry(redirectUri)
    );
}

export async function executeLegacyCardDebt(businessId: string): Promise<BusinessDebtResponse> {
    const response = await axios.post<BusinessDebtResponse>(
        `${appConfig.apiUrl}/self-service/business-admin/billing/businesses/${encodeURIComponent(
            businessId
        )}/debt/execute`,
        null,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useExecuteLegacyCardDebt() {
    return useMutation(
        [`/self-service/business-admin/billing/businesses/{businessId}/debt/execute`],
        async (businessId: string) => await executeLegacyCardDebt(businessId)
    );
}

export async function sendForgottenPasswordEmail(username: string) {
    const response = await axios.post(
        `${appConfig.apiUrl}/self-service/auth/send-forgotten-password-email`,
        null,
        {
            headers: headers(),
            params: {username},
        }
    );

    return response;
}

export function useSendForgottenPasswordEmail() {
    return useMutation(
        [`/self-service/auth/send-forgotten-password-email`],
        async (username: string) => await sendForgottenPasswordEmail(username)
    );
}

export async function login(body: LoginRequest): Promise<LoginResponse> {
    const response = await axios.post<LoginResponse>(
        `${appConfig.apiUrl}/self-service/auth/login`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useLogin() {
    return useMutation(
        [`/self-service/auth/login`],
        async (body: LoginRequest) => await login(body)
    );
}

export async function impersonateBusiness(
    body: ImpersonateBusinessRequest
): Promise<LoginResponse> {
    const response = await axios.post<LoginResponse>(
        `${appConfig.apiUrl}/self-service/auth/impersonate-business`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useImpersonateBusiness() {
    return useMutation(
        [`/self-service/auth/impersonate-business`],
        async (body: ImpersonateBusinessRequest) => await impersonateBusiness(body)
    );
}

export async function changePassword(body: ChangePasswordRequest) {
    const response = await axios.post(
        `${appConfig.apiUrl}/self-service/auth/change-password`,
        body,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useChangePassword() {
    return useMutation(
        [`/self-service/auth/change-password`],
        async (body: ChangePasswordRequest) => await changePassword(body)
    );
}

export async function addOrdererRole(body: AddOrdererRoleRequest): Promise<AddOrdererRoleResponse> {
    const response = await axios.post<AddOrdererRoleResponse>(
        `${appConfig.apiUrl}/self-service/auth/business-admin/add-orderer-role`,
        body,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useAddOrdererRole() {
    return useMutation(
        [`/self-service/auth/business-admin/add-orderer-role`],
        async (body: AddOrdererRoleRequest) => await addOrdererRole(body)
    );
}

export async function callerUserInfo(): Promise<OrdererInfo> {
    const response = await axios.get<OrdererInfo>(`${appConfig.apiUrl}/self-service/userInfo`, {
        headers: headers(),
    });

    return response.data;
}

export function useCallerUserInfo() {
    return useQuery([`/self-service/userInfo`], async () => await callerUserInfo());
}

export async function getReceiptPdf(rideId: string) {
    const response = await axios.get(
        `${appConfig.apiUrl}/self-service/rides/${encodeURIComponent(rideId)}/receipt/pdf`,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useGetReceiptPdf(rideId: string) {
    return useQuery(
        [`/self-service/rides/${encodeURIComponent(rideId)}/receipt/pdf`],
        async () => await getReceiptPdf(rideId)
    );
}

export async function getConfirmationPdf(rideId: string) {
    const response = await axios.get(
        `${appConfig.apiUrl}/self-service/rides/${encodeURIComponent(rideId)}/confirmation/pdf`,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useGetConfirmationPdf(rideId: string) {
    return useQuery(
        [`/self-service/rides/${encodeURIComponent(rideId)}/confirmation/pdf`],
        async () => await getConfirmationPdf(rideId)
    );
}

export async function getRideUpdates() {
    const response = await axios.get(`${appConfig.apiUrl}/self-service/rides/updates`, {
        headers: headers(),
    });

    return response;
}

export function useGetRideUpdates() {
    return useQuery([`/self-service/rides/updates`], async () => await getRideUpdates());
}

export async function getTaxiRides(): Promise<TaxiRidesResponse> {
    const response = await axios.get<TaxiRidesResponse>(
        `${appConfig.apiUrl}/self-service/rides/taxi`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetTaxiRides() {
    return useQuery([`/self-service/rides/taxi`], async () => await getTaxiRides());
}

export async function getDeliveryRides(): Promise<DeliveryRidesResponse> {
    const response = await axios.get<DeliveryRidesResponse>(
        `${appConfig.apiUrl}/self-service/rides/delivery`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetDeliveryRides() {
    return useQuery([`/self-service/rides/delivery`], async () => await getDeliveryRides());
}

export async function projectCodeSettings(): Promise<ProjectCodeSettings> {
    const response = await axios.get<ProjectCodeSettings>(
        `${appConfig.apiUrl}/self-service/orderer/projectCodes`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useProjectCodeSettings() {
    return useQuery(
        [`/self-service/orderer/projectCodes`],
        async () => await projectCodeSettings()
    );
}

export async function costCenters(): Promise<CostCenters> {
    const response = await axios.get<CostCenters>(
        `${appConfig.apiUrl}/self-service/orderer/costCenters`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useCostCenters() {
    return useQuery([`/self-service/orderer/costCenters`], async () => await costCenters());
}

export async function getExampleLink(): Promise<ExampleFileLink> {
    const response = await axios.get<ExampleFileLink>(
        `${appConfig.apiUrl}/self-service/deliveryOrder/import/example`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetExampleLink() {
    return useQuery(
        [`/self-service/deliveryOrder/import/example`],
        async () => await getExampleLink()
    );
}

export async function exportLegsToExcel() {
    const response = await axios.get(`${appConfig.apiUrl}/self-service/deliveryOrder/export`, {
        headers: headers(),
        responseType: "blob",
    });

    return response;
}

export function useExportLegsToExcel() {
    return useQuery([`/self-service/deliveryOrder/export`], async () => await exportLegsToExcel());
}

export async function callerCompany(): Promise<CompanyInfo> {
    const response = await axios.get<CompanyInfo>(`${appConfig.apiUrl}/self-service/company`, {
        headers: headers(),
    });

    return response.data;
}

export function useCallerCompany() {
    return useQuery([`/self-service/company`], async () => await callerCompany());
}

export async function employees(): Promise<Employees> {
    const response = await axios.get<Employees>(
        `${appConfig.apiUrl}/self-service/company/employees`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useEmployees() {
    return useQuery([`/self-service/company/employees`], async () => await employees());
}

export async function getCollectOnDeliveryStatus(): Promise<CollectOnDeliveryStatusResponse> {
    const response = await axios.get<CollectOnDeliveryStatusResponse>(
        `${appConfig.apiUrl}/self-service/collect-on-delivery/status`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetCollectOnDeliveryStatus() {
    return useQuery(
        [`/self-service/collect-on-delivery/status`],
        async () => await getCollectOnDeliveryStatus()
    );
}

export async function companyLookup(companyId: string): Promise<CompanyLookupResponse> {
    const response = await axios.get<CompanyLookupResponse>(
        `${appConfig.apiUrl}/self-service/businesses/lookup`,
        {
            headers: headers(),
            params: {companyId},
        }
    );

    return response.data;
}

export function useCompanyLookup(companyId: string) {
    return useQuery(
        [`/self-service/businesses/lookup`],
        async () => await companyLookup(companyId)
    );
}

export async function checkEmail(email: string): Promise<CheckEmailResult> {
    const response = await axios.get<CheckEmailResult>(
        `${appConfig.apiUrl}/self-service/businesses/check`,
        {
            headers: headers(),
            params: {email},
        }
    );

    return response.data;
}

export function useCheckEmail(email: string) {
    return useQuery([`/self-service/businesses/check`], async () => await checkEmail(email));
}

export async function isActive(companyId: string): Promise<CopmanyIdActiveResult> {
    const response = await axios.get<CopmanyIdActiveResult>(
        `${appConfig.apiUrl}/self-service/businesses/active`,
        {
            headers: headers(),
            params: {companyId},
        }
    );

    return response.data;
}

export function useIsActive(companyId: string) {
    return useQuery([`/self-service/businesses/active`], async () => await isActive(companyId));
}

export async function getBusinessInvoicings(): Promise<BusinessInvoicingList> {
    const response = await axios.get<BusinessInvoicingList>(
        `${appConfig.apiUrl}/self-service/business/invoicings`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetBusinessInvoicings() {
    return useQuery(
        [`/self-service/business/invoicings`],
        async () => await getBusinessInvoicings()
    );
}

export async function getEncodedInvoicingSummary(idInvoicing: number) {
    const response = await axios.get(
        `${appConfig.apiUrl}/self-service/business/invoicings/${encodeURIComponent(
            idInvoicing
        )}/summary/encoded`,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useGetEncodedInvoicingSummary(idInvoicing: number) {
    return useQuery(
        [`/self-service/business/invoicings/${encodeURIComponent(idInvoicing)}/summary/encoded`],
        async () => await getEncodedInvoicingSummary(idInvoicing)
    );
}

export async function getEncodedIssuedInvoice(idInvoice: number) {
    const response = await axios.get(
        `${appConfig.apiUrl}/self-service/business/invoices/${encodeURIComponent(
            idInvoice
        )}/encoded`,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useGetEncodedIssuedInvoice(idInvoice: number) {
    return useQuery(
        [`/self-service/business/invoices/${encodeURIComponent(idInvoice)}/encoded`],
        async () => await getEncodedIssuedInvoice(idInvoice)
    );
}

export async function getCard(): Promise<CardInfo> {
    const response = await axios.get<CardInfo>(`${appConfig.apiUrl}/self-service/business/cards`, {
        headers: headers(),
    });

    return response.data;
}

export function useGetCard() {
    return useQuery([`/self-service/business/cards`], async () => await getCard());
}

export async function removeCards() {
    const response = await axios.delete(`${appConfig.apiUrl}/self-service/business/cards`, {
        headers: headers(),
    });

    if (response.status === 204) {
        return null;
    }

    return response;
}

export function useRemoveCards() {
    return useMutation([`/self-service/business/cards`], async () => await removeCards());
}

export async function getLegacyBusinessInvoicings(
    businessId: string
): Promise<BusinessInvoicingList> {
    const response = await axios.get<BusinessInvoicingList>(
        `${appConfig.apiUrl}/self-service/business-admin/billing/businesses/${encodeURIComponent(
            businessId
        )}/invoicings`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useGetLegacyBusinessInvoicings(businessId: string) {
    return useQuery(
        [
            `/self-service/business-admin/billing/businesses/${encodeURIComponent(
                businessId
            )}/invoicings`,
        ],
        async () => await getLegacyBusinessInvoicings(businessId)
    );
}

export async function user(): Promise<UserInfo> {
    const response = await axios.get<UserInfo>(`${appConfig.apiUrl}/self-service/auth/user`, {
        headers: headers(),
    });

    return response.data;
}

export function useUser() {
    return useQuery([`/self-service/auth/user`], async () => await user());
}

export async function softUpdateNeeded(): Promise<SoftUpdateResponse> {
    const response = await axios.get<SoftUpdateResponse>(
        `${appConfig.apiUrl}/self-service/app/soft-update`,
        {
            headers: headers(),
        }
    );

    return response.data;
}

export function useSoftUpdateNeeded() {
    return useQuery([`/self-service/app/soft-update`], async () => await softUpdateNeeded());
}

export async function cancelRide(rideId: string) {
    const response = await axios.delete(
        `${appConfig.apiUrl}/self-service/rides/${encodeURIComponent(rideId)}`,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useCancelRide() {
    return useMutation(
        [`/self-service/rides/{rideId}`],
        async (rideId: string) => await cancelRide(rideId)
    );
}

export async function cancelStop(rideId: string, stopId: string) {
    const response = await axios.delete(
        `${appConfig.apiUrl}/self-service/rides/${encodeURIComponent(
            rideId
        )}/stops/${encodeURIComponent(stopId)}`,
        {
            headers: headers(),
        }
    );

    return response;
}

export function useCancelStop() {
    return useMutation(
        [`/self-service/rides/{rideId}/stops/{stopId}`],
        async ({rideId, stopId}: {rideId: string; stopId: string}) =>
            await cancelStop(rideId, stopId)
    );
}
