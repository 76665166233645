import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {TaxiRide} from "../api/apiClient";
import {SearchField} from "../components/SearchField";
import {hasRecentlyFailed} from "../helpers/hasRecentlyFailed";
import {
    Badge,
    EmptyState,
    ListTitle,
    NoSearchResults,
    RideItemContent,
    RideItemHead,
    RideItemView,
    RideList,
} from "../rides/layout";
import {
    formatRemainingMinutes,
    formatTaxiRideStatus,
    getPassengersCount,
    getScheduledTime,
} from "../rides/ridesModel";
import {useRemainingMinutes} from "../rides/useRemainingMinutes";
import {TaxiRideRecreate} from "./TaxiRideRecreate";

export interface TaxiRidesProps {
    rides: TaxiRide[];
    onOpenDetail: (id: string | null) => void;
}

export function TaxiRidesList({rides, onOpenDetail}: TaxiRidesProps) {
    const intl = useIntl();
    const [searchQuery, setSearchQuery] = useState("");
    const resetSearch = () => {
        setSearchQuery("");
    };

    return (
        <RideList>
            <ListTitle>
                <h2>
                    <FormattedMessage id="rides" />
                </h2>
            </ListTitle>
            {rides.length > 0 && (
                <SearchWrapper>
                    <SearchField
                        {...{searchQuery, setSearchQuery, resetSearch}}
                        placeholder={intl.formatMessage({id: "taxi_search_placeholder"})}
                    />
                </SearchWrapper>
            )}
            {searchQuery.length > 0 ? (
                <SearchResultList {...{rides, searchQuery, onOpenDetail}} />
            ) : (
                <NormalRideList {...{rides, onOpenDetail}} />
            )}
        </RideList>
    );
}

function NormalRideList({rides, onOpenDetail}: TaxiRidesProps) {
    const intl = useIntl();
    const inProgressRides = rides.filter((ride) => ride.statusGroup === "IN_PROGRESS");
    const upcomingRides = rides.filter((ride) => ride.statusGroup === "UPCOMING");
    const completedRides = rides.filter((ride) => ride.statusGroup === "COMPLETED");

    return (
        <>
            <h3>{intl.formatMessage({id: "in_progress"})}</h3>
            {inProgressRides.length > 0 ? (
                inProgressRides.map((ride) => (
                    <TaxiRideView key={ride.id} ride={ride} onClick={() => onOpenDetail(ride.id)} />
                ))
            ) : (
                <EmptyState>{intl.formatMessage({id: "no_rides_in_progress"})}</EmptyState>
            )}
            <h3>{intl.formatMessage({id: "upcoming"})}</h3>
            {upcomingRides.length > 0 ? (
                upcomingRides.map((ride) => (
                    <TaxiRideView key={ride.id} ride={ride} onClick={() => onOpenDetail(ride.id)} />
                ))
            ) : (
                <EmptyState>{intl.formatMessage({id: "no_rides_upcoming"})}</EmptyState>
            )}
            <h3>{intl.formatMessage({id: "completed"})}</h3>
            {completedRides.length > 0 ? (
                completedRides.map((ride) => (
                    <TaxiRideView key={ride.id} ride={ride} onClick={() => onOpenDetail(ride.id)} />
                ))
            ) : (
                <EmptyState>{intl.formatMessage({id: "no_rides_completed"})}</EmptyState>
            )}
        </>
    );
}

function SearchResultList({
    rides,
    searchQuery,
    onOpenDetail,
}: {
    rides: TaxiRide[];
    searchQuery: string;
    onOpenDetail: (id: string | null) => void;
}) {
    const filteredRides = rides.filter((ride) =>
        [
            ...ride.stops.map((stop) => stop.contact?.name),
            ...ride.stops.map((stop) => stop.contact?.phoneNumber),
            ...ride.stops.map((stop) => stop.location.address),
            ride.internalNote,
            ride.projectCode,
            ride.costCenter,
        ].some((value) => value?.trim().toLowerCase().includes(searchQuery.trim().toLowerCase()))
    );

    if (filteredRides.length === 0) {
        return (
            <NoSearchResults>
                <FormattedMessage
                    id="no_rides_found"
                    values={{
                        searchQuery: searchQuery,
                    }}
                />
            </NoSearchResults>
        );
    }

    return (
        <>
            {filteredRides.map(
                (ride) =>
                    ride && (
                        <TaxiRideView
                            key={ride.id}
                            ride={ride}
                            onClick={() => onOpenDetail(ride.id)}
                        />
                    )
            )}
        </>
    );
}

interface TaxiStatusLabelProps {
    status: TaxiRide["status"];
    failReason: TaxiRide["failReason"];
    pickupTime: string | undefined;
    requestedPickupTime: string | undefined;
    requestedDropoffTime: string | undefined;
    flightNumber: string | undefined;
}

function TaxiStatusLabel({
    status,
    failReason,
    pickupTime,
    requestedPickupTime,
    requestedDropoffTime,
    flightNumber,
}: TaxiStatusLabelProps) {
    const intl = useIntl();
    const minutesRemaining = useRemainingMinutes(pickupTime);
    function theme() {
        switch (status) {
            case "IN_SEARCHING":
                return "pulsing";
            case "PICKUP_ARRIVAL":
                return "blue";
            case "PICKUP_WAITING":
                return "orange";
            case "FAILED":
                return "red";
            case "CANCELLED":
                if (failReason === "CANCELLED_BY_DRIVER") {
                    return "red";
                }
                return undefined;
            default:
                return undefined;
        }
    }
    return (
        <Badge variant={theme()}>
            {formatTaxiRideStatus(status, failReason, intl)}
            {status === "PICKUP_ARRIVAL" && minutesRemaining != null
                ? ` ${formatRemainingMinutes(minutesRemaining, intl)}`
                : ""}
            {status === "SCHEDULED" && flightNumber && `✈ ${flightNumber} · `}
            {status === "SCHEDULED" &&
                (requestedDropoffTime
                    ? `${intl.formatMessage({id: "drop_off_taxi_eta"})} ${getScheduledTime(
                          requestedDropoffTime,
                          intl
                      )}`
                    : getScheduledTime(requestedPickupTime, intl))}
        </Badge>
    );
}

export interface TaxiRideViewProps {
    ride: TaxiRide;
    onClick: () => void;
}
export function TaxiRideView({ride, onClick}: TaxiRideViewProps) {
    return (
        <RideItemView onClick={onClick}>
            <RideItemHead>
                {getPassengersCount(ride.stops) > 1 ? (
                    <FormattedMessage
                        id="passengers_few"
                        values={{count: getPassengersCount(ride.stops)}}
                    />
                ) : (
                    ride.stops[0].contact?.name
                )}
                <TaxiStatusLabel
                    status={ride.status}
                    failReason={ride.failReason}
                    //FIXME: Remove fallback to pickupArrival when deprecated (~April 1st)
                    pickupTime={ride.stops[0].arrivalEstimate ?? ride.pickupArrival}
                    requestedPickupTime={ride.requestedPickupAt}
                    requestedDropoffTime={ride.requestedDropOffAt}
                    flightNumber={ride.flightNumber}
                />
            </RideItemHead>
            {getPassengersCount(ride.stops) > 1 && (
                <RideItemContent>
                    <Passengers>
                        {ride.stops
                            .filter((stop) => stop.contact)
                            .map((stop) => stop.contact?.name)
                            .join(" · ")}
                    </Passengers>
                </RideItemContent>
            )}
            {hasRecentlyFailed({
                finishedAt: ride.finishedAt,
                failReason: ride.failReason,
                status: ride.status,
            }) && (
                <RideItemContent>
                    <TaxiRideRecreate ride={ride} />
                </RideItemContent>
            )}
        </RideItemView>
    );
}

const SearchWrapper = styled.div`
    padding-top: var(--spacing-small);
    margin-bottom: var(--spacing-medium);
`;

const Passengers = styled.span`
    font-weight: var(--font-weight-thin);
`;
