import {z} from "zod";

export const removeAfterSchema = z
    .string()
    .date()
    .refine((data) => new Date(data) > new Date(), {message: "date_must_be_in_the_future"})
    .nullish()
    .or(z.literal(""));

export const monthlyLimitSchema = z
    .string()
    .regex(/^\d*$/, {message: "enter_numerical_amount"})
    .refine((value) => (value.length === 0 ? true : Number(value) > 0), {
        message: "amount_greater_than_zero",
    })
    .optional();
