import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {useSoftUpdateNeeded} from "../api/apiClient";

export function SoftUpdateToast() {
    const {data} = useSoftUpdateNeeded();
    if (data?.softUpdateNeeded) {
        return (
            <Toast onClick={() => window.location.reload()}>
                <div style={{display: "flex", alignItems: "top", gap: "12px"}}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M22.3867 19.0424C23.1136 20.3751 22.149 22.0001 20.6309 22.0001H3.36904C1.85092 22.0001 0.886291 20.3752 1.61325 19.0424L10.2442 3.21905C11.0022 1.82933 12.9977 1.82933 13.7557 3.21905L22.3867 19.0424ZM11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V8ZM12 17.0001C11.4477 17.0001 11 17.4478 11 18.0001C11 18.5524 11.4477 19.0001 12 19.0001C12.5522 19.0001 13 18.5524 13 18.0001C13 17.4478 12.5522 17.0001 12 17.0001Z"
                            fill="#FFB400"
                        />
                    </svg>

                    <div>
                        <p>
                            <strong>
                                <FormattedMessage id="soft_update_title" />
                            </strong>
                        </p>
                        <p style={{lineHeight: "130%", marginTop: "2px"}}>
                            <FormattedMessage id="soft_update_subtitle" />
                        </p>
                    </div>
                </div>
            </Toast>
        );
    }
    return null;
}

const Toast = styled.button`
    border: none;
    outline: none;
    text-align: left;
    position: fixed;
    bottom: 24px;
    right: 0px;
    margin: 0 24px;
    padding: 16px 16px;
    background-color: var(--white);
    box-shadow: var(--shadow-medium);
    border-radius: var(--border-radius);
    max-width: 320px;
    z-index: 10000;
`;
