/* eslint-disable react-hooks/exhaustive-deps */
import {useQueryClient} from "@tanstack/react-query";
import {Formik} from "formik";
import toast from "react-hot-toast";
import {IntlShape, useIntl} from "react-intl";
import * as yup from "yup";
import {
    deliveryOverview,
    sendFeedback,
    useCallerCompany,
    useCostCenters,
    useCreateDeliveryOrder,
    useProjectCodeSettings,
} from "../api/apiClient";
import {FormWrapper} from "../app/layout";
import {createCachedPhoneNumberValidator} from "../utils/cachedPhoneNumberValidator";
import {DeliveryOrderFormView, handoverLocations} from "./DeliveryOrderFormView";

import posthog from "posthog-js";
import {useContext} from "react";
import {useSearchParams} from "react-router-dom";
import {BusinessStatus} from "../app/BusinessStatus";
import {DELIVERY_EXPRESS_PREORDER_TIER_ID} from "../constants/constants";
import {generateUUID} from "../utils/generateUUID";
import {FormValues, getInitValues} from "./DeliveryPage";
import {formatStops} from "./DeliveryTiers";
import {DeliveryOrderFormValues, createDeliveryPayload} from "./deliveryOrderModel";
import * as storage from "./localStorageModel";

const createDeliveryOrderValidationSchema = (
    intl: IntlShape,
    projectCodeRequired: boolean,
    isCourierOrder: boolean
) => {
    const stopItemSchema = yup.object().shape({
        name: yup
            .string()
            .min(2, intl.formatMessage({id: "too_short"}))
            .required(intl.formatMessage({id: "required"})),
        phoneNumber: yup
            .string()
            .required(intl.formatMessage({id: "required"}))
            .test(
                "checkPhoneNumber",
                intl.formatMessage({id: "invalid_phone_number"}),
                createCachedPhoneNumberValidator()
            ),
        location: yup
            .object()
            .nullable()
            .required(intl.formatMessage({id: "required"}))
            .test(
                "isAddressComplete",
                intl.formatMessage({id: "incomplete_address"}),
                (value) => value?.complete === true
            ),
        noteForDriver: yup.string().max(300, intl.formatMessage({id: "too_long"})),
    });

    const courierFields = {
        projectCode: projectCodeRequired
            ? yup.string().required(intl.formatMessage({id: "required"}))
            : yup.string(),
        costCenter: yup.string(),
        tierId: yup.string(),
        pickupDates: yup.array().when(["tierId"], {
            is: (tierId: string) => tierId === DELIVERY_EXPRESS_PREORDER_TIER_ID,
            then: yup
                .array()
                .of(yup.number().required(intl.formatMessage({id: "required"})))
                .min(1, intl.formatMessage({id: "required"}))
                .nullable(false),
        }),
        pickupTime: yup.string().when(["tierId"], {
            is: (tierId: string) => tierId === DELIVERY_EXPRESS_PREORDER_TIER_ID,
            then: yup.string().required(intl.formatMessage({id: "required"})),
        }),
    };
    const deliveryFields = {
        fallback: yup.object().when("fallbackEnabled", {
            is: true,
            then: stopItemSchema,
        }),
    };

    return yup.object().shape({
        pickup: stopItemSchema,
        handovers: yup.array().of(stopItemSchema),
        ...(isCourierOrder ? courierFields : deliveryFields),
    });
};

export function DeliveryOrderForm() {
    const {data: company} = useCallerCompany();
    const {data: projectCodes} = useProjectCodeSettings();
    const {data: costCenters} = useCostCenters();
    const deliveryOrder = useCreateDeliveryOrder();
    const queryClient = useQueryClient();
    const intl = useIntl();
    const [, setSearchParams] = useSearchParams();
    const {initValues, setInitValues} = useContext(FormValues);

    const projectCodeRequired = projectCodes?.mode === "REQUIRED";

    const isCourierOrder = company?.partnerDeliveryOrderEnabled !== true;

    async function onSubmit(values: DeliveryOrderFormValues): Promise<void> {
        if (!company?.disabledInfo.ridesEnabled) {
            toast.error(intl.formatMessage({id: "business_account_not_active"}));
            return;
        }

        const mutateCallbacks = {
            onSuccess: () => {
                toast.success(intl.formatMessage({id: "order_created"}));
                posthog.capture("deliveryOrdered"); //FIXME remove in October23 and use only "orderCreated"
                posthog.capture("orderCreated", {type: "delivery"});
                storage.setPickupStorage(values.pickup);
                if (values.fallbackEnabled) {
                    storage.setFallbackStorage(values.fallback);
                }
                storage.setFallbackEnabledStorage(values.fallbackEnabled);
                setSearchParams({});
                setInitValues({
                    ...getInitValues({}),
                    id: generateUUID(),
                });
                queryClient.invalidateQueries(["/self-service/orderer/projectCodes"]);
                queryClient.invalidateQueries(["/self-service/orderer/costCenters"]);
                //Express preorder logging
                if (values.tierId === DELIVERY_EXPRESS_PREORDER_TIER_ID) {
                    posthog.capture("deliveryExpressPreorderOrdered");
                    sendFeedback({
                        currentUrl: "/delivery",
                        text: `Delivery Courier Express Preorder, company: ${company.companyName}`,
                        type: "AUTOMATED",
                    });
                }
            },
            onError: () => {
                queryClient.invalidateQueries(["deliveryTiers"]);
                toast.error(intl.formatMessage({id: "order_failed_title"}));
            },
        };

        if (
            values.tierId === DELIVERY_EXPRESS_PREORDER_TIER_ID &&
            values.pickupDates &&
            values.pickupDates.length > 1 &&
            values.pickup.location
        ) {
            //TODO: change to a promise allSettled.. do a callback only after all done (success or error)
            for (const pickupDate of values.pickupDates) {
                const {courier} = await deliveryOverview({
                    stops: formatStops(
                        values.pickup.location,
                        handoverLocations(values.handovers),
                        values.deliveryType
                    ),
                    serviceType: values.deliveryType,
                });

                const orderPayload = createDeliveryPayload(
                    {...values, pickupDates: [pickupDate]},
                    isCourierOrder,
                    courier?.express?.priceOffer.token //FIXME: the price could differ from the one seen in UI
                );
                await deliveryOrder.mutateAsync(orderPayload, mutateCallbacks);
            }
            return;
        }
        await deliveryOrder.mutateAsync(
            createDeliveryPayload(values, isCourierOrder),
            mutateCallbacks
        );
    }

    if (company == null) {
        return null;
    }

    return (
        <FormWrapper>
            <BusinessStatus />
            <Formik
                initialValues={initValues}
                enableReinitialize
                validationSchema={createDeliveryOrderValidationSchema(
                    intl,
                    projectCodeRequired,
                    isCourierOrder
                )}
                onSubmit={onSubmit}
            >
                <DeliveryOrderFormView {...{deliveryOrder, company, projectCodes, costCenters}} />
            </Formik>
        </FormWrapper>
    );
}
