import {ReactNode} from "react";
import styled from "styled-components";

export function DetailRow({title, children}: {title: string; children: ReactNode}) {
    return (
        <Wrapper>
            <Label>{title}</Label>
            <Value>{children}</Value>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    margin: var(--spacing-medium) 0;
`;

const Label = styled.div`
    min-width: 90px;
    color: var(--secondary);
    margin-right: 8px;
`;

const Value = styled.div`
    overflow: auto;
    overflow-wrap: break-word;
`;
