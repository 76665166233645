import {zodResolver} from "@hookform/resolvers/zod";
import axios from "axios";
import {Alert} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import {Link, useLocation} from "react-router-dom";
import styled from "styled-components";
import {z} from "zod";
import {useSendForgottenPasswordEmail} from "../api/apiClient";
import {Card, Error, Input, Label, Title, Wrapper} from "../app/Registration";
import {Button} from "../components/Button";
import {emailRegex} from "../constants/constants";
import logo from "../img/logo.svg";

const schema = z.object({
    email: z.string().min(1, {message: "required"}).regex(emailRegex, {message: "invalid_email"}),
});

type FormData = z.infer<typeof schema>;

export function ResetPassword() {
    const {search} = useLocation();
    //FIXME: Remove if driver ever gets own reset page
    const driverParam = new URLSearchParams(search).has("driver");

    const mutation = useSendForgottenPasswordEmail();
    const intl = useIntl();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<FormData>({
        mode: "onTouched",
        resolver: zodResolver(schema),
        criteriaMode: "all",
    });

    const handleFormSubmit = async (data: FormData) => {
        mutation.mutate(data.email);
    };

    function errorMessage(error: any) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 409) {
                return (
                    <Alert variant="danger">
                        <FormattedMessage id="email_not_found" />
                    </Alert>
                );
            }
        }
        return (
            <Alert variant="danger">
                <FormattedMessage id="generic_error" />
            </Alert>
        );
    }

    return (
        <Wrapper>
            <img src={logo} width="80px" alt="" />
            {mutation.isSuccess ? (
                <Alert variant="success" style={{marginTop: "16px"}}>
                    <FormattedMessage id="email_sent" />
                </Alert>
            ) : (
                <>
                    <Title>
                        <FormattedMessage id="reset_password" />
                    </Title>
                    <Text>
                        <FormattedMessage id="reset_password_explanation" />
                    </Text>
                    <Card>
                        {mutation.error && errorMessage(mutation.error)}

                        <form
                            onSubmit={handleSubmit(handleFormSubmit)}
                            style={{display: "flex", flexDirection: "column"}}
                        >
                            <Label htmlFor="email">
                                <FormattedMessage id="email" />
                            </Label>
                            <Input type="email" autoComplete="username" {...register("email")} />
                            {errors.email?.message && (
                                <Error>{intl.formatMessage({id: errors.email?.message})}</Error>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                style={{marginTop: "16px"}}
                                disabled={mutation.isLoading}
                            >
                                <FormattedMessage id="continue" />
                            </Button>
                        </form>
                    </Card>
                </>
            )}
            {!driverParam && (
                <Link to="/login" style={{marginTop: "16px", display: "inline-block"}}>
                    <FormattedMessage id="back_to_login" />
                </Link>
            )}
        </Wrapper>
    );
}

const Text = styled.div`
    color: var(--secondary);
    margin-top: var(--spacing-small);
    margin-bottom: var(--spacing-large);
`;
