import {ChangeEvent, useEffect, useMemo, useState} from "react";
import {IntlShape, useIntl} from "react-intl";
import {formatShortDateFancy, formatTime} from "../app/dateUtils";
import {IconFormControlWrapper, Select} from "../components/forms";
import timeIcon from "../img/ic_time.svg";
import {DateInterval} from "./deliveryOrderModel";

export interface ArrivalTimeSelectProps {
    selectedDate?: DateInterval;
    onDateSelected: (date?: DateInterval) => void;
}

const deliveryRangeMinutes = 120;
const selectOptionsIntervalMinutes = 30;
const nearestOrderTimeMinutes = 30;

function addDays(date: Date, days: number) {
    const copy = new Date(date);
    copy.setDate(date.getDate() + days);
    return copy;
}

function addMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * 60000);
}

function getNearestStartDate(): Date {
    const startDate = new Date(Math.ceil(new Date().getTime() / 600000) * 600000);
    return addMinutes(startDate, nearestOrderTimeMinutes);
}

function formatDefaultTimeLabel(intl: IntlShape): string {
    const startDate = getNearestStartDate();
    const endDate = addMinutes(startDate, deliveryRangeMinutes);
    return `${intl.formatMessage({id: "today_asap"})} (${formatTime(startDate)}-${formatTime(
        endDate
    )})`;
}

function formatDateInterval(intl: IntlShape, dateInterval: DateInterval): string {
    return `${formatShortDateFancy(intl, dateInterval.from)} ${formatTime(
        dateInterval.from
    )}-${formatTime(dateInterval.to)}`;
}

function getOptionId(dateInterval?: DateInterval): string | undefined {
    return `option-${dateInterval?.from?.toISOString()}`;
}

function generateOptions(): DateInterval[] {
    const startOption = getNearestStartDate();
    const endOption = addDays(startOption, 7);
    const halfHour = 30 * 60 * 1000;
    let currentOption = new Date(Math.ceil(startOption.getTime() / halfHour) * halfHour);
    const options: DateInterval[] = [];
    while (currentOption < endOption) {
        options.push({
            from: currentOption,
            to: addMinutes(currentOption, deliveryRangeMinutes),
        });
        currentOption = addMinutes(currentOption, selectOptionsIntervalMinutes);
    }

    return options;
}

export function ArrivalTimeSelect({selectedDate, onDateSelected}: ArrivalTimeSelectProps) {
    const intl = useIntl();

    const [options, setOptions] = useState<DateInterval[]>();

    useEffect(() => {
        setOptions(generateOptions());
        const interval = setInterval(() => {
            setOptions(generateOptions());
            checkSelectedTime();
        }, 60000);
        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function checkSelectedTime() {
        const startDate = getNearestStartDate();
        if (selectedDate && startDate > selectedDate.from) {
            onDateSelected(undefined);
        }
    }

    function handleChange(event: ChangeEvent<any>) {
        const value = event.target.value;
        const option = options?.find((o) => getOptionId(o) === value);
        onDateSelected(option);
    }

    return (
        <IconFormControlWrapper icon={timeIcon} name="arrivalTime">
            <Select value={getOptionId(selectedDate)} onChange={handleChange}>
                <option key={getOptionId(undefined)} value={getOptionId(undefined)}>
                    {formatDefaultTimeLabel(intl)}
                </option>
                {useMemo(
                    () =>
                        options?.map((option) => (
                            <option key={getOptionId(option)} value={getOptionId(option)}>
                                {formatDateInterval(intl, option)}
                            </option>
                        )),
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    [options]
                )}
            </Select>
        </IconFormControlWrapper>
    );
}
