import styled from "styled-components";

export const ListWrapper = styled.div`
    display: flex;
    height: 100%;
    overflow: auto;
`;

export const DetailWrapper = styled.div`
    width: 320px;
    height: 100%;
    background-color: white;
    overflow: auto;

    @media (max-width: 960px) {
        display: block;
        position: absolute;
        top: calc(var(--navigation-height) + 1px);
        right: 0;
        width: 320px;
        height: calc(100% - 1px - var(--navigation-height));
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.07), 0 24px 48px rgba(0, 0, 0, 0.07);
        z-index: 60;
        overflow: auto;
    }

    @media (max-width: 650px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        box-shadow: none;
        z-index: 80;
    }
`;

export const DetailHeader = styled.div`
    height: var(--detailheader-height);
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid var(--light);
    position: sticky;
    top: 0;
    z-index: 2;
`;

export const DetailContent = styled.div`
    padding: var(--spacing-small) var(--spacing-large);
`;

export const SectionTitle = styled.div`
    margin: 16px 0 8px 0;
`;

export const RideList = styled.div`
    height: 100%;
    width: 320px;
    padding: 16px;
    background-color: var(--light);
    overflow: auto;
    @media (max-width: 650px) {
        width: 100vw;
        height: calc(100% - var(--detailheader-height));
    }
`;

export const ListTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 24px;
`;

export const EmptyState = styled.div`
    text-align: center;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-thin);
    color: var(--secondary);
    background-color: rgba(0, 0, 0, 0.05);
    padding: 16px;
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-medium);
`;

export const Badge = styled.div<{variant?: "blue" | "orange" | "red" | "pulsing"}>`
    display: inline-block;
    padding: 4px 12px;
    background-color: var(--light);
    border-radius: 100px;
    font-size: 12px;
    text-align: center;
    ${(p) => p.variant === "blue" && `background-color: var(--blue-light);`}
    ${(p) => p.variant === "orange" && `background-color: var(--orange-light);`}
    ${(p) => p.variant === "red" && `background-color: var(--red-light); color: var(--red);`}
    ${(p) => p.variant === "pulsing" && `animation: pulsing ease-in-out 2400ms infinite;`}

    @keyframes pulsing {
        0% {
            background-color: var(--light);
        }
        50% {
            background-color: var(--quaternary);
        }
        100% {
            background-color: var(--light);
        }
    }
`;

export const RideItemView = styled.div`
    box-shadow: 0px 1px 0px 0px rgba(237, 237, 237, 1);
    background-color: white;
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-medium);
    transition: box-shadow ease-in 0.2s;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 4px 8px 0px rgba(228, 228, 228, 1);
        transition: box-shadow ease-out 0.2s;
    }
`;

export const RideItemHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 8px 16px;
    border-bottom: 0.5px solid var(--light);

    & img {
        margin-bottom: 2px;
        margin-right: 4px;
    }
`;

export const RideItemContent = styled.div`
    padding: 8px 16px 12px 16px;
`;

export const NoSearchResults = styled.div`
    overflow: auto;
    overflow-wrap: break-word;
`;
