import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {useCallerCompany} from "../api/apiClient";
import {useUserInfo} from "../auth/authModel";
import {useCardStatusToast} from "../hooks/useCardStatusToast";
import {BusinessStatus} from "./BusinessStatus";
import {Invoices} from "./Invoices";

export function Dashboard() {
    const {data: company} = useCallerCompany();
    const {data: user} = useUserInfo();
    useCardStatusToast();

    if (!company || !user) {
        return null;
    }
    const isAdmin = user?.roles.includes("BUSINESS_ADMIN");

    return (
        <BasicPageWrapper>
            <BusinessStatus />
            <CompanyName>{company?.companyName}</CompanyName>
            <h1>
                <FormattedMessage id="start_ordering" />
            </h1>
            <OrderSection>
                <DashboardLink to="/taxi" style={{fontSize: "18px"}}>
                    <RowLayout>
                        <div>
                            <svg
                                width="24"
                                height="24"
                                fill="currentColor"
                                style={{
                                    marginRight: "8px",
                                    marginTop: "-3px",
                                }}
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M17.9457 5.01C17.7482 4.42 17.1951 4 16.5432 4H7.45679C6.80494 4 6.26173 4.42 6.05433 5.01L3 11H2C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13H3V19C3 19.55 3.45679 20 4 20H5C5.54321 20 6 19.55 6 19V18H18V19C18 19.55 18.4568 20 19 20H20C20.5432 20 21 19.55 21 19V13H22C22.5523 13 23 12.5523 23 12C23 11.4477 22.5523 11 22 11H21L17.9457 5.01ZM8.43262 6H15.5674C15.8325 6 16.0749 6.1498 16.1935 6.38695L17.7829 9.56584C17.8827 9.76531 17.7376 10 17.5146 10H6.48541C6.2624 10 6.11735 9.76531 6.21708 9.56584L7.80652 6.38695C7.9251 6.1498 8.16748 6 8.43262 6ZM5 14C5 13.4477 5.44772 13 6 13H7.5C8.05228 13 8.5 13.4477 8.5 14C8.5 14.5523 8.05228 15 7.5 15H6C5.44772 15 5 14.5523 5 14ZM16.5 13C15.9477 13 15.5 13.4477 15.5 14C15.5 14.5523 15.9477 15 16.5 15H18C18.5523 15 19 14.5523 19 14C19 13.4477 18.5523 13 18 13H16.5Z"
                                />
                            </svg>

                            <FormattedMessage id="taxi_nav" />
                        </div>
                        <span>→</span>
                    </RowLayout>
                </DashboardLink>
                <DashboardLink to="/delivery" style={{fontSize: "18px"}}>
                    <RowLayout>
                        <div>
                            <svg
                                width="24"
                                height="24"
                                fill="currentColor"
                                style={{
                                    marginRight: "8px",
                                    marginTop: "-3px",
                                }}
                            >
                                <path d="M3.27535 6.45504C5.70978 7.50009 10.2941 9.4422 11.6303 10.0079C11.8716 10.1101 12.1404 10.1071 12.3806 10.0023C13.0553 9.70806 14.5509 9.05665 16.1507 8.36599L6.45993 4.25141C5.57275 4.63748 4.84741 4.95046 4.54923 5.0728C3.95742 5.31559 3.59201 5.46626 3.24897 5.62706C2.89028 5.79519 2.91134 6.29878 3.27535 6.45504Z" />
                                <path d="M20.693 6.43329C20.0436 6.7004 19.1387 7.08352 18.1566 7.5036C17.2304 7.1045 9.48271 3.76621 8.50442 3.35842C9.55039 2.90034 10.5658 2.45424 11.2311 2.16168C11.7245 1.94477 12.2847 1.94634 12.7772 2.16502C14.2018 2.79752 17.2928 4.16428 19.4392 5.0728C19.8819 5.26015 20.3399 5.45564 20.728 5.63009C21.0926 5.79402 21.0628 6.28121 20.693 6.43329Z" />
                                <path d="M14.2702 22.1569C13.6424 22.4131 12.955 21.9512 12.955 21.2731V12.4904C12.955 12.1108 13.1767 11.7687 13.5246 11.617C15.1313 10.9164 19.7187 8.91691 21.8206 8.00922C22.1352 7.87337 22.4862 8.10062 22.4871 8.44329C22.4918 10.1522 22.5033 14.5047 22.4991 17.5246C22.498 18.2978 22.0289 18.9909 21.313 19.283L14.2702 22.1569Z" />
                                <path d="M9.73904 22.1839C10.3662 22.4356 11.0491 21.9738 11.0491 21.298V12.5642C11.0491 12.1838 10.8267 11.8412 10.4777 11.6899C8.86812 10.9922 4.27785 9.00304 2.17561 8.09783C1.86098 7.96235 1.51174 8.18953 1.51229 8.53209C1.51501 10.2326 1.52008 14.5429 1.50004 17.5678C1.49482 18.3555 1.97037 19.0659 2.70137 19.3593L9.73904 22.1839Z" />
                            </svg>

                            <FormattedMessage id="delivery_nav" />
                        </div>
                        <span>→</span>
                    </RowLayout>
                </DashboardLink>
            </OrderSection>
            {isAdmin && (
                <>
                    <h1>
                        <FormattedMessage id="give_access_to_your_team" />
                    </h1>
                    <OrderSection>
                        <DashboardLink to="/admin/users/mobile-app">
                            <ColumnLayout>
                                <div>
                                    <h2 style={{marginBottom: "8px", color: "var(--dark)"}}>
                                        <FormattedMessage id="mobile_app" />
                                    </h2>
                                    <Text>
                                        <FormattedMessage id="mobile_app_users_banner_description" />
                                    </Text>
                                </div>
                                <div>
                                    <FormattedMessage id="invite_mobile_user" /> →
                                </div>
                            </ColumnLayout>
                        </DashboardLink>
                        <DashboardLink to="/admin/users/web-app">
                            <ColumnLayout>
                                <div>
                                    <h2 style={{marginBottom: "8px", color: "var(--dark)"}}>
                                        <FormattedMessage id="web_app" />
                                    </h2>
                                    <Text>
                                        <FormattedMessage id="web_app_users_banner_description" />
                                    </Text>
                                </div>
                                <div>
                                    <FormattedMessage id="invite_web_user" /> →
                                </div>
                            </ColumnLayout>
                        </DashboardLink>
                    </OrderSection>
                    <Invoices short />
                </>
            )}
        </BasicPageWrapper>
    );
}

export const BasicPageWrapper = styled.div`
    margin: 24px auto;
    padding: 0 16px 24px 16px;
    max-width: 740px;
    & h1:not(:first-of-type) {
        margin-top: 24px;
    }
`;

const OrderSection = styled.div`
    display: flex;
    align-items: space-between;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;
`;

const DashboardLink = styled(Link)`
    padding: var(--card-padding);
    border-radius: var(--border-radius-large);
    border: 0.5px solid var(--quaternary);
    min-width: 200px;
    display: "flex";
    flex: 1;
    box-shadow: 0px 1px 0px 0px rgba(237, 237, 237, 1);
    background-color: white;
    transition: box-shadow ease-in 0.2s;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 4px 8px 0px rgba(228, 228, 228, 1);
        transition: box-shadow ease-out 0.2s;
    }
`;

const ColumnLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-between;
`;

const RowLayout = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
`;

const Text = styled.p`
    color: var(--secondary);
    font-weight: var(--font-weight-thin);
`;

export const CompanyName = styled.h2`
    font-size: 14px;
    color: var(--secondary);
    margin-bottom: 8px;
    font-variant-caps: all-small-caps;
    letter-spacing: 0.4px;
`;
