import {ReactNode} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export function InfoTooltip({children}: {children: ReactNode}) {
    return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-id">{children}</Tooltip>}>
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7V10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10V7ZM7 3C6.44772 3 6 3.44772 6 4C6 4.55228 6.44772 5 7 5C7.55228 5 8 4.55228 8 4C8 3.44772 7.55228 3 7 3Z"
                    fill="#c3ccd7"
                />
            </svg>
        </OverlayTrigger>
    );
}
