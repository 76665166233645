import {Form, Formik} from "formik";
import {FormControl} from "react-bootstrap";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {useLocation} from "react-router-dom";
import * as yup from "yup";
import {LoginRequest} from "../api/apiClient";
import {Button} from "../components/Button";
import {FormControlWrapper} from "../components/forms";

export interface LoginFormProps {
    onSubmit: (values: LoginRequest) => void;
}

const createLoginValidationSchema = (intl: IntlShape) => {
    return yup.object().shape({
        username: yup
            .string()
            .required(intl.formatMessage({id: "required_email"}))
            .email(intl.formatMessage({id: "invalid_email"}))
            .label(intl.formatMessage({id: "email"})),
        password: yup
            .string()
            .required(intl.formatMessage({id: "required_password"}))
            .label(intl.formatMessage({id: "password"})),
    });
};

export function LoginForm({onSubmit}: LoginFormProps) {
    const intl = useIntl();
    const location = useLocation();

    const passedEmail = location.state?.email as string | undefined;
    const initValues = {
        username: passedEmail ?? "",
        password: "",
    };

    return (
        <Formik
            initialValues={initValues}
            onSubmit={onSubmit}
            validationSchema={createLoginValidationSchema(intl)}
        >
            {({getFieldProps, isSubmitting}) => (
                <Form>
                    <FormControlWrapper title={intl.formatMessage({id: "email"})} name="username">
                        <FormControl
                            type="email"
                            autoComplete="username"
                            {...getFieldProps("username")}
                        />
                    </FormControlWrapper>
                    <FormControlWrapper
                        title={intl.formatMessage({id: "password"})}
                        name="password"
                    >
                        <FormControl
                            type="password"
                            autoComplete="current-password"
                            {...getFieldProps("password")}
                        />
                    </FormControlWrapper>
                    <Button type="submit" disabled={isSubmitting} fullWidth>
                        <FormattedMessage id="login" />
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
