import {zodResolver} from "@hookform/resolvers/zod";
import axios from "axios";
import {Alert} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import {Link, useParams} from "react-router-dom";
import {z} from "zod";
import {useChangePassword} from "../api/apiClient";
import {Card, Error, Input, Label, Title, Wrapper} from "../app/Registration";
import {Button} from "../components/Button";
import logo from "../img/logo.svg";

const schema = z
    .object({
        password: z
            .string()
            .min(1, {message: "required"})
            .min(8, {message: "password_min_characters"}),
        confirm: z
            .string()
            .min(1, {message: "required"})
            .min(8, {message: "password_min_characters"}),
    })
    .refine((data) => data.password === data.confirm, {
        message: "confirm_password_error",
        path: ["confirm"],
    });

type FormData = z.infer<typeof schema>;

export function SetNewPassword() {
    const {token} = useParams();
    const mutation = useChangePassword();
    const intl = useIntl();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<FormData>({
        mode: "onTouched",
        resolver: zodResolver(schema),
        criteriaMode: "all",
    });

    if (token == null) {
        return null;
    }
    const handleFormSubmit = async (data: FormData) => {
        mutation.mutate({newPassword: data.password, changePasswordToken: token});
    };

    function errorMessage(error: any) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 400) {
                return (
                    <Alert variant="danger">
                        <FormattedMessage id="new_password_failure" />{" "}
                        <Link to=".." relative="path">
                            <FormattedMessage id="request_new_password_again" />
                        </Link>
                    </Alert>
                );
            }
        }
        return (
            <Alert variant="danger">
                <FormattedMessage id="generic_error" />
            </Alert>
        );
    }

    function getContent() {
        if (mutation.error) {
            return errorMessage(mutation.error);
        }

        if (mutation.isSuccess) {
            return (
                <>
                    <Alert variant="success">
                        <FormattedMessage id="pasword_changed" />
                    </Alert>
                    <Link to="/login">
                        <FormattedMessage id="back_to_login" />
                    </Link>
                </>
            );
        }

        return (
            <Card>
                <form
                    onSubmit={handleSubmit(handleFormSubmit)}
                    style={{display: "flex", flexDirection: "column"}}
                >
                    <Label htmlFor="password">
                        <FormattedMessage id="new_password" />
                    </Label>
                    <Input type="password" autoComplete="new-password" {...register("password")} />
                    {errors.password?.message && (
                        <Error>{intl.formatMessage({id: errors.password?.message})}</Error>
                    )}
                    <Label htmlFor="confirm">
                        <FormattedMessage id="confirm_password" />
                    </Label>
                    <Input type="password" autoComplete="new-password" {...register("confirm")} />
                    {errors.confirm?.message && (
                        <Error>{intl.formatMessage({id: errors.confirm?.message})}</Error>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        style={{marginTop: "16px"}}
                        disabled={mutation.isLoading}
                    >
                        <FormattedMessage id="continue" />
                    </Button>
                </form>
            </Card>
        );
    }

    return (
        <Wrapper>
            <img src={logo} width="80px" alt="" />
            <Title>
                <FormattedMessage id="reset_password" />
            </Title>
            {getContent()}
        </Wrapper>
    );
}
