import {useQuery} from "@tanstack/react-query";
import {createContext, useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {DateObject} from "react-multi-date-picker";
import {useSearchParams} from "react-router-dom";
import styled from "styled-components";
import {CompanyInfo, UserInfo, useCallerCompany} from "../api/apiClient";
import {
    Content,
    PageContentWrapper,
    TabLeft,
    TabLeftView,
    TabRight,
    TabRightView,
} from "../app/layout";
import {useUserInfo} from "../auth/authModel";
import {CountBadge} from "../components/CountBadge";
import {useDeliveryRides} from "../rides/useRides";
import {generateUUID} from "../utils/generateUUID";
import {DeliveryOrderForm} from "./DeliveryOrderForm";
import {DeliveryRidesPage} from "./DeliveryRidesPage";
import {DeliveryOrderFormValues, StopItem} from "./deliveryOrderModel";
import {parseDeliveryUrlParams} from "./deliveryOrderUrl";
import * as storage from "./localStorageModel";

export function getExpressPreorderInitialTime() {
    return new DateObject().set({
        minute: Math.ceil(new DateObject().minute / 10) * 10,
        hour: new DateObject().hour + 1,
    });
}

export function getInitValues({
    urlParamValues,
    company,
    user,
}: {
    urlParamValues?: StopItem[];
    company?: CompanyInfo;
    user?: UserInfo;
}): DeliveryOrderFormValues {
    const fallbackEnabled = storage.getStoredFallbackEnabled();

    const savedPickup = storage.getStoredPickup();
    const pickup: StopItem = {
        name: urlParamValues?.[0].name || savedPickup?.name || company?.companyName || "",
        phoneNumber:
            urlParamValues?.[0].phoneNumber || savedPickup?.phoneNumber || user?.phoneNumber || "",
        location: urlParamValues?.[0].location || savedPickup?.location,
        noteForDriver: urlParamValues?.[0].noteForDriver || savedPickup?.noteForDriver || "",
        kind: "PICKUP",
    };

    const handover: StopItem = {
        name: urlParamValues?.[1].name || "",
        phoneNumber: urlParamValues?.[1].phoneNumber || "",
        location: urlParamValues?.[1].location,
        noteForDriver: urlParamValues?.[1].noteForDriver || "",
        kind: "HANDOVER",
    };

    const savedFallback = storage.getStoredFallback();
    const fallback: StopItem = {
        name: urlParamValues?.[0].name || savedFallback?.name || company?.companyName || "",
        phoneNumber:
            urlParamValues?.[0].phoneNumber ||
            savedFallback?.phoneNumber ||
            user?.phoneNumber ||
            "",
        location: urlParamValues?.[0].location || savedFallback?.location,
        noteForDriver: savedFallback?.noteForDriver || "",
        kind: "FALLBACK",
    };

    return {
        pickup,
        handovers: [{tripId: generateUUID(), ...handover}],
        fallbackEnabled,
        fallback,
        projectCode: "",
        costCenter: "",
        arrivalTime: undefined,
        deliveryType: "ONE_WAY",
        tierId: "",
        pickupDates: [],
        pickupTime: getExpressPreorderInitialTime(),
        // uuid is used for unique init data to reinitialize form when prefilling form with new init data
        // could be solved differetnly and perhaps rewritten to react-hook-form as formik is no longer maintained
        id: generateUUID(),
    };
}

export const FormValues = createContext({
    initValues: getInitValues({}),
    setInitValues: (values: DeliveryOrderFormValues) => {},
    dirtyForm: false,
    setDirtyForm: (dirty: boolean) => {},
});

export function DeliveryPage() {
    const {data: company} = useCallerCompany();
    const {data: ridesResponse} = useDeliveryRides();
    const {data: user} = useUserInfo();

    const [searchParams] = useSearchParams();
    const {data: urlParamValues} = useQuery(
        ["urlParamValues", Object.fromEntries(searchParams.entries())],
        async () => await parseDeliveryUrlParams(searchParams)
    );
    const [initValues, setInitValues] = useState(() =>
        getInitValues({urlParamValues, company, user})
    );
    const [dirtyForm, setDirtyForm] = useState(false);

    useEffect(() => {
        setInitValues(getInitValues({urlParamValues, company, user}));
    }, [urlParamValues, company, user]);

    const activeRidesCount = ridesResponse?.rides.filter(
        (ride) => ride.statusGroup === "UPCOMING" || ride.statusGroup === "IN_PROGRESS"
    ).length;

    return (
        <>
            {company && (
                <>
                    <TabLeft type="radio" name="tabs" id="tab1" defaultChecked />
                    <TabLeftView className="tab1" htmlFor="tab1" role="tab" aria-controls="col1">
                        <FormattedMessage id="order" />
                    </TabLeftView>
                    <TabRight type="radio" name="tabs" id="tab2" />
                    <TabRightView className="tab2" htmlFor="tab2" role="tab" aria-controls="col2">
                        <div style={{display: "flex"}}>
                            <FormattedMessage id="rides_list" />
                            <CountBadge count={Number(activeRidesCount)} />
                        </div>
                    </TabRightView>
                    <Content>
                        <FormValues.Provider
                            value={{initValues, setInitValues, dirtyForm, setDirtyForm}}
                        >
                            <PageContentWrapper>
                                {company.paymentByCompanyAllowed ? (
                                    <DeliveryOrderForm />
                                ) : (
                                    <NarrowWrapper>
                                        <Alert variant="warning">
                                            <FormattedMessage
                                                id="company_payment_not_allowed"
                                                values={{
                                                    companyName: company.companyName,
                                                    a: (link: string) => (
                                                        <a href={`mailto:${link}`}>{link}</a>
                                                    ),
                                                }}
                                            />
                                        </Alert>
                                    </NarrowWrapper>
                                )}
                            </PageContentWrapper>
                            <DeliveryRidesPage rides={ridesResponse?.rides ?? []} />
                        </FormValues.Provider>
                    </Content>
                </>
            )}
        </>
    );
}

const NarrowWrapper = styled.div`
    margin: 24px auto;
    max-width: 350px;
`;
