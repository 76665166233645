import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {useGetBusinessCompany} from "../api/apiClient";
import {Card} from "./Registration";

export function BillingInfo() {
    const {data} = useGetBusinessCompany();

    if (data == null) {
        return null;
    }

    return (
        <>
            <Card
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <h2 style={{marginBottom: "4px"}}>
                        <FormattedMessage id="billing_details" />
                    </h2>
                    <p>{data.companyName}</p>
                    <p>{data.companyId}</p>
                    <p>{data.taxId}</p>
                    <p>
                        {data.paysVAT ? (
                            <FormattedMessage id="vat_payer" />
                        ) : (
                            <FormattedMessage id="vat_non_payer" />
                        )}
                    </p>
                    <p>{data.billingAddress.street}</p>
                    <p>
                        {data.billingAddress.zip} {data.billingAddress.city}{" "}
                        {data.billingAddress.country}
                    </p>
                </div>
                <Link data-cy="edit-billing-btn" to="/admin/company">
                    <FormattedMessage id="edit" />
                </Link>
            </Card>
        </>
    );
}
