import {useFormikContext} from "formik";
import {RefObject, useEffect} from "react";
import {DeliveryOrderFormValues} from "../delivery/deliveryOrderModel";

export function FocusFirstErrorField({formRef}: {formRef: RefObject<HTMLFormElement>}) {
    const {isSubmitting, errors, isValid, submitCount} =
        useFormikContext<DeliveryOrderFormValues>();

    useEffect(() => {
        if (!isValid && submitCount !== 0 && isSubmitting) {
            const firstFieldName = generateRoutes(errors)[0];
            if (formRef.current) {
                const element = formRef.current.querySelector(
                    `[name="${firstFieldName}"]`
                ) as HTMLElement;
                if (element) {
                    element.focus();
                }
            }
        }
    }, [submitCount, isValid, errors, isSubmitting]);
    return null;
}

//Ugliness which should work as expected for current order forms using Formik. It's fragile but rewrite from Formik to RHF is expected.
function generateRoutes(obj: any, parentRoute: string = ""): string[] {
    const routes: string[] = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const currentRoute = parentRoute ? `${parentRoute}.${key}` : key;

            if (Array.isArray(obj[key])) {
                if (obj[key].length > 0) {
                    const firstNonUndefined = obj[key].find(Boolean);
                    if (typeof firstNonUndefined === "object") {
                        const arrayRoutes = obj[key].map((_: any, index: any) => {
                            const arrayRoute = `${currentRoute}.${index}`;
                            const nestedRoutes = generateRoutes(obj[key][index], arrayRoute);
                            return nestedRoutes;
                        });
                        routes.push(...arrayRoutes.flat());
                    } else if (typeof firstNonUndefined === "string") {
                        const arrayRoutes = obj[key].map((item: any, index: any) =>
                            item != null ? `${currentRoute}.${index}` : null
                        );
                        routes.push(...arrayRoutes);
                    }
                }
            } else if (typeof obj[key] === "object" && Object.keys(obj[key]).length > 0) {
                const nestedRoutes = generateRoutes(obj[key], currentRoute);
                routes.push(...nestedRoutes);
            } else {
                routes.push(currentRoute);
            }
        }
    }

    return routes.flat().filter(Boolean);
}
