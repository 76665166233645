import {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef} from "react";

import {GoogleMapsContext, useMapsLibrary} from "@vis.gl/react-google-maps";

import type {Ref} from "react";

//FIXME: Once to the component ready in vis.gl library, use instead (~24Q2)

type PolylineEventProps = {
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onDrag?: (e: google.maps.MapMouseEvent) => void;
    onDragStart?: (e: google.maps.MapMouseEvent) => void;
    onDragEnd?: (e: google.maps.MapMouseEvent) => void;
    onMouseOver?: (e: google.maps.MapMouseEvent) => void;
    onMouseOut?: (e: google.maps.MapMouseEvent) => void;
};

type PolylineCustomProps = {
    encodedPaths?: string[];
};

export type PolylineProps = google.maps.PolylineOptions & PolylineEventProps & PolylineCustomProps;

export type PolylineRef = Ref<google.maps.Polyline | null>;

function usePolyline(props: PolylineProps) {
    const {
        onClick,
        onDrag,
        onDragStart,
        onDragEnd,
        onMouseOver,
        onMouseOut,
        encodedPaths,
        ...polylineOptions
    } = props;
    // Avoid triggering the useEffect when the callbacks change (if not memoized)
    const callbacks = useRef<Record<string, (e: unknown) => void>>({});
    Object.assign(callbacks.current, {
        onClick,
        onDrag,
        onDragStart,
        onDragEnd,
        onMouseOver,
        onMouseOut,
    });

    const geometryLibrary = useMapsLibrary("geometry");

    const polyline = useRef(new google.maps.Polyline()).current;

    useMemo(() => {
        polyline.setOptions(polylineOptions);
    }, [polyline, polylineOptions]);

    const map = useContext(GoogleMapsContext)?.map;

    useMemo(() => {
        if (!encodedPaths || !geometryLibrary) return;
        const paths = geometryLibrary.encoding.decodePath(encodedPaths[0]);
        polyline.setPath(paths);
    }, [polyline, encodedPaths, geometryLibrary]);

    useEffect(() => {
        if (!map) {
            return;
        }

        polyline.setMap(map);

        return () => {
            polyline.setMap(null);
        };
    }, [map]);

    useEffect(() => {
        if (!polyline) return;

        const gme = google.maps.event;
        [
            ["click", "onClick"],
            ["drag", "onDrag"],
            ["dragstart", "onDragStart"],
            ["dragend", "onDragEnd"],
            ["mouseover", "onMouseOver"],
            ["mouseout", "onMouseOut"],
        ].forEach(([eventName, eventCallback]) => {
            gme.addListener(polyline, eventName, (e: google.maps.MapMouseEvent) => {
                const callback = callbacks.current[eventCallback];
                if (callback) callback(e);
            });
        });

        return () => {
            gme.clearInstanceListeners(polyline);
        };
    }, [polyline]);

    return polyline;
}

export const Polyline = forwardRef((props: PolylineProps, ref: PolylineRef) => {
    const polyline = usePolyline(props);

    useImperativeHandle(ref, () => polyline, []);

    return null;
});
