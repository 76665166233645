import {ErrorMessage} from "formik";
import {FunctionComponent} from "react";
import {FormGroup, FormLabel} from "react-bootstrap";
import styled from "styled-components";

export interface FormControlWrapperProps {
    name: string;
    title: string;
}
export const FormControlWrapper: FunctionComponent<FormControlWrapperProps> = ({
    name,
    title,
    children,
}) => (
    <FormGroup controlId={name}>
        <FormLabel>{title}</FormLabel>
        {children}
        <FormError name={name} />
    </FormGroup>
);

export function FormError({name}: {name: string}) {
    return (
        <ErrorMessage
            name={name}
            render={(text) => <ErrorText data-cy={`field-error-${name}`}>{text}</ErrorText>}
        />
    );
}

export interface IconFormControlWrapperProps {
    icon?: string;
    name: string;
}
export const IconFormControlWrapper: FunctionComponent<IconFormControlWrapperProps> = ({
    icon,
    name,
    children,
}) => (
    <FormGroup style={{marginTop: "4px", marginBottom: "0"}} controlId={name}>
        <IconInputLayout>
            {icon && <img src={icon} alt="" />}
            {children}
        </IconInputLayout>
        <FormError name={name} />
    </FormGroup>
);

export const IconInputLayout = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    input {
        padding-left: 36px;
    }

    > .rbt {
        width: 100%; //layout fix for AsyncTypeahead comp
    }

    > img {
        position: absolute;
        left: 8px;
        z-index: 1;
    }
`;

const ErrorText = styled.div`
    font-size: 11px;
    color: #91341d;
    padding: 0 8px;
`;

export const Select = styled.select`
    display: block;
    font-size: 14px;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 36px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border: 0.5px solid transparent;
    border-radius: var(--border-radius-small);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230070D4%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
        display: none;
    }
    &:hover {
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230070D4%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
            linear-gradient(to bottom, #f0f3f7 0%, #f0f3f7 100%);
    }
    &:focus {
        outline: none;
        color: #222;
        border: 0.5px solid #d6dbe2;
    }
    &:disabled,
    &[aria-disabled="true"] {
        color: #8c9cb0;
    }

    &:disabled:hover,
    &[aria-disabled="true"] {
        cursor: not-allowed;
        background-color: #fff;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230070D4%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
            linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
    }
`;
