import {useQuery} from "@tanstack/react-query";
import {Field, useField, useFormikContext} from "formik";
import posthog from "posthog-js";
import {useFeatureFlagEnabled} from "posthog-js/react";
import {useEffect} from "react";
import gregorian_en from "react-date-object/locales/gregorian_en";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import DatePicker, {DateObject} from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import styled from "styled-components";
import {Stop, deliveryOverview, useCallerCompany} from "../api/apiClient";
import {queryClient} from "../app/App";
import {Label} from "../app/Registration";
import {
    formatDropoffArrival,
    formatPickupArrival,
    formatShortDateFancy,
    formatTimeInterval,
    getDurationInMiliseconds,
} from "../app/dateUtils";
import {EstimatesWrapper, FormSection} from "../app/layout";
import {formatMoney} from "../app/moneyUtils";
import {Button} from "../components/Button";
import {InfoTooltip} from "../components/InfoTooltip";
import {FormError, IconInputLayout} from "../components/forms";
import {
    DELIVERY_EXPRESS_PREORDER_TIER_ID,
    EXPRESS_MAX_DROPOFFS,
    SLOT_MAX_DROPOFFS,
    gregorian_cs,
} from "../constants/constants";
import calendarIcon from "../img/ic_calendar.svg";
import clockIcon from "../img/ic_clock.svg";
import {getLanguage} from "../translations";
import {LocationSuggestionWithId} from "../types/types";
import {DeliveryOrderFormValues} from "./deliveryOrderModel";

function formatExpressArrival(
    intl: IntlShape,
    estimatedArrival: string | undefined,
    estimatedRide: string | undefined
): string {
    return estimatedArrival && estimatedRide
        ? `${intl.formatMessage({id: "in"})} ${formatPickupArrival(estimatedArrival)}, 
                        ${intl
                            .formatMessage({id: "drop_off"})
                            .toLowerCase()} ${formatDropoffArrival(
              estimatedArrival,
              estimatedRide
          )}`
        : `${intl.formatMessage({id: "asap"})}`;
}

export function formatStops(
    pickup: LocationSuggestionWithId,
    handovers: LocationSuggestionWithId[],
    deliveryType: DeliveryOrderFormValues["deliveryType"]
) {
    const pickupFormatted: Stop = {
        contact: {
            name: "",
            phoneNumber: "",
        },
        location: pickup.location,
        kind: "PICKUP" as const,
        suggestSessionId: pickup?.suggestSessionId,
    };
    const handoversFormatted: Stop[] = handovers.map((handover) => {
        return {
            contact: {
                name: "",
                phoneNumber: "",
            },
            location: handover.location,
            kind: "HANDOVER" as const,
            suggestSessionId: handover.suggestSessionId,
        };
    });

    const destinationFormatted: Stop = {
        contact: {
            name: "",
            phoneNumber: "",
        },
        location: pickup.location,
        kind: "HANDOVER" as const,
        suggestSessionId: pickup.suggestSessionId,
    };

    const stops: Stop[] =
        deliveryType === "ROUND_TRIP"
            ? [pickupFormatted, ...handoversFormatted, destinationFormatted]
            : [pickupFormatted, ...handoversFormatted];
    return stops;
}

function Busy() {
    return (
        <>
            <FormattedMessage id="busy" />
            <InfoTooltip>
                <FormattedMessage id="busy_info" />
            </InfoTooltip>
        </>
    );
}

export interface PriceEstimateRowProps {
    pickup?: LocationSuggestionWithId;
    handovers?: LocationSuggestionWithId[];
    isPartner: boolean;
    deliveryType: DeliveryOrderFormValues["deliveryType"];
}

function MultiDateSelect() {
    const [field, , helpers] = useField<DateObject[] | undefined>("pickupDates");
    const intl = useIntl();
    const format = "DD.MM";

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "4px", width: "100%"}}>
            <Label style={{width: "100%", margin: "0"}}>
                {/* <FormattedMessage id="date_or_more_days" /> */}
                <IconInputLayout>
                    <img src={calendarIcon} alt="calendar" />
                    <DatePicker
                        value={field.value}
                        onChange={(value) => helpers.setValue(value)}
                        placeholder={intl.formatMessage({id: "select_dates"})}
                        multiple
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        weekStartDayIndex={1}
                        minDate={new DateObject()}
                        maxDate={new DateObject().add(14, "days")}
                        highlightToday={false}
                        locale={getLanguage() === "cs" ? gregorian_cs : gregorian_en}
                        plugins={[<DatePanel />]}
                        containerStyle={{width: "100%", height: "100%"}}
                        style={{width: "100%", paddingBlock: "18px"}}
                    />
                </IconInputLayout>
            </Label>
            <FormError name={field.name} />
        </div>
    );
}

function TimeSelect() {
    const [field, , helpers] = useField<DateObject | null>("pickupTime");
    const kont = useFormikContext<DeliveryOrderFormValues>();
    console.log(kont.errors);
    return (
        <Label style={{width: "180px", margin: "0"}}>
            {/* <FormattedMessage id="time" /> */}
            <IconInputLayout>
                <img src={clockIcon} alt="clock" />
                <DatePicker
                    disableDayPicker
                    value={field.value}
                    onChange={(value) => helpers.setValue(value)}
                    format="HH:mm"
                    plugins={[<TimePicker hideSeconds header mStep={10} />]}
                    containerStyle={{width: "100%", height: "100%"}}
                    style={{width: "100%", paddingBlock: "18px"}}
                />
            </IconInputLayout>
        </Label>
    );
}

export function DeliveryTiers({pickup, handovers, isPartner, deliveryType}: PriceEstimateRowProps) {
    const intl = useIntl();
    const {setFieldValue, values} = useFormikContext<DeliveryOrderFormValues>();
    const expressPreorderEnabled = useFeatureFlagEnabled("delivery-express-preorder");
    const {data: company} = useCallerCompany();

    const routeEntered = !!pickup && !!handovers;

    const stops: Stop[] = routeEntered ? formatStops(pickup, handovers, deliveryType) : [];

    const {data: tiers, isFetching} = useQuery(
        ["deliveryTiers"],
        () => deliveryOverview({stops, serviceType: deliveryType}),
        {
            enabled: routeEntered,
            refetchInterval: (tiers) =>
                tiers ? getDurationInMiliseconds(tiers.validFor) : 5 * 60 * 1000,
            cacheTime: 0,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        queryClient.removeQueries(["deliveryTiers"]);
    }, [JSON.stringify([pickup, handovers]), deliveryType]);

    useEffect(() => {
        if (tiers?.courier?.express) {
            setFieldValue("tierId", tiers?.courier?.express.tierId);
        } else if (tiers?.courier?.slotDelivery?.slots) {
            const availableTiers = tiers?.courier?.slotDelivery?.slots.filter(
                (tier) => tier.busy !== true
            );
            setFieldValue("tierId", availableTiers[0].tierId);
        }
    }, [tiers]);

    //Express preorder logging
    useEffect(() => {
        if (values.tierId === DELIVERY_EXPRESS_PREORDER_TIER_ID) {
            posthog.capture("deliveryExpressPreorderSelected");
        }
    }, [values]);

    if (isPartner) {
        if (tiers?.logistics?.estimate && handovers) {
            return (
                <EstimatesWrapper>
                    {`${formatMoney(tiers?.logistics?.estimate, intl)} · ${
                        handovers.length
                    } ${intl.formatMessage(
                        {id: "shipment_plural"}, //TODO not "Zásilku" in case of One
                        {count: handovers.length}
                    )}`}
                </EstimatesWrapper>
            );
        }
        return null;
    }

    function slots() {
        const slotDelivery = tiers?.courier?.slotDelivery;
        if (deliveryType === "ROUND_TRIP") {
            return (
                <Disclaimer>
                    <FormattedMessage id="slot_disclaimer_roundtrip" />
                </Disclaimer>
            );
        }
        if (tiers && slotDelivery == null) {
            return (
                <Disclaimer>
                    <FormattedMessage id="slot_disclaimer_not_available" />
                </Disclaimer>
            );
        }
        if (slotDelivery?.slots && slotDelivery.slots.length > 0) {
            return slotDelivery.slots.map((tier) => (
                <Radio key={tier.tierId}>
                    <Field
                        type="radio"
                        value={tier.tierId}
                        name="tierId"
                        disabled={!slotDelivery.enabled || tier.busy}
                    />
                    <Content>
                        {formatShortDateFancy(intl, new Date(tier.slot.from))}{" "}
                        {formatTimeInterval(tier.slot)}
                        <Price>
                            {tier.busy ? <Busy /> : formatMoney(tier.priceOffer.price, intl)}
                        </Price>
                    </Content>
                </Radio>
            ));
        }
    }

    return (
        <>
            <FormSection>
                {isFetching && <Loader />}
                {!routeEntered && (
                    <Info>
                        <FormattedMessage id="delivery_tiers_route_disclaimer" />
                    </Info>
                )}
                {routeEntered && handovers.length > SLOT_MAX_DROPOFFS && (
                    <Info>
                        {"⚠ "}
                        <FormattedMessage
                            id="available_for_up_to_dropoff_count"
                            values={{count: SLOT_MAX_DROPOFFS}}
                        />
                    </Info>
                )}

                <Tiers loaded={tiers != null}>
                    <Title>
                        <FormattedMessage id="express" />
                        <InfoTooltip>
                            <FormattedMessage id="express_tooltip" />
                        </InfoTooltip>
                    </Title>
                    <Dimensions>
                        <FormattedMessage id="express_max_weight" />
                    </Dimensions>
                    {tiers &&
                    tiers?.courier?.express == null &&
                    routeEntered &&
                    handovers.length > EXPRESS_MAX_DROPOFFS ? (
                        <div style={{color: "var(--secondary)"}}>
                            {"⚠ "}
                            <FormattedMessage
                                id="available_for_up_to_dropoff_count"
                                values={{count: EXPRESS_MAX_DROPOFFS}}
                            />
                        </div>
                    ) : (
                        <>
                            <Radio>
                                <Field
                                    type="radio"
                                    value={tiers?.courier?.express?.tierId}
                                    name="tierId"
                                />
                                <Content>
                                    {formatExpressArrival(
                                        intl,
                                        tiers?.courier?.express?.estimatedArrival,
                                        tiers?.courier?.express?.estimatedRide
                                    )}
                                    {tiers?.courier?.express?.priceOffer.price ? (
                                        <Price>
                                            {formatMoney(
                                                tiers?.courier?.express.priceOffer.price,
                                                intl
                                            )}
                                        </Price>
                                    ) : null}
                                </Content>
                            </Radio>
                            {tiers != null &&
                                expressPreorderEnabled &&
                                company?.tier === "ADVANCED" && (
                                    <Radio>
                                        <Field
                                            type="radio"
                                            value={DELIVERY_EXPRESS_PREORDER_TIER_ID}
                                            name="tierId"
                                        />
                                        <Content>
                                            <FormattedMessage id="later_recurring" />
                                            {tiers?.courier?.express?.priceOffer.price ? (
                                                <Price>
                                                    {formatMoney(
                                                        tiers?.courier?.express.priceOffer.price,
                                                        intl
                                                    )}
                                                </Price>
                                            ) : null}
                                        </Content>
                                    </Radio>
                                )}
                            {values.tierId === DELIVERY_EXPRESS_PREORDER_TIER_ID && (
                                <div style={{display: "flex", gap: "8px"}}>
                                    <MultiDateSelect />
                                    <TimeSelect />
                                </div>
                            )}
                        </>
                    )}
                </Tiers>
                <Tiers loaded={tiers != null}>
                    <Title>
                        <FormattedMessage id="time_window" />
                        <InfoTooltip>
                            <FormattedMessage id="time_window_tooltip" />
                        </InfoTooltip>
                    </Title>
                    <Dimensions>
                        <FormattedMessage id="time_window_max_weight" />
                    </Dimensions>
                    {tiers?.courier?.slotDelivery?.enabled === false && (
                        <div style={{color: "var(--secondary)"}}>
                            {"⚠ "}
                            <FormattedMessage
                                id="time_window_not_available_for_some_stops"
                                values={{
                                    stops: tiers?.courier?.slotDelivery?.blockingStopIndices.join(
                                        ", "
                                    ),
                                }}
                            />
                            <Button
                                variant="link"
                                type="button"
                                style={{display: "inline-block"}}
                                onClick={() => {
                                    const newHandovers = values.handovers.filter(
                                        (_, index) =>
                                            !tiers?.courier?.slotDelivery?.blockingStopIndices.includes(
                                                index + 1
                                            )
                                    );
                                    setFieldValue("handovers", newHandovers, true);
                                }}
                            >
                                <FormattedMessage id="remove" />
                            </Button>
                        </div>
                    )}
                    {slots()}
                </Tiers>
            </FormSection>
        </>
    );
}

const Loader = styled.div`
    width: 100%;
    height: 4px;
    position: absolute;
    top: 0px;
    margin-left: -8px;

    &:after {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        left: 0%;
        right: 100%;
        border-radius: 4px;
        animation: loading 1s ease infinite;
        background-color: var(--light);
    }

    @keyframes loading {
        0% {
            left: 0;
            right: 100%;
        }

        50% {
            left: 0;
            right: 0;
        }

        100% {
            left: 100%;
            right: 0;
        }
    }
`;

const Disclaimer = styled.div`
    color: var(--secondary);
`;

const Info = styled.div`
    padding: 8px;
    background-color: var(--light);
    border-radius: var(--border-radius-small);
    font-size: var(--font-size-small);
    margin-top: -2px;
    margin-bottom: 4px;
`;

const Title = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 14px;
`;

const Dimensions = styled.div`
    margin-bottom: 2px;
    color: var(--secondary);
    font-weight: 400;
    //font-size: var(--font-size-small);
`;

const Tiers = styled.div<{loaded: boolean}>`
    margin: 0 8px;
    &:not(:last-child) {
        margin-bottom: 8px;
    }
    ${(p) => !p.loaded && `opacity: 0.5`}
`;

const Radio = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    margin: 0 -8px;
    border-radius: var(--border-radius-small);
    font-size: 14px;
    color: var(--dark-secondary);
    &:not(:has(input:disabled)):hover {
        background-color: var(--light);
        color: var(--text);
    }
    & input {
        cursor: pointer;
        &:checked ~ div {
            color: var(--primary);
        }
    }

    &:has(input:checked) {
        background-color: var(--light);
    }
    &:has(input:disabled) {
        cursor: not-allowed;
        color: var(--tertiary);
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Price = styled.div`
    margin-left: auto;
    display: flex;
    gap: 4px;
    align-items: center;
`;
