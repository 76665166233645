import styled from "styled-components";

export const Shimmer = styled.div<{w?: string; h?: string; radius?: string}>`
    @keyframes placeHolderShimmer {
        0% {
            background-position: -400px 0;
        }
        100% {
            background-position: 400px 0;
        }
    }
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: var(--light);
    background: linear-gradient(to right, var(--light) 8%, var(--quaternary) 18%, var(--light) 33%);
    background-size: 400px 20px;
    height: ${(p) => (p.h ? `${p.h}px` : "15px")};
    width: ${(p) => (p.w ? `${p.w}px` : "30px")};
    position: relative;
    border-radius: ${(p) => (p.radius ? `${p.radius}px` : "4px")};
`;
