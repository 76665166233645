import {IntlShape, useIntl} from "react-intl";
import styled from "styled-components";
import {DeliveryRide, TimeInterval} from "../api/apiClient";
import {formatDateTimeInterval, formatFullDateFancy, formatTimeInterval} from "../app/dateUtils";
import {Badge, RideItemContent, RideItemHead, RideItemView} from "../rides/layout";
import {formatDeliveryRideStatus} from "../rides/ridesModel";
import {useOpenDetail} from "./OpenDetailContext";
import {StopItem} from "./StopItem";

export interface RideItemProps {
    ride: DeliveryRide;
}
export function RideItem({ride}: RideItemProps) {
    const intl = useIntl();
    const openDetail = useOpenDetail();
    const pickupTime = ride.stops.find((stop) => stop.kind === "PICKUP")?.arrival;
    return (
        <RideItemView onClick={() => openDetail({type: "ride", rideId: ride.id})}>
            <RideItemHead>
                <Title>{formatTime(intl, ride)}</Title>
                <DeliveryStatusLabel status={ride.status} pickupTime={pickupTime} />
            </RideItemHead>
            <RideItemContent>
                {ride.stops.map((stop) => (
                    <StopItem
                        key={stop.id}
                        stop={stop}
                        onItemClicked={() =>
                            openDetail({
                                type: "stop",
                                rideId: ride.id,
                                stopId: stop.id,
                            })
                        }
                    />
                ))}
            </RideItemContent>
        </RideItemView>
    );
}

interface DeliveryStatusLabelProps {
    status: DeliveryRide["status"];
    pickupTime?: string;
}

function DeliveryStatusLabel({status, pickupTime}: DeliveryStatusLabelProps) {
    const intl = useIntl();
    function theme(): string | undefined {
        switch (status) {
            case "IN_SEARCHING":
                return "pulsing";
            case "FAILED":
                return "red";
            default:
                return undefined;
        }
    }
    return <Badge theme={theme()}>{formatDeliveryRideStatus(status, intl)}</Badge>;
}

const Title = styled.div`
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-thin);
    color: var(--secondary);
`;

const formatTime = (intl: IntlShape, ride: DeliveryRide) => {
    if (ride.statusGroup === "IN_PROGRESS") {
        const firstStop = ride.stops[0];
        const lastStop = ride.stops[ride.stops.length - 1];
        if (firstStop.arrival != null && lastStop.arrival != null) {
            const timeInterval: TimeInterval = {
                from: firstStop.arrival,
                to: lastStop.arrival,
            };
            return formatTimeInterval(timeInterval);
        }
    }
    if (ride.statusGroup === "COMPLETED") {
        if (ride.finishedAt) {
            return formatFullDateFancy(intl, new Date(ride.finishedAt));
        }
    } else {
        const handoverTime = ride.order.deliveryTime;
        const pickupTime = ride.stops[0].arrivalTime; //TODO Quick fix. Waiting for design with pickup time set.
        if (handoverTime) {
            return formatDateTimeInterval(intl, handoverTime);
        } else if (pickupTime) {
            return formatDateTimeInterval(intl, pickupTime);
        } else {
            return intl.formatMessage({id: "today_asap"});
        }
    }
};
