import {PickupWithLocation} from "../helpers/taxiStopsWithLocation";
import {Pickup} from "../taxi/TaxiOrderForm";
import {LocationSuggestionWithId} from "../types/types";

export const isViableRoute = (
    pickups: Pickup[],
    dropoffs: (LocationSuggestionWithId | undefined)[]
): boolean => {
    const hasPickup = pickups.some(
        (pickup): pickup is PickupWithLocation => pickup.location != null
    );
    const hasDropoff = dropoffs.some(
        (dropoff): dropoff is LocationSuggestionWithId => dropoff != null
    );
    return hasPickup && hasDropoff;
};
