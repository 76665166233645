import {Field} from "formik";
import {ReactNode} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {TaxiOvervewTierData} from "../api/apiClient";
import {formatPickupArrival, getIsoDuration} from "../app/dateUtils";
import {getPrice} from "../app/moneyUtils";
import {tierIcon} from "../img/tierIcon";
import {Shimmer} from "./Shimmer";
import {TaxiOrderFormValues} from "./TaxiOrderForm";
import {preorderUnavailableMessage} from "./TaxiOrderFormView";

function getTierName(tierId: string) {
    switch (tierId) {
        case "TIER_DEFAULT":
        case "TIER_PREMIUM":
        case "TIER_STANDARD":
        case "TIER_ECO_FRIENDLY":
        case "TIER_KIDS":
        case "TIER_AIRPORT_TRANSFER":
        case "TIER_XL":
            return <FormattedMessage id={`${tierId}_name`} />;

        default:
            return tierId;
    }
}

function getTierDescription(tierId: string) {
    switch (tierId) {
        case "TIER_DEFAULT":
        case "TIER_PREMIUM":
        case "TIER_STANDARD":
        case "TIER_ECO_FRIENDLY":
        case "TIER_KIDS":
        case "TIER_AIRPORT_TRANSFER":
        case "TIER_XL":
            return <FormattedMessage id={`${tierId}_description`} />;

        default:
            return null;
    }
}

interface TierProps {
    tier: TaxiOvervewTierData;
    pickupTimeType: TaxiOrderFormValues["pickupTimeType"];
    overviewFetching: boolean;
}

function TierInfoTooltip({children}: {children: ReactNode}) {
    return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tier_info">{children}</Tooltip>}>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="var(--tertiary)"
                xmlns="http://www.w3.org/2000/svg"
                style={{marginBottom: "2px"}}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
                />
            </svg>
        </OverlayTrigger>
    );
}

export function Tier({tier, pickupTimeType, overviewFetching}: TierProps) {
    const intl = useIntl();

    const isDefaultTier = tier.tierId === "TIER_DEFAULT";
    const isPremiumTier = tier.tierId === "TIER_PREMIUM";
    const isAirportTier = tier.tierId === "TIER_AIRPORT_TRANSFER";

    const getPickupTime = (arrivalDuration?: string) => {
        if (pickupTimeType === "15min") {
            return (
                <>
                    <FormattedMessage id="in" /> <FormattedMessage id="pickup_time_15_min" />
                </>
            );
        }
        if (pickupTimeType === "now" && arrivalDuration != null) {
            return (
                <>
                    <FormattedMessage id="in" />
                    {` ${formatPickupArrival(arrivalDuration)}`}
                </>
            );
        }
        return null;
    };

    const getTierTooltip = () => {
        if (pickupTimeType === "later" && isPremiumTier) {
            return (
                <TierInfoTooltip>
                    <FormattedMessage id="premium_preorder_disclaimer" />
                </TierInfoTooltip>
            );
        }
        if (pickupTimeType === "later" && isAirportTier) {
            return (
                <TierInfoTooltip>
                    <FormattedMessage id="airport_tier_disclaimer" />
                </TierInfoTooltip>
            );
        }
        return null;
    };

    return (
        <Radio>
            <Field
                type="radio"
                name="tierId"
                value={tier.tierId}
                disabled={
                    pickupTimeType === "later"
                        ? !tier.preOrderAvailability.available
                        : (!tier.available && !isDefaultTier) ||
                          (overviewFetching && !isDefaultTier)
                }
            />
            {tierIcon[tier.tierId]}
            <Content>
                <TierFirstRow>
                    <div>
                        {getTierName(tier.tierId)} {getTierTooltip()}
                    </div>
                    <div>
                        {overviewFetching ? (
                            <Shimmer w="40" />
                        ) : pickupTimeType === "later" && !tier.preOrderAvailability.available ? (
                            <div style={{display: "flex", gap: "4px", textAlign: "right"}}>
                                <FormattedMessage id="cant_schedule" />
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="tier_info">
                                            {preorderUnavailableMessage(
                                                tier.preOrderAvailability,
                                                tier.tierId
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <div style={{display: "relative"}}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="16"
                                            width="16"
                                            viewBox="0 -960 960 960"
                                            fill="var(--tertiary)"
                                            style={{marginBottom: "2px"}}
                                        >
                                            <path d="M479.89-240Q500-240 514-253.89q14-13.88 14-34Q528-308 514.11-322q-13.88-14-34-14Q460-336 446-322.11q-14 13.88-14 34Q432-268 445.89-254q13.88 14 34 14Zm.39 144Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm2.93-554q24.33 0 43.56 15.18Q546-619.64 546-596.87 546-576 533.31-559q-12.7 17-29.31 31-23 20-42 44t-19 54q0 15 10.68 25t24.92 10q16.07 0 27.23-10.5Q517-416 520-432q4-21 18-37.14 14-16.13 30-30.8 23-21.06 39-47.75T623-605q0-51-41.5-83.5T484.91-721q-38.06 0-71.98 17-33.93 17-56.09 49.27-7.84 10.81-4.34 23.77Q356-618 367-609q14 11 30 6.5t27-18.5q11-14 26.35-21.5 15.35-7.5 32.86-7.5Z" />
                                        </svg>
                                    </div>
                                </OverlayTrigger>
                            </div>
                        ) : (
                            getPrice(tier.minPrice, tier.maxPrice, intl)
                        )}
                    </div>
                </TierFirstRow>
                <TierSecondRow>
                    <div>{getTierDescription(tier.tierId)}</div>

                    <Arrival>
                        {overviewFetching ? (
                            <Shimmer />
                        ) : tier.available ? (
                            getPickupTime(tier.arrivalDuration)
                        ) : isDefaultTier || pickupTimeType === "later" ? null : tier
                              .preOrderAvailability.available ? (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tier_info">
                                        {tier.preOrderAvailability.earliestAllowedPreOrder ? (
                                            <FormattedMessage
                                                id="later_only_tooltip"
                                                values={{
                                                    ...getIsoDuration(
                                                        tier.preOrderAvailability
                                                            .earliestAllowedPreOrder
                                                    ),
                                                    tierName: (
                                                        <FormattedMessage
                                                            id={`${tier.tierId}_name`}
                                                        />
                                                    ),
                                                }}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Tooltip>
                                }
                            >
                                <div style={{display: "relative"}}>
                                    <FormattedMessage id="later_only" />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="16"
                                        width="16"
                                        viewBox="0 -960 960 960"
                                        fill="var(--tertiary)"
                                        style={{marginBottom: "2px"}}
                                    >
                                        <path d="M479.89-240Q500-240 514-253.89q14-13.88 14-34Q528-308 514.11-322q-13.88-14-34-14Q460-336 446-322.11q-14 13.88-14 34Q432-268 445.89-254q13.88 14 34 14Zm.39 144Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm2.93-554q24.33 0 43.56 15.18Q546-619.64 546-596.87 546-576 533.31-559q-12.7 17-29.31 31-23 20-42 44t-19 54q0 15 10.68 25t24.92 10q16.07 0 27.23-10.5Q517-416 520-432q4-21 18-37.14 14-16.13 30-30.8 23-21.06 39-47.75T623-605q0-51-41.5-83.5T484.91-721q-38.06 0-71.98 17-33.93 17-56.09 49.27-7.84 10.81-4.34 23.77Q356-618 367-609q14 11 30 6.5t27-18.5q11-14 26.35-21.5 15.35-7.5 32.86-7.5Z" />
                                    </svg>
                                </div>
                            </OverlayTrigger>
                        ) : (
                            <FormattedMessage id="busy" />
                        )}
                    </Arrival>
                </TierSecondRow>
            </Content>
        </Radio>
    );
}

const TierFirstRow = styled.div`
    display: flex;
    gap: 4px;
    justify-content: space-between;
    font-weight: var(--font-weight-normal);
`;

const TierSecondRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-thin);
    color: var(--dark);
`;

const Radio = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 12px;
    margin: 0 -8px;
    border-radius: var(--border-radius-small);
    font-size: 14px;
    &:hover {
        background-color: var(--light);
    }
    & input {
        position: fixed;
        opacity: 0;
        pointer-events: none;
        &:checked ~ * {
            color: var(--primary);
        }
    }

    &:has(input:checked) {
        background-color: var(--blue-light);
        border: none;
        outline: none;
        box-shadow: inset 0 0 0 1px var(--primary);
    }
    &:has(input:disabled) {
        opacity: 0.6;
        cursor: not-allowed;
        &:hover {
            background-color: var(--white);
        }
    }
    & > svg {
        margin-right: 4px;
        flex-shrink: 0;
        color: var(--secondary);
    }
`;

const Content = styled.div`
    flex-grow: 1;
`;

const Arrival = styled.div`
    flex-shrink: 0;
    text-align: right;
`;
