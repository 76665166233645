import {useState} from "react";
import {Alert} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {LoginRequest} from "../api/apiClient";
import {Card, Title, Wrapper} from "../app/Registration";
import logo from "../img/logo.svg";
import {logGtm} from "../utils/gtm";
import {LoginForm} from "./LoginForm";
import {authenticate, useUserInfo} from "./authModel";

export function LoginPage() {
    const {data, isLoading} = useUserInfo();
    const [loginFailed, setLoginFailed] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const lftSegment = new URLSearchParams(location.search).get("lft_segment");

    if (isLoading) {
        return null;
    }

    if (data != null) {
        return <Navigate to="/" replace />;
    }

    const from = location.state?.from?.pathname || "/";

    async function handleLogin(values: LoginRequest) {
        setLoginFailed(false);

        try {
            await authenticate(values);
            navigate(from, {replace: true});
            logGtm("login");
        } catch {
            setLoginFailed(true);
        }
    }

    return (
        <Wrapper>
            <img src={logo} width="80px" alt="" />
            <Title>
                <FormattedMessage id="sign_in_to_business_account" />
            </Title>
            <Card>
                {loginFailed && (
                    <Alert variant="danger">
                        <FormattedMessage id="login_failed" />
                    </Alert>
                )}
                <LoginForm onSubmit={handleLogin} />
                <ResetLink to="/reset">
                    <FormattedMessage id="forgot_password" />
                </ResetLink>
            </Card>
            <RegisterLink>
                <p>
                    <FormattedMessage id="dont_have_account_yet" />
                </p>
                <Link data-cy="create-new-business-account-btn" to={`/register${lftSegment ? "?lft_segment=header-login" : ""}`}>
                    <FormattedMessage id="create_new_business_account" />
                </Link>
            </RegisterLink>
        </Wrapper>
    );
}

const ResetLink = styled(Link)`
    display: inline-block;
    margin-top: var(--spacing-medium);
`;

const RegisterLink = styled.div`
    text-align: center;
    margin: 24px 0;
`;
