import {UseMutationResult, useQuery} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {Field, FieldArray, Form, useFormikContext} from "formik";
import posthog from "posthog-js";
import {useFeatureFlagEnabled} from "posthog-js/react";
import {useContext, useEffect, useRef} from "react";
import {Alert, FormControl, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {
    CompanyInfo,
    CostCenters,
    PreOrderAvailability,
    ProjectCodeSettings,
    TaxiOrderRequest,
    taxiOverview,
} from "../api/apiClient";
import {queryClient} from "../app/App";
import {HomepageLink} from "../app/HomepageLink";
import {FormSection, FormTitle} from "../app/layout";
import {useUserInfo} from "../auth/authModel";
import {Button} from "../components/Button";
import {FocusFirstErrorField} from "../components/FocusFirstErrorField";
import {PhoneInputFormik} from "../components/PhoneInputFormik";
import {SearchPlacesInput} from "../components/SearchPlacesInput";
import {SwitchGroup, SwitchItem} from "../components/Switch";
import {SimpleTitle} from "../components/formTitles";
import {IconFormControlWrapper, Select} from "../components/forms";
import {formikLocationChange} from "../helpers/formikLocationChange";
import {taxiStopsPayload} from "../helpers/taxiStopsWithLocation";
import addIcon from "../img/ic_add.svg";
import removeIcon from "../img/ic_close.svg";
import costCenterIcon from "../img/ic_cost_center.svg";
import flagIcon from "../img/ic_flag.svg";
import flightIcon from "../img/ic_flight.svg";
import messageIcon from "../img/ic_message.svg";
import cardPaymentIcon from "../img/ic_payment_card.svg";
import cashPaymentIcon from "../img/ic_payment_cash.svg";
import companyPaymentIcon from "../img/ic_payment_company.svg";
import personIcon from "../img/ic_person.svg";
import projectCodeIcon from "../img/ic_project_code.svg";
import stopA from "../img/ic_stop_a.svg";
import stopB from "../img/ic_stop_b.svg";
import stopC from "../img/ic_stop_c.svg";
import stopD from "../img/ic_stop_d.svg";
import stopE from "../img/ic_stop_e.svg";
import {getOrderFailedMessage} from "../utils/getOrderFailedMessage";
import {isViableRoute} from "../utils/isViableRoute";
import {hasAdminRole} from "../utils/userUtils";
import {AutocompleteInput} from "./AutocompleteInput";
import {NameAutocompleteInput} from "./NameAutocompleteInput";
import {PaymentType, TaxiOrderFormValues} from "./TaxiOrderForm";
import {TaxiOverview} from "./TaxiOverview";
import {FormValues, defaultPickup} from "./TaxiPage";
import {TimeSelector} from "./TimeSelector";

interface TaxiOrderFormViewProps {
    projectCodes?: ProjectCodeSettings;
    costCenters?: CostCenters;
    company: CompanyInfo;
    taxiOrder: UseMutationResult<AxiosResponse<any>, any, TaxiOrderRequest, any>;
    tripId: string;
}

function getTaxiStopIcon(index: number) {
    switch (index) {
        case 0:
            return stopA;
        case 1:
            return stopB;
        case 2:
            return stopC;
        case 3:
            return stopD;
        case 4:
            return stopE;
        default:
            return;
    }
}

function getTaxiStopPlaceholder(index: number, length: number, intl: IntlShape) {
    if (index === length - 1) {
        return intl.formatMessage({
            id: "destination_location",
        });
    }
    return intl.formatMessage({
        id: "stop_location",
    });
}

export function preorderUnavailableMessage(
    preorderConfig: PreOrderAvailability | undefined,
    tierId: string | undefined
) {
    if (!preorderConfig || !tierId) {
        return <FormattedMessage id="preorder_disabled_route_not_set" />;
    }

    const minimalDistanceInKilometers = (preorderConfig.minimalDistance || 0) / 1000;

    switch (preorderConfig.unavailabilityReason) {
        case "NOT_AVAILABLE":
            return (
                <FormattedMessage
                    id="preorder_disabled_not_available"
                    values={{tierName: <FormattedMessage id={`${tierId}_name`} />}}
                />
            );
        case "RIDE_TOO_SHORT":
            return (
                <FormattedMessage
                    id="preorder_disabled_short_ride"
                    values={{
                        kmDistance: minimalDistanceInKilometers,
                        tierName: <FormattedMessage id={`${tierId}_name`} />,
                    }}
                />
            );
        case "UNSUPPORTED_DESTINATION":
            return (
                <FormattedMessage
                    id="preorder_disabled_destination"
                    values={{tierName: <FormattedMessage id={`${tierId}_name`} />}}
                />
            );
        case "UNSUPPORTED_TIER":
            return (
                <FormattedMessage
                    id="preorder_disabled_tier"
                    values={{tierName: <FormattedMessage id={`${tierId}_name`} />}}
                />
            );
    }
}

export function TaxiOrderFormView({
    projectCodes,
    costCenters,
    company,
    taxiOrder,
    tripId,
}: TaxiOrderFormViewProps) {
    const intl = useIntl();
    const {data: user} = useUserInfo();
    const {getFieldProps, values, isSubmitting, setFieldValue, dirty, setFieldTouched} =
        useFormikContext<TaxiOrderFormValues>();
    const formContext = useContext(FormValues);
    const formRef = useRef<HTMLFormElement>(null);
    const routePlannerEnabled = useFeatureFlagEnabled("route-planner");
    const dropoffPreorderEnabled = useFeatureFlagEnabled("dropoff-preorder");
    const phoneNumberOptional = company.optionalPassengerPhoneNumber;
    const showCompanyReporting = values.paymentType === "company";
    const showProjectCode = projectCodes != null && projectCodes.mode !== "NONE";
    const projectCodeOptional = projectCodes != null && projectCodes.mode === "OPTIONAL";
    const showInternalNote = company.horecaEnabled;
    const {
        data: overview,
        isFetching: overviewFetching,
        refetch,
    } = useQuery(
        ["taxiOverview"],
        () =>
            taxiOverview({
                stops: taxiStopsPayload(values.pickups, values.dropoffs),
                requestedPickupAt:
                    values.preorderType === "pickup"
                        ? values.preorderTime?.toISOString()
                        : undefined,
                requestedDropOffAt:
                    values.preorderType === "dropoff"
                        ? values.preorderTime?.toISOString()
                        : undefined,
                rideParams: {
                    acceptsCreditCards: values.paymentType === "credit_card",
                    premiumCar: false,
                },
                orderType:
                    values.pickupTimeType === "later"
                        ? "PREORDER"
                        : values.pickupTimeType === "15min"
                        ? "SHORT_PREORDER"
                        : "NORMAL",
                tripId: tripId,
            }),
        {
            cacheTime: 0,
            refetchOnWindowFocus: false,
            enabled: isViableRoute(values.pickups, values.dropoffs),
        }
    );

    const preorderConfig = overview?.tiers.find(
        (tier) => tier.tierId === values.tierId
    )?.preOrderAvailability;
    const preorderDisabled = !preorderConfig?.available;

    let submitText = intl.formatMessage({id: "place_order"});
    if (taxiOrder.isLoading) {
        submitText = intl.formatMessage({id: "sending"});
    }
    useEffect(() => {
        //Remove error message
        taxiOrder.reset();
    }, [values]);

    useEffect(() => {
        formContext.setDirtyForm(dirty);
    }, [dirty]);

    useEffect(() => {
        if (preorderDisabled) {
            setFieldValue("pickupTimeType", "now");
        }
    }, [preorderDisabled]);

    useEffect(() => {
        //Reset to Pickup time, when not Later set
        if (values.pickupTimeType !== "later") {
            setFieldValue("preorderType", "pickup");
            setFieldValue("flightNumber", "");
        }
    }, [values.pickupTimeType]);

    useEffect(() => {
        if (isViableRoute(values.pickups, values.dropoffs)) {
            const timeSetIfNeeded =
                values.pickupTimeType === "now" || values.preorderTime !== undefined;
            if (timeSetIfNeeded) {
                refetch();
            }
        } else {
            queryClient.removeQueries(["taxiOverview"]);
        }
    }, [
        values.pickups[0]?.location?.location.address,
        values.pickups[1]?.location?.location.address,
        values.pickups[2]?.location?.location.address,
        JSON.stringify(values.dropoffs),
        values.preorderType,
        values.pickupTimeType,
        values.preorderTime,
        values.paymentType,
    ]);

    const getLaterInput = () => {
        const laterInput = (
            <SwitchItem className={preorderDisabled ? "disabled" : ""}>
                <Field
                    data-cy="pickup-time-later-btn"
                    type="radio"
                    value="later"
                    name="pickupTimeType"
                    disabled={preorderDisabled}
                />
                <div>
                    <FormattedMessage id="pickup_time_later" />
                </div>
            </SwitchItem>
        );
        return preorderDisabled ? (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="later_tooltip">
                        {preorderUnavailableMessage(preorderConfig, values.tierId)}
                    </Tooltip>
                }
            >
                {laterInput}
            </OverlayTrigger>
        ) : (
            laterInput
        );
    };

    return (
        <Form ref={formRef}>
            {user?.roles && hasAdminRole(user.roles) && <HomepageLink />}
            <FocusFirstErrorField formRef={formRef} />
            <FormTitle>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "baseline",
                    }}
                >
                    <div>
                        <h2>
                            <FormattedMessage id="create_order" />
                        </h2>
                    </div>
                    {routePlannerEnabled && <Link to="routing">Plánování svozu</Link>}
                </div>
            </FormTitle>
            <FieldArray name="pickups">
                {(arrayHelpers) => (
                    <>
                        {values.pickups.map((_, index) => (
                            <FormSection key={`pickup${index}`}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "baseline",
                                    }}
                                >
                                    <SimpleTitle>
                                        <FormattedMessage id="pickup" />
                                        {values.pickups.length === 1
                                            ? ""
                                            : ` ${index + 1}/${values.pickups.length}`}{" "}
                                    </SimpleTitle>
                                    <div>
                                        {values.pickups.length === 1 ? (
                                            <AddStop
                                                type="button"
                                                onClick={() => {
                                                    arrayHelpers.push(defaultPickup);
                                                    posthog.capture("addMorePickups");
                                                }}
                                                disabled={values.dropoffs.length > 1}
                                            >
                                                <img alt="" src={addIcon} />
                                                <FormattedMessage id="add_pickup" />
                                            </AddStop>
                                        ) : (
                                            <div>
                                                <Button
                                                    variant="plain"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    <img
                                                        src={removeIcon}
                                                        alt={intl.formatMessage({
                                                            id: "remove_stop",
                                                        })}
                                                    />
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <IconFormControlWrapper
                                    icon={personIcon}
                                    name={`pickups.${index}.name`}
                                >
                                    <NameAutocompleteInput
                                        name={`pickups.${index}.name`}
                                        index={index}
                                        placeholder={intl.formatMessage({id: "name"})}
                                    />
                                </IconFormControlWrapper>
                                <PhoneInputFormik
                                    name={`pickups.${index}.phoneNumber`}
                                    placeholder={`${intl.formatMessage({
                                        id: "phone_number",
                                    })} ${
                                        phoneNumberOptional && values.pickups.length === 1
                                            ? `(${intl.formatMessage({id: "optional"})})`
                                            : ""
                                    }`}
                                />
                                <IconFormControlWrapper
                                    icon={getTaxiStopIcon(index)}
                                    name={`pickups.${index}.location`}
                                >
                                    <SearchPlacesInput
                                        placeholder={intl.formatMessage({
                                            id: "pickup_location",
                                        })}
                                        addressType={"PICKUP"}
                                        serviceType="TAXI"
                                        tripId={tripId}
                                        {...getFieldProps(`pickups.${index}.location`)}
                                        onChange={(selected) => {
                                            formikLocationChange(
                                                `pickups.${index}.location`,
                                                selected[0],
                                                setFieldValue
                                            );
                                        }}
                                        onBlur={() =>
                                            setFieldTouched(`pickups.${index}.location`, true)
                                        }
                                    />
                                </IconFormControlWrapper>
                                <IconFormControlWrapper
                                    icon={messageIcon}
                                    name={`pickups.${index}.noteForDriver`}
                                >
                                    <FormControl
                                        type="text"
                                        placeholder={`${intl.formatMessage({
                                            id: "message_to_driver",
                                        })} (${intl.formatMessage({id: "optional"})})`}
                                        {...getFieldProps(`pickups.${index}.noteForDriver`)}
                                    />
                                </IconFormControlWrapper>
                            </FormSection>
                        ))}
                        {values.pickups.length > 1 && values.pickups.length < 3 && (
                            <div style={{display: "flex"}}>
                                <AddStopCard
                                    type="button"
                                    onClick={() => {
                                        arrayHelpers.push(defaultPickup);
                                    }}
                                >
                                    <img alt="" src={addIcon} />
                                    <FormattedMessage id="add_pickup" />
                                </AddStopCard>
                            </div>
                        )}
                    </>
                )}
            </FieldArray>
            <FormSection>
                <FieldArray name="dropoffs">
                    {(arrayHelpers) => {
                        return (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "baseline",
                                    }}
                                >
                                    <SimpleTitle>
                                        {values.dropoffs.length === 1 ? "Cíl" : "Cíl a zastávky"}
                                    </SimpleTitle>
                                    <div>
                                        <AddStop
                                            type="button"
                                            onClick={() => {
                                                arrayHelpers.push(undefined);
                                                posthog.capture("addMoreDropoffs");
                                            }}
                                            disabled={
                                                values.pickups.length > 1 ||
                                                values.dropoffs.length > 3
                                            }
                                        >
                                            <img alt="" src={addIcon} />
                                            <FormattedMessage id="add_stop" />
                                        </AddStop>
                                    </div>
                                </div>
                                {values.dropoffs.map((dropoff, index) => (
                                    <IconFormControlWrapper
                                        icon={getTaxiStopIcon(index + values.pickups.length)}
                                        name={`dropoffs.${index}`}
                                        key={`${index}_${values.dropoffs.length}`}
                                    >
                                        <SearchPlacesInput
                                            placeholder={getTaxiStopPlaceholder(
                                                index,
                                                values.dropoffs.length,
                                                intl
                                            )}
                                            addressType={"DESTINATION"}
                                            pickup={values.pickups[0]?.location?.location}
                                            serviceType="TAXI"
                                            tripId={tripId}
                                            allowPredefinedPrice={
                                                index === 0 && values.pickups.length === 1
                                            }
                                            {...getFieldProps(`dropoffs.${index}`)}
                                            onChange={(selected) => {
                                                formikLocationChange(
                                                    `dropoffs.${index}`,
                                                    selected[0],
                                                    setFieldValue
                                                );
                                            }}
                                            onBlur={() =>
                                                setFieldTouched(`dropoffs.${index}`, true)
                                            }
                                        />
                                        {values.dropoffs.length + 1 >= 3 && (
                                            <Button
                                                variant="plain"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                <img
                                                    src={removeIcon}
                                                    alt={intl.formatMessage({
                                                        id: "remove_stop",
                                                    })}
                                                />
                                            </Button>
                                        )}
                                    </IconFormControlWrapper>
                                ))}
                            </>
                        );
                    }}
                </FieldArray>
            </FormSection>

            <FormSection>
                <SimpleTitle>
                    <FormattedMessage id="payment_method" />
                </SimpleTitle>
                <IconFormControlWrapper
                    icon={
                        {
                            cash: cashPaymentIcon,
                            credit_card: cardPaymentIcon,
                            company: companyPaymentIcon,
                        }[values.paymentType as PaymentType]
                    }
                    name="paymentType"
                >
                    <Select {...getFieldProps("paymentType")}>
                        {company.paymentByCompanyAllowed && (
                            <option value="company">
                                {intl.formatMessage({id: "paid_by_company"})}
                            </option>
                        )}
                        <option value="cash">{intl.formatMessage({id: "paid_by_pas_cash"})}</option>
                        <option value="credit_card">
                            {intl.formatMessage({id: "paid_by_pas_credit_card"})}
                        </option>
                    </Select>
                </IconFormControlWrapper>
            </FormSection>
            {company.driverSpecificationEnabled && (
                <FormSection>
                    <SimpleTitle>
                        <FormattedMessage id="specific_driver_id" />
                    </SimpleTitle>
                    <IconFormControlWrapper icon={personIcon} name="specifiedDriverId">
                        <FormControl
                            data-cy="taxi-specified-driver-id-input"
                            type="text"
                            placeholder={`${intl.formatMessage({
                                id: "specific_driver_id",
                            })} (${intl.formatMessage({id: "optional"})})`}
                            {...getFieldProps("specifiedDriverId")}
                        />
                    </IconFormControlWrapper>
                </FormSection>
            )}
            {(showCompanyReporting || showInternalNote) && (
                <FormSection>
                    <SimpleTitle>
                        <FormattedMessage id="reporting_info" />
                    </SimpleTitle>
                    {showInternalNote && (
                        <IconFormControlWrapper icon={flagIcon} name="internalNote">
                            <FormControl
                                data-cy="taxi-internal-note-input"
                                type="text"
                                placeholder={`${intl.formatMessage({
                                    id: "internal_note",
                                })} (${intl.formatMessage({id: "optional"})})`}
                                {...getFieldProps("internalNote")}
                            />
                        </IconFormControlWrapper>
                    )}
                    {showCompanyReporting && showProjectCode && (
                        <IconFormControlWrapper icon={projectCodeIcon} name="projectCode">
                            <AutocompleteInput
                                name="projectCode"
                                placeholder={`${intl.formatMessage({
                                    id: "project_code",
                                })} ${
                                    projectCodeOptional
                                        ? `(${intl.formatMessage({id: "optional"})})`
                                        : ""
                                }`}
                                autocompleteOptions={projectCodes?.codeHistory ?? []}
                            />
                        </IconFormControlWrapper>
                    )}
                    {showCompanyReporting && (
                        <IconFormControlWrapper icon={costCenterIcon} name="costCenter">
                            <AutocompleteInput
                                name="costCenter"
                                placeholder={`${intl.formatMessage({
                                    id: "cost_center",
                                })} (${intl.formatMessage({id: "optional"})})`}
                                autocompleteOptions={costCenters?.costCenterHistory ?? []}
                            />
                        </IconFormControlWrapper>
                    )}
                </FormSection>
            )}
            <FormSection>
                <PickupTimeHeader>
                    <SimpleTitle>
                        {dropoffPreorderEnabled && values.pickupTimeType === "later" ? (
                            <PreorderSelect {...getFieldProps("preorderType")}>
                                <option value="pickup">
                                    {intl.formatMessage({id: "pickup_time"})}
                                </option>
                                <option value="dropoff">
                                    {intl.formatMessage({id: "dropoff_time"})}
                                </option>
                            </PreorderSelect>
                        ) : (
                            <FormattedMessage id="pickup_time" />
                        )}
                    </SimpleTitle>
                    <div style={{flex: 1, margin: "-4px 0"}}>
                        <SwitchGroup small>
                            <SwitchItem>
                                <Field
                                    type="radio"
                                    value="now"
                                    data-cy="pickup-time-now-btn"
                                    name="pickupTimeType"
                                />
                                <div>
                                    <FormattedMessage id="pickup_time_now" />
                                </div>
                            </SwitchItem>
                            <SwitchItem>
                                <Field
                                    type="radio"
                                    value="15min"
                                    data-cy="pickup-time-15-btn"
                                    name="pickupTimeType"
                                />
                                <div>
                                    <FormattedMessage id="pickup_time_15_min" />
                                </div>
                            </SwitchItem>
                            {getLaterInput()}
                        </SwitchGroup>
                    </div>
                </PickupTimeHeader>
                {values.pickupTimeType === "later" &&
                    preorderConfig?.earliestAllowedPreOrder &&
                    preorderConfig?.latestAllowedPreOrder && (
                        <>
                            <TimeSelector
                                from={preorderConfig.earliestAllowedPreOrder}
                                to={preorderConfig.latestAllowedPreOrder}
                                {...getFieldProps("preorderTime")}
                            />
                            {preorderConfig?.flightNumberAllowed && (
                                <div style={{display: "flex", alignItems: "stretch"}}>
                                    <div style={{flex: "1"}}>
                                        <IconFormControlWrapper
                                            icon={flightIcon}
                                            name="flightNumber"
                                        >
                                            <FormControl
                                                type="text"
                                                placeholder={intl.formatMessage({
                                                    id: "flight_number",
                                                })}
                                                {...getFieldProps("flightNumber")}
                                            />
                                        </IconFormControlWrapper>
                                    </div>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="flight_number_info">
                                                <FormattedMessage id="flight_number_info" />
                                            </Tooltip>
                                        }
                                    >
                                        <div
                                            style={{
                                                width: "24px",
                                                display: "grid",
                                                placeItems: "center",
                                            }}
                                        >
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="var(--tertiary)"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{marginTop: "2px"}}
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
                                                />
                                            </svg>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            )}
                        </>
                    )}
            </FormSection>
            {(overview || overviewFetching) && (
                <TaxiOverview
                    overview={overview}
                    overviewFetching={overviewFetching}
                    preorderType={values.preorderType}
                    pickupTimeType={values.pickupTimeType}
                    preorderTime={values.preorderTime}
                />
            )}
            {taxiOrder.isError && (
                <Alert variant="danger">
                    <p>
                        <strong>
                            <FormattedMessage id="order_failed_title" />
                        </strong>
                    </p>
                    <p>{getOrderFailedMessage(taxiOrder.error.response.data.code)}</p>
                </Alert>
            )}
            <Button
                type="submit"
                variant="primary"
                fullWidth
                large
                disabled={
                    isSubmitting ||
                    (overviewFetching &&
                        (values.tierId !== "TIER_DEFAULT" || values.pickupTimeType === "later"))
                }
            >
                {submitText}
            </Button>
        </Form>
    );
}

const PickupTimeHeader = styled.div`
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
`;

const AddStop = styled.button`
    color: var(--primary);
    font-size: 14px;
    padding: 6px 8px;
    margin-top: -4px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--white);

    &:hover {
        background-color: #f0f3f7;
    }
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        background-color: white;
    }

    @media only screen and (max-width: 760px) {
        font-size: 16px;
    }
`;

export const AddStopCard = styled.button`
    font-weight: var(--font-weight-normal);
    color: var(--primary);
    padding: 16px;
    box-shadow: 0 0 0 1px rgba(0, 34, 80, 0.05), 0 1px 3px 0 rgba(0, 34, 80, 0.15);
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--white);
    margin-bottom: 16px;
    text-align: left;
    width: 100%;
    display: flex;
    gap: 10px;

    &:hover {
        background-color: #f0f3f7;
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &:disabled:hover {
        background-color: var(--white);
    }
`;

export const PreorderSelect = styled.select`
    display: block;
    padding: 8px 24px 8px 8px;
    margin: -8px;
    font-size: 14px;
    color: var(--secondary);
    line-height: 1.3;
    font-weight: var(--font-weight-normal);
    box-sizing: border-box;
    border: 0.5px solid transparent;
    border-radius: var(--border-radius-small);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230070D4%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &:focus,
    :hover {
        outline: none;
        color: #222;
        border: 0.5px solid #d6dbe2;
        color: var(--secondary);
    }
    &:disabled,
    &[aria-disabled="true"] {
        color: #8c9cb0;
    }

    &:disabled:hover,
    &[aria-disabled="true"] {
        cursor: not-allowed;
    }
`;
