import {zodResolver} from "@hookform/resolvers/zod";
import {useState} from "react";
import {Alert, Card} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import {isValidPhoneNumber} from "react-phone-number-input";
import styled from "styled-components";
import {z} from "zod";
import {AddOrdererRoleRequest} from "../api/apiClient";
import {Error, Input, Label} from "../app/Registration";
import {Button} from "../components/Button";
import {PhoneInputReactHookForm} from "../components/PhoneInputReactHookForm";
import {clearSpaces} from "../utils/formatString";
import {addOrdererRoleAndHandleToken} from "./authModel";

const requiredString = z.string().trim().min(1, {message: "required"});

const schema = z.object({
    name: requiredString,
    phoneNumber: requiredString.refine(
        (value) => {
            return isValidPhoneNumber(value.replace(/[^\x20-\x7F]/g, ""));
        },
        {
            message: "invalid_phone_number",
        }
    ),
});

type OrdererRoleSchema = z.infer<typeof schema>;

export function AddOrdererRolePage() {
    const intl = useIntl();
    const [addOrdererFailed, setAddOrdererFailed] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: {errors, isSubmitting},
    } = useForm<OrdererRoleSchema>({
        mode: "onTouched",
        resolver: zodResolver(schema),
        criteriaMode: "all",
        defaultValues: {
            name: "",
            phoneNumber: "",
        },
    });

    const onSubmit = async (values: AddOrdererRoleRequest) => {
        const payload = {...values, phoneNumber: clearSpaces(values.phoneNumber)};
        try {
            await addOrdererRoleAndHandleToken(payload);
        } catch {
            setAddOrdererFailed(true);
        }
    };

    return (
        <CardWrapper>
            <h1>
                <FormattedMessage id="add_orderer_role_headline" />
            </h1>
            <Card>
                <Card.Body>
                    {addOrdererFailed && (
                        <Alert variant="danger">
                            <FormattedMessage id="generic_error" />
                        </Alert>
                    )}
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{display: "flex", flexDirection: "column"}}
                    >
                        <Label htmlFor="name">
                            <FormattedMessage id="your_name" />
                        </Label>
                        <Input type="text" {...register("name")} />
                        {errors.name?.message && (
                            <Error>{intl.formatMessage({id: errors.name.message})}</Error>
                        )}
                        <Label htmlFor="phoneNumber">
                            <FormattedMessage id="phone_number" />
                        </Label>
                        <PhoneInputReactHookForm control={control} name="phoneNumber" />
                        {errors.phoneNumber?.message && (
                            <Error>{intl.formatMessage({id: errors.phoneNumber.message})}</Error>
                        )}
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            fullWidth
                            style={{marginTop: "16px"}}
                        >
                            <FormattedMessage id="continue" />
                        </Button>
                    </form>
                </Card.Body>
            </Card>
        </CardWrapper>
    );
}

const CardWrapper = styled.div`
    max-width: 380px;
    padding: 0 16px;
    margin: 40px auto;
`;
