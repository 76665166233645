import {useEffect} from "react";
import {useIntl} from "react-intl";
import {TaxiRide, useCancelRide} from "../api/apiClient";
import {Button} from "../components/Button";

export function TaxiRideCancel({ride}: {ride: TaxiRide}) {
    const intl = useIntl();
    const cancelRide = useCancelRide();

    useEffect(() => {
        cancelRide.reset();
    }, [ride.id]); // eslint-disable-line react-hooks/exhaustive-deps

    let cancelText = intl.formatMessage({id: "cancel_ride"});
    if (cancelRide.isLoading) {
        cancelText = intl.formatMessage({id: "canceling"});
    }
    if (cancelRide.isSuccess) {
        cancelText = intl.formatMessage({id: "ride_cancelled"});
    }

    function onCancelRide() {
        cancelRide.mutate(ride.id);
    }

    if (!ride.cancellable) {
        return null;
    }

    return (
        <>
            <Button
                data-cy="taxi-ride-cancel-btn"
                variant="destructive"
                fullWidth
                onClick={onCancelRide}
                disabled={cancelRide.isLoading || cancelRide.isSuccess}
                style={{marginTop: "var(--spacing-medium"}}
            >
                {cancelText}
            </Button>
            {cancelRide.isError && <div>{intl.formatMessage({id: "cancel_failed"})}</div>}
        </>
    );
}
