import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import {SwitchGroup, SwitchItem} from "../components/Switch";
import {DeliveryOrderFormValues} from "./deliveryOrderModel";

export function DeliverySwitch({
    name,
    values,
}: {
    name: string;
    values: DeliveryOrderFormValues["deliveryType"][];
}) {
    const getContent = (value: DeliveryOrderFormValues["deliveryType"]) => {
        switch (value) {
            case "ONE_WAY":
                return (
                    <div>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.75 10.25C4.75 9.83579 5.08579 9.5 5.5 9.5H12.6893L10.4697 7.28033C10.1768 6.98744 10.1768 6.51256 10.4697 6.21967C10.7626 5.92678 11.2374 5.92678 11.5303 6.21967L15.0303 9.71967C15.3232 10.0126 15.3232 10.4874 15.0303 10.7803L11.5303 14.2803C11.2374 14.5732 10.7626 14.5732 10.4697 14.2803C10.1768 13.9874 10.1768 13.5126 10.4697 13.2197L12.6893 11H5.5C5.08579 11 4.75 10.6642 4.75 10.25Z"
                                fill="currentColor"
                            />
                        </svg>
                        <FormattedMessage id="one_way" />
                    </div>
                );
            case "ROUND_TRIP":
                return (
                    <div>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.25488 15.0031C3.7361 15.0031 2.50488 13.7718 2.50488 12.2531L2.50488 9.75C2.50488 8.23121 3.7361 7 5.25488 7H7.96127L6.59015 5.62888C6.29726 5.33599 6.29726 4.86111 6.59015 4.56822C6.88304 4.27533 7.35792 4.27533 7.65081 4.56822L10.3023 7.21967C10.4429 7.36032 10.5219 7.55108 10.5219 7.75C10.5219 7.94891 10.4429 8.13967 10.3023 8.28033L7.65081 10.9318C7.35792 11.2247 6.88304 11.2247 6.59015 10.9318C6.29726 10.6389 6.29726 10.164 6.59015 9.87111L7.96127 8.5H5.25488C4.56453 8.5 4.00488 9.05964 4.00488 9.75V12.2531C4.00488 12.9434 4.56453 13.5031 5.25488 13.5031H13.25C13.9404 13.5031 14.5 12.9434 14.5 12.2531V9.75C14.5 9.05964 13.9404 8.5 13.25 8.5H12.2417C11.8275 8.5 11.4917 8.16421 11.4917 7.75C11.4917 7.33578 11.8275 7 12.2417 7H13.25C14.7688 7 16 8.23122 16 9.75V12.2531C16 13.7718 14.7688 15.0031 13.25 15.0031H5.25488Z"
                                fill="currentColor"
                            />
                        </svg>
                        <FormattedMessage id="round_trip" />
                    </div>
                );
        }
    };

    return (
        <SwitchGroup>
            {values.map((value) => (
                <SwitchItem key={value}>
                    <Field type="radio" name={name} value={value} />
                    {getContent(value)}
                </SwitchItem>
            ))}
        </SwitchGroup>
    );
}
