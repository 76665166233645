import {
    addMinutes,
    addSeconds,
    getHours,
    getMinutes,
    isBefore,
    isEqual,
    setMinutes,
    startOfDay,
    startOfHour,
    startOfMinute,
} from "date-fns";
import {FieldInputProps} from "formik";
import * as duration from "iso8601-duration";
import {first, groupBy, values} from "lodash";
import {useEffect, useState} from "react";

const INTERVAL_MINUTES = 5;
const EARLIEST_BUFFER_SECONDS = 5 * 60;
const LATEST_BUFFER_SECONDS = 1 * 60 * 60;

export type TimeSelectorProps = {from: string; to: string} & FieldInputProps<Date | undefined>;

function roundUpToNearestMinutes(date: Date, interval: number) {
    const roundedMinutes = Math.ceil(getMinutes(date) / interval) * interval;
    return setMinutes(startOfMinute(date), roundedMinutes);
}
export function getAllAvailableTimes({
    startDate,
    from,
    to,
}: {
    startDate: Date;
    from: string;
    to: string;
}) {
    const fromDuration = duration.parse(from);
    const toDuration = duration.parse(to);

    const minDateTime = roundUpToNearestMinutes(
        addSeconds(startDate, duration.toSeconds(fromDuration) + EARLIEST_BUFFER_SECONDS),
        INTERVAL_MINUTES
    );
    const maxDateTime = addSeconds(
        startDate,
        duration.toSeconds(toDuration) - LATEST_BUFFER_SECONDS
    );

    const availableTimes = [minDateTime];

    let lastTime = minDateTime;
    while (isBefore(addMinutes(lastTime, INTERVAL_MINUTES), maxDateTime)) {
        lastTime = addMinutes(lastTime, INTERVAL_MINUTES);
        availableTimes.push(lastTime);
    }

    return availableTimes;
}

function groupedSorted<T>(
    data: T[],
    groupByFnc: (item: T) => any,
    sortFnc: (a: T, b: T) => number
): T[] {
    return values(groupBy(data, groupByFnc)).map(
        (groupedData) => first(groupedData.sort(sortFnc))!
    );
}

function getSelectableDates(availableTimes: Date[], selectedDate: Date) {
    return groupedSorted(
        availableTimes,
        (time) => startOfDay(time),
        (a, b) => {
            const selectedTimeInDay = timeInDay(selectedDate);
            return (
                Math.abs(selectedTimeInDay - timeInDay(a)) -
                Math.abs(selectedTimeInDay - timeInDay(b))
            );
        }
    );
}

function getSelectableHours(availableTimes: Date[], selectedDate: Date) {
    const timesInDay = availableTimes.filter((time) =>
        isEqual(startOfDay(time), startOfDay(selectedDate))
    );

    return groupedSorted(
        timesInDay,
        (time) => startOfHour(time),
        (a: Date, b: Date) => {
            const selectedMinutes = getMinutes(selectedDate);
            return (
                Math.abs(selectedMinutes - getMinutes(a)) -
                Math.abs(selectedMinutes - getMinutes(b))
            );
        }
    );
}

function getSelectableMinutes(availableTimes: Date[], selectedDate: Date) {
    return availableTimes.filter((time) => isEqual(startOfHour(time), startOfHour(selectedDate)));
}

function timeInDay(selectedDate: Date) {
    return getHours(selectedDate) * 60 + getMinutes(selectedDate);
}

export function getAvailableTimes({from, to, time}: {from: string; to: string; time: Date}) {
    const startDate = new Date();
    const allAvailableTimes = getAllAvailableTimes({startDate, from, to});
    const dates = getSelectableDates(allAvailableTimes, time);
    const hours = getSelectableHours(allAvailableTimes, time);
    const minutes = getSelectableMinutes(allAvailableTimes, time);
    return {dates, hours, minutes};
}

export type AvailableTimesProps =
    | {
          dates: Date[];
          hours: Date[];
          minutes: Date[];
      }
    | undefined;

export function useAvailableTimes({from, to, time}: {from?: string; to?: string; time?: Date}) {
    const [availableTimes, setAvailableTimes] = useState<AvailableTimesProps>(undefined);

    useEffect(() => {
        if (time != null && from != null && to != null) {
            setAvailableTimes(
                getAvailableTimes({
                    from,
                    to,
                    time,
                })
            );
        }
    }, [time, from, to]);

    return availableTimes;
}
