import debounce from "debounce-promise";
import {phoneNumberValid} from "../api/apiClient";
import {createCache} from "./createCache";

//FIXME: Remove this and validate phone number only on FE

type PhoneValidator = (number: string | null | undefined) => Promise<boolean>;

export const getCachedResult = createCache<boolean>(true);

export function isPhoneNumberValid(phoneNumber: string): Promise<boolean> {
    return phoneNumberValid({phoneNumber: phoneNumber}).then((data) => data.valid);
}

const cachedValidator: PhoneValidator = (phoneNumber) => {
    // phone number is optional
    if (phoneNumber == null) {
        return Promise.resolve(true);
    }

    return getCachedResult(phoneNumber, () => isPhoneNumberValid(phoneNumber));
};

export const createCachedPhoneNumberValidator = () => {
    const validatorMapByPath: {[key: string]: PhoneValidator} = {};
    const getValidator = (path: string): PhoneValidator => {
        if (validatorMapByPath[path] != null) {
            return validatorMapByPath[path];
        }

        const validator = debounce(cachedValidator, 300);
        validatorMapByPath[path] = validator;
        return validator;
    };

    return (value: string | null | undefined, testContext: {path: string}) => {
        return getValidator(testContext.path)(value);
    };
};

type SetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

export function createSanitizedPhoneInputOnChangeHandler(setFieldValue: SetFieldValue) {
    return (e: any) => {
        // remove non ascii characters
        setFieldValue(e.target.name, e.target.value.replace(/[^\x20-\x7F]/g, ""));
    };
}
