import axios from "axios";
import {getAccessToken} from "../auth/authModel";

declare var appConfig: any;

export function setupAxiosInterceptors() {
    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response && error.response.status === 417) {
                let reloadCount = Number(sessionStorage.getItem("reloadCount")) || 0;
                if (reloadCount < 3) {
                    window.location.reload();
                    sessionStorage.setItem("reloadCount", String(reloadCount + 1));
                } else {
                    await axios.post(
                        `${appConfig.apiUrl}/self-service/feedback`,
                        {
                            text: "🚨 URGENT: Web app is down due to failing force update. Fix the Min version in LaunchDarkly feature flag (https://app.launchdarkly.com/projects/liftago-backend/flags/self-service-b4f-required-app-version/targeting?env=test&env=production&selected-env=production)",
                            currentUrl: window.location.pathname,
                            type: "AUTOMATED",
                        },
                        {
                            headers: {Authorization: `Bearer ${getAccessToken()}`},
                        }
                    );
                    alert(
                        "There is an issue with loading the page. We are working on it. Please try again later."
                    );
                    sessionStorage.removeItem("reloadCount");
                }
            }
            return Promise.reject(error);
        }
    );
}
