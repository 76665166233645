import {useMutation} from "@tanstack/react-query";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {updateBusinessPaymentInfo, useGetBusinessPaymentInfo} from "../api/apiClient";
import {Button} from "../components/Button";
import {queryClient} from "./App";
import {Card} from "./Registration";

const projectCodeModes = ["NONE", "OPTIONAL", "REQUIRED"] as const;
type Modes = typeof projectCodeModes[number];

type FormValue = {
    projectCode: Modes;
};

function formatProjectCodeMode(mode: Modes) {
    switch (mode) {
        case "NONE":
            return <FormattedMessage id="dont_use_project_codes" />;
        case "OPTIONAL":
            return <FormattedMessage id="optional_project_codes" />;
        case "REQUIRED":
            return <FormattedMessage id="mandatory_project_codes" />;
    }
}

export function ProjectCode() {
    const {data} = useGetBusinessPaymentInfo();
    const intl = useIntl();
    const mutation = useMutation(updateBusinessPaymentInfo, {
        onSuccess: () => {
            toast.success(intl.formatMessage({id: "saved"}));
        },
        onError: () => {
            alert(intl.formatMessage({id: "generic_error"}));
            reset();
        },
        onSettled: () => {
            queryClient.invalidateQueries(["/self-service/businesses/paymentInfo"]);
        },
    });

    const {register, handleSubmit, reset} = useForm<FormValue>();

    function onSubmit(data: FormValue) {
        mutation.mutate({projectCodeMode: data.projectCode});
    }

    if (data == null) {
        return null;
    }

    return (
        <>
            <Card>
                <h2>
                    <FormattedMessage id="project_codes" />
                </h2>
                <Description>
                    <FormattedMessage id="project_codes_text" />
                </Description>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {projectCodeModes.map((mode) => (
                        <Radio key={mode}>
                            <input
                                {...register("projectCode")}
                                type="radio"
                                value={mode}
                                defaultChecked={mode === data.projectCodeMode}
                            />
                            {formatProjectCodeMode(mode)}
                        </Radio>
                    ))}
                    <Button
                        data-cy="project-code-save-btn"
                        variant="outline"
                        type="submit"
                        disabled={mutation.isLoading}
                        style={{marginTop: "8px"}}
                    >
                        <FormattedMessage id="save" />
                    </Button>
                </form>
            </Card>
        </>
    );
}

const Description = styled.p`
    font-weight: var(--font-weight-thin);
    margin-top: 4px;
    margin-bottom: 4px;
`;

const Radio = styled.label`
    display: flex;
    padding: 4px;
    margin: 0px;
    gap: 4px;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;
