import {forwardRef} from "react";
import styled from "styled-components";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "primary" | "secondary" | "outline" | "plain" | "destructive" | "link";
    fullWidth?: boolean;
    large?: boolean;
    loading?: boolean;
}

export const Button = forwardRef(function Button(
    props: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
) {
    const {
        children,
        variant = "primary",
        /* eslint-disable @typescript-eslint/no-unused-vars */
        fullWidth = false,
        large = false,
        /* eslint-enable @typescript-eslint/no-unused-vars */
        loading = false,
    } = props;
    return (
        <StyledButton type="button" {...props} disabled={loading || props.disabled} ref={ref}>
            {children}
            {loading && <ButtonSpinner show={loading} variant={variant} />}
        </StyledButton>
    );
});

const ButtonSpinner = styled.div<{show: boolean; variant: ButtonProps["variant"]}>`
    position: absolute;
    display: ${(p) => (p.show ? `block` : `none`)};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: 1s linear 0s infinite normal none running spin;
    border-width: 2px;
    border-style: solid;
    border-color: ${(p) =>
        p.variant === "primary"
            ? `var(--white) var(--white) transparent`
            : `var(--tertiary) var(--tertiary) transparent`};
`;

export const StyledButton = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border: none;
    padding: ${(p) => (p.large ? `12px 12px` : `8px 12px`)};
    font-size: ${(p) => p.large && `15px`};
    border-radius: var(--border-radius-small);
    font-weight: var(--font-weight-normal);
    background-color: var(--primary);
    color: var(--white);
    ${(p) =>
        p.fullWidth &&
        `width: 100%;
    `}
    ${(p) =>
        p.variant === "primary" &&
        `
            background-color: var(--primary);
            color: var(--white);
        `}

    ${(p) =>
        p.variant === "secondary" &&
        `
            background-color: var(--blue-light);
            color: var(--primary);
        `}

    ${(p) =>
        p.variant === "destructive" &&
        `
            background-color: var(--white);
            color: var(--red);
            box-shadow: inset 0 0 0 1px var(--quaternary); 
        `}

    ${(p) =>
        p.variant === "outline" &&
        `
            background-color: var(--white);
            color: var(--dark);
            box-shadow: inset 0 0 0 1px var(--quaternary);
        `}

        ${(p) =>
        p.variant === "plain" &&
        `
            background-color: var(--white);
            color: var(--dark);
            padding: 8px 8px;
        `}

    ${(p) =>
        p.variant === "link" &&
        `
            background-color: transparent;
            color: var(--primary);
            padding: 4px;
        `}

    ${(p) =>
        p.loading &&
        `color: transparent;
    `}
        

    &:not(:disabled):hover {
        filter: brightness(95%);
    }

    &:disabled {
        opacity: 0.5;
        cursor: "not-allowed";
    }

    & svg:not(:only-child),
    img:not(:only-child) {
        margin-right: var(--spacing-tiny);
    }
`;
