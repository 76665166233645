export function getSlackHandle(id: string) {
    switch (id) {
        case "14":
            return "@Wendy";
        case "36":
            return "@Fanda";
        case "97":
            return "@Martin";
        case "64":
            return "@Jan Kárník";
        case "70":
            return "@David Š.";
        case "63":
            return "MyTimi";
        case "fb-taxi":
            return "Facebook/Instagram - Taxi";
        case "li-taxi":
            return "Linkedin - Taxi";
        case "go-taxi":
            return "Google - Taxi";
        case "se-taxi":
            return "Seznam - Taxi";
        case "fb-kuryr":
            return "Facebook/Instagram - Kurýr";
        case "li-kuryr":
            return "Linkedin - Kurýr";
        case "go-kuryr":
            return "Google - Kurýr";
        case "se-kuryr":
            return "Seznam - Kurýr";
        default:
            return `Unknown (r=${id})`;
    }
}
