import {UseMutationResult, useIsFetching} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {FieldArray, Form, useFormikContext} from "formik";
import {useEffect, useRef} from "react";
import {Alert} from "react-bootstrap";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import styled from "styled-components";
import {
    CompanyInfo,
    CostCenters,
    DeliveryOrderRequest,
    ProjectCodeSettings,
} from "../api/apiClient";
import {HomepageLink} from "../app/HomepageLink";
import {FormSection, FormTitle} from "../app/layout";
import {useUserInfo} from "../auth/authModel";
import {Button} from "../components/Button";
import {FocusFirstErrorField} from "../components/FocusFirstErrorField";
import {FallbackItemTitle, HandoverItemTitle, SimpleTitle} from "../components/formTitles";
import {IconFormControlWrapper} from "../components/forms";
import {DELIVERY_EXPRESS_PREORDER_TIER_ID, SLOT_MAX_DROPOFFS} from "../constants/constants";
import costCenterIcon from "../img/ic_cost_center.svg";
import projectCodeIcon from "../img/ic_project_code.svg";
import {AutocompleteInput} from "../taxi/AutocompleteInput";
import {LocationSuggestionWithId} from "../types/types";
import {generateUUID} from "../utils/generateUUID";
import {getOrderFailedMessage} from "../utils/getOrderFailedMessage";
import {hasAdminRole} from "../utils/userUtils";
import {ArrivalTimeSelect} from "./ArrivalTimeSelect";
import BatchUpload from "./BatchUpload";
import {BulkDropoff} from "./BulkDropoffs";
import {DeliverySwitch} from "./DeliverySwitch";
import {DeliveryTiers} from "./DeliveryTiers";
import {StopItemFormGroup} from "./StopItemFormGroup";
import {DeliveryOrderFormValues, StopItem, defaultHandover} from "./deliveryOrderModel";
import {useSetDeliveryUrl} from "./deliveryOrderUrl";

export interface DeliveryOrderFormViewProps {
    deliveryOrder: UseMutationResult<AxiosResponse<any>, any, DeliveryOrderRequest, any>;
    company: CompanyInfo;
    projectCodes?: ProjectCodeSettings;
    costCenters?: CostCenters;
}

export function handoverLocations(handovers: StopItem[]): LocationSuggestionWithId[] {
    return handovers.flatMap((handover) => {
        return handover.location?.location == null ? [] : [handover.location];
    });
}

export function DeliveryOrderFormView({
    deliveryOrder,
    company,
    projectCodes,
    costCenters,
}: DeliveryOrderFormViewProps) {
    const {values, isSubmitting, setFieldValue} = useFormikContext<DeliveryOrderFormValues>();
    const isFetchingTiers = useIsFetching(["deliveryTiers"]);
    const intl = useIntl();
    const {data: user} = useUserInfo();
    useSetDeliveryUrl();
    const showProjectCode = projectCodes != null && projectCodes.mode !== "NONE";
    const projectCodeOptional = projectCodes != null && projectCodes.mode === "OPTIONAL";
    const formRef = useRef<HTMLFormElement>(null);

    function canShowRemoveButton(handoverCount: number): Boolean {
        return handoverCount > 1;
    }

    function handoverTitle(index: number, handoverCount: number, intl: IntlShape): string {
        if (handoverCount > 1) {
            return `${intl.formatMessage({id: "drop_off"})} (${index + 1}/${handoverCount})`;
        } else {
            return intl.formatMessage({id: "drop_off"});
        }
    }

    let submitText = intl.formatMessage({id: "place_order"});
    if (deliveryOrder.isLoading) {
        submitText = intl.formatMessage({id: "sending"});
    }

    useEffect(() => {
        if (values.deliveryType === "ROUND_TRIP") {
            setFieldValue("handovers", [values.handovers[0]]);
        }
    }, [values.deliveryType]);

    useEffect(() => {
        //Remove error message
        deliveryOrder.reset();
    }, [values]);

    return (
        <Form ref={formRef}>
            {user?.roles && hasAdminRole(user.roles) && <HomepageLink />}
            <FocusFirstErrorField formRef={formRef} />
            <FormTitle>
                <h2>
                    <FormattedMessage id="create_order" />
                </h2>
                {company.partnerDeliveryOrderEnabled && <BatchUpload />}
            </FormTitle>
            {!company.partnerDeliveryOrderEnabled && (
                <div style={{marginBottom: "var(--spacing-small)"}}>
                    <DeliverySwitch name="deliveryType" values={["ONE_WAY", "ROUND_TRIP"]} />
                </div>
            )}
            <FormSection>
                <SimpleTitle>
                    {values.deliveryType === "ROUND_TRIP"
                        ? intl.formatMessage({id: "pickup_and_return"})
                        : intl.formatMessage({id: "pickup"})}
                </SimpleTitle>
                <StopItemFormGroup
                    prefix="pickup"
                    addressType="PICKUP"
                    namePlaceholder={intl.formatMessage({
                        id: "sender_name",
                    })}
                    locationPlaceholder={intl.formatMessage({
                        id: "pickup_location",
                    })}
                />
            </FormSection>
            <FieldArray name="handovers">
                {(arrayHelpers) => (
                    <>
                        {values.handovers.map((_, index) => (
                            <FormSection key={`handovers${index}`}>
                                <HandoverItemTitle
                                    title={
                                        values.deliveryType === "ROUND_TRIP"
                                            ? intl.formatMessage({id: "destination"})
                                            : handoverTitle(index, values.handovers.length, intl)
                                    }
                                    showRemoveButton={canShowRemoveButton(values.handovers.length)}
                                    onRemove={() => {
                                        arrayHelpers.remove(index);
                                    }}
                                />
                                <StopItemFormGroup
                                    prefix={`handovers.${index}`}
                                    addressType="DESTINATION"
                                    pickup={values.pickup?.location?.location}
                                    namePlaceholder={intl.formatMessage(
                                        values.deliveryType === "ROUND_TRIP"
                                            ? {id: "stop_name"}
                                            : {
                                                  id: "recipient_name",
                                              }
                                    )}
                                    locationPlaceholder={intl.formatMessage(
                                        values.deliveryType === "ROUND_TRIP"
                                            ? {id: "destination_location"}
                                            : {
                                                  id: "drop_off_location",
                                              }
                                    )}
                                />
                            </FormSection>
                        ))}
                        {values.deliveryType !== "ROUND_TRIP" && (
                            <div style={{display: "flex", flexWrap: "wrap", gap: "8px"}}>
                                <AddStop
                                    type="button"
                                    disabled={
                                        values.handovers.length >= SLOT_MAX_DROPOFFS &&
                                        !company.partnerDeliveryOrderEnabled
                                    }
                                    onClick={() => {
                                        arrayHelpers.push({
                                            tripId: generateUUID(),
                                            ...defaultHandover,
                                        });
                                    }}
                                    style={{flex: "1"}}
                                >
                                    + <FormattedMessage id="add_drop_off" />{" "}
                                    {values.handovers.length >= SLOT_MAX_DROPOFFS &&
                                        !company.partnerDeliveryOrderEnabled && (
                                            <FormattedMessage id="stop_limit_reached" />
                                        )}
                                </AddStop>
                                {values.handovers.length === 1 && <BulkDropoff />}
                            </div>
                        )}
                    </>
                )}
            </FieldArray>
            {company.partnerDeliveryOrderEnabled && (
                <FormSection>
                    <FallbackItemTitle
                        checked={values.fallbackEnabled}
                        onChecked={(checked) => {
                            setFieldValue("fallbackEnabled", checked);
                        }}
                    />
                    {values.fallbackEnabled && (
                        <StopItemFormGroup
                            prefix="fallback"
                            namePlaceholder={intl.formatMessage({
                                id: "fallback_name",
                            })}
                            locationPlaceholder={intl.formatMessage({
                                id: "return_address",
                            })}
                        />
                    )}
                </FormSection>
            )}
            {!company.partnerDeliveryOrderEnabled && (
                <FormSection>
                    <SimpleTitle>{intl.formatMessage({id: "reporting_info"})}</SimpleTitle>
                    {showProjectCode && (
                        <IconFormControlWrapper icon={projectCodeIcon} name="projectCode">
                            <AutocompleteInput
                                name="projectCode"
                                placeholder={`${intl.formatMessage({
                                    id: "project_code",
                                })} ${
                                    projectCodeOptional
                                        ? `(${intl.formatMessage({id: "optional"})})`
                                        : ""
                                }`}
                                autocompleteOptions={projectCodes?.codeHistory ?? []}
                            />
                        </IconFormControlWrapper>
                    )}
                    <IconFormControlWrapper icon={costCenterIcon} name="costCenter">
                        <AutocompleteInput
                            name="costCenter"
                            placeholder={`${intl.formatMessage({
                                id: "cost_center",
                            })} (${intl.formatMessage({id: "optional"})})`}
                            autocompleteOptions={costCenters?.costCenterHistory ?? []}
                        />
                    </IconFormControlWrapper>
                </FormSection>
            )}
            {company.partnerDeliveryOrderEnabled && (
                <FormSection>
                    <SimpleTitle>{intl.formatMessage({id: "delivery_time"})}</SimpleTitle>
                    <ArrivalTimeSelect
                        selectedDate={values.arrivalTime}
                        onDateSelected={(value) => {
                            setFieldValue("arrivalTime", value);
                        }}
                    />
                </FormSection>
            )}
            {company.priceEstimationAllowed && (
                <DeliveryTiers
                    pickup={values.pickup.location}
                    handovers={
                        handoverLocations(values.handovers).length > 0
                            ? handoverLocations(values.handovers)
                            : undefined
                    }
                    isPartner={company.partnerDeliveryOrderEnabled}
                    deliveryType={values.deliveryType}
                />
            )}

            {deliveryOrder.isError && (
                <Alert variant="danger">
                    <p>
                        <strong>
                            <FormattedMessage id="order_failed_title" />
                        </strong>
                    </p>
                    <p>{getOrderFailedMessage(deliveryOrder.error.response.data.code)}</p>
                </Alert>
            )}
            <Button
                type="submit"
                variant="primary"
                fullWidth
                large
                disabled={
                    isSubmitting ||
                    (Boolean(isFetchingTiers) && !company.partnerDeliveryOrderEnabled)
                }
            >
                {submitText}{" "}
                {values.pickupDates?.length != null &&
                    values.tierId === DELIVERY_EXPRESS_PREORDER_TIER_ID &&
                    (values.pickupDates?.length ?? 0) > 1 &&
                    `· ${values.pickupDates.length}×`}
            </Button>
        </Form>
    );
}

export const AddStop = styled.button`
    font-weight: var(--font-weight-normal);
    color: var(--primary);
    padding: 16px;
    box-shadow: 0 0 0 1px rgba(0, 34, 80, 0.05), 0 1px 3px 0 rgba(0, 34, 80, 0.15);
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--white);
    margin-bottom: 16px;
    text-align: left;

    &:hover {
        background-color: #f0f3f7;
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &:disabled:hover {
        background-color: var(--white);
    }
`;
