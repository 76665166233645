import TagManager from "react-gtm-module";

export function logGtm(event: "registration" | "login") {
    return TagManager.dataLayer({
        dataLayer: {
            event: event,
            user: {
                id: undefined, //FIXME: Add userId when B4F provides
                type: "business",
            },
        },
    });
}
