import {IntlShape} from "react-intl";
import {
    DeliveryRide,
    DeliveryStop,
    Stop,
    StopLocation,
    TaxiRide,
    TaxiStop,
    VehicleInfo,
} from "../api/apiClient";
import {formatShortDateFancy, formatTime} from "../app/dateUtils";

export function formatStopKind(kind: Stop["kind"], intl: IntlShape): string {
    switch (kind) {
        case "PICKUP":
            return intl.formatMessage({id: "pickup"});
        case "HANDOVER":
            return intl.formatMessage({id: "drop_off"});
        case "FALLBACK":
            return intl.formatMessage({id: "returning"});
    }
}

export function formatDeliveryRideStatus(status: DeliveryRide["status"], intl: IntlShape): string {
    switch (status) {
        case "SCHEDULING":
            return intl.formatMessage({id: "scheduling"});
        case "SCHEDULED":
            return intl.formatMessage({id: "scheduled"});
        case "IN_SEARCHING":
            return intl.formatMessage({id: "in_searching"});
        case "PICKUP_ARRIVAL":
        case "PICKUP_WAITING":
        case "ON_BOARD":
        case "RETURNING":
            return intl.formatMessage({id: "en_route"});
        case "FINISHED":
            return intl.formatMessage({id: "finished"});
        case "FAILED":
            return intl.formatMessage({id: "driver_not_found"});
        case "CANCELLED":
            return intl.formatMessage({id: "cancelled"});
    }
}

export function formatTaxiRideStatus(
    status: TaxiRide["status"],
    failReason: TaxiRide["failReason"],
    intl: IntlShape
): string {
    switch (status) {
        case "SCHEDULING":
            return intl.formatMessage({id: "scheduling"});
        case "SCHEDULED":
            return "";
        case "IN_SEARCHING":
            return intl.formatMessage({id: "in_searching"});
        case "PICKUP_ARRIVAL":
            return intl.formatMessage({id: "pickup"});

        case "PICKUP_WAITING":
            return intl.formatMessage({id: "waiting_at_pickup"});
        case "ON_BOARD":
            return intl.formatMessage({id: "on_board"});
        case "RETURNING":
            return intl.formatMessage({id: "returning"});
        case "FINISHED":
            return intl.formatMessage({id: "finished"});
        case "FAILED":
            if (failReason === "PAYMENT_AUTHORIZATION_FAILURE") {
                return intl.formatMessage({id: "payment_failed"});
            }
            return intl.formatMessage({id: "driver_not_found"});
        case "CANCELLED":
            if (failReason === "CANCELLED_BY_DRIVER") {
                return intl.formatMessage({id: "cancelled_by_driver"});
            }
            return intl.formatMessage({id: "cancelled"});
    }
}

export function formatRemainingMinutes(minutes: number | null, intl: IntlShape) {
    if (minutes == null) {
        return null;
    }
    if (minutes < 1) {
        return intl.formatMessage({id: "soon"});
    }
    return `${intl.formatMessage({id: "in_min_plural"}, {count: minutes})}`;
}

export function getScheduledTime(time: string | undefined, intl: IntlShape): string {
    if (time == null) {
        return "";
    }
    const arrival = new Date(time);
    return `${formatShortDateFancy(intl, arrival)} ${formatTime(arrival)}`;
}

export function formatStopState(
    stopKind: DeliveryStop["kind"],
    stopStatus: DeliveryStop["status"],
    arrival: DeliveryStop["arrival"],
    intl: IntlShape
): string | null {
    const isPickup = stopKind === "PICKUP";
    switch (stopStatus) {
        case "IN_PROGRESS":
            return intl.formatMessage({id: isPickup ? "pickup" : "drop_off"});
        case "ARRIVED":
            return intl.formatMessage({id: "arrived"});
        case "STORING":
            return intl.formatMessage({id: "storing"});
        case "STORED":
            return intl.formatMessage({id: "stored"});
        case "SCHEDULED":
            return arrival ? intl.formatMessage({id: "eta"}) : null;
        case "SCHEDULING":
            return null;
        case "FAILED":
            return intl.formatMessage({id: isPickup ? "not_picked_up" : "undelivered"});
        case "CANCELLED":
            return intl.formatMessage({id: "cancelled"});
        case "RETURNING":
            return intl.formatMessage({id: "returning"});
        case "RETURNED":
            return intl.formatMessage({id: "returned"});
        case "COMPLETED":
            return intl.formatMessage({id: isPickup ? "picked_up" : "delivered"});
    }
}

export function formatStopTime(
    stop: DeliveryStop,
    minutes: number | null,
    intl: IntlShape
): string | null {
    switch (stop.status) {
        case "IN_PROGRESS":
        case "STORING":
            return formatRemainingMinutes(minutes, intl);
        case "RETURNING":
        case "ARRIVED":
            return null;
        default:
            return formatStopArrivalTime(stop);
    }
}

function formatStopArrivalTime(stop: DeliveryStop): string | null {
    const arrival = () => {
        if (!stop.arrival) {
            return null;
        }
        return formatTime(new Date(stop.arrival));
    };

    const arrivalTime = () => {
        if (!stop.arrivalTime) {
            return null;
        }
        const from = new Date(stop.arrivalTime.from);
        const to = new Date(stop.arrivalTime.to);
        return `${formatTime(from)}-${formatTime(to)}`;
    };

    if (stop.status === "SCHEDULING") {
        return arrivalTime();
    } else {
        return arrival();
    }
}

export function formatAddress({
    description,
    street,
    houseNumber,
    city,
    zipCode,
}: StopLocation): string {
    if (street && description && !street.startsWith(description.slice(0, 4))) {
        return [description, street, houseNumber, city, zipCode].filter(Boolean).join(", ");
    } else {
        return [street, houseNumber, city, zipCode].filter(Boolean).join(", ");
    }
}

export function formatVehicleInfo(vehicleInfo: VehicleInfo, intl: IntlShape): string {
    const vehicleColor = mapVehicleColor(intl, vehicleInfo.color);
    return [vehicleColor, vehicleInfo?.brand, vehicleInfo?.model, vehicleInfo?.licencePlate]
        .filter((a) => a != null)
        .join(" ");
}

function mapVehicleColor(intl: IntlShape, vehicleInfoColor?: VehicleInfo["color"]): string | null {
    switch (vehicleInfoColor) {
        case "BLACK":
            return intl.formatMessage({id: "color_black"});
        case "BLUE":
            return intl.formatMessage({id: "color_blue"});
        case "BROWN":
            return intl.formatMessage({id: "color_brown"});
        case "GREEN":
            return intl.formatMessage({id: "color_green"});
        case "ORANGE":
            return intl.formatMessage({id: "color_orange"});
        case "PURPLE":
            return intl.formatMessage({id: "color_purple"});
        case "RED":
            return intl.formatMessage({id: "color_red"});
        case "SILVER":
            return intl.formatMessage({id: "color_silver"});
        case "WHITE":
            return intl.formatMessage({id: "color_white"});
        case "YELLOW":
            return intl.formatMessage({id: "color_yellow"});
        default:
            return null;
    }
}

export function getPassengersCount(stops: TaxiStop[]) {
    return stops.filter((stop) => stop.contact).length;
}
