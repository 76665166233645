import {useFormikContext} from "formik";
import {Typeahead, TypeaheadMenu} from "react-bootstrap-typeahead";

export interface AutocompleteInputProps {
    name: string;
    placeholder: string;
    autocompleteOptions: string[];
}

export function AutocompleteInput({
    name,
    placeholder,
    autocompleteOptions,
}: AutocompleteInputProps) {
    const {setFieldValue, setFieldTouched, getFieldProps} = useFormikContext();

    const fieldValue = getFieldProps(name).value as string;

    return (
        <Typeahead
            id={name}
            inputProps={{name}}
            onBlur={() => {
                setFieldTouched(name, true);
            }}
            selected={[fieldValue]}
            onInputChange={(s) => setFieldValue(name, s, true)}
            onChange={(suggestions: string[]) => {
                if (suggestions.length > 0) {
                    setFieldValue(name, suggestions[0], true);
                }
            }}
            renderMenu={
                ((results: any, menuProps: any, props: any) => {
                    if (results.length === 0) {
                        return null;
                    }
                    return (
                        <TypeaheadMenu
                            {...menuProps}
                            labelKey={props.labelKey}
                            options={results}
                            text={props.text}
                        />
                    );
                }) as any
            }
            options={autocompleteOptions}
            placeholder={placeholder}
            highlightOnlyResult={true}
        />
    );
}
