import {Buffer} from 'buffer';

export const downloadFileFromBase64String = (data: string, fileName: string) => {
    const bytes = Buffer.from(data, "base64");
    
    const url = window.URL.createObjectURL(new Blob([bytes]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
}
