import {useGetBusinessCompany} from "../api/apiClient";
import {DropdownMenuA} from "../app/Header";

declare var appConfig: any;

export function LinkToAdminConsole() {
    const {data: business} = useGetBusinessCompany();

    if (!business) {
        return null;
    }

    return (
        <DropdownMenuA href={`${appConfig.adminConsoleUrl}/business/${business.businessId}`}>
            Open in admin console →
        </DropdownMenuA>
    );
}
