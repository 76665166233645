import {useCallback, useEffect} from "react";

export const useKeyPress = (keyCode: number, callbackFn: () => void) => {
    const handleKeyPress = useCallback(
        (event) => {
            if (event.altKey && event.shiftKey && event.keyCode === keyCode) {
                callbackFn();
            }
        },
        [keyCode, callbackFn]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);
};
