import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {DeliveryRide, DeliveryStop, Depot} from "../api/apiClient";
import {formatDateTimeInterval} from "../app/dateUtils";
import {Button} from "../components/Button";
import {DetailRow} from "../components/DetailRow";
import {TrackingLink} from "../components/TrackingLink";
import closeIcon from "../img/ic_close.svg";
import currentLocationIcon from "../img/ic_current-location.svg";
import feedbackIcon from "../img/ic_feedback.svg";
import {DetailContent, DetailHeader, SectionTitle} from "../rides/layout";
import {
    formatAddress,
    formatStopKind,
    formatStopState,
    formatStopTime,
    formatVehicleInfo,
} from "../rides/ridesModel";
import {useRemainingMinutes} from "../rides/useRemainingMinutes";
import {CancelState} from "./DeliveryStopDetail";
import {useOpenDetail} from "./OpenDetailContext";

interface StopDetailViewProps {
    ride: DeliveryRide;
    stop: DeliveryStop;
    cancelState: CancelState;
    onCancel: () => void;
    onOpenRideDetail: () => void;
}

export function StopDetailView({
    ride,
    stop,
    cancelState,
    onCancel,
    onOpenRideDetail,
}: StopDetailViewProps) {
    const intl = useIntl();
    const openDetail = useOpenDetail();
    const minutesRemaining = useRemainingMinutes(stop.arrival);

    let deliveryTime;
    if (ride.statusGroup === "COMPLETED") {
        deliveryTime = stop.arrivalTime && formatDateTimeInterval(intl, stop.arrivalTime);
    }
    const formattedState = formatStopState(stop.kind, stop.status, stop.arrival, intl);
    const formattedTime = formatStopTime(stop, minutesRemaining, intl);
    const stopStatus = [formattedState, formattedTime].filter((a) => a != null).join(" ");
    const depotType: {[key in Depot["type"]]: string} = {
        ALZA_BOX: "AlzaBox",
    };

    function cancelButtonText(cancelState: CancelState): string {
        switch (cancelState) {
            case "LOADING":
                return intl.formatMessage({id: "canceling"});
            case "SUCCESS":
                return intl.formatMessage({id: "stop_cancelled"});
            default:
                return intl.formatMessage({id: "cancel_stop"});
        }
    }

    function closeDetail() {
        openDetail(null);
    }

    return (
        <div>
            <DetailHeader>
                <div>
                    <Link onClick={() => onOpenRideDetail()}>
                        {intl.formatMessage({id: "ride"})}
                    </Link>
                    {" ‣ "} {stop.contact.name}
                </div>
                <Button variant="plain" onClick={closeDetail}>
                    <img src={closeIcon} alt="Close detail" />
                </Button>
            </DetailHeader>
            <DetailContent>
                {/* FIXME: Support also pickup time requests */}
                {deliveryTime && (
                    <DetailRow title={intl.formatMessage({id: "delivery_time"})}>
                        {deliveryTime}
                    </DetailRow>
                )}

                <SectionTitle>{formatStopKind(stop.kind, intl)}</SectionTitle>

                {stopStatus && (
                    <DetailRow title={intl.formatMessage({id: "status"})}>{stopStatus}</DetailRow>
                )}
                {stop.depot && (
                    <DetailRow title={depotType[stop.depot.type]}>{stop.depot.address}</DetailRow>
                )}
                <DetailRow title={intl.formatMessage({id: "address"})}>
                    {formatAddress(stop.destination)}
                </DetailRow>
                <DetailRow title={intl.formatMessage({id: "name"})}>{stop.contact.name}</DetailRow>
                <DetailRow title={intl.formatMessage({id: "phone_number"})}>
                    {stop.contact.phoneNumber}
                </DetailRow>
                {stop.noteForDriver && (
                    <DetailRow title={intl.formatMessage({id: "message_to_driver"})}>
                        {stop.noteForDriver}
                    </DetailRow>
                )}
                {stop.courierInfo?.projectCode != null && (
                    <DetailRow title={intl.formatMessage({id: "project_code"})}>
                        {stop.courierInfo?.projectCode}
                    </DetailRow>
                )}
                {stop.courierInfo?.costCenter != null && (
                    <DetailRow title={intl.formatMessage({id: "cost_center"})}>
                        {stop.courierInfo?.costCenter}
                    </DetailRow>
                )}
                <DetailRow title={intl.formatMessage({id: "stop_id"})}>
                    {stop.clientStopId}
                </DetailRow>
                {(ride.driver || ride.vehicle) && <hr />}
                {ride.driver && (
                    <DetailRow title={intl.formatMessage({id: "driver"})}>
                        {ride.driver.name}
                    </DetailRow>
                )}
                {ride.driver && (
                    <DetailRow title={intl.formatMessage({id: "phone_number"})}>
                        {ride.driver.phoneNumber}
                    </DetailRow>
                )}
                {ride.vehicle && (
                    <DetailRow title={intl.formatMessage({id: "vehicle"})}>
                        {formatVehicleInfo(ride.vehicle, intl)}
                    </DetailRow>
                )}
                {ride.shareUrl &&
                    (ride.statusGroup === "IN_PROGRESS" ? (
                        <TrackingLink url={ride.shareUrl} imgSrc={currentLocationIcon}>
                            <FormattedMessage id="driver_location" />
                        </TrackingLink>
                    ) : (
                        <TrackingLink url={ride.shareUrl} imgSrc={feedbackIcon}>
                            <FormattedMessage id="rate_the_delivery" />
                        </TrackingLink>
                    ))}
                {stop.cancellable && (
                    <>
                        <Button
                            variant="destructive"
                            onClick={() => onCancel()}
                            disabled={cancelState === "LOADING" || cancelState === "SUCCESS"}
                        >
                            {cancelButtonText(cancelState)}
                        </Button>
                        {cancelState === "FAILED" && (
                            <div>{intl.formatMessage({id: "cancel_failed"})}</div>
                        )}
                    </>
                )}
            </DetailContent>
        </div>
    );
}

const Link = styled.span`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;
