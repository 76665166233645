import {KeyboardEvent} from "react";
import {FormControl} from "react-bootstrap";
import styled from "styled-components";
import {Button} from "../components/Button";
import closeIcon from "../img/ic_close.svg";
import searchIcon from "../img/ic_search.svg";
import {IconInputLayout} from "./forms";

export function SearchField({
    searchQuery,
    setSearchQuery,
    resetSearch,
    placeholder,
}: {
    searchQuery: string;
    setSearchQuery: (searchQuery: string) => void;
    resetSearch: () => void;
    placeholder: string;
}) {
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            resetSearch();
            e.currentTarget.blur();
        }
    };

    return (
        <SearchFieldView>
            <IconInputLayout>
                <img src={searchIcon} alt="" />
                <FormControl
                    type="text"
                    placeholder={placeholder}
                    value={searchQuery}
                    autoComplete="off"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
            </IconInputLayout>
            {searchQuery && (
                <CloseButton variant="plain" onClick={resetSearch}>
                    <img src={closeIcon} alt="Reset search" />
                </CloseButton>
            )}
        </SearchFieldView>
    );
}

const SearchFieldView = styled.div`
    position: relative;
    display: flex;
    & input {
        outline: none;
        border: 1px solid #d6dbe2;
        background-color: var(--light);
        padding-right: 24px;
    }
    & input:placeholder-shown {
        padding-right: 6px;
    }
`;

const CloseButton = styled(Button)`
    position: absolute;
    right: 3px;
    margin-top: 3px;
`;
