import {ReactNode} from "react";
import {FormCheck, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {Button} from "../components/Button";
import removeIcon from "../img/ic_close.svg";

export function SimpleTitle({children}: {children: ReactNode}) {
    return (
        <Row>
            <Title>{children}</Title>
        </Row>
    );
}

export interface HandoverItemTitleProps {
    title: string;
    showRemoveButton: Boolean;
    onRemove: () => void;
}

export function HandoverItemTitle({title, showRemoveButton, onRemove}: HandoverItemTitleProps) {
    const intl = useIntl();

    return (
        <Row>
            <Title>{title}</Title>
            {showRemoveButton && (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="remove-drop-off">
                            {intl.formatMessage({id: "remove_drop_off"})}
                        </Tooltip>
                    }
                >
                    <Button variant="plain" onClick={onRemove}>
                        <img src={removeIcon} alt={intl.formatMessage({id: "remove_drop_off"})} />
                    </Button>
                </OverlayTrigger>
            )}
        </Row>
    );
}

export interface FallbackItemTitleProps {
    checked: boolean;
    onChecked: (value: boolean) => any;
}

export function FallbackItemTitle({checked, onChecked}: FallbackItemTitleProps) {
    const intl = useIntl();

    return (
        <Row>
            <Title>{intl.formatMessage({id: "return_undelivered_packages"})}</Title>
            <FormCheck
                data-cy="delivery-form-fallback-switch"
                id="fallbackSwitch"
                type="switch"
                style={{
                    fontSize: "14px",
                }}
                checked={checked}
                onChange={(event) => {
                    onChecked((event.target as any).checked);
                }}
                label=""
            />
        </Row>
    );
}

const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary);
    margin-left: 8px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
`;
