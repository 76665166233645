import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Link, Outlet, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {
    BusinessEmployeeDto,
    useCallerCompany,
    useGetBusinessCompany,
    useGetBusinessPaymentInfo,
    useGetEmployees,
} from "../api/apiClient";
import {Button} from "../components/Button";
import {SearchField} from "../components/SearchField";
import {formatUserPaymentType} from "../helpers/formatUserPaymentType";
import {formatMoney} from "./moneyUtils";

function userContainsQuery(user: BusinessEmployeeDto, query: string): boolean {
    return [user.name, user.phoneNumber, user.costCenter].some((value) =>
        value?.trim().toLowerCase().includes(query.trim().toLowerCase())
    );
}

export function MobileUsers() {
    const {data} = useGetEmployees();
    const {data: business} = useGetBusinessPaymentInfo();
    const {data: company} = useGetBusinessCompany();
    const {data: companyInfo} = useCallerCompany();
    const [searchQuery, setSearchQuery] = useState("");
    const intl = useIntl();
    const navigate = useNavigate();

    if (data == null || business == null || company == null || companyInfo == null) {
        return null;
    }

    const resetSearch = () => {
        setSearchQuery("");
    };

    const isMonthlyLimitAllowed = ["CZ", "SK"].includes(company.billingAddress.country);
    const users = searchQuery ? data.filter((user) => userContainsQuery(user, searchQuery)) : data;

    return (
        <>
            <p style={{margin: "8px 0"}}>
                <FormattedMessage
                    id="mobile_users_invite_description"
                    values={{
                        a: (name: string) => (
                            <a
                                href={intl.formatMessage({id: "mobile_app_url"})}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {name}
                            </a>
                        ),
                    }}
                />
            </p>
            <Header>
                <div style={{display: "flex", gap: "8px"}}>
                    <Button
                        data-cy="invite-new-mobile-user-btn"
                        onClick={() => navigate("new-user")}
                    >
                        + <FormattedMessage id="invite" />
                    </Button>
                    {
                        //FIXME: Bulk doesn't support "no monthly limit" fully yet. Few impacted customers currently.
                        isMonthlyLimitAllowed && (
                            <Button
                                data-cy="bulk-invite-new-mobile-user-btn"
                                variant="secondary"
                                onClick={() => navigate("bulk")}
                            >
                                <FormattedMessage id="in_bulk_csv" />
                            </Button>
                        )
                    }
                </div>
                <SearchField
                    {...{searchQuery, setSearchQuery, resetSearch}}
                    placeholder={intl.formatMessage({id: "search"})}
                />
            </Header>
            {searchQuery && users.length === 0 ? (
                <FormattedMessage
                    id="no_mobile_users_found"
                    values={{
                        searchQuery: searchQuery,
                    }}
                />
            ) : (
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="name" />
                                </th>
                                <th>
                                    <FormattedMessage id="phone_number" />
                                </th>
                                <th>
                                    <FormattedMessage id="status" />
                                </th>
                                {(company?.billingAddress?.country === "CZ" ||
                                    company?.billingAddress?.country === "SK") && (
                                    <th align="right" style={{textAlign: "right"}}>
                                        <FormattedMessage id="monthly_limit" />
                                    </th>
                                )}
                                <th>
                                    <FormattedMessage id="cost_center" />
                                </th>
                                {business.paymentByEmployeeAllowed && (
                                    <th>
                                        <FormattedMessage id="payment_type" />
                                    </th>
                                )}
                                {companyInfo.employeeRemoveAfterEnabled && (
                                    <th>
                                        <FormattedMessage id="remove_after" />
                                    </th>
                                )}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user) => (
                                <tr key={user.employeeId}>
                                    <td>{user.name}</td>
                                    <td>
                                        <Monospace>{user.phoneNumber}</Monospace>
                                    </td>
                                    <td>
                                        {user.inviteAcceptedAt ? (
                                            <Tag positive>
                                                <FormattedMessage id="joined" />
                                            </Tag>
                                        ) : (
                                            <Tag>
                                                <FormattedMessage id="invited" />
                                            </Tag>
                                        )}
                                    </td>
                                    {(company?.billingAddress?.country === "CZ" ||
                                        company?.billingAddress?.country === "SK") && (
                                        <td align="right">
                                            {user.monthlyLimit &&
                                                formatMoney(user.monthlyLimit, intl)}
                                        </td>
                                    )}
                                    <td>{user.costCenter}</td>
                                    {business.paymentByEmployeeAllowed && (
                                        <td>{formatUserPaymentType(user.paymentOriginator)}</td>
                                    )}
                                    {companyInfo.employeeRemoveAfterEnabled && (
                                        <td>
                                            {user.removeAfter
                                                ? new Date(user.removeAfter).toLocaleString(
                                                      undefined,
                                                      {
                                                          dateStyle: "short",
                                                          timeStyle: undefined,
                                                      }
                                                  )
                                                : null}
                                        </td>
                                    )}
                                    <td style={{padding: "0"}}>
                                        <Link to={`${user.employeeId}`} style={{padding: "8px"}}>
                                            <svg
                                                width="16"
                                                height="16"
                                                fill="var(--secondary)"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M12.8667 5.95004L10.0333 3.15004L10.9667 2.21671C11.2222 1.96115 11.5361 1.83337 11.9083 1.83337C12.2806 1.83337 12.5944 1.96115 12.85 2.21671L13.7833 3.15004C14.0389 3.4056 14.1722 3.71393 14.1833 4.07504C14.1944 4.43615 14.0722 4.74449 13.8167 5.00004L12.8667 5.95004ZM11.9 6.93337L4.97978 13.8536C4.88601 13.9474 4.75884 14 4.62623 14H2.5C2.22386 14 2 13.7762 2 13.5V11.3738C2 11.2412 2.05268 11.114 2.14645 11.0203L9.06667 4.10004L11.9 6.93337Z" />
                                            </svg>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </TableWrapper>
            )}
            <Outlet />
        </>
    );
}

const Tag = styled.span<{positive?: boolean}>`
    font-size: var(--font-size-small);
    color: ${(p) => (p.positive ? `var(--green)` : `var(--secondary)`)};
    background-color: ${(p) => (p.positive ? `var(--green-light)` : `var(--light)`)};
    padding: 2px 8px;
    border-radius: 20px;
    display: inline-block;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    flex-wrap: wrap;
`;

export const TableWrapper = styled.div`
    border-radius: 4px;
    box-shadow: 0px 1px 0px 0px rgba(237, 237, 237, 1), 0px 0px 0px 1px rgba(237, 237, 237, 1);
    overflow: scroll;
`;
export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    font-weight: var(--font-weight-thin);
    thead tr {
        background-color: var(--light);
        text-align: left;
        color: var(--secondary);
    }

    th,
    td {
        padding: 8px;
        overflow: visible;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    td:first-child {
        font-weight: var(--font-weight-bold);
    }

    tbody tr {
        border-bottom: 1px solid var(--light);
    }
`;

const Monospace = styled.span`
    font-variant-numeric: tabular-nums;
    text-align: right;
`;
