import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Link, Outlet, useNavigate} from "react-router-dom";
import {BusinessUserDto, useGetUsers} from "../api/apiClient";
import {Button} from "../components/Button";
import {SearchField} from "../components/SearchField";
import {formatRole} from "../utils/userUtils";
import {Header, Table, TableWrapper} from "./MobileUsers";

function userContainsQuery(user: BusinessUserDto, query: string): boolean {
    return [user.name, user.phoneNumber, user.email].some((value) =>
        value?.trim().toLowerCase().includes(query.trim().toLowerCase())
    );
}

export function WebUsers() {
    const {data} = useGetUsers();
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();
    const intl = useIntl();

    if (data == null) {
        return null;
    }

    const resetSearch = () => {
        setSearchQuery("");
    };

    const users = searchQuery ? data.filter((user) => userContainsQuery(user, searchQuery)) : data;

    return (
        <>
            <p style={{margin: "8px 0"}}>
                <FormattedMessage id="web_users_invite_description" />
            </p>
            <Header>
                <Button data-cy="invite-new-webapp-user-btn" onClick={() => navigate("new-user")}>
                    + <FormattedMessage id="invite" />
                </Button>
                <SearchField
                    {...{searchQuery, setSearchQuery, resetSearch}}
                    placeholder={intl.formatMessage({id: "search"})}
                />
            </Header>
            {searchQuery && users.length === 0 ? (
                <FormattedMessage
                    id="no_web_users_found"
                    values={{
                        searchQuery: searchQuery,
                    }}
                />
            ) : (
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="name" />
                                </th>
                                <th>
                                    <FormattedMessage id="email" />
                                </th>
                                <th>
                                    <FormattedMessage id="phone_number" />
                                </th>
                                <th>
                                    <FormattedMessage id="role" />
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user) => (
                                <tr key={user.userId}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td>{formatRole(user.roles)}</td>
                                    <td style={{padding: "0"}}>
                                        <Link to={`${user.userId}`} style={{padding: "8px"}}>
                                            <svg
                                                width="16"
                                                height="16"
                                                fill="var(--secondary)"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M12.8667 5.95004L10.0333 3.15004L10.9667 2.21671C11.2222 1.96115 11.5361 1.83337 11.9083 1.83337C12.2806 1.83337 12.5944 1.96115 12.85 2.21671L13.7833 3.15004C14.0389 3.4056 14.1722 3.71393 14.1833 4.07504C14.1944 4.43615 14.0722 4.74449 13.8167 5.00004L12.8667 5.95004ZM11.9 6.93337L4.97978 13.8536C4.88601 13.9474 4.75884 14 4.62623 14H2.5C2.22386 14 2 13.7762 2 13.5V11.3738C2 11.2412 2.05268 11.114 2.14645 11.0203L9.06667 4.10004L11.9 6.93337Z" />
                                            </svg>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </TableWrapper>
            )}
            <Outlet />
        </>
    );
}
