import {posthog} from "posthog-js";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {
    BusinessPaymentType,
    useCallerCompany,
    useGetBusinessPaymentInfo,
    useGetCard,
    useInitPaymentCardEntry,
    useRemoveCards,
    useRequestMonthlyPayment,
    useSendFeedback,
    useSetBusinessPaymentType,
} from "../api/apiClient";
import {Button} from "../components/Button";
import {isMonthlyRequestPending} from "../helpers/isMonthlyRequestPending";
import checkIcon from "../img/ic_check.svg";
import editIcon from "../img/ic_edit.svg";
import removeIcon from "../img/ic_remove.svg";
import {cardIcon, formatCard} from "../utils/formatCard";
import {queryClient} from "./App";

export function PaymentMethod() {
    const intl = useIntl();
    const {data: card} = useGetCard();
    const {data: company} = useCallerCompany();
    const {data: paymentInfo} = useGetBusinessPaymentInfo();
    const removeCard = useRemoveCards();
    const addCard = useInitPaymentCardEntry();
    const requestMonthlyPayment = useRequestMonthlyPayment();
    const setPaymentMethod = useSetBusinessPaymentType();
    const sendRequest = useSendFeedback();

    if (card == null || company == null || paymentInfo == null) {
        return null;
    }

    const paymentType: "card" | "invoice" | "none" =
        card && paymentInfo.paymentType === "CARD"
            ? "card"
            : company.tier === "ADVANCED" && paymentInfo.paymentType === "INVOICE"
            ? "invoice"
            : "none";

    function handleAddCard() {
        addCard.mutate(window.location.href, {
            onSuccess: (response) => {
                posthog.capture("paymentSectionCardEntry");
                window.location.href = response.processUrl;
            },
            onError: () => toast.error(intl.formatMessage({id: "generic_error"})),
        });
    }

    function handleRemoveCard() {
        removeCard.mutate(undefined, {
            onSuccess: () => {
                queryClient.invalidateQueries([`/self-service/business/cards`]);
                queryClient.invalidateQueries([`/self-service/businesses/paymentInfo`]);
                toast.success(intl.formatMessage({id: "card_removed"}));
            },
            onError: () => {
                toast.error(intl.formatMessage({id: "generic_error"}));
            },
        });
    }

    function handlePaymentMethod(method: BusinessPaymentType["paymentType"]) {
        setPaymentMethod.mutate(
            {paymentType: method},
            {
                onSuccess: () => {
                    queryClient.invalidateQueries([`/self-service/businesses/paymentInfo`]);
                },
                onError: () => {
                    toast.error(intl.formatMessage({id: "generic_error"}));
                },
            }
        );
    }

    function handleMonthlyPaymentRequest() {
        requestMonthlyPayment.mutate(undefined, {
            onSuccess: () => {
                queryClient.setQueryData([`/self-service/company`], (data: any) => {
                    return {...data, monthlyPaymentRequestedAt: new Date().toISOString};
                });
                queryClient.invalidateQueries([`/self-service/company`]);
                posthog.capture("paymentSectionMonthlyRequest");
                sendRequest.mutate({
                    text: `🙏 Company "${company?.companyName}" just requested monthly payment (invoice) as payment method.`,
                    currentUrl: "payments/monthlyInvoiceRequest",
                    type: "AUTOMATED",
                });
            },
            onError: () => {
                toast.error(intl.formatMessage({id: "generic_error"}));
            },
        });
    }

    return (
        <>
            <h1>
                <FormattedMessage id="payment_method" />
            </h1>
            <PaymentTypes>
                <Type style={paymentType === "card" ? {border: "2px solid var(--primary)"} : {}}>
                    <div>
                        {paymentType === "card" && <CheckImg src={checkIcon} alt="" />}
                        <h2>
                            <FormattedMessage id="payment_card" />
                        </h2>
                        <MethodDescription>
                            <ul>
                                <li>
                                    <FormattedMessage id="payment_card_bullet_1" />
                                </li>
                                <li>
                                    <FormattedMessage id="payment_card_bullet_2" />
                                </li>
                            </ul>
                        </MethodDescription>
                    </div>
                    <div>
                        {card ? (
                            <>
                                {company.disabledInfo.cardExpired ? (
                                    <p
                                        style={{
                                            //FIXME: Create universal Lozenge component in more variants
                                            color: "var(--red)",
                                            borderRadius: "4px",
                                            backgroundColor: "var(--red-light)",
                                            padding: "4px 8px",
                                            marginBottom: "8px",
                                            width: "fit-content",
                                        }}
                                    >
                                        ⚠ <FormattedMessage id="expired_card" />
                                    </p>
                                ) : (
                                    <Divider />
                                )}
                                <CardWrapper>
                                    <div>
                                        <CardIconWrapper>{cardIcon(card.type)}</CardIconWrapper>
                                        {formatCard(card)}
                                    </div>
                                    <Actions>
                                        <Button
                                            variant="link"
                                            onClick={handleAddCard}
                                            disabled={removeCard.isLoading}
                                            loading={addCard.isLoading}
                                        >
                                            <img
                                                src={editIcon}
                                                alt={intl.formatMessage({id: "change"})}
                                            />
                                        </Button>
                                        <Button
                                            variant="link"
                                            onClick={handleRemoveCard}
                                            disabled={addCard.isLoading}
                                            loading={removeCard.isLoading}
                                        >
                                            <img
                                                src={removeIcon}
                                                alt={intl.formatMessage({id: "remove"})}
                                            />
                                        </Button>
                                    </Actions>
                                </CardWrapper>
                                {paymentType === "invoice" && (
                                    <Button
                                        data-cy="switch-to-payment-card-btn"
                                        variant="secondary"
                                        fullWidth
                                        onClick={() => handlePaymentMethod("CARD")}
                                        loading={setPaymentMethod.isLoading}
                                        style={{marginTop: "8px"}}
                                    >
                                        <FormattedMessage id="switch_to_payment_card" />
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button
                                fullWidth
                                onClick={handleAddCard}
                                loading={addCard.isLoading}
                                variant={paymentType === "invoice" ? "secondary" : "primary"}
                            >
                                <FormattedMessage id="add_payment_card" />
                            </Button>
                        )}
                    </div>
                </Type>
                <Type style={paymentType === "invoice" ? {border: "2px solid var(--primary)"} : {}}>
                    <div>
                        {paymentType === "invoice" && <CheckImg src={checkIcon} alt="" />}
                        <h2>
                            <FormattedMessage id="monthly_payment" />
                        </h2>
                        <MethodDescription>
                            <ul>
                                <li>
                                    <FormattedMessage id="monthly_payment_bullet_1" />
                                </li>
                                <li>
                                    <FormattedMessage id="monthly_payment_bullet_2" />
                                </li>
                            </ul>
                        </MethodDescription>
                    </div>
                    <div>
                        {paymentType === "invoice" ? (
                            <>
                                <Divider />
                                <InvoiceType>
                                    <FormattedMessage id="monthly_invoicing_with_14_days_due_date" />
                                </InvoiceType>
                            </>
                        ) : company.tier === "BASIC" ? (
                            isMonthlyRequestPending(company.monthlyPaymentRequestedAt) ? (
                                <>
                                    <Divider />
                                    <InvoiceRequested>
                                        <FormattedMessage id="request_received" />
                                    </InvoiceRequested>
                                </>
                            ) : (
                                <Button
                                    variant="secondary"
                                    fullWidth
                                    onClick={handleMonthlyPaymentRequest}
                                    loading={requestMonthlyPayment.isLoading}
                                >
                                    <FormattedMessage id="request_monthly_payment" />
                                </Button>
                            )
                        ) : (
                            <Button
                                data-cy="switch-to-monthly-payment-btn"
                                variant="secondary"
                                fullWidth
                                onClick={() => handlePaymentMethod("INVOICE")}
                                loading={setPaymentMethod.isLoading}
                            >
                                <FormattedMessage id="switch_to_monthly_payment" />
                            </Button>
                        )}
                    </div>
                </Type>
            </PaymentTypes>
        </>
    );
}

const PaymentTypes = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    align-items: stretch;
    & > * {
        flex: 1 0 min(200px);
    }
`;

const Type = styled.div`
    padding: var(--card-padding);
    border-radius: var(--border-radius-large);
    border: 0.5px solid var(--quaternary);
    background-color: var(--white);
    box-shadow: 0px 1px 0px 0px rgba(237, 237, 237, 1);
    position: relative;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const InvoiceType = styled.div`
    color: var(--secondary);
    font-weight: var(--font-weight-thin);
`;

const InvoiceRequested = styled.div`
    color: var(--green);
    font-weight: var(--font-weight-thin);
`;

const CheckImg = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
`;

const CardWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

const MethodDescription = styled.p`
    margin: 8px 0 16px 0;
    line-height: 1.4;
    font-weight: var(--font-weight-thin);
`;

const CardIconWrapper = styled.div`
    padding: 2px 8px;
    background-color: var(--light);
    border-radius: 2px;
    width: max-content;
    display: inline-block;
    margin-right: 8px;
`;

const Divider = styled.div`
    height: 1px;
    margin: 8px 0 16px;
    background-color: var(--divider);
`;
