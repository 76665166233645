export const messages = {
    en: {
        ok: "OK",
        taxi_nav: "Taxi",
        delivery_nav: "Delivery",
        required_email: "Enter email address",
        required_password: "Enter password",
        invalid_email: "Enter valid email address",
        login_failed: "Incorrect email or password",
        login: "Sign in",
        logout: "Sign out",
        email: "Email",
        password: "Password",
        create_order: "Create order",
        upload_excel: "or upload XLSX",
        place_order_by_uploading: "Place order by uploading XLSX file",
        download: "Download",
        sample_template: "a sample XLSX template",
        choose_excel_file: "Choose a XLSX file",
        rides: "Rides",
        ride: "Ride",
        sender_name: "Sender's name (company)",
        recipient_name: "Recipient's name (company)",
        stop_name: "Person's name (company)",
        fallback_name: "Person's name (company)",
        your_name: "Your name",
        phone_number: "Phone number",
        pickup_location: "Pickup address",
        drop_off_location: "Drop-off address",
        stop_location: "Stop address",
        destination_location: "Destination address",
        return_address: "Return address",
        address: "Address",
        pickup: "Pickup",
        add_pickup: "Add pickup",
        pickup_and_return: "Pickup and return",
        delivery: "Delivery",
        optional: "optional",
        message_to_driver: "Message to driver",
        add_stop: "Add stop",
        remove_stop: "Remove stop",
        add_drop_off: "Add another dropoff",
        remove_drop_off: "Remove dropoff",
        place_order: "Place order",
        required: "Required",
        invalid_flight_number: "Must start with at least one letter followed by numbers",
        too_short: "Enter at least 2 characters",
        too_long: "Enter up to 300 characters",
        invalid_phone_number: "Enter valid phone number",
        invalid_driver_id: "Enter the numeric driver ID",
        order_failed: "Order failed. Check the information you entered and try again.",
        order_failed_title: "Order failed",
        order_failed_generic: "Check the information you entered and try again.",
        order_failed_phone_number_validation_exception: "Check the phone numbers.",
        order_failed_validation_exception: "Check the information you entered.",
        order_failed_location_validation_exception: "Check the addresses.",
        order_failed_incompatible_state_exception: "Try again later.",
        order_failed_project_code_required: "Enter project code.",
        order_failed_legs_cant_be_delivered_on_time:
            "The route cannot be completed in the requested time. Contact us at service@liftago.com to change the settings.",
        order_failed_legs_cant_be_delivered_in_limit:
            "The route cannot be completed within the allowed time limit. Contact us at service@liftago.com to change the settings.",
        order_failed_too_many_stops:
            "The route has more stops than you are allowed. Contact us at service@liftago.com to change the settings.",
        order_failed_pickup_and_drop_off_at_the_same_location:
            "Pickup and drop-off address cannot be the same.",
        order_failed_pre_order_sooner_than_allowed:
            "The pickup time is too soon. Choose later time and order again.",
        order_failed_pre_order_later_than_allowed:
            "The pickup time is too far in future. Choose earlier time and order again",
        order_failed_invalid_time_interval: "Choose later time interval and order again.",
        order_failed_pre_order_in_past: "The pickup time must be in the future.",
        pre_order_not_updatable: "Unable to update. Verify the information and try again.",
        order_failed_ride_distance_longer_than_allowed: "Route is too long.",
        order_failed_unsupported_region: "Some stops are outside the supported region.",
        order_failed_driver_not_found: "Driver ID does not exist.",
        order_failed_invalid_price_token: "The price was not valid, please try again.",
        order_failed_flight_number_not_found:
            "Your flight number can't be found for selected date.",
        order_failed_flight_not_in_future: "Flight has already landed",
        order_created: "Order created",
        pre_order_sooner_than_allowed:
            "The pickup time is too soon. Choose later time and try again.",
        pre_order_later_than_allowed:
            "The pickup time is too far in future. Choose earlier time and try again",
        pre_order_in_past: "The pickup time must be in the future.",
        return_undelivered_packages: "Return undelivered package",
        shipment_plural: "{count, plural, one {shipment} other {shipments}}",
        CZK: "CZK",
        EUR: "€",
        delivery_time: "Delivery time",
        today_asap: "Today ASAP",
        asap: "As soon as possible",
        today: "Today",
        tomorrow: "Tomorrow",
        scheduled: "Scheduled ",
        scheduling: "Scheduling",
        in_searching: "Requesting driver",
        en_route: "En route",
        delivering: "Delivering",
        storing: "Storing",
        stored: "Stored",
        on_board: "On board",
        returning: "Returning",
        in: "in",
        soon: "soon",
        in_min_plural: "in {count} {count, plural, =0 {min} one {min} few {mins} other {mins}}",
        waiting_at_pickup: "Waiting at pickup",
        finished: "Finished",
        cancelled: "Cancelled",
        cancelled_by_driver: "Cancelled by driver",
        driver_not_found: "Driver not found",
        order: "Order",
        rides_list: "Rides list",
        in_progress: "In progress",
        upcoming: "Upcoming",
        completed: "Completed",
        no_rides_in_progress: "No rides in progress",
        no_rides_upcoming: "No upcoming rides",
        no_rides_completed: "No rides completed",
        arriving: "Arriving",
        arrived: "Arrived",
        eta: "ETA",
        returned: "Returned",
        undelivered: "Undelivered",
        delivered: "Delivered",
        picked_up: "Picked up",
        not_picked_up: "Not picked up",
        color_black: "Black",
        color_blue: "Blue",
        color_brown: "Brown",
        color_green: "Green",
        color_orange: "Orange",
        color_purple: "Purple",
        color_red: "Red",
        color_silver: "Silver",
        color_white: "White",
        color_yellow: "Yellow",
        status: "Status",
        recipient: "Recipient",
        sender: "Sender",
        drop_off: "Drop-off",
        driver: "Driver",
        vehicle: "Vehicle",
        driver_location: "Track driver location",
        sending: "Sending…",
        incomplete_address: "Address is not complete",
        cancel_stop: "Cancel stop",
        cancel: "Cancel",
        cancel_ride: "Cancel the ride",
        canceling: "Cancelling…",
        cancel_failed: "Cancel failed",
        ride_cancelled: "Ride cancelled",
        stop_cancelled: "Stop cancelled",
        download_failed: "Download failed",
        about_info: "Package delivery for a fixed price in requested time",
        passenger: "Passenger",
        passengers_few: "{count} passengers",
        name: "Name",
        route: "Route",
        destination: "Destination",
        ride_for: "Ride for {name}",
        paid_by_company: "Company pays",
        paid_by_pas_cash: "Passenger pays in cash",
        paid_by_pas_credit_card: "Passenger pays by card",
        car_type_all: "All available cars",
        car_type_premium: "Only premium cars",
        reporting_info: "Reporting info",
        project_code: "Project code",
        cost_center: "Cost center",
        internal_note: "Internal note",
        price_estimate: "Price Estimate",
        pickup_time_estimate: "Pickup in ~{pickupTime} min",
        feedback: "Feedback",
        feedback_title: "Let us know what we can improve",
        feedback_placeholder: "Bugs, ideas and any other suggestions",
        send_feedback: "Send feedback",
        thank_you: "Thank you",
        something_went_wrong: "Something went wrong",
        new: "New",
        new_taxi_app: "Welcome to the new taxi app",
        whats_new: "See what's new",
        whats_new_taxi_first: "App chooses the best driver for you",
        whats_new_taxi_second: "Estimate for price and pickup time",
        whats_new_taxi_third: "Choose from last used project codes and cost centers",
        whats_new_taxi_fourth: "Search in the rides list",
        new_delivery_app: "Welcome to the new delivery app",
        whats_new_delivery_first: "App chooses the best driver for you",
        whats_new_delivery_second: "You see the final price before order",
        whats_new_delivery_third: "Message to driver for pickup and dropoff as well",
        whats_new_delivery_fourth: "Search in the rides list",
        dropoff_time: "Dropoff time",
        pickup_time: "Pickup time",
        pickup_time_now: "Now",
        pickup_time_15_min: "15 min",
        pickup_time_later: "Later",
        later_recurring: "Schedule pickup",
        select_one_or_more_days: "Select one or more days",
        select_dates: "Select date(s)",
        date_or_more_days: "Date or more days",
        time: "Time",
        flight_number: "Flight number",
        flight_number_info:
            "The driver will be ready to pick up the passenger 15 minutes after the flight lands. We monitor the flight status in real-time.",
        preorder_disabled_route_not_set:
            "Enter the pickup address and destination to check availability",
        preorder_disabled_not_available:
            "Not available for your pickup place and {tierName} category",
        preorder_disabled_short_ride:
            "Not available for {tierName} category on route shorter than {kmDistance} km",
        preorder_disabled_destination: "Not available for your destination and {tierName} category",
        preorder_disabled_tier: "Not available for {tierName} category",
        stop_id: "Stop ID",
        taxi_search_placeholder: "Name, phone, address, reporting…",
        delivery_search_placeholder: "Name, phone, address or ID",
        no_rides_found: "No recent rides found for „{searchQuery}“",
        no_mobile_users_found: "No mobile app users found for „{searchQuery}“",
        no_web_users_found: "No web app users found for „{searchQuery}“",
        estimated_price: "Estimated price",
        fixed_price: "Fixed price",
        payment_method: "Payment method",
        payment_company: "Company",
        payment_passenger_by_card: "Passenger by card",
        payment_passenger_in_cash: "Passenger in cash",
        specific_driver_id: "Specific driver ID",
        driver_id: "Driver ID",
        company_payment_not_allowed:
            "You are not allowed to order on the expense of company {companyName}. To change the settings, contact us at <a>sales@liftago.com</a>.",
        filter: "Filter…",
        all: "All",
        filter_by_pickup: "Pickup place",
        forgot_password: "Forgot password",
        continue: "Continue",
        add_orderer_role_headline: "Enter your contact details to start ordering",
        generic_error: "Something went wrong. Please try again later.",
        company_not_approved_yet: "Your business account is not approved yet.",
        company_disabled:
            "Your business account is currently suspended. Please contact us at <a>sales@liftago.com</a> to activate again.",
        company_not_active:
            "Your business account is currently not active. For more information contact us at <a>sales@liftago.com</a>.",
        drop_off_taxi_eta: "drop-off",
        pickup_taxi_eta: "pickup",
        drop_off_delivery_eta: "Drop-off",
        no_address_found: "No address found",
        help: "Help",
        customer_support: "Customer support",
        faq: "Frequently asked questions",
        faq_url: "https://www.liftago.cz/en/support?category=Business",
        administration: "Administration",
        stops: "Stops",
        unknown_arrival: "Unknown arrival",
        one_way: "One way",
        round_trip: "Round trip",
        repeat_order: "Prefill new order",
        repeat_order_warning: "The form will be overwritten",
        repeat_order_error: "Something went wrong. Try again or order via form.",
        form_prefilled: "Form prefilled",
        premium_preorder_disclaimer:
            "If a premium vehicle will be unavailable, we'll arrange a standard vehicle for you.",
        airport_tier_disclaimer: "Enter the flight number in the order form",
        invalid_vat_id:
            "VAT ID has to have format of 'XXYYYYYYYYYY', where XX is 2-letter country code and Y's are digits",
        password_min_characters: "Password must be at least 8 characters",
        email_already_taken:
            "This email already exists in another business account. Please enter another email.",
        AD: "Andorra",
        AT: "Austria",
        AU: "Australia",
        BE: "Belgium",
        BG: "Bulgaria",
        BR: "Brazil",
        CA: "Canada",
        CH: "Switzerland",
        CN: "China",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DE: "Germany",
        DK: "Denmark",
        EE: "Estonia",
        ES: "Spain",
        FI: "Finland",
        FR: "France",
        GB: "United Kingdom",
        GR: "Greece",
        HK: "Hongkong",
        HR: "Croatia",
        HU: "Hungary",
        ID: "Indonesia",
        IE: "Ireland",
        IS: "Iceland",
        IT: "Italy",
        JP: "Japan",
        KR: "South Korea",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        LV: "Latvia",
        MC: "Monaco",
        ME: "Montenegro",
        MT: "Malta",
        MX: "Mexico",
        MY: "Malaysia",
        NL: "Netherland",
        NO: "Norway",
        NZ: "New Zealand",
        PL: "Poland",
        PT: "Portugal",
        RO: "Romania",
        RU: "Russian Federation",
        SE: "Sweden",
        SG: "Singapore",
        SI: "Slovenia",
        SK: "Slovakia",
        SM: "San Marino",
        TH: "Thailand",
        TR: "Turkey",
        US: "United States of America",
        ZA: "South Africa",
        stop_limit_reached: "(reached the limit)",
        express: "Express",
        time_window: "Time window",
        express_tooltip: "Courier will deliver your order directly as ordered. Package max. 15 kg.",
        express_max_weight: "max. 15 kg",
        time_window_tooltip:
            "Pickup and drop-off will be in selected time window. Courier might be delivering some other orders as well. Package max. 15 kg, 55×45×25 cm",
        time_window_max_weight: "max. 15 kg, 55×45×25 cm",
        slot_disclaimer_not_available: "Currently not available for your route",
        slot_disclaimer_roundtrip: "Not available for Round trip",
        delivery_tiers_route_disclaimer: "Enter addresses to check prices and availability",
        paid: "Paid",
        payment_pending: "Pending",
        payment_overdue: "Overdue",
        taxi: "Taxi",
        shipment: "Shipment",
        invoice: "Invoice",
        summary: "Summary",
        add_payment_card: "Add payment card",
        remove_payment_card: "Remove card",
        save: "Save",
        users: "Users",
        settings: "Settings",
        billing: "Billing",
        payments_and_billing: "Payments and billing",
        account_details: "Account details",
        company_display_name: "Company display name",
        company_display_name_description:
            "Will be used in Liftago apps and other communication channels for your users and customers",
        main_contact: "Main contact",
        main_contact_description: "Main contact if our team will need to reach you",
        email_addresses: "Email addresses",
        email_addresses_description:
            "Additional email addresses. We send all information to admins as well. More addresses separate with comma.",
        billing_email: "Billing email for invoices and summaries",
        news_email: "Email for product updates and new features",
        billing_details_description: "Make sure your billing details are correct and up to date",
        business_account_not_active: "Business account is not active",
        business_account_not_active_description:
            "Choose payment method to activate and start using the business account.",
        expired_card_headline: "Your payment card expired",
        expired_card_text: "Please add new payment card to activate your account again.",
        expired_card: "Expired card",
        pay: "Pay",
        show_all: "Show all",
        summary_password: "Summaries password",
        summary_password_tooltip:
            "Use this password to open summaries files sent by email every month",
        loading: "Loading…",
        downloading: "Downloading",
        unable_to_load: "Unable to load",
        load_details: "Load details",
        project_codes: "Project codes",
        project_codes_text:
            "The codes are displayed in monthly summary. You can use them to easily sort the orders.",
        dont_use_project_codes: "Don't use",
        optional_project_codes: "Optionally before each order",
        mandatory_project_codes: "Mandatory before each order",
        saved: "Saved",
        payment_failed: "Payment failed",
        reset_password: "Reset password",
        reset_password_explanation:
            "To reset your password, please enter email address you use for login. You will receive further instructions by email",
        back_to_login: "Back to login",
        email_sent: "Reset password link sent, please check your inbox",
        new_password_failure: "Request for password change already expired.",
        request_new_password_again: "Request password change again",
        new_password: "New password",
        confirm_password: "Confirm password",
        confirm_password_error: "Password doesn't match",
        pasword_changed: "Password successfully changed",
        email_not_found: "This email address doesn't match any Liftago account",
        orderer: "Booker",
        admin: "Admin",
        role: "Role",
        edit: "Edit",
        web_app_users_banner_description: "Create orders and manage your business account",
        invite: "Invite",
        invite_web_user: "Invite to web app",
        mobile_app_users_banner_description:
            "All services for your team members with simple management costs",
        invite_mobile_user: "Invite to mobile app",
        mobile_users_invite_description:
            "Users can access company account in <a>Liftago mobile app</a> after sign in with phone number. We will send invitation SMS. They can choose personal or business account before each order.",
        mobile_app_url: "https://www.liftago.com/app/",
        web_users_invite_description:
            "Users will receive email invitation to web app. Admins have full access to the business account and ordering. Bookers can only order.",
        invite_user: "Invite user",
        remove_user: "Remove user",
        edit_user: "Edit user",
        joined: "Joined",
        invited: "Invited",
        user_removed: "User removed",
        user_added: "Used added",
        cant_load_user: "Can't load user",
        user_doesnt_exists: "User doesn't exists",
        search: "Search…",
        monthly_limit: "Monthly limit",
        payment_type: "Payment",
        give_access_to_your_team: "Give access to your team",
        mobile_app: "Mobile app",
        web_app: "Web app",
        payment_originator_company: "Group",
        payment_originator_employee: "Individual",
        enter_numerical_amount: "Enter whole numbers only",
        amount_greater_than_zero: "Amount must be greater than 0 or none",
        mobile_user_already_invited:
            "User with given phone number is already part of your business account",
        mobile_user_already_in_other_business:
            "User with given phone number is already part of another business account",
        delete: "Delete",
        no_limit: "No limit",
        cannot_remove_last_admin:
            "User can't be removed. Business account must have at least one admin.",
        do_you_want_to_create_new_business_account: "Do you want to create a new business account?",
        already_logged_in_as: "Already logged in as",
        already_have_an_account: "Already have an account?",
        payment_card: "Card payment",
        payment_card_bullet_1: "Automatic payment after each ride",
        payment_card_bullet_2: "Summary invoice at the end of the month",
        change: "Change",
        remove: "Remove",
        card_removed: "Card removed",
        switch_to_payment_card: "Switch to payment card",
        monthly_payment: "Bank transfer payment",
        monthly_payment_bullet_1: "Monthly payment via bank transfer",
        monthly_payment_bullet_2: "Summary invoice at the end of the month",
        request_monthly_payment: "Request monthly payment",
        switch_to_monthly_payment: "Switch to monthly payment",
        monthly_invoicing_with_14_days_due_date: "Monthly invoicing with 14 day due date",
        request_received: "Request received. Expect a response within one business day.",
        billing_details: "Billing details",
        vat_payer: "VAT payer",
        vat_non_payer: "VAT non-payer",
        start_ordering: "Start ordering",
        sign_in_to_business_account: "Sign in to business account",
        create_new_business_account: "Create new business account",
        dont_have_account_yet: "Don't have an account yet?",
        monthly_payment_request_pending:
            "We received your request for monthly payment. Expect a response within one business day. For immediate activation, please <link>enter payment card</link> and start ordering right away.",
        choose_payment_method_action: "Choose payment method",
        choose_payment_method: "Choose payment method",
        choose_payment_method_subtitle:
            "Business account is free. You only pay for Taxi and Delivery services.",
        approval_within_one_business_day: "Approval within 1 business day",
        immediate_activation: "Immediate activation",
        skip_now: "Skip now",
        payment_card_disclaimer:
            "We'll authorize a payment of 1 CZK and refund it within a few days. Card details are safely stored with a certified provider ČSOB. Liftago doesn't have access to card details.",
        card_added_successfully: "Card added successfully",
        failed_to_add_card: "Failed to add card",
        close: "Close",
        payment_method_needed_to_invite_mobile_app_users:
            "You must have an active payment method to invite users to the mobile app.",
        billing_details_not_found: "We didn't found entered TAX ID in company register",
        create_business_account: "Create business account",
        create_business_account_subtitle:
            "Save time with Liftago business account. No fees, zero paperwork.",
        login_credentials: "Login credentials",
        contact_details: "Contact details",
        tax_id: "Company ID (IČO)",
        vat_id: "VAT ID",
        tax_id_not_found: "Company ID can't be found in company register",
        tax_id_already_registred:
            "Business account with this ID (IČO) already exists. To join the existing account, please contact your administrator.",
        use_anyway: "Use anyway",
        company_name: "Company name",
        street: "Street",
        city: "City",
        zip_code: "Zip code",
        country: "Country",
        create_account: "Create account",
        vat_payer_warning:
            "If you're not a VAT payer, prices will increase by 21%. We suggest using our <a>mobile app</a>. You will receive electronic receipts after each ride.",
        min_8_chars: "Minimum 8 characters",
        access_to_others: "Later you can give access to others as well",
        registration_failed:
            "Creating account failed. Please try again or contact us at service@liftago.com",
        email_is_already_admin_in_existing_account:
            "Email {email} is already admin in existing account. Continue to login.",
        TIER_DEFAULT_name: "Liftago",
        TIER_DEFAULT_description: "All available cars",
        TIER_PREMIUM_name: "Premium",
        TIER_PREMIUM_description: "Higher-class cars preferred",
        TIER_KIDS_name: "Kids",
        TIER_KIDS_description: "1 car seat 0-36kg, 1 booster seat",
        TIER_STANDARD_name: "Standard",
        TIER_STANDARD_description: "Compact cars preferred",
        TIER_ECO_FRIENDLY_name: "Eco",
        TIER_ECO_FRIENDLY_description: "Electric and hybrid cars preferred",
        TIER_AIRPORT_TRANSFER_name: "Airport pick-up",
        TIER_AIRPORT_TRANSFER_description: "Pick up in the arrivals hall and escort to the car",
        TIER_XL_name: "XL",
        TIER_XL_description: "Cars for 6 passengers",
        car_category: "Car category",
        busy: "busy",
        busy_info: "Unavailable due to increased demand, please select another time.",
        cant_schedule: "Can't schedule",
        registration_legal_info:
            "By continuing you agree with <terms>General Business Terms and Conditions</terms>. Personal data will be processed for the purpose stated in <policy>Privacy Policy</policy>",
        terms_url: "https://www.liftago.com/general-terms-and-conditions/",
        policy_url: "https://www.liftago.com/privacy-policy/",
        need_help: "Need help? Call us.",
        need_help_email: "Need help? Contact us at <a>sales@liftago.com</a>",
        reply_withing_24_hours: "We'll reply within 24 hours",
        back: "Back",
        register_usp_1_title: "Reliable taxi and courier",
        register_usp_1_text:
            "Verified and licensed drivers who know the city. All rides and shipments are insured.",
        register_usp_2_title: "User-friendly app",
        register_usp_2_text:
            "Simple mobile and web app. Manage your entire team and their expenses with ease.",
        register_usp_3_title: "Customer support",
        register_usp_3_text:
            "Your questions, ideas, and suggestions are addressed even on weekends and holidays.",
        register_usp_4_title: "No commitments",
        register_usp_4_text: "Use Liftago without fees, notice periods, or order minimums.",
        register_usp_5_title: "Zero paperwork",
        register_usp_5_text:
            "All company rides and courier services are on one invoice. No more receipts.",
        join_happy_customers: "Join thousands of happy customers",
        tip_for_driver: "Tip for driver",
        tip_the_driver: "Tip the driver",
        enter_the_tip: "Enter the tip",
        custom: "Custom",
        tip_payment_success: "Tip sent",
        tip_payment_failed: "Tip payment failed",
        tip_sent_to_the_driver: "Tip {tip} sent to the driver",
        rate_the_ride: "Rate the ride",
        rate_the_delivery: "Rate the delivery",
        max_tip_is: "Maximum tip is",
        change_pickup_time: "Change pickup time",
        change_pickup_or_flight_number: "Change date or flight number",
        set_pickup_time: "Set pickup time",
        pickup_time_changed: "Pickup time changed",
        in_bulk_csv: "In bulk (.csv)",
        something_went_wrong_with_parsing_csv_file: "Something went wrong with parsing .csv file",
        mobile_app_users_bulk_update: "Mobile app users bulk update",
        download_current_csv_mobile_users:
            "Download <a>current users</a> in .csv and upload back the updated file. Name and phone number are required.",
        download_sample_csv_mobile_users:
            "Download <a>template</a> and upload back the updated file. Name and phone number are required.",
        select_file: "Select file",
        or_drop_file_here: "or drop file here",
        drop_the_file_here: "Drop the file here...",
        only_csv_file_is_supported: "Only .csv file is supported",
        add: "Přidat",
        modify: "Modify",
        keep: "Keep",
        all_users_already_in_your_company: "All users in .csv file already in you company",
        there_is_invalid_data_in_users_table:
            "There is some invalid data in uploaded file. Please fix them here or in .csv file and upload again.",
        confirm_users_changes:
            "Confirm user changes ({add} add · {modify} modify · {remove} remove)",
        users_updated_successfully: "Users updated successfully",
        users_updated_partially: "Users updated partailly",
        users_updated_counts: "{add} added  ·  {modify} modified  ·  {remove} removed",
        go_to_invalid_data: "Go to invalid data",
        bulk_users_failed_partially_in_another_company:
            "Users in table can't be invited as they are already part of another company.",
        bulk_users_failed_partially:
            "Users in table weren't updated. Check the data and try again.",
        bulk_users_failed_invited_in_another_company:
            "Following users are already part of another company",
        bulk_users_failed: "Something went wrong when updating. Check the data and try again.",
        download_sample_csv_dropoffs:
            "Download <a>.csv template</a> and upload back the completed file.",
        upload_csv: "Upload .csv",
        upload_more_dropoffs_in_csv_file: "Upload more drop-offs in .csv file",
        cant_find_following_addresses:
            "Can't find following addresses. Try to fix them and upload file again.",
        cant_upload_empty_file: "Can't upload empty file",
        maximum_dropoffs_reached: "You can upload a maximum of {count} drop-offs",
        time_window_not_available_for_some_stops:
            "Can't order time window to drop-off address no. {stops}.",
        are_you_a_driver: "Are you a driver?",
        register_as_driver: "Register as driver",
        or: "Or",
        available_for_up_to_dropoff_count: "Available for up to {count} drop-offs",
        file_cant_contain_duplicit_phone_numbers:
            "The file cannot contain duplicate phone numbers. Please remove the following duplicates and upload the file again. \n Duplicate numbers",
        copied: "Copied",
        later_only: "Later only",
        later_only_tooltip:
            "{tierName} can currently be ordered for pickup starting in {days, plural, =0 {} one {{days} day} few {{days} days} other {{days} days}} {hours, plural, =0 {} one {{hours} hour} few {{hours} hours} other {{hours} hours}} {minutes, plural, =0 {} one {{minutes} minute} few {{minutes} minutes} other {{minutes} minutes}}. Select 'Later' pickup time.",
        remove_after: "Remove after",
        date_must_be_in_the_future: "Date must be in the future",
        soft_update_title: "You have outdated version",
        soft_update_subtitle: "Please click here or reload the page to get the latest version",
        you_dont_have_permissions_to_access_section:
            "You don't have permissions to access this section",
    },
    cs: {
        ok: "OK",
        taxi_nav: "Taxi",
        delivery_nav: "Zásilky",
        required_email: "Zadejte emailovou adresu",
        required_password: "Zadejte heslo",
        invalid_email: "Zadejte platnou emailovou adresu",
        login_failed: "Nesprávný email nebo heslo",
        login: "Přihlásit se",
        logout: "Odhlásit se",
        email: "Email",
        password: "Heslo",
        create_order: "Vytvořte objednávku",
        upload_excel: "nebo nahrajte XLSX",
        place_order_by_uploading: "Objednejte nahráním XLSX souboru",
        download: "Stáhnout",
        sample_template: "vzorovou XLSX šablonu",
        choose_excel_file: "Vyberte XLSX soubor",
        rides: "Jízdy",
        ride: "Jízda",
        sender_name: "Jméno odesílatele (firma)",
        recipient_name: "Jméno příjemce (firma)",
        stop_name: "Jméno osoby (firma)",
        fallback_name: "Jméno osoby (firma)",
        your_name: "Vaše jméno",
        phone_number: "Telefonní číslo",
        pickup_location: "Adresa vyzvednutí",
        drop_off_location: "Adresa doručení",
        stop_location: "Adresa zastávky",
        destination_location: "Cílová adresa",
        return_address: "Adresa vrácení", // TODO: check translation
        address: "Adresa",
        pickup: "Vyzvednutí",
        add_pickup: "Přidat vyzvednutí",
        pickup_and_return: "Vyzvednutí a vrácení",
        delivery: "Doručení",
        optional: "nepovinné",
        message_to_driver: "Poznámka pro řidiče",
        add_stop: "Přidat zastávku",
        remove_stop: "Smazat zastávku",
        add_drop_off: "Přidat další doručení",
        remove_drop_off: "Smazat doručení",
        place_order: "Objednat",
        required: "Údaj je povinný",
        invalid_flight_number: "Musí začínat alespoň jedním písmenem a následovat číslicemi",
        too_short: "Zadejte alespoň 2 znaky",
        too_long: "Zadejte maximálně 300 znaků",
        invalid_phone_number: "Zadejte platné telefonní číslo",
        invalid_driver_id: "Zadejte číselné ID řidiče",
        order_failed: "Objednávka se nezdařila. Zkontrolujte zadané údaje a zkuste to znovu.",
        order_failed_title: "Objednávka se nezdařila",
        order_failed_generic: "Zkontrolujte zadané údaje a zkuste to znovu.",
        order_failed_phone_number_validation_exception: "Zkontrolujte zadané telefonní čísla.",
        order_failed_validation_exception: "Zkontrolujte zadané údaje.",
        order_failed_location_validation_exception: "Zkontrolujte zadané adresy.",
        order_failed_incompatible_state_exception: "Zkuste to později znovu.",
        order_failed_project_code_required: "Zadejte projektový kód.",
        order_failed_legs_cant_be_delivered_on_time:
            "Trasu nelze dokončit v požadovaném čase. Napište na service@liftago.com pro změnu nastavení.",
        order_failed_legs_cant_be_delivered_in_limit:
            "Trasu nelze dokončit v povoleném časovém limitu. Napište na service@liftago.com pro změnu nastavení.",
        order_failed_too_many_stops:
            "Trasa má více zastávek než máte povolené. Napište na service@liftago.com pro změnu nastavení.",
        order_failed_pickup_and_drop_off_at_the_same_location:
            "Adresa vyzvednutí a doručení nemůže být shodná.",
        order_failed_pre_order_sooner_than_allowed:
            "Čas vyzvednutí je příliš brzy. Vyberte pozdější čas a objednejte znovu.",
        order_failed_pre_order_later_than_allowed:
            "Čas vyzvednutí je příliš vzdálený. Vyberte dřívější čas a objednejte znovu.",
        order_failed_invalid_time_interval: "Vyberte pozdější časový interval a objednejte znovu.",
        order_failed_pre_order_in_past: "Čas vyzvednutí musí být v budoucnosti.",
        pre_order_not_updatable: "Objednávka nelze aktualizovat, zkontrolujte správnost údajů.",
        order_failed_ride_distance_longer_than_allowed: "Trasa je příliš dlouhá.",
        order_failed_unsupported_region: "Některé zastávky jsou mimo podporovaný region.",
        order_failed_driver_not_found: "ID řidiče neexistuje",
        order_failed_invalid_price_token: "Cena nebyla aktuální, zkuste to prosím znovu.",
        order_failed_flight_number_not_found: "Číslo letu v zadaném datu nebylo nalezeno.",
        order_failed_flight_not_in_future: "Zadaný let již přistál.",
        order_created: "Objednávka vytvořena",
        pre_order_sooner_than_allowed:
            "Čas vyzvednutí je příliš brzy. Vyberte pozdější čas a zkuste to znovu.",
        pre_order_later_than_allowed:
            "Čas vyzvednutí je příliš vzdálený. Vyberte dřívější čas a zkuste to znovu.",
        pre_order_in_past: "Čas vyzvednutí musí být v budoucnosti.",
        return_undelivered_packages: "Vrátit nedoručené zásilky",
        shipment_plural: "{count, plural, one {zásilku} few {zásilky} other {zásilek}}", // TODO: check translation //FIXME sklonovani 1 zásilka, 1 zásilku podle kontextu: sumář.. Zrušit 1 zásilku
        CZK: "Kč",
        EUR: "€",
        delivery_time: "Čas doručení",
        today_asap: "Dnes co nejdřív",
        asap: "Co nejdříve",
        today: "Dnes",
        tomorrow: "Zítra",
        scheduled: "Naplánováno",
        scheduling: "Plánování",
        in_searching: "Poptáváme řidiče",
        en_route: "Na cestě",
        delivering: "Doručování",
        storing: "Uložení",
        stored: "Uloženo",
        on_board: "Ve voze",
        returning: "Vrácení",
        in: "za",
        soon: "brzy",
        in_min_plural: "za {count} min",
        waiting_at_pickup: "Na místě vyzvednutí",
        finished: "Dokončeno",
        cancelled: "Zrušeno",
        cancelled_by_driver: "Zrušeno řidičem",
        driver_not_found: "Řidič nenalezen",
        order: "Objednávka",
        rides_list: "Seznam jízd",
        in_progress: "Probíhající",
        upcoming: "Nadcházející",
        completed: "Dokončené",
        no_rides_in_progress: "Žádné probíhající jízdy",
        no_rides_upcoming: "Žádné nadcházející jízdy",
        no_rides_completed: "Žádné dokončené jízdy",
        arriving: "Příjezd",
        arrived: "Na místě",
        eta: "Odhad",
        returned: "Vráceno",
        undelivered: "Nedoručeno",
        delivered: "Doručeno",
        picked_up: "Vyzvednuto",
        not_picked_up: "Nevyzvednuto",
        color_black: "Černá barva,",
        color_blue: "Modrá barva,",
        color_brown: "Hnědá barva,",
        color_green: "Zelená barva,",
        color_orange: "Oranžová barva,",
        color_purple: "Fialová barva,",
        color_red: "Červená barva,",
        color_silver: "Stříbrná barva,",
        color_white: "Bílá barva,",
        color_yellow: "Žlutá barva,",
        status: "Stav",
        recipient: "Příjemce",
        sender: "Odesílatel",
        drop_off: "Doručení",
        driver: "Řidič",
        vehicle: "Vůz",
        driver_location: "Sledovat polohu řidiče",
        sending: "Odesílání…",
        incomplete_address: "Adresa není kompletní",
        cancel_stop: "Zrušit zastávku",
        cancel: "Zrušit",
        cancel_ride: "Zrušit jízdu",
        canceling: "Ruší se…",
        cancel_failed: "Zrušení selhalo",
        ride_cancelled: "Jizda zrušena",
        stop_cancelled: "Zastávka zrušena",
        download_failed: "Stažení se nezdařilo",
        about_info: "Přeprava zásilek za fixní cenu v požadovaném čase",
        passenger: "Pasažér",
        passengers_few: "{count} pasažéři",
        name: "Jméno",
        route: "Cesta",
        destination: "Cíl",
        ride_for: "Jízda pro {name}",
        paid_by_company: "Platí firma",
        paid_by_pas_cash: "Platí pasažér v hotovosti",
        paid_by_pas_credit_card: "Platí pasažér kartou",
        car_type_all: "Všechny dostupné vozy",
        car_type_premium: "Pouze premium vozy",
        reporting_info: "Reporting",
        project_code: "Projektový kód",
        cost_center: "Nákladové středisko",
        internal_note: "Interní poznámka",
        price_estimate: "Odhad ceny",
        pickup_time_estimate: "Vyzvednutí za ~{pickupTime} min",
        feedback: "Zpětná vazba",
        feedback_title: "Dejte nám vědět, co můžeme zlepšit",
        feedback_placeholder: "Chyby, nápady a jakékoliv další podněty",
        send_feedback: "Poslat",
        thank_you: "Děkujeme",
        something_went_wrong: "Došlo k chybě",
        new: "Nově",
        new_taxi_app: "Vítejte v nové taxi aplikaci",
        whats_new: "Podívejte se co je nového",
        whats_new_taxi_first: "Vhodného řidiče za vás vybere aplikace",
        whats_new_taxi_second: "Uvidíte odhad ceny a času vyzvednutí",
        whats_new_taxi_third:
            "Můžete vybrat naposledy zadané projektové kódy a nákladové střediska",
        whats_new_taxi_fourth: "Vyhledávejte v seznamu jízd",
        new_delivery_app: "Vítejte v nové aplikaci pro zásilky",
        whats_new_delivery_first: "Vhodného řidiče za vás vybere aplikace",
        whats_new_delivery_second: "Finální cenu vidíte už před objednáním",
        whats_new_delivery_third: "Poznámka pro řidiče u vyzvednutí i doručení",
        whats_new_delivery_fourth: "Vyhledávejte v seznamu jízd",
        dropoff_time: "Čas vysazení",
        pickup_time: "Čas vyzvednutí",
        pickup_time_now: "Teď",
        pickup_time_15_min: "15 min",
        pickup_time_later: "Později",
        later_recurring: "Naplánovat vyzvednutí",
        select_one_or_more_days: "Vyberte jeden nebo více dní",
        select_dates: "Vybrat datum(y)",
        date_or_more_days: "Datum nebo více dní",
        time: "Čas",
        flight_number: "Číslo letu",
        flight_number_info:
            "Řidič bude připraven vyzvednout pasažéra 15 minut po přistání letadla. Stav letu sledujeme v reálném čase.",
        preorder_disabled_route_not_set: "Zadejte adresu vyzvednutí a cíle pro ověření dostupnosti",
        preorder_disabled_not_available:
            "Není dostupné pro zadané místo vyzvednutí a kategorii {tierName}",
        preorder_disabled_short_ride:
            "Není dostupné pro kategorii {tierName} na trase kratší než {kmDistance} km",
        preorder_disabled_destination: "Není dostupné pro zadaný cíl a kategorii {tierName}",
        preorder_disabled_tier: "Není dostupné pro kategorii {tierName}",
        stop_id: "ID zastávky",
        taxi_search_placeholder: "Jméno, telefon, adresa, reporting…",
        delivery_search_placeholder: "Jméno, telefon, adresa nebo ID",
        no_rides_found: "Na dotaz „{searchQuery}“ nebyly nalezeny žádné nedávné jízdy.",
        no_mobile_users_found:
            "Na dotaz „{searchQuery}“ nebyli nalezeni žádní uživatelé mobilní aplikace.",
        no_web_users_found:
            "Na dotaz „{searchQuery}“ nebyli nalezeni žádní uživatelé webového rozhraní.",
        estimated_price: "Odhadovaná cena",
        fixed_price: "Fixní cena",
        payment_method: "Platební metoda",
        payment_company: "Firma",
        payment_passenger_by_card: "Pasažér kartou",
        payment_passenger_in_cash: "Pasažér hotově",
        specific_driver_id: "ID konkrétního řidiče",
        driver_id: "ID řidiče",
        company_payment_not_allowed:
            "Nejste oprávněni objednávat na náklad firmy {companyName}. Pro změnu nastavení nás kontaktujte na emailu <a>sales@liftago.com</a>.",
        filter: "Filtrovat…",
        all: "Vše",
        filter_by_pickup: "Místo vyzvednutí",
        forgot_password: "Zapomenuté heslo",
        continue: "Pokračovat",
        add_orderer_role_headline: "Doplňte vaše kontaktní údaje a začněte objednávat",
        generic_error: "Něco se pokazilo. Prosím zkuste to znovu později.",
        company_not_approved_yet: "Váš firemní účet zatím nebyl schválen.",
        company_disabled:
            "Váš firemní účet je momentálně pozastavený. Pro opětovné spuštění nás prosím kontaktujte na <a>sales@liftago.com</a>.",
        company_not_active:
            "Váš firemní účet momentálně není aktivní. Pro více informací nás kontaktujte na emailu <a>sales@liftago.com</a>.",
        drop_off_taxi_eta: "v cíli",
        pickup_taxi_eta: "vyzvednutí",
        drop_off_delivery_eta: "Doručení",
        no_address_found: "Žádná adresa nenalezena",
        help: "Pomoc",
        customer_support: "Zákaznická podpora",
        faq: "Časté dotazy",
        faq_url: "https://www.liftago.cz/caste-dotazy?category=Pro+firmy",
        administration: "Administrace",
        stops: "Zastávky",
        unknown_arrival: "Neznámý příjezd",
        one_way: "Jeden směr",
        round_trip: "Tam a zpět",
        repeat_order: "Předvyplnit novou objednávku",
        repeat_order_warning: "Údaje ve formuláři se přepíšou",
        repeat_order_error: "Něco se nezdařilo. Zkuste to znovu nebo objednejte přes formulář.",
        form_prefilled: "Formulář předvyplněn",
        premium_preorder_disclaimer:
            "Pokud se nám nepodaří najít řidiče s premium vozem, zajistíme pro vás standardní vůz.",
        airport_tier_disclaimer: "V objednávce zadejte číslo letu",
        invalid_vat_id:
            "Zadejte prosím DIČ ve formátu 'XXYYYYYYYYYY', kde XX je 2-písmenný kód země (např. CZ) a Y jsou čislíce",
        password_min_characters: "Heslo musí mít minimálně 8 znaků",
        email_already_taken:
            "Tento email již existuje v jiném firemním účtu. Zadejte prosím jiný email.",
        AD: "Andorra",
        AT: "Rakousko",
        AU: "Austrálie",
        BE: "Belgie",
        BG: "Bulharsko",
        BR: "Brazílie",
        CA: "Kanada",
        CH: "Švýcarsko",
        CN: "Čína",
        CY: "Kypr",
        CZ: "Česká republika",
        DE: "Německo",
        DK: "Dánsko",
        EE: "Estonsko",
        ES: "Španělsko",
        FI: "Finsko",
        FR: "Francie",
        GB: "Spojené království",
        GR: "Řecko",
        HK: "Hongkong",
        HR: "Chorvatsko",
        HU: "Maďarsko",
        ID: "Indonésie",
        IE: "Irsko",
        IS: "Island",
        IT: "Itálie",
        JP: "Japonsko",
        KR: "Korea",
        LI: "Lichtenštejnsko",
        LT: "Litva",
        LU: "Lucembursko",
        LV: "Lotyšsko",
        MC: "Monacké knížectví",
        ME: "Černá Hora",
        MT: "Malta",
        MX: "Mexiko",
        MY: "Malajsie",
        NL: "Nizozemsko",
        NO: "Norsko",
        NZ: "Nový Zéland",
        PL: "Polsko",
        PT: "Portugalsko",
        RO: "Rumunsko",
        RU: "Rusko",
        SE: "Švédsko",
        SG: "Singapur",
        SI: "Slovinsko",
        SK: "Slovensko",
        SM: "San Marino",
        TH: "Thajsko",
        TR: "Turecko",
        US: "Spojené státy",
        ZA: "Jihoafrická republika",
        stop_limit_reached: "(dosáhli jste limitu)",
        express: "Expresně",
        time_window: "V časovém okně",
        express_tooltip: "Kurýr doručí výhradně vaši objednávku. Zásilka max. 15 kg.",
        express_max_weight: "max. 15 kg",
        time_window_tooltip:
            "Vyzvednutí i doručení proběhne ve vybraném časovém okně. Kurýr může vyřizovat i jiné objednávky. Zásilka max. 15 kg a 55×45×25 cm.",
        time_window_max_weight: "max. 15 kg a 55×45×25 cm",
        slot_disclaimer_not_available: "Momentálně pro vaší trasu není dostupné",
        slot_disclaimer_roundtrip: "Není dostupné pro Tam a zpět",
        delivery_tiers_route_disclaimer: "Zadejte adresy pro ověření dostupnosti a ceny",
        paid: "Zaplaceno",
        payment_pending: "Čeká na platbu",
        payment_overdue: "Po splatnosti",
        taxi: "Taxi",
        shipment: "Zásilky",
        invoice: "Faktura",
        summary: "Přehled",
        add_payment_card: "Přidat platební kartu",
        remove_payment_card: "Smazat kartu",
        save: "Uložit",
        users: "Uživatelé",
        settings: "Nastavení",
        billing: "Vyúčtování",
        payments_and_billing: "Platby a vyúčtování",
        account_details: "Údaje o účtu",
        company_display_name: "Název firemního účtu",
        company_display_name_description:
            "Pro zobrazení v Liftago aplikacích a ostatních komunikačních kanálech",
        main_contact: "Hlavní kontakt",
        main_contact_description:
            "Hlavní kontakt pro komunikaci se zákaznickou podporou a obchodním týmem",
        email_addresses: "Emailové adresy",
        email_addresses_description:
            "Dodatečné emailové adresy. Všechny informace posíláme také administrátorům. Více emailových adres oddělte čárkou",
        billing_email: "Email pro faktury a přehledy",
        news_email: "Email pro informace o novinkách",
        billing_details_description:
            "Ujistěte se, že vaše fakturační údaje jsou aktuální pro správnost všech dokladů",
        business_account_not_active: "Firemní účet není aktivní",
        business_account_not_active_description:
            "Pro aktivaci vyberte platební metodu a začněte využívat firemní účet.",
        expired_card_headline: "Vaše platební karta expirovala",
        expired_card_text: "Pro opětovnou aktivaci účtu prosím přidejte novou platební kartu.",
        expired_card: "Expirovaná karta",
        pay: "Zaplatit",
        show_all: "Zobrazit vše",
        summary_password: "Heslo k přehledům",
        summary_password_tooltip: "Slouží k otevření přehledů, které posíláme každý měsíc emailem",
        loading: "Načítání…",
        downloading: "Stahování",
        unable_to_load: "Nelze načíst",
        load_details: "Načíst údaje",
        project_codes: "Projektové kódy",
        project_codes_text:
            "Kódy se zobrazují v měsíčním přehledu. Můžete je využít pro lepší přehled uskutečněných objednávek.",
        dont_use_project_codes: "Nepoužívat",
        optional_project_codes: "Nepovinně před každou objednávkou",
        mandatory_project_codes: "Povinně před každou objednávkou",
        saved: "Uloženo",
        payment_failed: "Platba se nezdařila",
        reset_password: "Změna hesla",
        reset_password_explanation:
            "Pro změnu hesla prosím zadejte email, který používáte pro přihlášení. Další instrukce obdržíte emailem.",
        back_to_login: "Zpět na přihlášení",
        email_sent:
            "Odeslán email s informacemi o změně hesla. Prosím zkontrolujte svoji emailovou schránku.",
        new_password_failure: "Platnost žádosti o změnu hesla již vypršela.",
        request_new_password_again: "Zažádat znovu o změnu hesla",
        new_password: "Nové heslo",
        confirm_password: "Potvrďte heslo",
        confirm_password_error: "Zadaná hesla nesouhlasí",
        pasword_changed: "Heslo úspěšně změněno",
        email_not_found: "Tato emailová adresa se neshoduje s žádným Liftago účtem",
        orderer: "Objednavatel",
        admin: "Administrátor",
        role: "Role",
        edit: "Upravit",
        web_app_users_banner_description: "Objednávání i správa vašeho firemního účtu",
        invite: "Pozvat",
        invite_web_user: "Pozvat do webového rozhraní",
        mobile_app_users_banner_description:
            "Všechny služby pro členy vašeho týmu s jednoduchou správou nákladů",
        invite_mobile_user: "Pozvat do mobilní aplikace",
        mobile_users_invite_description:
            "Uživatelé mohou využívat firemní účet po přihlášení do <a>Liftago mobilní aplikace</a> s telefonním číslem. Informaci o pozvání obdrží SMS. Před každou objednávkou mohou přepínat mezi firemním a osobním účtem.",
        mobile_app_url: "https://www.liftago.cz/aplikace/",
        web_users_invite_description:
            "Pozvaným uživatelům pošleme pozvánku na email, kterým se mohou přihlásit do webového rozhraní. Administrátoři mají plný přístup k firemnímu účtu i objednávání. Objednavatelé mohou pouze objednávat.",
        invite_user: "Pozvat uživatele",
        remove_user: "Smazat uživatele",
        edit_user: "Upravit uživatele",
        joined: "Připojený",
        invited: "Pozvaný",
        user_removed: "Uživatel smazán",
        user_added: "Uživatel přidán",
        cant_load_user: "Uživatele se nepodařilo načíst",
        user_doesnt_exists: "Uživatel neexistuje",
        search: "Vyhledat…",
        monthly_limit: "Měsíční limit",
        payment_type: "Vyúčtování",
        give_access_to_your_team: "Dejte přístup vašemu týmu",
        mobile_app: "Mobilní aplikace",
        web_app: "Webové rozhraní",
        payment_originator_company: "Hromadně",
        payment_originator_employee: "Jednotlivě",
        enter_numerical_amount: "Zadejte pouze kladná celá čísla",
        amount_greater_than_zero: "Částka musí být větší než 0 nebo žádná",
        mobile_user_already_invited:
            "Uživatel s daným telefonním číslem je již součástí vašeho firemního účtu",
        mobile_user_already_in_other_business:
            "Uživatel s daným telefonním číslem je již součástí jiného firemního účtu",
        delete: "Smazat",
        no_limit: "Bez limitu",
        cannot_remove_last_admin:
            "Uživatel nelze smazat. Firemní účet musí mít alespoň jednoho administrátora.",
        do_you_want_to_create_new_business_account: "Chcete vytvořit nový firemní účet?",
        already_logged_in_as: "Jste již přihlášeni pod emailem",
        already_have_an_account: "Máte již účet?",
        payment_card: "Platba kartou",
        payment_card_bullet_1: "Platba automaticky po každé jízdě",
        payment_card_bullet_2: "Souhrnná faktura na konci měsíce",
        change: "Změnit",
        remove: "Odebrat",
        card_removed: "Karta odebrána",
        switch_to_payment_card: "Přepnout na platbu kartou",
        monthly_payment: "Platba bankovním převodem",
        monthly_payment_bullet_1: "Platba převodem jednou měsíčně",
        monthly_payment_bullet_2: "Souhrnná faktura na konci měsíce",
        request_monthly_payment: "Požádat o měsíční platbu",
        switch_to_monthly_payment: "Přepnout na měsíční platbu",
        monthly_invoicing_with_14_days_due_date: "Měsíční fakturace se splatností 14 dní",
        request_received: "Žádost jsme obdrželi. Ozveme se vám do jednoho pracovního dne.",
        billing_details: "Fakturační údaje",
        vat_payer: "Plátce DPH",
        vat_non_payer: "Neplátce DPH",
        start_ordering: "Objednávejte",
        sign_in_to_business_account: "Přihlásit se do firemního účtu",
        create_new_business_account: "Vytvořit nový firemní účet",
        dont_have_account_yet: "Nemáte účet?",
        monthly_payment_request_pending:
            "Obdrželi jsme vaší žádost o měsíční platbu. Ozveme se vám do jednoho pracovního dne. Pro okamžitou aktivaci prosím <link>zadejte platební kartu</link> a začněte ihned objednávat.",
        choose_payment_method_action: "Vybrat platební metodu",
        choose_payment_method: "Vyberte platební metodu",
        choose_payment_method_subtitle: "Firemní účet je zdarma. Platíte jen za využité služby.",
        approval_within_one_business_day: "Schválení do 1 pracovního dne",
        immediate_activation: "Okamžitá aktivace",
        skip_now: "Nyní přeskočit",
        payment_card_disclaimer:
            "Pro přidání karty provedeme autorizační platbu 1 Kč, kterou vám vrátíme do několika dnů. Údaje o kartě jsou bezpečně uloženy u certifikovaného poskytovatele ČSOB. Liftago k údajům o kartě nemá přístup.",
        card_added_successfully: "Karta úspěšně přidána",
        failed_to_add_card: "Přidání karty se nezdařilo",
        close: "Zavřít",
        payment_method_needed_to_invite_mobile_app_users:
            "Pro pozvání uživatelů do mobilní aplikace musíte mít aktivní platební metodu.",
        billing_details_not_found: "Zadané IČO jsme nenašli v registru firem",
        create_business_account: "Vytvořit firemní účet",
        create_business_account_subtitle:
            "Šetřete čas s Liftagem a založte si firemní účet bez poplatků a zbytečného papírování",
        login_credentials: "Údaje pro příhlášení", //FIXME: not used
        contact_details: "Kontaktní údaje", //FIXME: not used
        tax_id: "IČO",
        vat_id: "DIČ",
        tax_id_not_found: "IČO nelze najít v registru firem",
        tax_id_already_registred:
            "Firemní účet s tímto IČO již existuje. Pro přidání ke stávajícímu účtu kontaktujte svého administrátora.",
        use_anyway: "Přesto použít",
        company_name: "Název firmy",
        street: "Ulice, č.p.",
        city: "Město",
        zip_code: "PSČ",
        country: "Země",
        create_account: "Vytvořit účet",
        vat_payer_warning:
            "Pokud nejste plátce DPH, ceny budou navýšeny o 21 %. Doporučujeme využít soukromý účet v naší <a>mobilní aplikaci</a>. Účtenky zasíláme elektronicky po každé jízdě.",
        min_8_chars: "Minimálně 8 znaků",
        access_to_others: "Později můžete dát přístup k účtu i ostatním",
        registration_failed:
            "Vytvoření účtu se nezdařilo. Zkuste to prosím znovu nebo nám napište na service@liftago.com",
        email_is_already_admin_in_existing_account:
            "Email {email} je již administrátorem v existujícím účtu. Pokračujte na přihlášení.",
        TIER_DEFAULT_name: "Liftago",
        TIER_DEFAULT_description: "Všechny dostupné vozy",
        TIER_PREMIUM_name: "Premium",
        TIER_PREMIUM_description: "Preference vozů vyšší třídy",
        TIER_KIDS_name: "Kids",
        TIER_KIDS_description: "1 sedačka 0-36kg, 1 podsedák",
        TIER_STANDARD_name: "Standard",
        TIER_STANDARD_description: "Preference vozů střední třídy",
        TIER_ECO_FRIENDLY_name: "Eco",
        TIER_ECO_FRIENDLY_description: "Preference elektrických a hybridních vozů",
        TIER_AIRPORT_TRANSFER_name: "Letištní vyzvednutí",
        TIER_AIRPORT_TRANSFER_description: "Vyzvednutí v příletové hale a doprovod k vozu",
        TIER_XL_name: "XL",
        TIER_XL_description: "Vozy pro 6 osob",
        car_category: "Kategorie vozu",
        busy: "nedostupné",
        busy_info: "Nedostupné z důvodu zvýšené poptávky, vyberte jiný čas.",
        cant_schedule: "Nelze naplánovat",
        registration_legal_info:
            "Pokračováním souhlasíte s <terms>Všeobecnými obchodními podmínkami</terms>. Osobní údaje budou zpracovávány k účelu uvedenému v <policy>Zásadách ochrany osobních údajů</policy>",
        terms_url: "https://www.liftago.cz/vseobecne-obchodni-podminky/",
        policy_url: "https://www.liftago.cz/zasady-ochrany-osobnich-udaju/",
        need_help: "Máte dotazy? Zavolejte nám.",
        need_help_email: "Máte dotazy? Kontakujte nás na <a>sales@liftago.com</a>",
        reply_withing_24_hours: "Ozveme se do 24 hodin",
        back: "Zpět",
        register_usp_1_title: "Spolehlivé firemní taxi a kurýr",
        register_usp_1_text:
            "Pouze prověření a licencovaní řidiči, kteří se ve městě vyznají. Všechny jízdy i zásilky jsou pojištěné.",
        register_usp_2_title: "Přehledná aplikace",
        register_usp_2_text:
            "Jednoduchá mobilní i webová aplikace, kde máte celý tým a jeho výdaje pod kontrolou.",
        register_usp_3_title: "Česká zákaznická podpora",
        register_usp_3_text:
            "Vaše dotazy, podněty a návrhy na vylepšení řešíme i o víkendech a svátcích.",
        register_usp_4_title: "Žádné závazky",
        register_usp_4_text:
            "Liftago můžete využívat bez poplatků, výpovědní lhůty a minimálního objemu zakázek.",
        register_usp_5_title: "Bez papírování",
        register_usp_5_text:
            "Účtenky nepotřebujete. Všechny firemní jízdy i služby kurýra máte na jedné faktuře.",
        join_happy_customers: "Přidejte se mezi tisíce spokojených firemních zákazníků",
        tip_for_driver: "Spropitné pro řidiče",
        tip_the_driver: "Poslat řidiči",
        enter_the_tip: "Zadejte spropitné",
        custom: "Vlastní",
        tip_payment_success: "Spropitné posláno",
        tip_payment_failed: "Platba spropitného se nezdařila",
        tip_sent_to_the_driver: "Spropitné {tip} posláno řidiči",
        rate_the_ride: "Ohodnotit jízdu",
        rate_the_delivery: "Ohodnotit doručení",
        max_tip_is: "Maximální spropitné je",
        change_pickup_time: "Změnit čas vyzvednutí",
        change_pickup_or_flight_number: "Změnit datum nebo číslo letu",
        set_pickup_time: "Vyberte čas vyzvednutí",
        pickup_time_changed: "Čas vyzvednutí změněn",
        in_bulk_csv: "Hromadně (.csv)",
        something_went_wrong_with_parsing_csv_file: "Něco se nepovedlo při načítání .csv souboru",
        mobile_app_users_bulk_update: "Hromadná úprava uživatelů mobilní aplikace",
        download_current_csv_mobile_users:
            "Stáhněte .csv soubor <a>součaných uživatelů</a> a upravený soubor nahrajte zpět. Jméno a tel. číslo jsou povinná pole.",
        download_sample_csv_mobile_users:
            "Stáhněte <a>šablonu</a> a upravený soubor nahrajte zpět. Jméno a tel. číslo jsou povinná pole.",
        select_file: "Vyberte soubor",
        or_drop_file_here: "nebo sem přetáhněte soubor",
        drop_the_file_here: "Přetáhněte soubor...",
        only_csv_file_is_supported: "Pouze soubor ve formátu .csv je podporován",
        add: "Přidat",
        modify: "Upravit",
        keep: "Ponechat",
        all_users_already_in_your_company:
            "Všichni uživatelé v .csv souboru jsou již součástí vaší firmy",
        there_is_invalid_data_in_users_table:
            "Některé údaje obsahují chybu. Opravte je prosím zde nebo nahrajte znovu opravený soubor.",
        confirm_users_changes:
            "Potvrdit změny ({add} přidat · {modify} upravit  · {remove} odebrat)",
        users_updated_successfully: "Uživatelé úspěšně aktualizováni",
        users_updated_partially: "Uživatelé částečně aktualizování",
        users_updated_counts: "Přidáno {add}  ·  Upraveno {modify}  ·  Odebráno {remove}",
        go_to_invalid_data: "Přejít na chybný údaj",
        bulk_users_failed_partially_in_another_company:
            "Uživatele v tabulce nelze přidat, protože jsou již součástí jiného firemního účtu.",
        bulk_users_failed_partially:
            "Uživatele v tabulce se nepodařilo aktualizovat. Zkontrolujte údaje a zkuste to znovu.",
        bulk_users_failed_invited_in_another_company:
            "Tito uživatelé jsou již součástí jiného firemního účtu",
        bulk_users_failed:
            "Aktualizace uživatelů se nezdařila. Zkontrolujte údaje a zkuste to znovu.",
        download_sample_csv_dropoffs:
            "Stáhněte <a>.csv šablonu</a> a vyplněný soubor nahrajte zpět.",
        upload_csv: "Nahrát .csv",
        upload_more_dropoffs_in_csv_file: "Nahrajte více doručení v .csv souboru",
        cant_find_following_addresses:
            "Nepodařilo se najít následující adresy. Zkuste je prosím opravit a nahrát soubor znovu.",
        cant_upload_empty_file: "Nelze nahrát prázdný soubor",
        maximum_dropoffs_reached: "Můžete nahrát maximálně {count} doručení",
        time_window_not_available_for_some_stops:
            "Na adresu doručení č. {stops} nelze objednat na časové okno.",
        are_you_a_driver: "Jste řidič?",
        register_as_driver: "Registrovat se jako řidič",
        or: "Nebo",
        available_for_up_to_dropoff_count: "Dostupné maximálně pro {count} doručení",
        file_cant_contain_duplicit_phone_numbers:
            "Soubor nemůže obsahovat duplicitní tel. čísla. Odstraňte prosím následující duplicity a nahrajte soubor znovu. \n Duplicitní čísla",
        copied: "Zkopírováno",
        later_only: "pouze později",
        later_only_tooltip:
            "{tierName} lze nyní objednat s vyzvednutím nejdříve za {days, plural, =0 {} one {{days} den} few {{days} dny} other {{days} dní}} {hours, plural, =0 {} one {{hours} hodinu} few {{hours} hodiny} other {{hours} hodin}} {minutes, plural, =0 {} one {{minutes} minutu} few {{minutes} minuty} other {{minutes} minut}}. Zvolte čas vyzvednutí 'Později'.",
        remove_after: "Odebrat po",
        date_must_be_in_the_future: "Datum musí být v budoucnosti",
        soft_update_title: "Používáte starou verzi",
        soft_update_subtitle: "Pro nejnovější verzi klikněte zde nebo znovu načtěte stránku",
        you_dont_have_permissions_to_access_section: "Nemáte práva pro přístup do této sekce",
    },
};

export function getLanguage() {
    const language = navigator.language.split("-")[0];
    return language === "cs" ? "cs" : "en";
}

export function getMessages() {
    return messages[getLanguage()];
}
