import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import styled from "styled-components";
import {TaxiRide, TaxiStop} from "../api/apiClient";
import {formatShortDateFancy, formatTime} from "../app/dateUtils";
import {formatMoney} from "../app/moneyUtils";
import {Button} from "../components/Button";
import {DetailRow} from "../components/DetailRow";
import {TrackingLink} from "../components/TrackingLink";
import closeIcon from "../img/ic_close.svg";
import currentLocationIcon from "../img/ic_current-location.svg";
import feedbackIcon from "../img/ic_feedback.svg";
import messageIcon from "../img/ic_message.svg";
import completedIcon from "../img/status_delivered.svg";
import arrivingIcon from "../img/status_delivering.svg";
import scheduledIcon from "../img/status_scheduled.svg";
import canceledIcon from "../img/status_undelivered.svg";

import {ReactNode} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Lozenge} from "../components/Lozenge";
import {DetailContent, DetailHeader} from "../rides/layout";
import {
    formatRemainingMinutes,
    formatTaxiRideStatus,
    formatVehicleInfo,
    getPassengersCount,
    getScheduledTime,
} from "../rides/ridesModel";
import {useRemainingMinutes} from "../rides/useRemainingMinutes";
import {ChangeTime} from "./ChangeTime";
import {TaxiRideCancel} from "./TaxiRideCancel";
import {TaxiRideRecreate} from "./TaxiRideRecreate";
import {TaxiTip} from "./TaxiTip";
import {TipGiven} from "./TipGiven";

function Note({children}: {children: ReactNode}) {
    return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="note">{children}</Tooltip>}>
            <img width="16" height="16" src={messageIcon} alt="Message for driver" />
        </OverlayTrigger>
    );
}

function Stops({
    stops,
    rideStatusGroup,
}: {
    stops: TaxiStop[];
    rideStatusGroup: TaxiRide["statusGroup"];
}) {
    return (
        <StopsWrapper data-cy="route-detail" className="cypress-route-detail">
            {stops.map((stop) => (
                <Stop key={stop.id}>
                    <StopIconWrapper>
                        <img alt="" src={getStatusIcon(stop.status)} />
                    </StopIconWrapper>
                    <div>
                        {
                            //FIXME: remove rideStatusGroup condition if we start to update arrivalEstimate after each leg
                            stop.arrivalEstimate &&
                                stop.status === "SCHEDULED" &&
                                rideStatusGroup === "UPCOMING" && (
                                    <Lozenge style={{marginBottom: "2px"}}>
                                        {formatTime(new Date(stop.arrivalEstimate))}
                                    </Lozenge>
                                )
                        }
                        <div>{stop.location.address}</div>
                        {stop.contact && (
                            <Contact>
                                {stop.contact?.name + ", " + stop.contact?.phoneNumber}{" "}
                                {!!stop.noteForDriver && <Note>{stop.noteForDriver}</Note>}
                            </Contact>
                        )}
                    </div>
                </Stop>
            ))}
        </StopsWrapper>
    );
}

export function TaxiRideDetail({ride, onClose}: {ride: TaxiRide; onClose: () => void}) {
    const intl = useIntl();
    //FIXME: Remove fallback to pickupArrival when deprecated (~April 1st)
    const minutesRemaining = useRemainingMinutes(
        ride.stops[0].arrivalEstimate ?? ride.pickupArrival
    );

    const formattedStatus = formatTaxiRideStatus(ride.status, ride.failReason, intl);
    const finishedAt = getFinishedAt(ride, intl);

    const status = [formattedStatus, finishedAt].filter((a) => a).join(" ");

    function paymentMethodText(paymentMethod: TaxiRide["paymentMethod"]): string {
        switch (paymentMethod) {
            case "COMPANY":
                return intl.formatMessage({id: "payment_company"});
            case "PASSENGER_CARD":
                return intl.formatMessage({id: "payment_passenger_by_card"});
            case "PASSENGER_CASH":
                return intl.formatMessage({id: "payment_passenger_in_cash"});
        }
    }

    return (
        <div>
            <DetailHeader>
                {getPassengersCount(ride.stops) > 1 ? (
                    <FormattedMessage
                        id="passengers_few"
                        values={{count: getPassengersCount(ride.stops)}}
                    />
                ) : (
                    <FormattedMessage id="ride_for" values={{name: ride.stops[0].contact?.name}} />
                )}
                <Button variant="plain" onClick={onClose}>
                    <img src={closeIcon} alt="Close detail" />
                </Button>
            </DetailHeader>
            <DetailContent>
                <DetailRow
                    title={
                        ride.status === "SCHEDULED"
                            ? intl.formatMessage({id: "scheduled"})
                            : intl.formatMessage({id: "status"})
                    }
                >
                    <span data-cy="status">
                        {status}{" "}
                        {ride.status === "PICKUP_ARRIVAL" &&
                            minutesRemaining &&
                            formatRemainingMinutes(minutesRemaining, intl)}
                        {ride.status === "SCHEDULED" &&
                            (ride.requestedDropOffAt
                                ? `${intl.formatMessage({
                                      id: "drop_off_taxi_eta",
                                  })} ${getScheduledTime(ride.requestedDropOffAt, intl)}`
                                : getScheduledTime(ride.requestedPickupAt, intl))}
                    </span>
                </DetailRow>
                {ride.flightNumber && (
                    <DetailRow title={intl.formatMessage({id: "flight_number"})}>
                        {ride.flightNumber}
                    </DetailRow>
                )}
                {ride.statusGroup === "UPCOMING" &&
                    ride.requestedPickupAt &&
                    !ride.requestedDropOffAt && (
                        <ChangeTime
                            key={ride.id}
                            rideId={ride.id}
                            stops={ride.stops}
                            originalTime={ride.requestedPickupAt}
                            flightNumber={ride.flightNumber}
                        />
                    )}
                <hr />
                <Stops stops={ride.stops} rideStatusGroup={ride.statusGroup} />
                <hr />
                <DetailRow title={intl.formatMessage({id: "payment_method"})}>
                    {paymentMethodText(ride.paymentMethod)}
                </DetailRow>
                {ride.ridePrice?.estimatedPrice && (
                    <DetailRow
                        title={
                            ride.ridePrice.fixedPrice
                                ? intl.formatMessage({id: "fixed_price"})
                                : intl.formatMessage({id: "estimated_price"})
                        }
                    >
                        {formatMoney(ride.ridePrice.estimatedPrice, intl)}
                    </DetailRow>
                )}
                {ride.projectCode && (
                    <DetailRow title={intl.formatMessage({id: "project_code"})}>
                        {ride.projectCode}
                    </DetailRow>
                )}
                {ride.costCenter && (
                    <DetailRow title={intl.formatMessage({id: "cost_center"})}>
                        {ride.costCenter}
                    </DetailRow>
                )}
                {ride.internalNote && (
                    <DetailRow title={intl.formatMessage({id: "internal_note"})}>
                        {ride.internalNote}
                    </DetailRow>
                )}
                {(ride.driver || ride.vehicle) && <hr />}
                {ride.driver && (
                    <DetailRow title={intl.formatMessage({id: "driver"})}>
                        {ride.driver.name}
                    </DetailRow>
                )}
                {ride.driver && (
                    <DetailRow title={intl.formatMessage({id: "phone_number"})}>
                        {ride.driver.phoneNumber}
                    </DetailRow>
                )}
                {ride.vehicle && (
                    <DetailRow title={intl.formatMessage({id: "vehicle"})}>
                        {formatVehicleInfo(ride.vehicle, intl)}
                    </DetailRow>
                )}
                {!ride.driver && ride.specifiedDriver && (
                    <>
                        <hr />
                        <DetailRow title={intl.formatMessage({id: "driver_id"})}>
                            {ride.specifiedDriver.id}
                        </DetailRow>
                        <DetailRow title={intl.formatMessage({id: "driver"})}>
                            {ride.specifiedDriver.name}
                        </DetailRow>
                        <DetailRow title={intl.formatMessage({id: "phone_number"})}>
                            {ride.specifiedDriver.phoneNumber}
                        </DetailRow>
                    </>
                )}
                <div style={{display: "flex", flexDirection: "column", gap: "var(--spacing-small"}}>
                    {ride.tipped ? (
                        <TipGiven tip={ride.tipped} />
                    ) : (
                        ride.tipEnabled &&
                        (ride.tipCcy === "EUR" || ride.tipCcy === "CZK") && (
                            <TaxiTip rideId={ride.id} ccy={ride.tipCcy} />
                        )
                    )}
                    <br />
                    {ride.shareUrl &&
                        (ride.statusGroup === "IN_PROGRESS" ? (
                            <TrackingLink url={ride.shareUrl} imgSrc={currentLocationIcon}>
                                <FormattedMessage id="driver_location" />
                            </TrackingLink>
                        ) : (
                            <TrackingLink url={ride.shareUrl} imgSrc={feedbackIcon}>
                                <FormattedMessage id="rate_the_ride" />
                            </TrackingLink>
                        ))}
                    <TaxiRideRecreate ride={ride} onDetailClose={onClose} />
                    <TaxiRideCancel ride={ride} />
                </div>
            </DetailContent>
        </div>
    );
}

function getFinishedAt(ride: TaxiRide, intl: IntlShape): string | null {
    if (!ride.finishedAt) {
        return null;
    }
    const finishedAt = new Date(ride.finishedAt);
    return `${formatShortDateFancy(intl, finishedAt)} ${formatTime(finishedAt)}`;
}

const Stop = styled.div`
    display: flex;
    margin: 8px 0;
    align-items: flex-start;
    position: relative;
`;

const StopIconWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 20px;

    &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        z-index: 0;
    }

    & > img  {
        position: relative;
        margin-right: 12px;
        height: 16px;
        width: 16px;
        z-index: 1;
    }
`;

const StopsWrapper = styled.div`
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 7px;
        width: 2px;
        height: calc(100% - 40px);
        margin-top: 20px;
        background-color: var(--quaternary);
        z-index: 0;
    }
`;

const Contact = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    font-weight: var(--font-weight-thin);
    color: var(--secondary);
`;

function getStatusIcon(status: TaxiStop["status"]) {
    switch (status) {
        case "SCHEDULING":
        case "SCHEDULED":
            return scheduledIcon;
        case "IN_PROGRESS":
        case "ARRIVED":
            return arrivingIcon;
        case "COMPLETED":
            return completedIcon;
        case "FAILED":
        case "CANCELLED":
            return canceledIcon;
        default:
            return scheduledIcon;
    }
}
