import styled from "styled-components";

export const Lozenge = styled.span`
    display: inline-block;
    border-radius: 4px;
    background-color: var(--light);
    color: var(--dark);
    padding: 2px 5px 3px 5px;
    font-variant: all-small-caps;
    width: fit-content;
    letter-spacing: 0.3px;
    font-size: 12px;
    line-height: 100%;
`;
