import * as duration from "iso8601-duration";
import {IntlShape} from "react-intl";
import {TimeInterval} from "../api/apiClient";

export function formatShortDateFancy(intl: IntlShape, date: Date) {
    return formatDateFancy(intl, date, false);
}

export function formatFullDateFancy(intl: IntlShape, date: Date) {
    return formatDateFancy(intl, date, true);
}

function formatDateFancy(intl: IntlShape, date: Date, includeYear: boolean) {
    function diffDays(d1: Date, d2: Date): number {
        const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
        const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
        return Math.floor((utc1 - utc2) / (1000 * 60 * 60 * 24));
    }

    const daysDiff = diffDays(date, new Date());
    if (daysDiff === 0) {
        return intl.formatMessage({id: "today"});
    }
    if (daysDiff === 1) {
        return intl.formatMessage({id: "tomorrow"});
    }
    if (includeYear) {
        return formatDate(date);
    } else {
        return formatDateShort(date);
    }
}

export function formatDate(date: Date): string {
    return date.toLocaleDateString(navigator.language, {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
}

export function formatDateShort(date: Date): string {
    return date.toLocaleDateString(navigator.language, {
        weekday: "short",
        day: "numeric",
        month: "short",
    });
}

export function formatTime(date: Date): string {
    return date.toLocaleTimeString(navigator.language, {
        hour12: false,
        hour: "numeric",
        minute: "numeric",
    });
}

export function formatTimeInterval(timeInterval: TimeInterval): string {
    const from = new Date(timeInterval.from);
    const to = new Date(timeInterval.to);
    return `${formatTime(from)}-${formatTime(to)}`;
}

export function formatDateTimeInterval(intl: IntlShape, timeInterval: TimeInterval): string {
    const from = new Date(timeInterval.from);
    const to = new Date(timeInterval.to);
    return `${formatDateFancy(intl, from, false)} ${formatTime(from)}-${formatTime(to)}`;
}

export function getDurationInMiliseconds(isoDuration: string) {
    const parsed = duration.parse(isoDuration);
    const seconds = duration.toSeconds(parsed);
    return seconds * 1000;
}

export function getDurationInMinutes(isoDuration: string) {
    const parsed = duration.parse(isoDuration);
    const durationInMinutes = Math.ceil(duration.toSeconds(parsed) / 60);
    return durationInMinutes;
}

export function getIsoDuration(isoDuration: string) {
    const {days = 0, hours = 0, minutes = 0} = duration.parse(isoDuration);
    return {days, hours, minutes};
}

export function formatPreorderDropoffArrival(preorderTime: Date, rideDuration: string) {
    const time = new Date(preorderTime.getTime() + getDurationInMinutes(rideDuration) * 60000);
    return `~${formatTime(time)}`;
}

export function formatPreorderPickupArrival(preorderTime: Date, rideDuration: string) {
    const time = new Date(preorderTime.getTime() - getDurationInMinutes(rideDuration) * 60000);
    return `~${formatTime(time)}`;
}

export function formatShortPreorderDropoffArrival(rideDuration: string) {
    const time = new Date(
        new Date().getTime() + 15 * 60 * 1000 + getDurationInMinutes(rideDuration) * 60000
    );
    return `~${formatTime(time)}`;
}

export function formatDropoffArrival(estimatedArrival: string, estimatedRide: string) {
    const time = new Date(
        new Date().getTime() +
            getDurationInMinutes(estimatedArrival) * 60 * 1000 +
            getDurationInMinutes(estimatedRide) * 60 * 1000
    );
    return `~${formatTime(time)}`;
}

export function formatPickupArrival(pickupDuration: string) {
    return `~${getDurationInMinutes(pickupDuration).toString()} min`;
}

export function formatDateToYYYYMM(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}`;
}

export function isPastWeek(date: Date) {
    const now = new Date();
    const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    return date < weekAgo;
}
