import {EventSourcePolyfill} from "event-source-polyfill";
import {useEffect, useRef} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {useGetDeliveryRides, useGetTaxiRides} from "../api/apiClient";
import {getAccessToken} from "../auth/authModel";

declare var appConfig: any;

export function useRefreshRideList(eventType: "TAXI" | "DELIVERY") {
    const queryClient = useQueryClient();

    const eventSource = useRef<EventSourcePolyfill>();

    useEffect(() => {
        const es = new EventSourcePolyfill(`${appConfig.apiUrl}/self-service/rides/updates`, {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });

        es.onmessage = (e) => {
            try {
                const data = JSON.parse(e.data);
                if (data.type === eventType) {
                    const queryKey = {
                        TAXI: "/self-service/rides/taxi",
                        DELIVERY: "/self-service/rides/delivery",
                    }[eventType];
                    queryClient.invalidateQueries([queryKey]);
                }
            } catch {}
        };

        eventSource.current = es;

        return () => {
            eventSource.current?.close();
        };
    }, []);
}

export function useTaxiRides() {
    useRefreshRideList("TAXI");
    return useGetTaxiRides();
}

export function useDeliveryRides() {
    useRefreshRideList("DELIVERY");
    return useGetDeliveryRides();
}
