export interface CacheItem<T> {
    key: string;
    promise: Promise<T>;
}

export function createCache<T>(
    backupValue: T,
    capacity = 10
): (key: string, resolver: () => Promise<T>) => Promise<T> {
    let cache: CacheItem<T>[] = [];

    return (key, resolver) => {
        const cachedResult = cache.find((item) => item.key === key);

        if (cachedResult != null) {
            return cachedResult.promise;
        }

        const loadingPromise = resolver();

        if (cache.length > capacity) {
            cache.pop();
        }

        // save loading promise
        cache.unshift({
            key,
            promise: loadingPromise,
        });

        return loadingPromise
            .then((value) => {
                // save resolved value
                const newCacheItem = {
                    key,
                    promise: Promise.resolve(value),
                };
                cache = cache.map((item) => (item.key === key ? newCacheItem : item));

                return newCacheItem.promise;
            })
            .catch(() => {
                // remove cache item and return backup value
                cache = cache.filter((item) => item.key !== key);
                return Promise.resolve(backupValue);
            });
    };
}
