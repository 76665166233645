import {zodResolver} from "@hookform/resolvers/zod";
import {Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import {z} from "zod";
import {
    BusinessEmployeeDto,
    useCallerCompany,
    useDeleteEmployee,
    useGetBusinessCompany,
    useGetBusinessPaymentInfo,
    useGetEmployees,
    useUpdateEmployee,
} from "../api/apiClient";
import {Button} from "../components/Button";
import closeIcon from "../img/ic_close.svg";
import {clearSpaces} from "../utils/formatString";
import {monthlyLimitSchema, removeAfterSchema} from "../utils/schemas";
import {Currency, MoneyInput} from "./AddMobileUser";
import {queryClient} from "./App";
import {Error, Input, Label, Select, TrailingLabel} from "./Registration";
import {getCountryCurrencyString, getCurrencyByCountry} from "./moneyUtils";

export const editMobileUserSchema = z.object({
    name: z.string().min(1, {message: "required"}),
    phoneNumber: z.string().min(1, {message: "required"}),
    costCenter: z.string().optional(),
    paymentOriginator: z.union([z.literal("EMPLOYEE"), z.literal("COMPANY")]).optional(),
    monthlyLimit: monthlyLimitSchema,
    removeAfter: removeAfterSchema,
});

type EditMobileUserValue = z.infer<typeof editMobileUserSchema>;

export function UserForm({user, employeeId}: {user: BusinessEmployeeDto; employeeId: string}) {
    const navigate = useNavigate();
    const intl = useIntl();
    const removeUser = useDeleteEmployee();
    const updateUser = useUpdateEmployee();
    const {data: company} = useGetBusinessCompany();
    const {data: business} = useGetBusinessPaymentInfo();
    const {data: companyInfo} = useCallerCompany();

    const handleClose = () => navigate("..", {replace: true});

    function handleRemoveUser(employeeId: string) {
        removeUser.mutate(employeeId, {
            onSuccess: () => {
                toast.success(intl.formatMessage({id: "user_removed"}));
                queryClient.invalidateQueries(["/self-service/business/employees"]);
                handleClose();
            },
            onError: () => toast.error(intl.formatMessage({id: "generic_error"})),
        });
    }

    const {
        register,
        handleSubmit,
        formState: {errors, isDirty},
        setValue,
    } = useForm<EditMobileUserValue>({
        defaultValues: {...user, monthlyLimit: user.monthlyLimit?.amount.toString()},
        mode: "onTouched",
        resolver: zodResolver(editMobileUserSchema),
        criteriaMode: "all",
    });

    if (business == null || company == null || companyInfo == null) {
        return null;
    }

    async function onSubmit(data: EditMobileUserValue, country: string) {
        updateUser.mutate(
            {
                body: {
                    name: data.name,
                    phoneNumber: clearSpaces(data.phoneNumber),
                    costCenter: data.costCenter ?? undefined,
                    paymentOriginator:
                        data.paymentOriginator === "EMPLOYEE" ? "EMPLOYEE" : "COMPANY",
                    monthlyLimit:
                        data.monthlyLimit && data.monthlyLimit.length > 0
                            ? {
                                  amount: Number(data.monthlyLimit),
                                  ccy: getCurrencyByCountry(country),
                              }
                            : undefined,
                    removeAfter: data.removeAfter ?? undefined,
                },
                employeeId: employeeId,
            },
            {
                onSuccess: () => {
                    toast.success(intl.formatMessage({id: "saved"}));
                    queryClient.invalidateQueries(["/self-service/business/employees"]);
                    navigate("..", {replace: true});
                },
                onError: () => toast.error(intl.formatMessage({id: "generic_error"})),
            }
        );
    }
    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="edit_user" />
                </Modal.Title>
                <Button
                    variant="plain"
                    onClick={handleClose}
                    disabled={updateUser.isLoading || removeUser.isLoading}
                >
                    <img src={closeIcon} alt="Close dialog" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <form
                    id="user"
                    onSubmit={handleSubmit((data) =>
                        onSubmit(data, company.billingAddress.country)
                    )}
                    style={{display: "flex", flexDirection: "column"}}
                >
                    <Label htmlFor="name">
                        <FormattedMessage id="name" />
                    </Label>
                    <Input type="text" {...register("name")} />
                    {errors.name?.message && (
                        <Error>{intl.formatMessage({id: errors.name.message})}</Error>
                    )}
                    <Label htmlFor="phoneNumber">
                        <FormattedMessage id="phone_number" />
                    </Label>
                    <Input type="tel" {...register("phoneNumber")} disabled />
                    {errors.phoneNumber?.message && (
                        <Error>{intl.formatMessage({id: errors.phoneNumber.message})}</Error>
                    )}
                    {(company?.billingAddress?.country === "CZ" ||
                        company?.billingAddress?.country === "SK") && (
                        <>
                            <Label htmlFor="monthlyLimit">
                                <FormattedMessage id="monthly_limit" />
                                <TrailingLabel>
                                    (<FormattedMessage id="optional" />)
                                </TrailingLabel>
                            </Label>

                            <MoneyInput>
                                <Input
                                    type="text"
                                    inputMode="decimal"
                                    {...register("monthlyLimit")}
                                    placeholder={intl.formatMessage({id: "no_limit"})}
                                    style={{maxWidth: "130px", paddingRight: "40px"}}
                                />
                                <Currency>
                                    {getCountryCurrencyString(company.billingAddress.country)}
                                </Currency>
                            </MoneyInput>
                            {errors.monthlyLimit?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.monthlyLimit.message})}
                                </Error>
                            )}
                        </>
                    )}
                    <Label htmlFor="costCenter">
                        <FormattedMessage id="cost_center" />
                        <TrailingLabel>
                            (<FormattedMessage id="optional" />)
                        </TrailingLabel>
                    </Label>
                    <Input type="text" {...register("costCenter")} />
                    {errors.costCenter?.message && (
                        <Error>{intl.formatMessage({id: errors.costCenter.message})}</Error>
                    )}
                    {companyInfo.employeeRemoveAfterEnabled && (
                        <>
                            <Label htmlFor="removeAfter">
                                <FormattedMessage id="remove_after" />
                                <TrailingLabel>
                                    (<FormattedMessage id="optional" />)
                                </TrailingLabel>
                            </Label>
                            <div style={{display: "flex"}}>
                                <Input id="removeAfter" type="date" {...register("removeAfter")} />
                                <Button
                                    variant="plain"
                                    onClick={() =>
                                        setValue("removeAfter", undefined, {shouldDirty: true})
                                    }
                                >
                                    <img src={closeIcon} alt="Reset search" />
                                </Button>
                            </div>
                        </>
                    )}
                    {errors.removeAfter?.message && (
                        <Error>{intl.formatMessage({id: errors.removeAfter.message})}</Error>
                    )}
                    {business.paymentByEmployeeAllowed && (
                        <>
                            <Label htmlFor="paymentOriginator">
                                <FormattedMessage id="payment_type" />
                            </Label>
                            <Select autoComplete="nope" {...register("paymentOriginator")}>
                                <option value="COMPANY">
                                    {intl.formatMessage({id: "payment_originator_company"})}
                                </option>
                                <option value="EMPLOYEE">
                                    {intl.formatMessage({id: "payment_originator_employee"})}
                                </option>
                            </Select>
                            {errors.paymentOriginator?.message && (
                                <Error>
                                    {intl.formatMessage({id: errors.paymentOriginator.message})}
                                </Error>
                            )}
                        </>
                    )}
                </form>
            </Modal.Body>

            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button
                    data-cy="remove-user-btn"
                    variant="destructive"
                    onClick={() => handleRemoveUser(employeeId)}
                    loading={removeUser.isLoading}
                    disabled={updateUser.isLoading}
                >
                    <FormattedMessage id="remove_user" />
                </Button>
                <Button
                    data-cy="submit-user-btn"
                    type="submit"
                    form="user"
                    variant="primary"
                    loading={updateUser.isLoading}
                    disabled={removeUser.isLoading || !isDirty}
                >
                    <FormattedMessage id="save" />
                </Button>
            </Modal.Footer>
        </>
    );
}

export function EditMobileUser() {
    const {employeeId} = useParams();
    const {data, isLoading, isError} = useGetEmployees();
    const navigate = useNavigate();
    const user = data?.find((user) => user.employeeId === employeeId);

    const handleClose = () => navigate("..");

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static" centered>
                {isError && <FormattedMessage id="cant_load_user" />}
                {isLoading && <FormattedMessage id="loading" />}
                {data && employeeId && user ? (
                    <UserForm {...{user, employeeId}} />
                ) : (
                    <FormattedMessage id="user_doesnt_exists" />
                )}
            </Modal>
        </>
    );
}
