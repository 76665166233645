import {FormattedMessage} from "react-intl";
import {BusinessEmployeeDto} from "../api/apiClient";

export function formatUserPaymentType(paymentOriginator: BusinessEmployeeDto["paymentOriginator"]) {
    switch (paymentOriginator) {
        case "COMPANY":
            return <FormattedMessage id="payment_originator_company" />;
        case "EMPLOYEE":
            return <FormattedMessage id="payment_originator_employee" />;
    }
}
