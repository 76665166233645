import {ChangeEvent, useState} from "react";
import {FormCheck, OverlayTrigger} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {DeliveryRide, DeliveryStop, StopLocation, useCallerCompany} from "../api/apiClient";
import {Button} from "../components/Button";
import {SearchField} from "../components/SearchField";
import chevronIcon from "../img/ic_chevron.svg";
import {EmptyState, ListTitle, NoSearchResults, RideList} from "../rides/layout";
import {formatAddress} from "../rides/ridesModel";
import DeliveryDownload from "./DeliveryDownload";
import {RideItem} from "./DeliveryRideItem";
import {getStoredPickupFilter, setPickupFilterStorage} from "./localStorageModel";

interface DeliveryRidesProps {
    rides: DeliveryRide[];
}

function stopContainsQuery(stop: DeliveryStop, query: string): boolean {
    const stopAddress = formatAddress(stop.destination);
    return [stop.contact.name, stop.contact.phoneNumber, stop.clientStopId, stopAddress].some(
        (value) => value.trim().toLowerCase().includes(query.trim().toLowerCase())
    );
}

function formatPickupFilter(location: StopLocation) {
    return `${location.street}, ${location.city}`;
}

function getPickupFilters(rides: DeliveryRide[]) {
    const pickups = rides.reduce<string[]>((pickups, ride): string[] => {
        const pickupStops = ride.stops.filter((stop) => stop.kind === "PICKUP");
        const pickupPlaces = pickupStops.map((stop) => formatPickupFilter(stop.destination));
        return pickups.concat(pickupPlaces);
    }, []);

    const storedPickupFilter = getStoredPickupFilter();
    if (storedPickupFilter != null) {
        pickups.push(storedPickupFilter);
    }

    const uniquePickups = pickups.filter((value, index, self) => self.indexOf(value) === index);
    return uniquePickups.sort((a, b) => a.localeCompare(b));
}

function getFilteredRides(rides: DeliveryRide[], pickupFilter: string) {
    return rides.filter((ride) =>
        ride.stops.some(
            (stop) =>
                formatPickupFilter(stop.destination).includes(pickupFilter) &&
                stop.kind === "PICKUP"
        )
    );
}

export function DeliveryRidesList({rides}: DeliveryRidesProps) {
    const intl = useIntl();
    const {data: company} = useCallerCompany();
    const isCourierOrder = company?.partnerDeliveryOrderEnabled !== true;
    const [pickupFilter, setPickupFilter] = useState<string | null>(getStoredPickupFilter());
    const [searchQuery, setSearchQuery] = useState("");
    const resetSearch = () => {
        setSearchQuery("");
    };

    const filteredRides = pickupFilter == null ? rides : getFilteredRides(rides, pickupFilter);

    const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
        const filter = event.target.value === "all" ? null : event.target.value;
        setPickupFilter(filter);
        setPickupFilterStorage(filter);
        document.body.click();
    };

    return (
        <RideList>
            <ListTitle>
                <TitleGroup>
                    <h2>
                        <FormattedMessage id="rides" />
                    </h2>
                    {rides.length > 0 && (
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom-start"
                            rootClose={true}
                            overlay={
                                <FilterOverlay>
                                    <div>
                                        <FormattedMessage id="filter_by_pickup" />
                                    </div>
                                    <RadioInputs onChange={handleFilter}>
                                        <FormCheck
                                            id="all"
                                            type="radio"
                                            value="all"
                                            checked={pickupFilter === null}
                                            label={intl.formatMessage({id: "all"})}
                                        />
                                        {getPickupFilters(rides).map((pickup) => (
                                            <FormCheck
                                                id={pickup}
                                                key={pickup}
                                                type="radio"
                                                value={pickup}
                                                checked={pickupFilter === pickup}
                                                label={pickup}
                                            />
                                        ))}
                                    </RadioInputs>
                                </FilterOverlay>
                            }
                        >
                            <Button variant="link">
                                {pickupFilter == null ? (
                                    <FormattedMessage id="filter" />
                                ) : (
                                    <div>
                                        <SelectedFilter>{pickupFilter}</SelectedFilter>
                                        <img src={chevronIcon} alt="" />
                                    </div>
                                )}
                            </Button>
                        </OverlayTrigger>
                    )}
                </TitleGroup>
                {!searchQuery && !isCourierOrder && <DeliveryDownload />}
            </ListTitle>
            {rides.length > 0 && (
                <SearchWrapper>
                    <SearchField
                        {...{searchQuery, setSearchQuery, resetSearch}}
                        placeholder={intl.formatMessage({id: "delivery_search_placeholder"})}
                    />
                </SearchWrapper>
            )}
            {searchQuery.length > 0 ? (
                <SearchResultList rides={filteredRides} searchQuery={searchQuery} />
            ) : (
                <NormalRideList rides={filteredRides} />
            )}
        </RideList>
    );
}

function SearchResultList({rides, searchQuery}: {rides: DeliveryRide[]; searchQuery: string}) {
    const foundRides = rides
        .filter((ride) => ride.stops.some((stop) => stopContainsQuery(stop, searchQuery)))
        .map((ride) => ({
            ...ride,
            stops: ride.stops.filter((stop) => stopContainsQuery(stop, searchQuery)),
        }));

    if (foundRides.length === 0) {
        return (
            <NoSearchResults>
                <FormattedMessage
                    id="no_rides_found"
                    values={{
                        searchQuery: searchQuery,
                    }}
                />
            </NoSearchResults>
        );
    }

    return <>{foundRides.map((ride) => ride && <RideItem key={ride.id} ride={ride} />)}</>;
}

function NormalRideList({rides}: {rides: DeliveryRide[]}) {
    const intl = useIntl();
    const inProgressRides = rides.filter((ride) => ride.statusGroup === "IN_PROGRESS");
    const upcomingRides = rides.filter((ride) => ride.statusGroup === "UPCOMING");
    const completedRides = rides.filter((ride) => ride.statusGroup === "COMPLETED");

    return (
        <>
            <h3>{intl.formatMessage({id: "in_progress"})}</h3>
            {inProgressRides.length > 0 ? (
                inProgressRides.map((ride) => <RideItem key={ride.id} ride={ride} />)
            ) : (
                <EmptyState>{intl.formatMessage({id: "no_rides_in_progress"})}</EmptyState>
            )}
            <h3>{intl.formatMessage({id: "upcoming"})}</h3>
            {upcomingRides.length > 0 ? (
                upcomingRides.map((ride) => <RideItem key={ride.id} ride={ride} />)
            ) : (
                <EmptyState>{intl.formatMessage({id: "no_rides_upcoming"})}</EmptyState>
            )}
            <h3>{intl.formatMessage({id: "completed"})}</h3>
            {completedRides.length > 0 ? (
                completedRides.map((ride) => <RideItem key={ride.id} ride={ride} />)
            ) : (
                <EmptyState>{intl.formatMessage({id: "no_rides_completed"})}</EmptyState>
            )}
        </>
    );
}

const SearchWrapper = styled.div`
    padding-top: var(--spacing-small);
    margin-bottom: var(--spacing-medium);
`;

const FilterOverlay = styled.fieldset`
    background-color: white;
    padding: var(--spacing-medium);
    box-shadow: 0px 4px 8px 0px rgba(228, 228, 228, 1);
    border-radius: var(--border-radius-small);
    z-index: 2;
    overflow: auto;
    max-width: min(300px, 90%);
`;

const RadioInputs = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-tiny);
`;

const TitleGroup = styled.div`
    display: flex;
    align-items: baseline;
`;

const SelectedFilter = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    display: inline-block;
    vertical-align: bottom;
    font-weight: 500;
`;
