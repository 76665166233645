import {useState} from "react";
import {TaxiRide} from "../api/apiClient";
import {DetailWrapper, ListWrapper} from "../rides/layout";
import {TaxiRideDetail} from "./TaxiRideDetail";
import {TaxiRidesList} from "./TaxiRidesList";

export function TaxiRidesPage({rides}: {rides: TaxiRide[]}) {
    const [openedRideId, setOpenedRideId] = useState<string | null>(null);
    const openedRide = rides.find((r) => r.id === openedRideId);
    return (
        <ListWrapper>
            <TaxiRidesList rides={rides} onOpenDetail={setOpenedRideId} />
            {openedRide && (
                <DetailWrapper>
                    <TaxiRideDetail ride={openedRide} onClose={() => setOpenedRideId(null)} />
                </DetailWrapper>
            )}
        </ListWrapper>
    );
}
