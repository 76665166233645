import {LocationSuggestionWithId} from "../types/types";
import {updateLocationCoords} from "../utils/updateLocationCoords";

export async function formikLocationChange(
    name: string,
    location: LocationSuggestionWithId | undefined,
    setFieldlocation: (field: string, location: any, shouldValidate?: boolean | undefined) => void
) {
    if (location != null) {
        setFieldlocation(name, location);
        const updatedLocation = await updateLocationCoords(location);
        setFieldlocation(name, updatedLocation);
    } else {
        setFieldlocation(name, null);
    }
}
