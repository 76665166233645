import {FormattedMessage} from "react-intl";
import {Tier} from "./Tier";

import {useFormikContext} from "formik";
import {useEffect, useRef} from "react";
import styled from "styled-components";
import {TaxiOverviewResponse} from "../api/apiClient";
import {
    formatDropoffArrival,
    formatPreorderDropoffArrival,
    formatPreorderPickupArrival,
    formatShortPreorderDropoffArrival,
} from "../app/dateUtils";
import {FormSection} from "../app/layout";
import {SimpleTitle} from "../components/formTitles";
import {TaxiOrderFormValues} from "./TaxiOrderForm";
import {TierSkeleton} from "./TierSkeleton";

export interface OverviewProps {
    overview: TaxiOverviewResponse | undefined;
    overviewFetching: boolean;
    preorderType: TaxiOrderFormValues["preorderType"];
    pickupTimeType: TaxiOrderFormValues["pickupTimeType"];
    preorderTime: Date | undefined;
}

export interface TiersProps {
    overview: TaxiOverviewResponse;
    overviewFetching: boolean;
    pickupTimeType: TaxiOrderFormValues["pickupTimeType"];
}

function Tiers({overview, overviewFetching, pickupTimeType}: TiersProps) {
    const {setFieldValue, getFieldProps} = useFormikContext<TaxiOrderFormValues>();
    const initialRender = useRef(true);
    const isLater = pickupTimeType === "later";

    const selectFirstAvailableTier = () => {
        setFieldValue(
            "tierId",
            overview.tiers.filter((tier) =>
                isLater ? tier.preOrderAvailability.available : tier.available
            )[0]?.tierId || "TIER_DEFAULT"
        );
    };

    useEffect(() => {
        selectFirstAvailableTier();
        //May be questionable if better to persist for user or risk ordering ASAP order with "wrong" tier
        return () => setFieldValue("tierId", "");
    }, []);

    //Clear flight number field when flight number is not allowed
    useEffect(() => {
        if (!overview.tiers[0]?.preOrderAvailability.flightNumberAllowed) {
            setFieldValue("flightNumber", "");
        }
    }, [overview]);

    //Fallback to first tier, when currently selected tier becomes unavailable
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }

        const availableTiers = overview?.tiers.filter((tier) =>
            isLater ? tier.preOrderAvailability.available : tier.available
        );
        const isSelectedTierAvailable = availableTiers?.some(
            (tier) => tier.tierId === getFieldProps("tierId").value
        );

        if (!isSelectedTierAvailable) {
            selectFirstAvailableTier();
        }
    }, [overview]);

    return (
        <>
            {overview?.tiers.map((tier) => (
                <Tier
                    key={tier.tierId}
                    tier={tier}
                    overviewFetching={overviewFetching}
                    pickupTimeType={pickupTimeType}
                />
            ))}
        </>
    );
}

export function TaxiOverview({
    overview,
    overviewFetching,
    preorderType,
    pickupTimeType,
    preorderTime,
}: OverviewProps) {
    const {getFieldProps} = useFormikContext<TaxiOrderFormValues>();

    const selectedTier = overview?.tiers.find(
        (tier) => tier.tierId === getFieldProps("tierId").value //FIXME: move to prop?
    );

    const getDropoffTime = (arrivalDuration: string, rideDuration: string) => {
        if (pickupTimeType === "later" && preorderTime) {
            return formatPreorderDropoffArrival(preorderTime, rideDuration);
        } else if (pickupTimeType === "15min") {
            return formatShortPreorderDropoffArrival(rideDuration);
        } else {
            return formatDropoffArrival(arrivalDuration, rideDuration);
        }
    };

    function eta() {
        if ((overview == null && overviewFetching) || selectedTier?.arrivalDuration == null) {
            return null;
        }
        if (preorderType === "pickup") {
            return (
                <>
                    {selectedTier != null &&
                        getDropoffTime(
                            selectedTier.arrivalDuration,
                            selectedTier.rideDuration
                        )}{" "}
                    <FormattedMessage id="drop_off_taxi_eta" />
                </>
            );
        }
        if (preorderType === "dropoff" && preorderTime != null) {
            return (
                <>
                    {selectedTier != null &&
                        formatPreorderPickupArrival(preorderTime, selectedTier.rideDuration)}{" "}
                    <FormattedMessage id="pickup_taxi_eta" />
                </>
            );
        }
    }

    return (
        <>
            <FormSection>
                <SimpleTitle>
                    <FormattedMessage id="car_category" />
                </SimpleTitle>
                <Wrapper>
                    {overview ? (
                        <Tiers
                            overview={overview}
                            overviewFetching={overviewFetching}
                            pickupTimeType={pickupTimeType}
                        />
                    ) : (
                        <>
                            <TierSkeleton />
                            <TierSkeleton />
                            <TierSkeleton />
                        </>
                    )}
                </Wrapper>
            </FormSection>
            <DropOffTime style={overviewFetching ? {opacity: 0.6} : {}}>{eta()}</DropOffTime>
        </>
    );
}

const Wrapper = styled.div`
    margin: 0 8px;
`;

const DropOffTime = styled.div`
    margin-top: -4px;
    margin-bottom: 8px;
    text-align: center;
`;
