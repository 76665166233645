import styled from "styled-components";

export const SwitchGroup = styled.div<{small?: boolean}>`
    display: flex;
    background-color: var(--light);
    border-radius: 8px;
    padding: 4px;
    height: ${({small}) => (small ? "36px" : "40px")};
`;

export const SwitchItem = styled.label`
    flex: 1;
    margin: 0;
    cursor: pointer;
    & input {
        opacity: 0;
        width: 1px;
        height: 0px;
        position: absolute;
        &:checked {
            & ~ div {
                background-color: var(--white);
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
                color: var(--primary);
            }
        }
    }
    & div {
        font-weight: var(--font-weight-thin);
        flex: 1;
        color: var(--secondary);
        display: flex;
        line-height: 1;
        align-items: center;
        justify-content: center;
        margin-left: 1px;
        border-radius: 6px;
        gap: var(--spacing-tiny);
        height: 100%;
        font-size: var(--font-size-normal);
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }

    &:has(input:disabled) {
        cursor: not-allowed;
        & div {
            color: var(--tertiary);
        }
    }

    & svg {
        flex-shrink: 0;
        margin-left: -8px;
    }
`;

export const SwitchItemField = styled.label<{selected: boolean}>`
    flex: 1;
    margin: 0;
    cursor: pointer;
    & div {
        font-weight: var(--font-weight-thin);
        flex: 1;
        color: var(--secondary);
        display: flex;
        line-height: 1;
        align-items: center;
        justify-content: center;
        margin-left: 1px;
        border-radius: 6px;
        gap: var(--spacing-tiny);
        height: 100%;
        font-size: var(--font-size-normal);
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
        &:focus-within {
            background-color: var(--white);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
            color: var(--primary);
            &:hover {
                background-color: white;
            }
        }
        ${(p) =>
            p.selected &&
            `
                background-color: var(--white);
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
                color: var(--primary);
            `}
    }

    & input {
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        box-sizing: border-box;
        background-color: transparent;
        border-radius: 0;
        font-weight: var(--font-weight-thin);
        color: var(--brand);
        line-height: 1;
        font-size: var(--font-size-normal);
    }

    & svg {
        flex-shrink: 0;
        margin-left: -8px;
    }
`;
