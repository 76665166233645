import posthog from "posthog-js";
import {ChangeEvent, useState} from "react";
import {Alert} from "react-bootstrap";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {usePayTaxiTip} from "../api/apiClient";
import {queryClient} from "../app/App";
import {Button} from "../components/Button";
import {SwitchGroup, SwitchItem, SwitchItemField} from "../components/Switch";
import { generateUUID } from "../utils/generateUUID";

const TIP_AMOUNTS = {
    CZK: ["15", "25", "50"],
    EUR: ["0.5", "1", "2"],
};

const MAX_TIP = {
    CZK: "5000",
    EUR: "200",
};

export function TaxiTip({rideId, ccy}: {rideId: string; ccy: "CZK" | "EUR"}) {
    const [predefinedAmount, setPredefinedAmount] = useState<string | undefined>(
        TIP_AMOUNTS[ccy][0]
    );
    const [customAmount, setCustomAmount] = useState("");
    const [customSelected, setCustomSelected] = useState(false);
    const pay = usePayTaxiTip();
    const intl = useIntl();

    const tipExceedsMax = parseFloat(customAmount) > parseFloat(MAX_TIP[ccy]);
    const isDisabled =
        (predefinedAmount === undefined && !customAmount) ||
        parseFloat(customAmount) === 0 ||
        tipExceedsMax;

    const handlePredefinedAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPredefinedAmount(e.target.value);
    };

    const handleCustomAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCustomAmount(e.target.value.replace(/[^0-9,.]/g, ""));
    };

    const handleFocus = () => {
        setPredefinedAmount(undefined);
        setCustomSelected(true);
    };

    const handleBlur = () => {
        if (!customAmount) {
            setCustomSelected(false);
            setCustomAmount("");
        }
    };

    const handlePay = () => {
        const amountString = predefinedAmount ?? customAmount;
        const amount = parseFloat(amountString);
        pay.mutate(
            {
                body: {
                    tipPaymentId: generateUUID(),
                    tipAmountType: customSelected ? "CUSTOM" : "FIXED",
                    price: {amount, ccy},
                },
                rideId,
            },
            {
                onSuccess: () => {
                    toast.success(intl.formatMessage({id: "tip_payment_success"}));
                    queryClient.invalidateQueries(["/self-service/rides/taxi"]);
                },
                onError: () => toast.error(intl.formatMessage({id: "tip_payment_failed"})),
            }
        );
        posthog.capture("sendingTip", {type: "taxi"});
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "var(--spacing-small)",
                margin: "16px 0",
            }}
        >
            <FormattedMessage id="tip_for_driver" />
            <SwitchGroup>
                {TIP_AMOUNTS[ccy].map((value) => (
                    <SwitchItem key={value}>
                        <input
                            type="radio"
                            name="tip"
                            value={value}
                            checked={predefinedAmount === value}
                            onChange={handlePredefinedAmountChange}
                        />
                        <div>
                            {value} <FormattedMessage id={ccy} />
                        </div>
                    </SwitchItem>
                ))}
                <SwitchItemField selected={!!customAmount && !predefinedAmount}>
                    <div>
                        <input
                            type="text"
                            inputMode="decimal"
                            value={customAmount}
                            onChange={handleCustomAmountChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            style={{
                                width:
                                    customAmount.length === 0 ? "1px" : `${customAmount.length}ch`,
                                marginTop: "-1px",
                            }}
                        />
                        {customAmount || customSelected ? (
                            <FormattedMessage id={ccy} />
                        ) : (
                            <FormattedMessage id="custom" />
                        )}
                    </div>
                </SwitchItemField>
            </SwitchGroup>
            {tipExceedsMax && (
                <Alert variant="danger">
                    <FormattedMessage id="max_tip_is" /> {MAX_TIP[ccy]}{" "}
                    <FormattedMessage id={ccy} />
                </Alert>
            )}
            <Button
                onClick={handlePay}
                disabled={isDisabled || pay.isLoading}
                loading={pay.isLoading}
                fullWidth
            >
                {isDisabled ? (
                    <FormattedMessage id="enter_the_tip" />
                ) : (
                    <>
                        <FormattedMessage id="tip_the_driver" /> {predefinedAmount || customAmount}{" "}
                        <FormattedMessage id={ccy} />
                    </>
                )}
            </Button>
        </div>
    );
}
