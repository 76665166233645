import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import posthog from "posthog-js";
import {Dispatch, useEffect} from "react";
import {Alert, Modal} from "react-bootstrap";
import {useFieldArray, useForm} from "react-hook-form";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";
import {
    BusinessEmployeeDto,
    useGetBusinessCompany,
    useGetBusinessPaymentInfo,
} from "../../api/apiClient";
import {Button} from "../../components/Button";
import {queryClient} from "../App";
import {getCurrencyByCountry} from "../moneyUtils";
import {UsersTableInteractive, UsersTableNonInteractive} from "./UsersTable";
import {
    AddMobileUserFormValue,
    BulkRequest,
    EditMobileUserFormValue,
    FormValues,
    Segments,
    bulkEmployeeRequest,
    bulkSchema,
} from "./bulkModel";

export function MobileUsersForm({
    usersToAdd,
    usersToModify,
    usersToRemove,
    usersToKeep,
    setSegments,
}: {
    usersToAdd: AddMobileUserFormValue[];
    usersToModify: EditMobileUserFormValue[];
    usersToRemove: EditMobileUserFormValue[];
    usersToKeep: BusinessEmployeeDto[];
    setSegments: Dispatch<React.SetStateAction<Segments | null>>;
}) {
    const {data: company} = useGetBusinessCompany();
    const {data: business} = useGetBusinessPaymentInfo();
    const mutation = useMutation(["bulkRequest"], (variables: BulkRequest) =>
        bulkEmployeeRequest(variables)
    );
    const navigate = useNavigate();
    const handleClose = () => navigate("..", {replace: true});

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
        control,
        trigger,
        setError,
    } = useForm<FormValues>({
        mode: "onBlur",
        resolver: zodResolver(bulkSchema),
        criteriaMode: "all",
        defaultValues: {
            newUsers: [...usersToAdd],
            modifiedUsers: [...usersToModify],
            removedUsers: [...usersToRemove],
        },
    });

    useEffect(() => {
        //Validate all default fields
        trigger();
    }, []);

    const {fields: newUsersFields, remove: removeNewUser} = useFieldArray({
        control,
        name: "newUsers",
    });

    const {fields: modifiedUsersFields, remove: removeModifiedUser} = useFieldArray({
        control,
        name: "modifiedUsers",
    });

    const {fields: removedUsersFields, remove: removeRemovedUser} = useFieldArray({
        control,
        name: "removedUsers",
    });

    if (company == null || business == null) {
        return null;
    }

    const showMonthlyLimit = ["CZ", "SK"].includes(company.billingAddress.country);
    const showPaymentType = business.paymentByEmployeeAllowed;

    const onSubmit = (data: FormValues) => {
        mutation.mutate(
            {
                data,
                ccy: getCurrencyByCountry(company.billingAddress.country),
                remove: {
                    newUser: removeNewUser,
                    modifiedUser: removeModifiedUser,
                    removedUser: removeRemovedUser,
                },
                setError,
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries([`/self-service/business/employees`]);
                    posthog.capture("mobileUsersCsvUploadImported");
                },
            }
        );
    };

    if (usersToModify.length === 0 && usersToAdd.length === 0 && usersToRemove.length === 0) {
        return (
            <Modal.Body
                style={{
                    minHeight: "calc(100vh - 400px)",
                    maxHeight: "calc(100vh - 300px)",
                    overflow: "auto",
                    display: "grid",
                    placeContent: "center",
                    justifyItems: "center",
                    gap: "16px",
                }}
            >
                <svg
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.75 36C6.75 19.845 19.845 6.75 36 6.75C52.155 6.75 65.25 19.845 65.25 36C65.25 52.155 52.155 65.25 36 65.25C19.845 65.25 6.75 52.155 6.75 36ZM46.83 30.558C47.01 30.3182 47.1402 30.0447 47.2131 29.7538C47.2859 29.4629 47.2998 29.1604 47.2541 28.8641C47.2083 28.5677 47.1037 28.2835 46.9466 28.0281C46.7894 27.7727 46.5827 27.5513 46.3388 27.377C46.0948 27.2026 45.8184 27.0787 45.5259 27.0127C45.2334 26.9466 44.9307 26.9397 44.6354 26.9924C44.3402 27.045 44.0585 27.1562 43.8068 27.3192C43.5552 27.4823 43.3387 27.694 43.17 27.942L33.462 41.532L28.59 36.66C28.1635 36.2626 27.5993 36.0462 27.0164 36.0565C26.4335 36.0668 25.8774 36.3029 25.4651 36.7151C25.0529 37.1274 24.8168 37.6835 24.8065 38.2664C24.7962 38.8493 25.0126 39.4135 25.41 39.84L32.16 46.59C32.391 46.8208 32.6694 46.9986 32.976 47.1109C33.2825 47.2233 33.6099 47.2676 33.9353 47.2408C34.2607 47.214 34.5764 47.1166 34.8604 46.9556C35.1444 46.7945 35.39 46.5735 35.58 46.308L46.83 30.558Z"
                        fill="#20955C"
                    />
                </svg>
                <div>
                    <FormattedMessage id="all_users_already_in_your_company" />
                </div>
                <Button variant="outline" onClick={handleClose}>
                    <FormattedMessage id="ok" />
                </Button>
            </Modal.Body>
        );
    }

    if (mutation.isSuccess && mutation.data.resolution === "success") {
        return (
            <Modal.Body
                style={{
                    minHeight: "calc(100vh - 400px)",
                    maxHeight: "calc(100vh - 300px)",
                    overflow: "auto",
                    display: "grid",
                    placeContent: "center",
                    justifyItems: "center",
                    gap: "16px",
                }}
            >
                <svg
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.75 36C6.75 19.845 19.845 6.75 36 6.75C52.155 6.75 65.25 19.845 65.25 36C65.25 52.155 52.155 65.25 36 65.25C19.845 65.25 6.75 52.155 6.75 36ZM46.83 30.558C47.01 30.3182 47.1402 30.0447 47.2131 29.7538C47.2859 29.4629 47.2998 29.1604 47.2541 28.8641C47.2083 28.5677 47.1037 28.2835 46.9466 28.0281C46.7894 27.7727 46.5827 27.5513 46.3388 27.377C46.0948 27.2026 45.8184 27.0787 45.5259 27.0127C45.2334 26.9466 44.9307 26.9397 44.6354 26.9924C44.3402 27.045 44.0585 27.1562 43.8068 27.3192C43.5552 27.4823 43.3387 27.694 43.17 27.942L33.462 41.532L28.59 36.66C28.1635 36.2626 27.5993 36.0462 27.0164 36.0565C26.4335 36.0668 25.8774 36.3029 25.4651 36.7151C25.0529 37.1274 24.8168 37.6835 24.8065 38.2664C24.7962 38.8493 25.0126 39.4135 25.41 39.84L32.16 46.59C32.391 46.8208 32.6694 46.9986 32.976 47.1109C33.2825 47.2233 33.6099 47.2676 33.9353 47.2408C34.2607 47.214 34.5764 47.1166 34.8604 46.9556C35.1444 46.7945 35.39 46.5735 35.58 46.308L46.83 30.558Z"
                        fill="var(--green)"
                    />
                </svg>
                <FormattedMessage id="users_updated_successfully" />
                <p>
                    <FormattedMessage
                        id="users_updated_counts"
                        values={{
                            add: mutation.data.newUsersToRemove.length,
                            modify: mutation.data.modifiedUsersToRemove.length,
                            remove: mutation.data.removedUsersToRemove.length,
                        }}
                    />
                </p>
                <Button variant="outline" onClick={handleClose}>
                    <FormattedMessage id="ok" />
                </Button>
            </Modal.Body>
        );
    }

    return (
        <>
            <form
                id="user"
                onSubmit={handleSubmit(onSubmit)}
                style={{display: "flex", flexDirection: "column"}}
            >
                <Modal.Body
                    style={{
                        minHeight: "calc(100vh - 400px)",
                        maxHeight: "calc(100vh - 300px)",
                        overflow: "auto",
                    }}
                >
                    {mutation.isSuccess && mutation.data.resolution === "partially" && (
                        <>
                            <Alert variant="success">
                                <FormattedMessage id="users_updated_partially" />
                                <p>
                                    <FormattedMessage
                                        id="users_updated_counts"
                                        values={{
                                            add: mutation.data.newUsersToRemove.length,
                                            modify: mutation.data.modifiedUsersToRemove.length,
                                            remove: mutation.data.removedUsersToRemove.length,
                                        }}
                                    />
                                </p>
                            </Alert>
                            <Alert variant="warning">
                                {mutation.data.failedOnlyExisting ? (
                                    <FormattedMessage id="bulk_users_failed_partially_in_another_company" />
                                ) : (
                                    <FormattedMessage id="bulk_users_failed_partially" />
                                )}
                            </Alert>
                        </>
                    )}
                    {mutation.isError ||
                        (mutation.isSuccess && mutation.data.resolution === "failed" && (
                            <Alert variant="danger">
                                {mutation.data.failedOnlyExisting ? (
                                    <FormattedMessage id="bulk_users_failed_invited_in_another_company" />
                                ) : (
                                    <FormattedMessage id="bulk_users_failed" />
                                )}
                            </Alert>
                        ))}
                    <UsersTableInteractive
                        title={<FormattedMessage id="add" />}
                        userFields={newUsersFields}
                        fieldsName="newUsers"
                        register={register}
                        errors={errors}
                        showMonthlyLimit={showMonthlyLimit}
                        showPaymentType={showPaymentType}
                        ccy={getCurrencyByCountry(company.billingAddress.country)}
                        isDisabled={mutation.isLoading}
                    />
                    <UsersTableInteractive
                        title={<FormattedMessage id="modify" />}
                        userFields={modifiedUsersFields}
                        fieldsName="modifiedUsers"
                        register={register}
                        errors={errors}
                        showMonthlyLimit={showMonthlyLimit}
                        showPaymentType={showPaymentType}
                        ccy={getCurrencyByCountry(company.billingAddress.country)}
                        isDisabled={mutation.isLoading}
                    />
                    <UsersTableInteractive
                        title={<FormattedMessage id="remove" />}
                        userFields={removedUsersFields}
                        fieldsName="removedUsers"
                        register={register}
                        errors={errors}
                        showMonthlyLimit={showMonthlyLimit}
                        showPaymentType={showPaymentType}
                        ccy={getCurrencyByCountry(company.billingAddress.country)}
                        isDisabled={mutation.isLoading}
                    />
                    {!mutation.data && (
                        <UsersTableNonInteractive
                            title={<FormattedMessage id="keep" />}
                            users={usersToKeep}
                            showMonthlyLimit={showMonthlyLimit}
                            showPaymentType={showPaymentType}
                            ccy={getCurrencyByCountry(company.billingAddress.country)}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!isValid && (
                        <Alert variant="danger" style={{width: "100%"}}>
                            <FormattedMessage id="there_is_invalid_data_in_users_table" />
                        </Alert>
                    )}
                    <div style={{display: "flex", gap: "8px", width: "100%"}}>
                        <Button
                            variant="outline"
                            onClick={() => setSegments(null)}
                            style={{flexShrink: 0}}
                        >
                            <FormattedMessage id="back" />
                        </Button>
                        {isValid ? (
                            <Button fullWidth type="submit" loading={mutation.isLoading}>
                                <FormattedMessage
                                    id="confirm_users_changes"
                                    values={{
                                        add: newUsersFields.length,
                                        modify: modifiedUsersFields.length,
                                        remove: removedUsersFields.length,
                                    }}
                                />
                            </Button>
                        ) : (
                            <Button fullWidth type="submit" variant="secondary">
                                <FormattedMessage id="go_to_invalid_data" />
                            </Button>
                        )}
                    </div>
                </Modal.Footer>
            </form>
        </>
    );
}
