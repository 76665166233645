import {useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useImpersonateBusiness} from "../api/apiClient";
import {queryClient} from "../app/App";
import {logout, setAccessToken} from "../auth/authModel";

export function Impersonate() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const {businessId} = useParams();
    const impersonate = useImpersonateBusiness();

    useEffect(() => {
        if (businessId) {
            impersonate.mutate(
                {businessId},
                {
                    onSuccess: (data) => {
                        setAccessToken(data.accessToken);
                        queryClient.refetchQueries();
                        navigate(state?.callback || "/", {replace: true});
                    },
                }
            );
        }
    }, []);

    if (impersonate.isError) {
        return (
            <div>
                Error impersonating business.<button onClick={logout}>Sign out</button>
            </div>
        );
    }

    return <div>Loading...</div>;
}
