import {useFormikContext} from "formik";
import posthog from "posthog-js";
import toast from "react-hot-toast";
import {FormattedMessage} from "react-intl";
import {useSearchParams} from "react-router-dom";
import {getLocationSuggestions} from "../api/apiClient";
import {DeliveryOrderFormValues} from "../delivery/deliveryOrderModel";
import {useKeyPress} from "../hooks/useKeyPress";
import {LocationSuggestionWithId} from "../types/types";
import {StopItem} from "./deliveryOrderModel";
import { generateUUID } from "../utils/generateUUID";

type ParamsValues = {
    pickupName?: string;
    pickupPhoneNumber?: string;
    pickupAddress?: string;
    pickupNote?: string;
    dropoffName?: string;
    dropoffPhoneNumber?: string;
    dropoffAddress?: string;
    dropoffNote?: string;
};

export function useSetDeliveryUrl() {
    const [, setSearchParams] = useSearchParams();
    const {values} = useFormikContext<DeliveryOrderFormValues>();
    useKeyPress(68, () => {
        setSearchParams(createParams(values));
        navigator.clipboard.writeText(window.location.href).then(() => {
            toast.success(<FormattedMessage id="copied" />);
        });
    });
}

function createParams(values: DeliveryOrderFormValues): ParamsValues {
    const params = {
        pickupName: values.pickup.name,
        pickupPhoneNumber: values.pickup.phoneNumber,
        pickupAddress: values.pickup.location?.location.address,
        pickupNote: values.pickup.noteForDriver,
        dropoffName: values.handovers[0].name,
        dropoffPhoneNumber: values.handovers[0].phoneNumber,
        dropoffAddress: values.handovers[0].location?.location.address,
        dropoffNote: values.handovers[0].noteForDriver,
    };

    Object.keys(params).forEach(
        (key) =>
            (params[key as keyof typeof params] === undefined ||
                params[key as keyof typeof params] === "") &&
            delete params[key as keyof typeof params]
    );

    // Seems that heavy encode is not needed as setSearchParams already encode impactful chars
    // Object.keys(params).forEach(
    //     (key) =>
    //         (params[key as keyof typeof params] = encodeURIComponent(
    //             params[key as keyof typeof params] || ""
    //         ))
    // );

    return params;
}

export async function parseDeliveryUrlParams(searchParams: URLSearchParams): Promise<StopItem[]> {
    const searchValues: ParamsValues = Object.fromEntries(searchParams.entries());
    if (Object.keys(searchValues).length > 0) {
        posthog.capture("deliveryUrlParamsUsed");
    }

    let pickup: LocationSuggestionWithId | undefined;
    if (searchValues.pickupAddress) {
        const locations = await getLocationSuggestions({
            serviceType: "DELIVERY",
            query: decodeURIComponent(searchValues.pickupAddress),
            addressType: "PICKUP",
            suggestSessionId: generateUUID(),
            tripId: generateUUID(),
        });

        pickup = locations.suggestions[0];
    }
    let dropoff: LocationSuggestionWithId | undefined;
    if (searchValues.dropoffAddress) {
        const locations = await getLocationSuggestions({
            serviceType: "DELIVERY",
            query: decodeURIComponent(searchValues.dropoffAddress),
            addressType: "DESTINATION",
            suggestSessionId: generateUUID(),
            tripId: generateUUID(),
        });

        dropoff = locations.suggestions[0];
    }
    return [
        {
            name: decodeURIComponent(searchValues?.pickupName || ""),
            kind: "PICKUP",
            phoneNumber: decodeURIComponent(searchValues?.pickupPhoneNumber || ""),
            location: pickup,
            noteForDriver: decodeURIComponent(searchValues?.pickupNote || ""),
        },
        {
            name: decodeURIComponent(searchValues?.dropoffName || ""),
            kind: "HANDOVER",
            phoneNumber: decodeURIComponent(searchValues?.dropoffPhoneNumber || ""),
            location: dropoff,
            noteForDriver: decodeURIComponent(searchValues?.dropoffNote || ""),
        },
    ];
}
