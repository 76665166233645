import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {APIProvider} from "@vis.gl/react-google-maps";
import posthog from "posthog-js";
import {PostHogProvider, useFeatureFlagEnabled} from "posthog-js/react";
import {useEffect} from "react";
import {Toaster} from "react-hot-toast";
import {IntlProvider} from "react-intl";
import {
    BrowserRouter,
    Link,
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import {LoginPage} from "../auth/LoginPage";
import {ResetPassword} from "../auth/ResetPassword";
import {SetNewPassword} from "../auth/SetNewPassword";
import {setAccessToken, useUserInfo} from "../auth/authModel";
import {Impersonate} from "../components/Impersonate";
import {DeliveryPage} from "../delivery/DeliveryPage";
import "../index.scss";
import {RoutePlanner} from "../taxi/RoutePlanner";
import {TaxiKiosk} from "../taxi/TaxiKiosk";
import {TaxiPage} from "../taxi/TaxiPage";
import {getLanguage, getMessages} from "../translations";
import {AddMobileUser} from "./AddMobileUser";
import {AddWebUser} from "./AddWebUser";
import {AdminAccess} from "./AdminAccess";
import {Billing} from "./Billing";
import {CompanyDetails} from "./CompanyDetails";
import {Dashboard} from "./Dashboard";
import {EditMobileUser} from "./EditMobileUser";
import {EditWebUser} from "./EditWebUser";
import {Header} from "./Header";
import {MobileUsers} from "./MobileUsers";
import {OnboardingPayment} from "./OnboardingPayment";
import {OrderPageAccess} from "./OrderPageAccess";
import {Registration} from "./Registration";
import ScrollToTop from "./ScrollToTop";
import {Users} from "./Users";
import {WebUsers} from "./WebUsers";
import {BulkMobileUsers} from "./bulk/BulkMobileUsers";
import {Wrapper} from "./layout";

declare var appConfig: any;

export const queryClient = new QueryClient();

function NotFound() {
    return (
        <div style={{display: "grid", placeItems: "center", margin: "32px"}}>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for doesn't exist or has been moved.</p>
            <Link to="/">Go to Home</Link>
        </div>
    );
}

function AuthRoute() {
    const {data: user, isLoading} = useUserInfo();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const taxiKioskEnabled = useFeatureFlagEnabled("taxi-kiosk");

    useEffect(() => {
        const token = searchParams.get("t");
        if (token) {
            localStorage.setItem("accessedViaQR", "1");
            setAccessToken(token);
            queryClient.refetchQueries();
            posthog.reset();
            searchParams.delete("t");
            setSearchParams(searchParams, {replace: true});
        }
    }, []);

    if (isLoading || taxiKioskEnabled == null) return null;

    if (!user) {
        return <Navigate to="/login" state={{from: location}} replace />;
    }

    //FF defaults to false on initial visit, so the Outlet will render anyway briefly
    if (taxiKioskEnabled && !location.pathname.startsWith("/cs/")) {
        return (
            <OrderPageAccess>
                <Routes>
                    <Route path="*" element={<TaxiKiosk />} />
                </Routes>
            </OrderPageAccess>
        );
    }

    return <Outlet />;
}

export function AppContent() {
    let location = useLocation();

    useEffect(() => {
        posthog.capture("$pageview");
    }, [location]);

    // This can be used in Superadmin use-case for updating for most reecent company when switching tabs
    // useEffect(() => {
    //     function handleWindowFocus() {
    //         if (getAccessToken() !== cookie.get("accessToken")) {
    //             window.location.reload();
    //         }
    //     }

    //     window.addEventListener("focus", handleWindowFocus);

    //     return () => {
    //         window.removeEventListener("focus", handleWindowFocus);
    //     };
    // }, []);

    return (
        <Wrapper>
            <Routes>
                <Route path="/*" element={<AuthRoute />}>
                    <Route
                        path="taxi/kiosk"
                        element={
                            <OrderPageAccess>
                                <TaxiKiosk />
                            </OrderPageAccess>
                        }
                    />
                    <Route path="/*" element={<Header />}>
                        <Route index element={<Dashboard />} />
                        <Route path="cs/:businessId" element={<Impersonate />} />
                        <Route
                            path="taxi"
                            element={
                                <OrderPageAccess>
                                    <TaxiPage />
                                </OrderPageAccess>
                            }
                        />
                        <Route
                            path="taxi/routing"
                            element={
                                <OrderPageAccess>
                                    <RoutePlanner />
                                </OrderPageAccess>
                            }
                        />
                        <Route
                            path="delivery"
                            element={
                                <OrderPageAccess>
                                    <DeliveryPage />
                                </OrderPageAccess>
                            }
                        />
                        <Route path="admin" element={<AdminAccess />}>
                            <Route path="users" element={<Users />}>
                                <Route
                                    index
                                    element={<Navigate to="mobile-app" replace={true} />}
                                />
                                <Route path="mobile-app" element={<MobileUsers />}>
                                    <Route path=":employeeId" element={<EditMobileUser />} />
                                    <Route path="new-user" element={<AddMobileUser />} />
                                    <Route path="bulk" element={<BulkMobileUsers />} />
                                </Route>
                                <Route path="web-app" element={<WebUsers />}>
                                    <Route path=":userId" element={<EditWebUser />} />
                                    <Route path="new-user" element={<AddWebUser />} />
                                </Route>
                            </Route>
                            <Route path="billing" element={<Billing />} />
                            <Route path="company" element={<CompanyDetails />} />
                        </Route>
                    </Route>
                    <Route path="onboarding" element={<AdminAccess />}>
                        <Route path="payment" element={<OnboardingPayment />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/reset" element={<ResetPassword />} />
                <Route path="/reset/:token" element={<SetNewPassword />} />
                <Route path="/register" element={<Registration />} />
            </Routes>
        </Wrapper>
    );
}

export function App() {
    return (
        <PostHogProvider
            apiKey={appConfig.posthogKey}
            options={{
                api_host: appConfig.posthogHost,
                autocapture: {
                    dom_event_allowlist: ["click"],
                    element_allowlist: ["a", "button", "select", "label"],
                },
            }}
        >
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <IntlProvider locale={getLanguage()} messages={getMessages()}>
                        <APIProvider apiKey={appConfig.googleMapsKey}>
                            <AppContent />
                            <ScrollToTop />
                            <Toaster />
                        </APIProvider>
                    </IntlProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </BrowserRouter>
        </PostHogProvider>
    );
}
