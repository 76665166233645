import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {useGetCard, useInitPaymentCardEntry} from "../api/apiClient";
import {Button} from "../components/Button";
import {formatCardWithType} from "../utils/formatCard";

export function ExpiredCardBanner() {
    const {data: card, isLoading: cardLoading} = useGetCard();
    const addCard = useInitPaymentCardEntry();
    const intl = useIntl();

    if (cardLoading || card == null) {
        return null;
    }

    function handleAddCard() {
        addCard.mutate(window.location.href, {
            onSuccess: (response) => (window.location.href = response.processUrl),
            onError: () => toast.error(intl.formatMessage({id: "generic_error"})),
        });
    }

    return (
        <Card>
            <div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM12 8.25C12.1989 8.25 12.3897 8.32902 12.5303 8.46967C12.671 8.61032 12.75 8.80109 12.75 9V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75V9C11.25 8.80109 11.329 8.61032 11.4697 8.46967C11.6103 8.32902 11.8011 8.25 12 8.25ZM12 16.5C12.1989 16.5 12.3897 16.421 12.5303 16.2803C12.671 16.1397 12.75 15.9489 12.75 15.75C12.75 15.5511 12.671 15.3603 12.5303 15.2197C12.3897 15.079 12.1989 15 12 15C11.8011 15 11.6103 15.079 11.4697 15.2197C11.329 15.3603 11.25 15.5511 11.25 15.75C11.25 15.9489 11.329 16.1397 11.4697 16.2803C11.6103 16.421 11.8011 16.5 12 16.5Z"
                        fill="#DD2822"
                    />
                </svg>
            </div>
            <CardContent>
                <h2>
                    <FormattedMessage id="expired_card_headline" />
                </h2>
                <Text>
                    <FormattedMessage id="expired_card_text" />
                </Text>
                <Action>
                    <Button variant="outline" onClick={handleAddCard} loading={addCard.isLoading}>
                        <FormattedMessage id="add_payment_card" />
                    </Button>
                    <Info>
                        <FormattedMessage id="expired_card" />: {formatCardWithType(card)}
                    </Info>
                </Action>
            </CardContent>
        </Card>
    );
}

const Card = styled.div`
    display: flex;
    gap: 16px;
    border-radius: 16px;
    padding: 24px 20px;
    margin-bottom: 24px;
    border: 1px solid #fcd8d5;
    box-shadow: #f7e4e3 0px 1px 1px 0px;
    background: linear-gradient(175deg, #fbede9 0%, #fefbfb 50%);
`;

const Text = styled.p`
    color: var(--secondary);
    font-weight: 400;
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Action = styled.div`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 8px;
`;

const Info = styled.div`
    color: var(--secondary);
    font-weight: 400;
`;
