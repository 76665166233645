import {ReactNode} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import styled from "styled-components";

export function ErrorIconLabel({children}: {children: ReactNode}) {
    return (
        <ErrorLabel>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="error">{children}</Tooltip>}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    height="20"
                    width="20"
                    fill="var(--red)"
                    className="w-5 h-5"
                >
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                    />
                </svg>
            </OverlayTrigger>
        </ErrorLabel>
    );
}

const ErrorLabel = styled.div`
    position: absolute;
    margin-bottom: auto;
    margin-top: auto;
    top: 0;
    bottom: 0;
    right: 8px;
    display: flex;
    align-items: center;
`;
