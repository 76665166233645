import {useEffect, useState} from "react";
import {cancelStop, DeliveryRide} from "../api/apiClient";
import {useOpenDetail} from "./OpenDetailContext";
import {StopDetailView} from "./DeliveryStopDetailView";

export interface StopDetailProps {
    ride: DeliveryRide;
    stopId: string;
}

export type CancelState = "NONE" | "LOADING" | "SUCCESS" | "FAILED";

export function StopDetail({ride, stopId}: StopDetailProps) {
    const openDetail = useOpenDetail();
    const stop = ride.stops.find((stop) => stop.id === stopId)!;

    const [cancelState, setCancelState] = useState<CancelState>("NONE");

    useEffect(() => {
        setCancelState("NONE");
    }, [stop.status, ride.id, stopId]);

    function onCancelStop() {
        setCancelState("LOADING");
        cancelStop(ride.id, stopId)
            .then(() => {
                setCancelState("SUCCESS");
            })
            .catch(() => {
                setCancelState("FAILED");
            });
    }

    function openRideDetail() {
        openDetail({type: "ride", rideId: ride.id});
    }

    return (
        <StopDetailView
            ride={ride}
            stop={stop}
            cancelState={cancelState}
            onCancel={onCancelStop}
            onOpenRideDetail={openRideDetail}
        />
    );
}
