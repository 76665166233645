import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100%;
`;

export const Content = styled.div`
    display: flex;
    height: calc(100% - var(--navigation-height));
    width: 100%;
`;

export const PageContentWrapper = styled.div`
    flex: 1 0 auto;
    height: 100%;
    overflow: auto;
    @media (max-width: 650px) {
        height: calc(100% - var(--tabbar-height) - env(safe-area-inset-bottom));
    }
`;

export const TabView = styled.label`
    display: none;
    @media (max-width: 650px) {
        display: grid;
        justify-content: center;
        align-content: center;
        position: fixed;
        width: 50%;
        height: var(--tabbar-height);
        bottom: 0;
        margin: 0;
        background-color: white;
        border-top: 1px solid var(--light);
        text-align: center;
        padding: 8px;
        z-index: 50;
        color: var(--secondary);
        font-size: 16px;
        margin-bottom: env(safe-area-inset-bottom);

        &:hover {
            background-color: var(--light);
            cursor: pointer;
        }
    }
`;

export const TabLeftView = styled(TabView)`
    left: 0;
`;

export const TabRightView = styled(TabView)`
    right: 0;
`;

export const Tab = styled.input`
    &:checked + label {
        color: inherit;
        box-shadow: inset 0 2px var(--primary);
    }
`;

export const TabLeft = styled(Tab)`
    @media (max-width: 650px) {
        &:checked ~ div > div:nth-child(2) {
            display: none;
        }
    }
`;

export const TabRight = styled(Tab)`
    @media (max-width: 650px) {
        &:checked ~ div > div:nth-child(1) {
            display: none;
        }
    }
`;

export const FormWrapper = styled.div`
    padding: 16px;
    margin: 0 auto 24px auto;
    width: 350px;

    input[type="text"] {
        color: var(--color-primary);
        border: 0.5px solid transparent;

        :hover {
            background-color: #f0f3f7;
        }

        :focus {
            color: var(--color-primary);
            background-color: #ffffff;
            outline: none;
            box-shadow: none;
            border: 0.5px solid #d6dbe2;
        }

        ::placeholder {
            color: #8c9cb0;
        }
    }
`;

export const FormTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`;

export const FormSection = styled.div`
    padding: 12px 8px;
    box-shadow: 0 0 0 1px rgba(0, 34, 80, 0.05), 0 1px 3px 0 rgba(0, 34, 80, 0.15);
    border-radius: var(--border-radius);
    margin-bottom: 16px;
    position: relative;
`;

export const EstimatesWrapper = styled.div`
    background-color: var(--light);
    display: grid;
    grid-template-columns: repeat(3, auto);
    padding: 12px 16px;
    justify-content: space-between;
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-medium);
`;
