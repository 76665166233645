export const EXPRESS_MAX_DROPOFFS = 10;
export const SLOT_MAX_DROPOFFS = 100;
export const DELIVERY_EXPRESS_PREORDER_TIER_ID = "TIER_DELIVERY_EXPRESS_PREORDER";

export const emailRegex = /^[\w+-]+(?:\.[\w+-]+)*@[\da-z]+(?:[.-][\da-z]+)*\.[a-z]{2,}$/i;

export const countries = [
    "CZ",
    "SK",
    "AD",
    "AT",
    "AU",
    "BE",
    "BG",
    "BR",
    "CA",
    "CH",
    "CN",
    "CY",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GR",
    "HK",
    "HR",
    "HU",
    "ID",
    "IE",
    "IS",
    "IT",
    "JP",
    "KR",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "ME",
    "MT",
    "MX",
    "MY",
    "NL",
    "NO",
    "NZ",
    "PL",
    "PT",
    "RO",
    "RU",
    "SE",
    "SG",
    "SI",
    "SM",
    "TH",
    "TR",
    "US",
    "ZA",
];

export const gregorian_cs = {
    name: "gregorian_cs_lowercase",
    months: [
        ["leden", "led"],
        ["únor", "úno"],
        ["březen", "bře"],
        ["duber", "dub"],
        ["květen", "kvě"],
        ["červen", "čer"],
        ["červenec", "čer"],
        ["srpen", "srp"],
        ["září", "zář"],
        ["říjen", "říj"],
        ["listopad", "list"],
        ["prosince", "pro"],
    ],
    weekDays: [
        ["sobota", "so"],
        ["neděle", "ne"],
        ["pondělí", "po"],
        ["úterý", "út"],
        ["středa", "st"],
        ["čtvrtek", "čt"],
        ["pátek", "pá"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
    ],
};
