import {zodResolver} from "@hookform/resolvers/zod";
import axios from "axios";
import {isValidPhoneNumber} from "libphonenumber-js";
import {Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import {z} from "zod";
import {
    BusinessUserDto,
    useGetUsers,
    useRemoveUserFromBusiness,
    useUpdateUser,
} from "../api/apiClient";
import {Button} from "../components/Button";
import {PhoneInputReactHookForm} from "../components/PhoneInputReactHookForm";
import closeIcon from "../img/ic_close.svg";
import {clearSpaces} from "../utils/formatString";
import {queryClient} from "./App";
import {Error, Input, Label, Select} from "./Registration";

function transformToRole(roles: BusinessUserDto["roles"]) {
    if (roles.includes("BUSINESS_ADMIN")) {
        return "BUSINESS_ADMIN";
    }
    return "BUSINESS_ORDERER";
}

const schema = z.object({
    name: z.string().min(1, {message: "required"}),
    email: z.string(),
    phoneNumber: z
        .string()
        .min(1, {message: "required"})
        .refine((value) => isValidPhoneNumber(value), {message: "invalid_phone_number"}),
    role: z.string().min(1, {message: "required"}),
});

type UserFormValue = z.infer<typeof schema>;

export function UserForm({user, userId}: {user: BusinessUserDto; userId: string}) {
    const navigate = useNavigate();
    const removeUser = useRemoveUserFromBusiness();
    const updateUser = useUpdateUser();
    const intl = useIntl();

    const handleClose = () => navigate("..", {replace: true});

    function handleRemoveUser(userId: string) {
        removeUser.mutate(userId, {
            onSuccess: () => {
                toast.success(intl.formatMessage({id: "user_removed"}));
                queryClient.invalidateQueries(["/self-service/business/users"]);
                handleClose();
            },
            onError: (error) => {
                if (axios.isAxiosError(error)) {
                    if (error.response?.data.code === "cannot_remove_last_admin") {
                        toast.error(intl.formatMessage({id: "cannot_remove_last_admin"}));
                    }
                } else {
                    toast.error(intl.formatMessage({id: "generic_error"}));
                }
            },
        });
    }

    const {
        register,
        handleSubmit,
        control,
        formState: {errors, isDirty},
    } = useForm<UserFormValue>({
        defaultValues: {...user, role: transformToRole(user.roles)},
        mode: "onTouched",
        resolver: zodResolver(schema),
        criteriaMode: "all",
    });

    async function onSubmit(data: UserFormValue) {
        updateUser.mutate(
            {
                body: {
                    name: data.name.trim(),
                    phoneNumber: clearSpaces(data.phoneNumber),
                    roles:
                        data.role === "BUSINESS_ORDERER"
                            ? ["BUSINESS_ORDERER"]
                            : ["BUSINESS_ADMIN", "BUSINESS_ORDERER"],
                },
                userId: userId,
            },
            {
                onSuccess: () => {
                    toast.success(intl.formatMessage({id: "saved"}));
                    queryClient.invalidateQueries(["/self-service/business/users"]);
                    navigate("..", {replace: true});
                },
                onError: () => toast.error(intl.formatMessage({id: "generic_error"})),
            }
        );
    }
    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="edit_user" />
                </Modal.Title>
                <Button
                    variant="plain"
                    onClick={handleClose}
                    disabled={updateUser.isLoading || removeUser.isLoading}
                >
                    <img src={closeIcon} alt="Close dialog" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <form
                    id="user"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{display: "flex", flexDirection: "column"}}
                >
                    <Label htmlFor="name">
                        <FormattedMessage id="name" />
                    </Label>
                    <Input type="text" {...register("name")} />
                    {errors.name?.message && (
                        <Error>{intl.formatMessage({id: errors.name.message})}</Error>
                    )}
                    <Label htmlFor="email">
                        <FormattedMessage id="email" />
                    </Label>
                    <Input type="email" {...register("email")} disabled />
                    <Label htmlFor="phoneNumber">
                        <FormattedMessage id="phone_number" />
                    </Label>
                    <PhoneInputReactHookForm control={control} name="phoneNumber" />
                    {errors.phoneNumber?.message && (
                        <Error>{intl.formatMessage({id: errors.phoneNumber.message})}</Error>
                    )}
                    <Label htmlFor="role">
                        <FormattedMessage id="role" />
                    </Label>
                    <Select autoComplete="nope" {...register("role")}>
                        <option value="BUSINESS_ORDERER">
                            {intl.formatMessage({id: "orderer"})}
                        </option>
                        <option value="BUSINESS_ADMIN">{intl.formatMessage({id: "admin"})}</option>
                    </Select>
                    {errors.role?.message && (
                        <Error>{intl.formatMessage({id: errors.role.message})}</Error>
                    )}
                </form>
            </Modal.Body>

            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button
                    data-cy="remove-user-btn"
                    variant="destructive"
                    onClick={() => handleRemoveUser(userId)}
                    loading={removeUser.isLoading}
                    disabled={updateUser.isLoading}
                >
                    <FormattedMessage id="remove_user" />
                </Button>
                <Button
                    data-cy="submit-user-btn"
                    type="submit"
                    form="user"
                    onClick={handleSubmit(onSubmit)}
                    variant="primary"
                    loading={updateUser.isLoading}
                    disabled={removeUser.isLoading || !isDirty}
                >
                    <FormattedMessage id="save" />
                </Button>
            </Modal.Footer>
        </>
    );
}

export function EditWebUser() {
    const {userId} = useParams();
    const {data, isLoading, isError} = useGetUsers();
    const navigate = useNavigate();
    const user = data?.find((user) => user.userId === userId);

    const handleClose = () => navigate("..");

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static" centered>
                {isError && <FormattedMessage id="cant_load_user" />}
                {isLoading && <FormattedMessage id="loading" />}
                {data && userId && user ? (
                    <UserForm {...{user, userId}} />
                ) : (
                    <FormattedMessage id="user_doesnt_exists" />
                )}
            </Modal>
        </>
    );
}
