import {useState} from "react";
import {toast} from "react-hot-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {exportLegsToExcel} from "../api/apiClient";
import {Button} from "../components/Button";

const DeliveryDownload = () => {
    const intl = useIntl();
    const [downloading, setDownloading] = useState<boolean>(false);

    const downloadFile = () => {
        setDownloading(true);
        exportLegsToExcel()
            .then((response) => {
                let filename = "";
                const disposition = response.request.getResponseHeader("Content-Disposition");
                if (disposition && disposition.indexOf("attachment") !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, "");
                    }
                }

                const url = URL.createObjectURL(response.data);

                const a = document.createElement("a");
                a.href = url;
                a.download = filename;
                a.click();

                URL.revokeObjectURL(url);

                setDownloading(false);
            })
            .catch(() => {
                toast.error(intl.formatMessage({id: "download_failed"}));
                setDownloading(false);
            });
    };

    return (
        <Button variant="link" onClick={downloadFile} loading={downloading} style={{padding: 0}}>
            <FormattedMessage id="download" />
        </Button>
    );
};

export default DeliveryDownload;
